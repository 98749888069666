import React, { Component } from 'react'
import MaterialTable from 'material-table';
import Service from "../Networking/networkutils";
import { LoopCircleLoading } from 'react-loadingg';
import { getCookie } from '../Helper/CookieHelper';
import { withSnackbar } from 'notistack';
import ReactGA from 'react-ga';
import { errorMessage } from '../Helper/ErrorMessage';
import moment from 'moment';
import { getPrimaryColor } from '../Helper/CommonThings';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';

const token = getCookie("bb_ts_token");
const access = JSON.parse(getCookie("bb_ts_access"));
const user = JSON.parse(getCookie("bb_ts_user"));

class MCRTimeline extends Component {

    constructor(props) {
        super()

        this.state = {
            data: [],
            isLoading: true,
        }
    }

    componentDidMount() {
        this.fetchOps();
    }

    fetchOps() {

        var pageView = window.location.pathname + "/#MCR Timeline";
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);

        Service.get('/bb/api/mcr/timeline/?year=' + this.props.value, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    element.before_date_format = moment(element.before_date, 'YYYY-MM-DD').toDate()
                    element.report_till_format = moment(element.report_till, 'YYYY-MM-DD').toDate()
                }
                this.setState({ isLoading: false, data: res.data, })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    postOps(data) {

        var pageView = window.location.pathname + "/#MCR Timeline";
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);
        data.before_date = moment(data.before_date_format).format('YYYY-MM-DD')
        data.report_till = moment(data.report_till_format).format('YYYY-MM-DD')
        data.year = this.props.value
        data.created_by = user.id

        Service.post('/bb/api/mcr/timeline/', {
            headers: {
                Authorization: "Token " + token,
            },
            data: data,
        })
            .then(res => {
                this.setState({ isLoading: true, })
                this.fetchOps();
                this.props.enqueueSnackbar('Timeline added successfully', {
                    variant: 'success'
                });
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    putOps(data) {

        var pageView = window.location.pathname + "/#Rate Card Template";
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);
        data.before_date = moment(data.before_date_format).format('YYYY-MM-DD')
        data.report_till = moment(data.report_till_format).format('YYYY-MM-DD')
        Service.put('/bb/api/mcr/timeline/' + data.id + "/", {
            headers: {
                Authorization: "Token " + token,
            },
            data: data,
        })
            .then(res => {
                this.props.enqueueSnackbar('Designation updated successfully', {
                    variant: 'success'
                });
            })
            .catch(error => {
                this.setState({ isLoading: true, })
                errorMessage(this.props, error)
                this.fetchOps();
            });
    }

    deleteData(data) {

        var pageView = window.location.pathname + "/#Rate Card Template";
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);

        Service.delete('/bb/api/mcr/timeline/' + data.id + "/", {
            headers: {
                Authorization: "Token " + token,
            },

        })
            .then(res => {
                this.props.enqueueSnackbar('Designation deleted successfully', {
                    variant: 'success'
                });
            })
            .catch(error => {
                this.setState({ isLoading: true, })
                errorMessage(this.props, error)
                this.fetchOps();
            });
    }

    render() {
        return (
            <div>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                    <div>
                        <MaterialTable
                            key={3}
                            title="MCR Timeline"
                            columns={[
                                { title: 'Name', field: 'name', },
                                { title: 'After', field: 'before_date_format', type: 'date' },
                                { title: 'Report till', field: 'report_till_format', type: 'date' },
                            ]}
                            options={{
                                padding: "dense",
                                pageSize: 10,
                                pageSizeOptions: [10, 25, 50],
                                paging: false,
                                addRowPosition: "first",
                            }}
                            icons={{
                                Add: props => <Button size='small' color='primary' variant="contained" startIcon={<AddIcon />}>
                                    Add MCR Timeline
                                </Button>
                            }}
                            data={this.state.data}
                            editable={(access && access.access && access.access.l21a && access.access.l21a.l21a_b) ? (
                                {
                                    onRowAdd: newData =>
                                        new Promise(resolve => {

                                            setTimeout(() => {
                                                resolve();
                                                this.setState(prevState => {
                                                    const data = [...prevState.data];
                                                    newData.created_by = user.email;
                                                    this.postOps(newData);
                                                    data.push(newData);
                                                    // this.postData(newData)
                                                    return { ...prevState, data };
                                                });
                                            }, 600);
                                        }),
                                    onRowUpdate: (newData, oldData) =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                if (oldData) {
                                                    this.setState(prevState => {
                                                        const data = [...prevState.data];
                                                        data[data.indexOf(oldData)] = newData;
                                                        this.putOps(newData)
                                                        return { ...prevState, data };
                                                    });
                                                }
                                            }, 600);
                                        }),
                                    onRowDelete: oldData =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                this.setState({ saved: false });
                                                this.setState(prevState => {
                                                    const data = [...prevState.data];
                                                    data.splice(data.indexOf(oldData), 1);
                                                    this.deleteData(oldData)
                                                    return { ...prevState, data };
                                                });
                                            }, 600);
                                        }),
                                }
                            ) : (null)}
                        />
                    </div>
                )}
            </div>
        )
    }
}

export default withSnackbar(MCRTimeline);