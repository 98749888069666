import React, { Component } from 'react';
import { LoopCircleLoading } from 'react-loadingg';
import { getDarkMode, getPrimaryColor } from '../Helper/CommonThings';
import { Box, Card, CardContent, CircularProgress, Divider, Grid, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, } from '@mui/material';
import Service from '../Networking/networkutils';
import { errorMessage } from '../Helper/ErrorMessage';
import { getCookie } from '../Helper/CookieHelper';
import { screenSizeCheck } from '../MainDashBoard/ScreensizeCheck/ScreensizeCheck';
import SearchIcon from '@mui/icons-material/Search';
import { withSnackbar } from 'notistack';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Link, } from '@material-ui/core';
import MaterialTable from 'material-table';
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import AddIcon from '@mui/icons-material/Add';
import AllocateRole from './AllocateRole';
import AllocateUser from './AllocateUser';
import FindUserByRole from '../FindUserByRole/FindUserByRole';

const token = getCookie("bb_ts_token");
const access = JSON.parse(getCookie("bb_ts_access"));

var fontSize = screenSizeCheck() ? '11px' : '12px';
class Allocation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            data: [],
            rolesSearch: "",
            usersSearch: "",
            copyData: [],
            details: null,
            saveDisabled: false,
            historyData: [],
            openHistory: false,
            openAddRole: false,
            openAddUser: false,
            disableDiscard: true,
            searchUserRoles: false,
            roleAllocHrs: null
        };
        this.addRoleClose = this.addRoleClose.bind(this);
        this.addUserClose = this.addUserClose.bind(this);
    }

    componentDidMount() {
        this.fetchRoles();
    }

    fetchRoles() {
        this.setState({ isLoading: true })
        Service.get(`/bb/api/tender/user/task/get_task_role_details/?task_id=${this.props.value.id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                let data = res.data.roles
                for (let i = 0; i < data.length; i++) {
                    const element = data[i];
                    element.isLoading = true;
                    element.copy_hours_at_completion = element.hours_at_completion;
                    element.copy_remark = element.remark;
                }
                this.setState({
                    data: data,
                    copyData: JSON.parse(JSON.stringify(data)),
                    isLoading: false,
                    details: res.data
                }, () => {
                    this.fetchUsers()
                })
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    fetchUsers() {
        Service.get(`/bb/api/tender/user/task/get_task_allocations/?task_id=${this.props.value.id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                res.data = res.data.map((item) => {
                    item.copy_planed_hours = item.planed_hours;
                    item.dynamic_allocation_cost = item.allocation_cost
                    return item
                })
                let data = this.state.data;
                for (let i = 0; i < data.length; i++) {
                    data[i].users = res.data.filter(user => user.task_role_id === data[i].id);
                    data[i].copy_users = res.data.filter(user => user.task_role_id === data[i].id);
                    data[i].isLoading = false;
                    this.setState({ data: data, copyData: data });
                }

                this.setState({
                    data: data.sort((a, b) => b.users.length - a.users.length),
                    copyData: data.sort((a, b) => b.users.length - a.users.length),
                }, () => {
                    this.getDynamicRoleData()
                    let roleAllocHrs = {}
                    for (let i = 0; i < data.length; i++) {
                        const element = data[i];
                        roleAllocHrs[element.t_role_id] = element.allocated_or_planned_hours
                    }
                    this.setState({ roleAllocHrs: roleAllocHrs })
                })
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    rolesSearchChange = (value) => {
        // let data = (this.state.data.length === 0 ? this.state.copyData : this.state.data);
        // let search = value.toLowerCase();
        // let filteredData = data.filter(item => item.name.toLowerCase().includes(search));
        // this.setState({ data: filteredData, rolesSearch: value }, () => {
        //     if (value === "") {
        //         this.setState({ data: this.state.copyData })
        //     }
        // });
        this.setState({ rolesSearch: value })
    }

    usersSearchChange = (value) => {
        // let data = (this.state.data.length === 0 ? this.state.copyData : this.state.data);
        // let search = value.toLowerCase();
        // let filteredData = []
        // filteredData = data.map((item, i) => {
        //     let users = []
        //     let check_users = item.users.length === 0 ? item.copy_users : item.users
        //     users = check_users.filter(user => user.first_name.toLowerCase().includes(search) || user.last_name.toLowerCase().includes(search))
        //     return { ...item, users: users }
        // }
        // );
        // filteredData = filteredData.sort((a, b) => b.users.length - a.users.length)
        // this.setState({ data: filteredData, usersSearch: value }, () => {
        //     if (value === "") {
        //         let data = this.state.data
        //         for (let i = 0; i < data.length; i++) {
        //             const element = data[i];
        //             element.users = element.copy_users
        //         }
        //         this.setState({ data: data.sort((a, b) => b.users.length - a.users.length) })
        //     }
        // });
        this.setState({ usersSearch: value })
    }

    hrsAtComplChange = (e, row) => {
        let data = this.state.data;
        let i = data.findIndex(item => item.id === row.id);
        data[i].hours_at_completion = e.target.valueAsNumber;
        this.setState({ data: data, disableDiscard: false }, () => {
            this.hrsAtComplCheck(i)
        });
    }

    hrsAtComplCheck = (i) => {
        let data = this.state.data;
        let value = !data[i].hours_at_completion ? 0 : data[i].hours_at_completion;
        let total_user_hours = data[i].users.reduce((acc, user) => acc + (!user.planed_hours ? 0 : user.planed_hours), 0);
        if (total_user_hours > value) {
            let sum = total_user_hours - value
            data[i].error = true
            data[i].helperText = <Link style={{ cursor: "pointer" }} color='inherit' underline='always' onClick={() => this.addExtraHours(i, sum)} >{"+ " + sum + " hours needed"}</Link>
            this.setState({ saveDisabled: false, data }, () => {
                this.getDynamicRoleData()
            })
        } else {
            data[i].error = false
            data[i].helperText = ""
            for (let j = 0; j < data[i].users.length; j++) {
                const user = data[i].users[j];
                if (user) {
                    // user.error = false
                    // user.helperText = ""
                    this.allocHrsChangeCheck(i, j)
                }
            }
            this.setState({ saveDisabled: false, data }, () => {
                this.getDynamicRoleData()
            })
        }
    }

    getDynamicRoleData = () => {
        let details = this.state.details;
        let data = this.state.data;
        let dynamic_hours = 0;
        let dynamic_cost = 0;

        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            let formula = element.hours_at_completion * element.rate
            if (element) {
                dynamic_hours += !element.hours_at_completion ? 0 : element.hours_at_completion
                dynamic_cost += !formula ? 0 : formula
            }
        }
        details.dynamic_revised_hours = dynamic_hours
        details.dynamic_cost_at_completion = dynamic_cost
        this.setState({ details: details })
    }

    getDynamicCost = (i, j) => {
        // debugger
        let data = this.state.data;
        let difference = 0
        difference = (!data[i].users[j].planed_hours ? 0 : data[i].users[j].planed_hours) - data[i].users[j].copy_planed_hours
        let formula = difference * data[i].users[j].balance_hours_rate
        data[i].users[j].dynamic_allocation_cost = data[i].users[j].allocation_cost + (formula)
        this.setState({ data: data })
    }

    remarkChange = (e, row) => {
        let data = this.state.data;
        let i = data.findIndex(item => item.id === row.id);
        data[i].remark = e.target.value;
        this.setState({ data: data, disableDiscard: false });
    }

    allocHoursChange = (e, row, value) => {
        let data = this.state.data;
        let i = data.findIndex(item => item.id === row.id);
        let j = data[i].users.findIndex(item => item.user_id === value.user_id);
        data[i].users[j].planed_hours = e.target.valueAsNumber;
        this.setState({ data: data, disableDiscard: false }, () => {
            this.allocHrsChangeCheck(i, j)
        });
    }

    allocHrsChangeCheck = (i, j) => {
        let data = this.state.data;
        let selcted_user_id = data[i].users[j].user_id;
        let total_user_hours = data[i].users.reduce((acc, user) => acc + (!user.planed_hours ? 0 : user.planed_hours), 0);
        let users_actual_inprogress = data[i].users[j].act_and_inprogress;
        let value = !data[i].hours_at_completion ? 0 : data[i].hours_at_completion;

        if (total_user_hours > value) {

            let sum = total_user_hours - value
            data[i].error = true
            data[i].helperText = <Link style={{ cursor: "pointer" }} color='inherit' underline='always' onClick={() => this.addExtraHours(i, sum)} >{"+ " + sum + " hours needed"}</Link>
            data[i].users[j].error = true
            data[i].users[j].helperText = ""
            this.setState({ saveDisabled: true, data: data }, () => {
                this.getDynamicCost(i, j)
            })
        } else {
            data[i].error = false
            data[i].helperText = ""
            data[i].users[j].error = false
            data[i].users[j].helperText = ""
            this.setState({ data: data, saveDisabled: false }, () => {
                this.getDynamicCost(i, j)
            })
        }

        if (this.actInProgressCheck(users_actual_inprogress, selcted_user_id)) {
            let total_sel_allo_hrs = 0

            for (let k = 0; k < data.length; k++) {
                const element = data[k];
                for (let l = 0; l < element.users.length; l++) {
                    const emp = element.users[l];
                    if (emp.user_id === selcted_user_id) {
                        total_sel_allo_hrs += (!emp.planed_hours ? 0 : emp.planed_hours)
                    }
                }
            }

            for (let k = 0; k < data.length; k++) {
                const element = data[k];
                for (let l = 0; l < element.users.length; l++) {
                    const emp = element.users[l];
                    let sum = emp.act_and_inprogress - total_sel_allo_hrs;
                    if (emp.user_id === selcted_user_id) {
                        emp.error = true
                        emp.helperText = <Link style={{ cursor: "pointer" }} color='inherit' underline='always' onClick={() => this.addAllocatedHours(k, l, sum)} >{"+ " + sum + " hours needed"}</Link>
                    }
                }
            }
            this.setState({ data: data, saveDisabled: true }, () => {
                this.getDynamicCost(i, j)
            })
        } else {
            for (let k = 0; k < data.length; k++) {
                const element = data[k];
                for (let l = 0; l < element.users.length; l++) {
                    const emp = element.users[l];
                    if (emp.user_id === selcted_user_id) {
                        emp.error = false
                        emp.helperText = ""
                    }
                }
            }
            this.setState({ data: data, saveDisabled: false }, () => {
                this.getDynamicCost(i, j)
            })
        }
    }

    actInProgressCheck = (users_actual_inprogress, selcted_user_id) => {
        let copy_data = this.state.copyData;

        let selected_user_overall_planned_hours = copy_data.map((item) => {
            return item.users.filter(user => user.user_id === selcted_user_id).reduce((acc, user) => acc + user.planed_hours, 0)
        });

        let sum = 0;
        for (let i = 0; i < selected_user_overall_planned_hours.length; i++) {
            const element = selected_user_overall_planned_hours[i];
            if (element) {
                sum += element
            }
        }
        if (users_actual_inprogress > sum) {
            return true
        }
        return false
    }

    addExtraHours = (i, sum) => {
        let data = this.state.data;
        data[i].hours_at_completion = !data[i].hours_at_completion ? (0 + sum) : (data[i].hours_at_completion + sum);
        data[i].error = false
        data[i].helperText = ""
        for (let k = 0; k < data.length; k++) {
            const element = data[k];
            for (let l = 0; l < element.users.length; l++) {
                const emp = element.users[l];
                if (emp) {
                    emp.error = false
                    emp.helperText = ""
                }
            }
        }
        this.setState({ data: data, saveDisabled: false, disableDiscard: false }, () => {
            this.getDynamicRoleData()
        })
    }

    addAllocatedHours = (k, l, sum) => {
        let data = this.state.data;
        // data[k].users[l].planed_hours += sum;
        data[k].users[l].planed_hours = !data[k].users[l].planed_hours ? (0 + sum) : (data[k].users[l].planed_hours + sum);
        data[k].users[l].error = false
        data[k].users[l].helperText = ""
        let user_id = data[k].users[l].user_id;
        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            for (let j = 0; j < element.users.length; j++) {
                const emp = element.users[j];
                if (emp.user_id === user_id) {
                    emp.error = false
                    emp.helperText = ""
                }
            }
        }
        this.setState({ data: data, saveDisabled: false, disableDiscard: false }, () => {
            this.getDynamicCost(k, l)
            this.hrsAtComplCheck(k)
        })
    }

    userDelete = (item) => {
        Service.delete('/bb/api/tender/user/task/delete_user_task/?user_task=' + item.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.props.enqueueSnackbar('Deleted Successfully!', {
                    variant: 'success'
                });
                this.fetchRoles();
            })
            .catch(error => {
                this.fetchRoles();
                errorMessage(this.props, error)
                this.setState({ isLoading: false })
            });
    }

    roleDelete(data1) {
        var allocation_exists = false;
        for (let i = 0; i < data1.users.length; i++) {
            const element = data1.users[i];
            if (element.planed_hours > 0) {
                allocation_exists = true;
                break;
            }
        }
        if (!allocation_exists) {
            Service.delete('/bb/api/tender/tasks/rolesrevhistory/' + data1.id + '/', {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    this.props.enqueueSnackbar('Role Deleted Successfully!', {
                        variant: 'success'
                    });
                    this.fetchRoles();
                })
                .catch(error => {
                    console.log(error)
                    errorMessage(this.props, error)
                });
        } else {
            this.props.enqueueSnackbar('Role with allocated hours cannot be deleted!', {
                variant: 'warning'
            });
        }

    }

    saveData = () => {
        let changedRoleData = []
        let ChangedUserData = []
        for (let i = 0; i < this.state.data.length; i++) {
            const element = this.state.data[i];
            if (element.hours_at_completion !== element.copy_hours_at_completion || element.remark !== element.copy_remark) {
                changedRoleData.push({
                    role: element.id,
                    hours_need: !element.hours_at_completion ? 0 : element.hours_at_completion,
                    remark: element.remark,
                    copy_hours_at_completion: element.copy_hours_at_completion
                })
            }
            for (let j = 0; j < element.users.length; j++) {
                const user = element.users[j];
                if (user.planed_hours !== user.copy_planed_hours) {
                    user.planed_hours = !user.planed_hours ? 0 : user.planed_hours
                    ChangedUserData.push(user)
                }
            }
        }

        if (changedRoleData.length === 0 && ChangedUserData.length === 0) {
            this.props.enqueueSnackbar("No changes to save", { variant: 'info' })
            this.setState({ saveDisabled: false })
        } else {
            this.saveChanges({ role: changedRoleData, user: ChangedUserData })
        }
    }

    saveChanges = (data) => {
        Service.post(`/bb/api/tender/user/task/task_and_role_level_allocation/`, {
            headers: {
                Authorization: "Token " + token,
            },
            data: data
        })
            .then(res => {
                if (res.data?.role_error || res.data?.user_error) {
                    if (res.data.role_error) {
                        res.data.role_error.map((item) => {
                            return this.props.enqueueSnackbar(item, { variant: 'error' })
                        })
                    }
                    if (res.data.user_error) {
                        res.data.user_error.map((item) => {
                            return this.props.enqueueSnackbar(item, { variant: 'error' })
                        })
                    }
                }
                this.props.enqueueSnackbar("Data saved successfully", { variant: 'success' })
                this.fetchRoles()
                this.setState({ saveDisabled: false, rolesSearch: "", usersSearch: "", disableDiscard: true })

            })
            .catch(error => {
                if (error && error.status === 406) {
                    this.props.enqueueSnackbar(error?.data?.message, {
                        variant: 'warning'
                    });
                    this.setState({ saveDisabled: false, disableDiscard: true })
                } else {
                    console.log(error)
                    this.setState({ saveDisabled: false })
                    errorMessage(this.props, error)
                }
            });
    }

    discardChanges = () => {
        this.setState({ saveDisabled: false, rolesSearch: "", usersSearch: "", disableDiscard: true }, () => {
            this.fetchRoles()
        })
        // let data = this.state.data;
        // for (let i = 0; i < data.length; i++) {
        //     const element = data[i];
        //     element.hours_at_completion = element.copy_hours_at_completion;
        //     element.error = false;
        //     element.helperText = "";
        //     element.remark = element.copy_remark;
        //     for (let j = 0; j < element.users.length; j++) {
        //         const user = element.users[j];
        //         user.error = false;
        //         user.helperText = "";
        //         user.planed_hours = user.copy_planed_hours;
        //     }
        // }
        // this.setState({ data: this.state.copyData, saveDisabled: false, rolesSearch: "", usersSearch: "", disableDiscard: true })
    }

    addRoleClose = (refresh) => {
        this.setState({ openAddRole: false })
        if (refresh) {
            this.fetchRoles()
        }
    }

    addUserClose = (refresh) => {
        this.setState({ openAddUser: false })
        if (refresh) {
            this.fetchRoles()
        }
    }

    getCountry = (country) => {
        if (country === "in") {
            return "India"
        } else if (country === 'uk') {
            return "UK"
        } else if (country === 'sl') {
            return "SL"
        } else {
            return ""
        }
    }

    roleDelDisabled = (role) => {
        let delete_access = access && access.access && access.access.l14a && access.access.l14a.l14a_a
        if (role.budget_hours === 0 && role.copy_hours_at_completion === 0 && delete_access) {
            return false
        }
        return true
    }

    customUserFilterAndSearch = (data, userSearch) => {
        if (userSearch) {
            if (data.first_name.toLowerCase().includes(userSearch) || data.last_name.toLowerCase().includes(userSearch)) {
                return true
            }
        } else {
            return true
        }
    }

    customRoleFilterAndSearch = (data, rolesSearch) => {
        if (rolesSearch) {
            if (data.name.toLowerCase().includes(rolesSearch)) {
                return true
            } else {
                return false
            }
        } else {
            return true
        }
    }

    onErrorDialogClose = () => {
        this.setState({
            errorData: null,
            errorDialog: false,
            saveDisabled: false,
            rolesSearch: "",
            usersSearch: "",
            disableDiscard: true
        }, () => {
            this.fetchRoles()
        })
    }

    render() {

        const handleHistoryClose = (e) => {
            this.setState({ openHistory: false, historyData: [] })
        };

        const handleClose = () => {
            this.setState({ searchUserRoles: false })
        }

        return (
            <div>
                {this.state.isLoading ?
                    <LoopCircleLoading color={getPrimaryColor()} />
                    :
                    <div >
                        <Paper elevation={2} style={{ padding: "2px", marginBottom: "5px", backgroundColor: getDarkMode() === 'dark' ? '#4f7e76' : '#AFD6CB' }}>
                            <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-evenly'} >
                                <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                    <b>Project: </b> &nbsp;
                                    {this.state.details?.project_name}
                                </Box>
                                <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                    <b>DS:</b>&nbsp;
                                    {this.state.details?.ds_name}
                                </Box>
                                <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                    <b>WBS: </b>&nbsp;
                                    {this.state.details?.wbs_name}
                                </Box>
                            </Box>
                        </Paper>

                        <Paper elevation={2} style={{ padding: "2px", marginBottom: "5px", backgroundColor: getDarkMode() === 'dark' ? '#4f7e76' : '#AFD6CB' }}>
                            <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-evenly'} >
                                <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                                    <b>Budget Hours </b>
                                    {this.state.details?.estimated_hours}
                                </Box>
                                <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                                    <b>Hours at Completion</b>
                                    {this.state.details?.dynamic_revised_hours}
                                </Box>
                                <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                                    <b>Unallocated Hours </b>
                                    {this.state.details?.unallocated_hours}
                                </Box>
                                <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                                    <b>Allocated Hours </b>
                                    {this.state.details?.allocated_hours}
                                </Box>
                                <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                                    <b>Actual & Inprogress Hours </b>
                                    {this.state.details?.actual_and_inprogress_hours}
                                </Box >
                                <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                                    <b>Unutilized Hours </b>
                                    {this.state.details?.unutilized_hours}
                                </Box>
                                <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                                    <b>Budget Cost </b>
                                    {new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(this.state.details?.budget_cost)}
                                </Box>
                                <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                                    <b>Cost at Completion </b>
                                    {new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(this.state.details?.dynamic_cost_at_completion)}
                                </Box>

                                <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                                    <b>Variance Cost </b>
                                    {new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format((this.state.details?.dynamic_cost_at_completion) - this.state.details?.budget_cost)}
                                </Box>

                                <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'center'}>
                                    <Button disabled={this.state.disableDiscard} style={{ marginRight: "10px" }} size='small' variant="contained" color="primary" onClick={() => this.discardChanges()}>
                                        Discard
                                    </Button>

                                    <Button size='small' disabled={this.state.saveDisabled} variant="contained" color="primary" onClick={() => this.saveData()}>
                                        Save
                                    </Button>
                                </Box>
                            </Box>
                        </Paper>

                        <TableContainer component={Paper} sx={{ maxHeight: window.innerHeight - 160, overflowY: 'scroll' }}>
                            <Table size='small' sx={{ minWidth: 650, tableLayout: 'fixed', }} aria-label="simple table" stickyHeader>
                                <TableHead>
                                    <TableRow sx={{ width: "100%" }}>
                                        <TableCell sx={{ border: '1px solid rgba(224, 224, 224, 1)', width: screenSizeCheck() ? "70%" : '75%', zIndex: 999, position: "sticky" }} >
                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: "center" }}>
                                                <Box flexGrow={1} sx={{ fontSize: screenSizeCheck() ? '15px' : '16px' }}>
                                                    Users
                                                </Box>
                                                <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'center'}>
                                                    <Button size='small' variant="contained" color="primary" onClick={() => this.setState({ searchUserRoles: true })}>Find User Role</Button>
                                                    <Tooltip title="Add User" arrow onClick={() => this.setState({ openAddUser: true })}>
                                                        <IconButton size='small' style={{ marginLeft: "10px" }}>
                                                            <AddIcon fontSize='small' />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <TextField
                                                        style={{ marginLeft: "10px" }}
                                                        autoComplete='off'
                                                        size='small'
                                                        placeholder="Search"
                                                        id="search"
                                                        value={this.state.usersSearch}
                                                        onChange={(e) => this.usersSearchChange(e.target.value)}
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start"><SearchIcon fontSize='small' /></InputAdornment>,
                                                        }}
                                                    />
                                                </Box>
                                            </Box>
                                        </TableCell>
                                        <TableCell sx={{ border: '1px solid rgba(224, 224, 224, 1)', width: screenSizeCheck() ? "30%" : '25%', zIndex: 999, position: "sticky" }}>
                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: "center" }}>
                                                <Box flexGrow={1} sx={{ fontSize: screenSizeCheck() ? '15px' : '16px' }}>
                                                    Roles
                                                </Box>
                                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: "center" }}>
                                                    <Tooltip title="Add Role" arrow>
                                                        <IconButton size='small' onClick={() => this.setState({ openAddRole: true })} >
                                                            <AddIcon fontSize='small' />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <TextField
                                                        autoComplete='off'
                                                        size='small'
                                                        style={{ marginLeft: "10px" }}
                                                        placeholder="Search"
                                                        id="search"
                                                        value={this.state.rolesSearch}
                                                        onChange={(e) => this.rolesSearchChange(e.target.value)}
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start"><SearchIcon fontSize='small' /></InputAdornment>,
                                                        }}
                                                    />
                                                </Box>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.data.filter((data => this.customRoleFilterAndSearch(data, this.state.rolesSearch))).map((row, i) => (
                                        <TableRow
                                            key={i}
                                            sx={{ '&:last-child td, &:last-child th': { border: '1px solid rgba(224, 224, 224, 1)' }, width: '100%' }}>
                                            <TableCell component="th" scope="row" sx={{ width: screenSizeCheck() ? "70%" : "75%", border: '1px solid rgba(224, 224, 224, 1)' }}>
                                                {
                                                    row.isLoading ?
                                                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: "center", height: "100%", width: '100%' }}>
                                                            <CircularProgress />
                                                        </Box> :
                                                        <div>
                                                            <Grid container spacing={2}>
                                                                {
                                                                    row.users.length > 0 ?
                                                                        (row.users).filter((data => this.customUserFilterAndSearch(data, this.state.usersSearch))).map((value, j) =>
                                                                            <Grid item xs={screenSizeCheck() ? 4 : 3} key={j}>
                                                                                <Card elevation={8} sx={{ borderRadius: '10px' }}>
                                                                                    <CardContent>
                                                                                        <Box sx={{ mb: 1, fontSize: screenSizeCheck() ? '15px' : '16px', fontWeight: "bold", display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center' }}>
                                                                                            <Box sx={{ flexGrow: 1 }}>
                                                                                                {value.last_name + ", " + value.first_name}
                                                                                            </Box>
                                                                                            <IconButton size='small' disabled={value.copy_planed_hours > 0} onClick={() => this.userDelete(value)}>
                                                                                                <DeleteIcon />
                                                                                            </IconButton>
                                                                                        </Box>

                                                                                        <Box sx={{
                                                                                            display: 'flex',
                                                                                            flexDirection: 'row',
                                                                                            justifyContent: 'space-between',
                                                                                            alignItems: 'center',
                                                                                            width: '100%'
                                                                                        }}>
                                                                                            <Box sx={{
                                                                                                display: "flex",
                                                                                                flexDirection: "column",
                                                                                                width: '50%',
                                                                                                // m: "5px"
                                                                                            }}>
                                                                                                <Tooltip title="Allocated Hours" arrow>
                                                                                                    <TextField
                                                                                                        error={value.error}
                                                                                                        fullWidth
                                                                                                        type='number'
                                                                                                        id="alloc_hours"
                                                                                                        label="Allocated Hours"
                                                                                                        variant="outlined"
                                                                                                        value={value.planed_hours}
                                                                                                        size='small'
                                                                                                        fontSize={fontSize}
                                                                                                        margin='dense'
                                                                                                        InputLabelProps={{ fontSize: '10px' }}
                                                                                                        placeholder='Allocated Hours'
                                                                                                        sx={{ mt: 1 }}
                                                                                                        onChange={(e) => this.allocHoursChange(e, row, value)}
                                                                                                        // onBlur={() => this.allocHrsChangeCheck(i, j)}
                                                                                                        helperText={!value.helperText ? '' : <p style={{ fontSize: "10px" }}>{value.helperText}</p>}
                                                                                                    />
                                                                                                </Tooltip>
                                                                                                <Box sx={{ mt: 1, fontSize: fontSize }}>
                                                                                                    <b>Allocated Cost:</b> {new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format((value.dynamic_allocation_cost).toFixed(2))}
                                                                                                </Box>
                                                                                                <Box sx={{ mt: 1, fontSize: fontSize }}>
                                                                                                    <b>Unutilized Hours:</b> {value.balance_hours}
                                                                                                </Box>
                                                                                            </Box>

                                                                                            <Divider orientation="vertical" flexItem variant='middle' />

                                                                                            <Box sx={{
                                                                                                display: "flex",
                                                                                                flexDirection: "column",
                                                                                                width: '45%',
                                                                                                // m: "5px"
                                                                                            }}>
                                                                                                <Box sx={{ mt: 1, fontSize: fontSize }}>
                                                                                                    <b>Total Hours:</b> {value.act_and_inprogress}
                                                                                                </Box>
                                                                                                <Box sx={{ mt: 1, fontSize: fontSize }}>
                                                                                                    <b>Approved Hours:</b> {value.approved_hours}
                                                                                                </Box>
                                                                                                <Box sx={{ mt: 1, fontSize: fontSize }}>
                                                                                                    <b>Unapproved Hours:</b> {value.unapproved_hours}
                                                                                                </Box>
                                                                                                <Box sx={{ mt: 1, fontSize: fontSize }}>
                                                                                                    <b>Inprogress Hours:</b> {value.inprogress_hours}
                                                                                                </Box>

                                                                                            </Box>
                                                                                        </Box>
                                                                                    </CardContent>
                                                                                </Card>
                                                                            </Grid>
                                                                        ) :
                                                                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: "center", height: "100%", fontSize: fontSize, width: '100%' }}>
                                                                            No users to display!
                                                                        </Box>
                                                                }
                                                            </Grid>
                                                        </div>
                                                }
                                            </TableCell>
                                            <TableCell component="th" scope="row" sx={{ border: '1px solid rgba(224, 224, 224, 1)', width: screenSizeCheck() ? "30%" : "25%" }}>
                                                <Card elevation={8} sx={{ borderRadius: '10px' }}>
                                                    <CardContent>

                                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', mb: 1 }}>
                                                            <Box sx={{ flexGrow: 1, fontSize: screenSizeCheck() ? '15px' : '16px', fontWeight: "bold" }}>
                                                                {row.name + " (" + row?.grade.toUpperCase() + " - " + this.getCountry(row.country) + ")"}
                                                            </Box>
                                                            <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-evenly'} >
                                                                <Tooltip title="Change History" arrow>
                                                                    <IconButton disabled={!row.hours_rev_history} size='small' onClick={() => { this.setState({ openHistory: true, historyData: row.hours_rev_history }) }}>
                                                                        <ChangeHistoryIcon fontSize='small' />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title="delete" arrow>
                                                                    <IconButton
                                                                        size='small'
                                                                        disabled={this.roleDelDisabled(row)}
                                                                        onClick={() => this.roleDelete(row)}
                                                                    >
                                                                        <DeleteIcon fontSize='small' />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Box>
                                                        </Box>

                                                        <Box sx={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center',
                                                            width: '100%'
                                                        }}>
                                                            <Box sx={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                width: '45%',
                                                                // m: "5px"
                                                            }}>
                                                                <Box sx={{ fontSize: fontSize }}>
                                                                    <b>Budget Hours:</b> {row.budget_hours}
                                                                </Box>

                                                                <Tooltip title="Hours at Completion" arrow>
                                                                    <TextField
                                                                        error={row.error}
                                                                        fullWidth
                                                                        id="hours_at_completion"
                                                                        label="Hours at Completion"
                                                                        variant="outlined"
                                                                        value={row.hours_at_completion}
                                                                        size='small'
                                                                        fontSize={fontSize}
                                                                        margin='dense'
                                                                        type='number'
                                                                        InputLabelProps={{ fontSize: '10px' }}
                                                                        placeholder='Hours at Completion'
                                                                        sx={{ mt: 1 }}
                                                                        helperText={!row.helperText ? '' : <p style={{ fontSize: "10px" }}>{row.helperText}</p>}
                                                                        onChange={(e) => this.hrsAtComplChange(e, row)}
                                                                    // onBlur={() => { this.hrsAtComplCheck(i) }}
                                                                    />
                                                                </Tooltip>
                                                                <Box sx={{ mt: 1, fontSize: fontSize }}>
                                                                    <b>Allocated Hours:</b> {row.allocated_or_planned_hours}
                                                                </Box>
                                                                <Box sx={{ mt: 1, fontSize: fontSize }}>
                                                                    <b>Unallocated Hours:</b> {row.hours_at_completion - row.allocated_or_planned_hours}
                                                                </Box>
                                                            </Box>

                                                            <Divider orientation="vertical" flexItem variant='middle' />

                                                            <Box sx={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                width: '50%',
                                                                // m: "5px"
                                                            }}>
                                                                <Box sx={{ mt: 1, fontSize: fontSize }}>
                                                                    <b>Budget Cost:</b> {new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(row.budget_cost)}
                                                                </Box>
                                                                <Box sx={{ mt: 1, fontSize: fontSize }}>
                                                                    <b>Cost at Completion:</b> {new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format((row.hours_at_completion * row.rate.toFixed(2)))}
                                                                </Box>

                                                                <Box sx={{ mt: 1, fontSize: fontSize }}>
                                                                    <b>Variance Cost:</b> {new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(((row.hours_at_completion * row.rate) - row.budget_cost).toFixed(2))}
                                                                </Box>
                                                                <Tooltip title="Remarks" arrow>
                                                                    <TextField
                                                                        fullWidth
                                                                        id="remarks"
                                                                        variant="outlined"
                                                                        value={row.remark}
                                                                        size='small'
                                                                        fontSize={fontSize}
                                                                        margin='dense'
                                                                        InputLabelProps={{ fontSize: '10px' }}
                                                                        label="Remarks"
                                                                        placeholder='Remarks'
                                                                        sx={{ mt: 1 }}
                                                                        multiline
                                                                        rows={2}
                                                                        disabled={row.copy_hours_at_completion === row.hours_at_completion}
                                                                        onChange={(e) => this.remarkChange(e, row)}
                                                                    />
                                                                </Tooltip>
                                                            </Box>
                                                        </Box>
                                                    </CardContent>
                                                </Card>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Dialog open={this.state.openHistory} maxWidth={"lg"} fullWidth onClose={handleHistoryClose} aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">Revised Hours Modified History</DialogTitle>
                            <DialogContent>
                                <MaterialTable
                                    key={3}
                                    title={""}
                                    columns={[
                                        {
                                            title: 'User', field: 'user_name', editable: 'never',
                                        },
                                        {
                                            title: 'Old Revised Hours', field: 'rev_old', editable: 'never',
                                        },
                                        {
                                            title: 'New Revised Hours', field: 'rev_new', editable: 'never',
                                        },
                                        {
                                            title: 'Modified On', field: 'date_modified', type: 'datetime', editable: 'never', defaultSort: 'desc',
                                        },

                                    ]}
                                    data={this.state.historyData}
                                    options={{
                                        padding: "dense",
                                    }}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleHistoryClose} color="primary" size='small'> 
                                    Close
                                </Button>

                            </DialogActions>
                        </Dialog>

                        <Dialog open={this.state.openAddRole} fullWidth aria-labelledby="form-dialog-title" disableEnforceFocus>
                            <DialogTitle id="form-dialog-title">Add Roles</DialogTitle>
                            <DialogContent>
                                <AllocateRole value={this.props.value} data={this.state.data} closeDialog={this.addRoleClose} />
                            </DialogContent>
                        </Dialog>

                        <Dialog open={this.state.openAddUser} fullWidth aria-labelledby="form-dialog-title" disableEnforceFocus>
                            {/* <DialogTitle id="form-dialog-title">Add User</DialogTitle> */}
                            <DialogContent>
                                <AllocateUser value={this.props.value} data={this.state.data} closeUserDialog={this.addUserClose} userData={this.props.userData} roleAllocHrs={this.state.roleAllocHrs} />
                            </DialogContent>
                        </Dialog>

                        <Dialog
                            open={this.state.searchUserRoles}
                            fullWidth
                            maxWidth={'sm'}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <DialogTitle id="customized-dialog-title2" >
                                Find Role of a user
                            </DialogTitle>
                            <DialogContent>
                                <FindUserByRole />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="primary" size='small'>
                                    Close
                                </Button>

                            </DialogActions>
                        </Dialog>

                    </div >
                }
            </div>
        );
    }
}

export default withSnackbar(Allocation);