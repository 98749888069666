import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
// import MaterialTable from 'material-table';
import Service from "../../Networking/networkutils";
import { withSnackbar } from 'notistack';
import { LoopCircleLoading } from 'react-loadingg';
import { getCookie } from '../../Helper/CookieHelper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { errorMessage } from '../../Helper/ErrorMessage';
import moment from 'moment';
import { getPrimaryColor } from '../../Helper/CommonThings';
// const access = JSON.parse(getCookie("bb_ts_access"));
// import ReactGA from 'react-ga';

const useStyles = (theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
});

const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));
const access = JSON.parse(getCookie("bb_ts_access"));

var fulldata = 'null'
class Coversheet extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedDate: new Date(),
            ratedata: [],
            eoidate: null,
            stagedate: null,
            returndate: null,
            awarddate: null,
            completiondate: null,
            prepdate: null,
            reviewdate: null,
            doedate: null,
            proj_title: '',
            ourRef: '',
            crm: '',
            client: '',
            principal_designer: '',
            contract_value: '',
            prepared_by: '',
            reviewed_by: '',
            approval_by: '',
            scope_summary: '',
            isLoading: true,
            allData: 'null',
            type: 0,
            from_date: null,
            to_date: null,
            audit_status: 0,
            allBit: [],
            tenderBid: null,
            usersData: [],
            lead_dicipline: '',
            approval_status: 0,
            contract_type: 0,
            rateCardYears: [],
            selectedRateCard: 0,
            customRateTemp: []
        }
    }

    componentDidMount() {
        if (!getCookie("bb_ts_token")) {
            window.location.href = '/'
        }

        // Service.get(`/bb/api/users/`, {
        //     headers: {
        //         Authorization: "Token " + token,
        //     },
        // })
        //     .then(res1 => {
        //         this.setState({ usersData: res1.data })

        //     })
        //     .catch(error => {
        //         errorMessage(this.props, error)
        //     });

        if (this.props.tender && this.props.tender.id) {
            this.fetchRateCards()
        }

        Service.get('/bb/api/tender/template/ratecard/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                var ratedata = []
                for (let i = moment().year(); i <= moment().year(); i++) {
                    var temprate = []
                    for (let j = 0; j < res.data.length; j++) {
                        const element = res.data[j];
                        if (element.year === i) {
                            temprate.push(element)
                        }
                    }

                    ratedata.push(temprate)

                }
                console.log(ratedata)
                this.setState({ ratedata: ratedata, }, () => {
                    if (this.state.allBit.length > 0) {
                        this.setState({ isLoading: false })
                    }
                })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });

        Service.get('/bb/api/tender/bid/get_unused/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                var allCli = [];
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    if (element.pdm && element.stage === 1 && element.status === 100 && (element.bid_lead === user.id || element.lde === user.id || element.pdm === user.id || element.lead_engineer === user.id || user.is_admin || (access.access && access.access.l6a && access.access.l6a.l6a_c))) {
                        allCli.push(element)
                    }
                }
                // console.log(allCli)
                this.setState({ allBit: allCli }, () => {
                    if (this.state.ratedata.length > 0) {
                        this.setState({ isLoading: false })
                    }
                })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });

        fulldata = JSON.parse(JSON.stringify(this.props.value));
        // console.log(this.props.tender.tenderbid_data)
        var tenderbid_data = {}
        if (fulldata !== 'null' && fulldata.tenderbid_data && !this.props.tender) {
            // console.log("this.props.tender 123")
            tenderbid_data = fulldata.tenderbid_data
        } else if (this.props.tender && this.props.tender.tenderbid_data) {
            // console.log("this.props.tender 3456")
            tenderbid_data = this.props.tender.tenderbid_data
            // console.log(tenderbid_data.crm)
        }
        this.setState({ allData: fulldata })
        var name = '';

        if (fulldata.from_tenderbid) {
            name = fulldata.tender_name
        } else {
            name = fulldata.name
        }

        if (this.props.value !== 'null') {

            this.setState({
                proj_title: name,
                ourRef: fulldata.our_ref,
                crm: (tenderbid_data.crm) ? (tenderbid_data.crm) : (''),
                client: (tenderbid_data.client_name) ? (tenderbid_data.client_name) : (''),
                bid_lead: (tenderbid_data.bid_lead_data) ? (tenderbid_data.bid_lead_data.first_name + " " + tenderbid_data.bid_lead_data.last_name) : (''),
                lead_engineer: (tenderbid_data.lead_engineer_data) ? (tenderbid_data.lead_engineer_data.first_name + " " + tenderbid_data.lead_engineer_data.last_name) : (''),
                principal_designer: fulldata.principal_designer,
                eoidate: tenderbid_data.itt_start,
                stagedate: tenderbid_data.gate_3,
                returndate: fulldata.return_date,
                awarddate: tenderbid_data.contract_award,
                completiondate: tenderbid_data.contract_completion,
                contract_value: tenderbid_data.value,
                // data: JSON.parse(JSON.stringify(this.props.value.dor)),
                prepared_by: fulldata.prepared_by_data,
                prepdate: fulldata.prepared_by_date,
                reviewed_by: fulldata.reviewed_by_data,
                reviewdate: fulldata.reviewed_by_date,
                approval_by: fulldata.approved_by_data,
                doedate: fulldata.approved_by_date,
                scope_summary: fulldata.scope_summary,
                type: fulldata.type,
                from_date: fulldata.from_date,
                to_date: fulldata.to_date,
                audit_status: fulldata.audit_status,
                tenderBid: fulldata.from_tenderbid,
                approval_status: fulldata.approval_status,
                contract_type: fulldata.contract_type,
            })
        } else if (tenderbid_data !== {}) {
            this.setState({
                // proj_title: name,
                // ourRef: fulldata.our_ref,
                crm: tenderbid_data.crm,
                client: (tenderbid_data.client_name) ? (tenderbid_data.client_name) : (''),
                bid_lead: (tenderbid_data.bid_lead_data) ? (tenderbid_data.bid_lead_data.first_name + " " + tenderbid_data.bid_lead_data.last_name) : (''),
                lead_engineer: (tenderbid_data.lead_engineer_data) ? (tenderbid_data.lead_engineer_data.first_name + " " + tenderbid_data.lead_engineer_data.last_name) : (''),
                // principal_designer: fulldata.principal_designer,
                eoidate: tenderbid_data.itt_start,
                stagedate: tenderbid_data.gate_3,
                // returndate: fulldata.return_date,
                awarddate: tenderbid_data.contract_award,
                completiondate: tenderbid_data.contract_completion,
                contract_value: tenderbid_data.value,
                // data: JSON.parse(JSON.stringify(this.props.value.dor)),
                // prepared_by: fulldata.prepared_by_data,
                // prepdate: fulldata.prepared_by_date,
                // reviewed_by: fulldata.reviewed_by_data,
                // reviewdate: fulldata.reviewed_by_date,
                // approval_by: fulldata.approved_by_data,
                // doedate: fulldata.approved_by_date,
                // scope_summary: ful/ldata.scope_summary,
                // type: fulldata.type,
                // from_date: fulldata.from_date,
                // to_date: fulldata.to_date,
                // audit_status: fulldata.audit_status,
                // tenderBid: fulldata.from_tenderbid,
            })
        }
    }

    postData() {

        if (this.state.proj_title === '') {
            this.props.enqueueSnackbar('Project title should not be empty!', {
                variant: 'warning'
            });
        } else {
            this.setState({ isLoading: true })

            // console.log(this.state.allData)

            if (this.state.allData !== 'null') {
                var json = this.props.allData;
                json = {
                    name: this.state.proj_title,
                    our_ref: this.state.ourRef,
                    crm: this.state.crm,
                    client: this.state.client,
                    principal_designer: this.state.principal_designer,
                    eoi_iid: this.state.eoidate,
                    stage_gate3: this.state.stagedate,
                    return_date: this.state.returndate,
                    contract_award: this.state.awarddate,
                    contract_completion: this.state.completiondate,
                    contract_value: this.state.contract_value,
                    // dor: this.state.data,
                    // prepared_by: this.state.prepared_by.id,
                    prepared_by_date: this.state.prepdate,
                    // reviewed_by: this.state.reviewed_by,
                    reviewed_by_date: this.state.reviewdate,
                    // approved_by: this.state.approval_by,
                    approved_by_date: this.state.doedate,
                    scope_summary: this.state.scope_summary,
                    created_by: user.email,
                    type: this.state.type,
                    from_date: this.state.from_date,
                    to_date: this.state.to_date,
                    audit_status: this.state.audit_status,
                    contract_type: this.state.contract_type,
                    // from_tenderbid: this.state.tenderBid,
                }

                if (json.type === 1) {
                    json.approval_status = this.state.approval_status
                } else {
                    json.approval_status = 1
                }

                try {
                    json.prepared_by = this.state.prepared_by.id;
                } catch (error) {
                    json.prepared_by = null;
                }
                try {
                    json.reviewed_by = this.state.reviewed_by.id;
                } catch (error) {
                    json.reviewed_by = null;
                }
                try {
                    json.approved_by = this.state.approved_by.id;
                } catch (error) {
                    json.approved_by = null;
                }
                // console.log(json)

                Service.put("/bb/api/tender/project/" + this.state.allData.id + "/", {
                    headers: {
                        Authorization: "Token " + token,
                    },
                    data: json,
                })
                    .then(res => {
                        if (this.state.tenderBid) {
                            Service.put('/bb/api/tender/bid/' + this.state.tenderBid, {
                                headers: {
                                    Authorization: "Token " + token,
                                },
                                data: { status: this.state.audit_status },
                            })
                                .then(res1 => {
                                    this.props.onAllData(res.data);
                                    // console.log(res.data)
                                    this.props.enqueueSnackbar('Project saved successfully', {
                                        variant: 'success'
                                    });
                                    // console.log(res1.data)
                                    this.setState({ isLoading: false, allData: res.data, })
                                })
                                .catch(error => {

                                    errorMessage(this.props, error)
                                });
                        } else {
                            this.setState({ isLoading: false, allData: res.data, })
                        }

                    })
                    .catch(error => {
                        console.log(error)
                        this.setState({ isLoading: false })
                        // console.log(error)
                        errorMessage(this.props, error)
                    });

            } else {
                var json1 = {};
                if (this.props.subproj.length === 0) {
                    json1 = {
                        name: this.state.proj_title,
                        our_ref: this.state.ourRef,
                        crm: this.state.crm,
                        client: this.state.client,
                        principal_designer: this.state.principal_designer,
                        eoi_iid: this.state.eoidate,
                        stage_gate3: this.state.stagedate,
                        return_date: this.state.returndate,
                        contract_award: this.state.awarddate,
                        contract_completion: this.state.completiondate,
                        contract_value: this.state.contract_value,
                        // dor: this.state.data,
                        // prepared_by: this.state.prepared_by,
                        prepared_by_date: this.state.prepdate,
                        // reviewed_by: this.state.reviewed_by,
                        reviewed_by_date: this.state.reviewdate,
                        // approved_by: this.state.approval_by,
                        approved_by_date: this.state.doedate,
                        scope_summary: this.state.scope_summary,
                        created_by: user.email,
                        type: this.state.type,
                        from_date: this.state.from_date,
                        to_date: this.state.to_date,
                        audit_status: this.state.audit_status,
                        from_tenderbid: this.state.tenderBid,
                        approval_status: 1,
                        lead_dicipline: this.state.lead_dicipline,
                    }
                    try {
                        json1.prepared_by = this.state.prepared_by.id;
                    } catch (error) {
                        json1.prepared_by = null;
                    }
                    try {
                        json1.reviewed_by = this.state.reviewed_by.id;
                    } catch (error) {
                        json1.reviewed_by = null;
                    }
                    try {
                        json1.approved_by = this.state.approved_by.id;
                    } catch (error) {
                        json1.approved_by = null;
                    }
                } else {
                    json1 = {
                        name: this.state.proj_title,
                        our_ref: this.state.ourRef,
                        crm: this.state.crm,
                        client: this.state.client,
                        principal_designer: this.state.principal_designer,
                        eoi_iid: this.state.eoidate,
                        stage_gate3: this.state.stagedate,
                        return_date: this.state.returndate,
                        contract_award: this.state.awarddate,
                        contract_completion: this.state.completiondate,
                        contract_value: this.state.contract_value,
                        // dor: this.state.data,
                        // prepared_by: this.state.prepared_by,
                        prepared_by_date: this.state.prepdate,
                        // reviewed_by: this.state.reviewed_by,
                        reviewed_by_date: this.state.reviewdate,
                        // approved_by: this.state.approval_by,
                        approved_by_date: this.state.doedate,
                        scope_summary: this.state.scope_summary,
                        is_subproject: true,
                        subproject_pid: this.props.subproj[0].id,
                        created_by: user.email,
                        type: this.state.type,
                        from_date: this.state.from_date,
                        to_date: this.state.to_date,
                        audit_status: this.state.audit_status,
                        contract_type: this.state.contract_type,
                    }
                    if (json1.type === 1) {
                        json1.approval_status = this.state.approval_status
                    } else {
                        json1.approval_status = 1
                    }
                    try {
                        json1.prepared_by = this.state.prepared_by.id;
                    } catch (error) {
                        json1.prepared_by = null;
                    }
                    try {
                        json1.reviewed_by = this.state.reviewed_by.id;
                    } catch (error) {
                        json1.reviewed_by = null;
                    }
                    try {
                        json1.approved_by = this.state.approved_by.id;
                    } catch (error) {
                        json1.approved_by = null;
                    }
                }

                // console.log(json1)

                Service.post("/bb/api/tender/project/", {
                    headers: {
                        Authorization: "Token " + token,
                    },
                    data: json1,
                })
                    .then(res => {

                        if (this.props.subproj.length === 0) {

                            json1.name = "Estimates Effort"
                            json1.is_subproject = true;
                            json1.subproject_pid = res.data.id;
                            json1.audit_status = 11;
                            json1.from_tenderbid = null;

                            Service.post("/bb/api/tender/project/", {
                                headers: {
                                    Authorization: "Token " + token,
                                },
                                data: json1,
                            })
                                .then(res1 => {
                                    var postrate_data = []
                                    for (let i = 0; i < this.state.ratedata.length; i++) {
                                        const element = this.state.ratedata[i];
                                        if (element.length > 0) {
                                            if (element[0].year === moment().year()) {
                                                postrate_data.push({
                                                    t_pid: res1.data.id,
                                                    t_pname: res1.data.name,
                                                    created_by: user.email,
                                                    t_rates: element,
                                                    default: true,
                                                    year: element[0].year,
                                                    remarks: [
                                                        {
                                                            date: new Date(),
                                                            remark: 'default rates (initiated for project)'
                                                        }
                                                    ]
                                                })
                                            } else {
                                                postrate_data.push({
                                                    t_pid: res1.data.id,
                                                    t_pname: res1.data.name,
                                                    created_by: user.email,
                                                    t_rates: element,
                                                    default: false,
                                                    year: element[0].year,
                                                    remarks: [
                                                        {
                                                            date: new Date(),
                                                            remark: 'default rates (initiated for project)'
                                                        }
                                                    ]
                                                })
                                            }
                                        }
                                    }

                                    Service.post("/bb/api/tender/rate/", {
                                        headers: {
                                            Authorization: "Token " + token,
                                        },
                                        data: postrate_data,
                                    })
                                        .then(res => {

                                            this.setState({ isLoading: false })

                                            this.props.enqueueSnackbar('Project added successfully', {
                                                variant: 'success'
                                            });

                                        })
                                        .catch(error => {
                                            this.props.enqueueSnackbar('Rates import failed. Try after sometime!', {
                                                variant: 'error'
                                            });
                                            errorMessage(this.props, error)
                                        });

                                })
                                .catch(error => {
                                    console.log(error)
                                    // this.setState({ isLoading: false })
                                    // console.log(error)
                                    errorMessage(this.props, error)
                                });

                        }

                        this.setState({ allData: res.data, })
                        this.props.onAllData(res.data);
                        // console.log(res.data)

                        if (this.state.tenderBid) {
                            Service.put('/bb/api/tender/bid/' + this.state.tenderBid + '/', {
                                headers: {
                                    Authorization: "Token " + token,
                                },
                                data: { status: this.state.audit_status },
                            })
                                .catch(error => {

                                    errorMessage(this.props, error)
                                });
                        }

                        if (!this.state.tenderBid) {
                            var postrate_data = []
                            if (this.state.selectedRateCard === 0) {
                                for (let i = 0; i < this.state.ratedata.length; i++) {
                                    const element = this.state.ratedata[i];
                                    if (element.length > 0) {
                                        if (element[0].year === moment().year()) {
                                            postrate_data.push({
                                                t_pid: res.data.id,
                                                t_pname: res.data.name,
                                                created_by: user.email,
                                                t_rates: element,
                                                default: true,
                                                year: element[0].year,
                                                remarks: [
                                                    {
                                                        date: new Date(),
                                                        remark: 'default rates (initiated for project)'
                                                    }
                                                ]
                                            })
                                        } else {
                                            postrate_data.push({
                                                t_pid: res.data.id,
                                                t_pname: res.data.name,
                                                created_by: user.email,
                                                t_rates: element,
                                                default: false,
                                                year: element[0].year,
                                                remarks: [
                                                    {
                                                        date: new Date(),
                                                        remark: 'default rates (initiated for project)'
                                                    }
                                                ]
                                            })
                                        }
                                    }
                                }
                            } else {
                                let rates_data = []
                                for (let i = 0; i < this.state.customRateTemp.length; i++) {
                                    const element = this.state.customRateTemp[i];
                                    if (element) {
                                        let data = {
                                            id: element.id,
                                            year: element.year,
                                            desig: element.designation,
                                            grade: element.grade,
                                            costin: element.rate_in,
                                            costuk: element.rate_uk,
                                            remark: '',
                                            created_by: user.email,
                                            date_created: new Date()
                                        }
                                        rates_data.push(data)
                                    }
                                }
                                postrate_data.push({
                                    t_pid: res.data.id,
                                    t_pname: res.data.name,
                                    created_by: user.email,
                                    t_rates: rates_data,
                                    default: true,
                                    year: moment().year(),
                                    remarks: [
                                        {
                                            date: new Date(),
                                            remark: 'default rates (initiated from Tender Rate Card)'
                                        }
                                    ]
                                })


                            }

                            // console.log(postrate_data)


                            Service.post("/bb/api/tender/rate/", {
                                headers: {
                                    Authorization: "Token " + token,
                                },
                                data: postrate_data,
                            })
                                .then(res => {

                                    this.setState({ isLoading: false })

                                    this.props.enqueueSnackbar('Project added successfully', {
                                        variant: 'success'
                                    });

                                })
                                .catch(error => {
                                    console.log(error)
                                    this.props.enqueueSnackbar('Rates import failed. Try after sometime!', {
                                        variant: 'error'
                                    });
                                });
                        }


                    })
                    .catch(error => {
                        // console.log(error)
                        this.setState({ isLoading: false })
                        // console.log(error)
                        errorMessage(this.props, error)
                    });
            }
        }


    }

    onTags2Change = (event, values) => {
        // console.log(event.target.id.split("-")[0])
        this.setState({ [event.target.id.split("-")[0]]: values })
    }

    onTagsChange = (event, values) => {
        this.setState({ proj_title: values.opportunity, tenderBid: values.id, lead_dicipline: values.lead_dicipline });
    }

    fetchRateCards() {
        Service.get(`/bb/api/tender/ratecard/template/?tender=${this.props.tender.id}&year=${parseInt(moment().format('YYYY'))}`, {
            headers: {
                Authorization: "Token " + token,
            }
        })
            .then(res => {
                this.setState({
                    rateCardYears: res.data
                })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    fetchRates(data) {
        if (data) {
            Service.get(`/bb/api/tender/ratecard/template/detail/?tender_ratecard_template=${data}`, {
                headers: {
                    Authorization: "Token " + token,
                }
            })
                .then(res => {
                    this.setState({
                        customRateTemp: res.data,
                    })
                })
                .catch(error => {
                    console.log(error)
                    errorMessage(this.props, error)
                });
        }
    }

    onRateCardChange = (event, values) => {
        this.setState({ selectedRateCard: values }, () => {
            if (values !== null) {
                this.fetchRates(this.state.selectedRateCard)
            }
        });
    }

    render() {
        const { classes } = this.props;

        const handleTitle = (e) => {
            this.setState({ proj_title: e.target.value })
        };

        // const handleOurref = (e) => {
        //     this.setState({ ourRef: e.target.value })
        // };

        // const handleCrm = (e) => {
        //     this.setState({ crm: e.target.value })
        // };

        // const handleClient = (e) => {
        //     this.setState({ client: e.target.value })
        // };


        // // const handlePricipalDesigner = (e) => {
        // //     this.setState({ principal_designer: e.target.value })
        // // };

        // const handleEoiDate = (e) => {
        //     this.setState({ eoidate: e })
        // };

        // const handleStageGate = (e) => {
        //     this.setState({ stagedate: e })
        // };

        // // const handleReturnDate = (e) => {
        // //     this.setState({ returndate: e })
        // // };

        // const handleAward = (e) => {
        //     this.setState({ awarddate: e })
        // };

        // const handleCompletion = (e) => {
        //     this.setState({ completiondate: e })
        // };

        // const handleContractValue = (e) => {
        //     this.setState({ contract_value: e.target.value })
        // };

        // // const handlePreparedBy = (e) => {
        // //     this.setState({ prepared_by: e.target.value })
        // // };

        // const handlePreparedDate = (e) => {
        //     this.setState({ prepdate: e })
        // };

        // const handleReviewedDate = (e) => {
        //     this.setState({ reviewdate: e })
        // };

        // const handleReviewedBy = (e) => {
        //     this.setState({ reviewed_by: e.target.value })
        // };

        // const handleApproved = (e) => {
        //     this.setState({ approval_by: e.target.value })
        // };

        // const handleApprovedDate = (e) => {
        //     this.setState({ doedate: e })
        // };

        const handleScopeSummary = (e) => {
            this.setState({ scope_summary: e.target.value })
        };

        const handleType = (e) => {
            this.setState({ type: e.target.value })
        };

        const handleContractType = (e) => {
            this.setState({ contract_type: e.target.value })
        };

        const handleApprovalStatus = (e) => {
            this.setState({ approval_status: e.target.value })
        };
        const onRateCardChange = (e) => {
            this.setState({ selectedRateCard: e.target.value }, () => {
                if (this.state.selectedRateCard !== 0) {
                    this.fetchRates(this.state.selectedRateCard)
                }
            });
        }

        // const handleAuditStatus = (e) => {
        //     this.setState({ audit_status: e.target.value })
        // };

        // const handleStart = (e) => {
        //     this.setState({ from_date: e })
        // };

        // const handleTo = (e) => {
        //     this.setState({ to_date: e })
        // };

        return (
            <div>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                    <div>
                        <Grid container spacing={3}>
                            <Grid item xs={12} >
                                <Paper className={classes.paper}>
                                    <Box display="flex" justifyContent="flex-start" m={1} p={1} bgcolor="background.paper">
                                        {(this.props.value.is_subproject || this.props.subTender) ? (

                                            <FormControl style={{ minWidth: '110px', marginRight: '15px' }}>
                                                <InputLabel id="demo-simple-select-label">Budget/Change</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={this.state.type}
                                                    onChange={handleType}
                                                >
                                                    <MenuItem value={0}>Budget</MenuItem>
                                                    <MenuItem value={1}>Change</MenuItem>

                                                </Select>
                                            </FormControl>

                                        ) : (
                                            null
                                        )}

                                        {((this.props.value.is_subproject || this.props.subTender) && this.state.type === 1) ? (
                                            <FormControl style={{ minWidth: '150px', marginRight: '15px' }}>
                                                <InputLabel id="demo-simple-select-label1">Approved/Not</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label1"
                                                    id="demo-simple-select1"
                                                    value={this.state.approval_status}
                                                    onChange={handleApprovalStatus}
                                                >
                                                    <MenuItem value={0}>Not Approved</MenuItem>
                                                    <MenuItem value={1}>Approved</MenuItem>

                                                </Select>
                                            </FormControl>
                                        ) : (null)}

                                        {(this.props.value.is_subproject || this.props.subTender) ? (

                                            <FormControl style={{ minWidth: '140px', marginRight: '15px' }}>
                                                <InputLabel id="demo-simple-select-label2">Contract Type</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label2"
                                                    id="demo-simple-select2"
                                                    value={this.state.contract_type}
                                                    onChange={handleContractType}
                                                >
                                                    <MenuItem value={0}>Normal</MenuItem>
                                                    <MenuItem value={1}>Sub Contract</MenuItem>

                                                </Select>
                                            </FormControl>

                                        ) : (
                                            null
                                        )}

                                        {(this.state.allData !== 'null' || this.props.subTender) ? (

                                            <TextField fullWidth id="project-title" label="Deliverables Schedule"
                                                value={this.state.proj_title}
                                                disabled={(!this.props.subTender)}
                                                onChange={handleTitle} />

                                        ) : (
                                            <Autocomplete
                                                id="combo-box-demo"
                                                options={this.state.allBit}
                                                style={{ minWidth: 500 }}
                                                onChange={this.onTagsChange}
                                                getOptionLabel={(option) => option.opportunity}
                                                renderInput={(params) => <TextField {...params} label="Select Tender" variant="outlined" />}
                                            />
                                        )}

                                    </Box>
                                    {this.state.rateCardYears.length !== 0 && !this.props.value.id ?
                                        <Box display="flex" justifyContent="flex-start" m={1} p={1} bgcolor="background.paper">
                                            <FormControl style={{ minWidth: 300, marginRight: '15px' }}>
                                                <InputLabel id="demo-simple-select-label2">Select Rate Card</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label2"
                                                    id="demo-simple-select2"
                                                    value={this.state.selectedRateCard}
                                                    onChange={onRateCardChange}
                                                >
                                                    <MenuItem value={0}>Default</MenuItem>
                                                    {this.state.rateCardYears.map((value, index) =>
                                                        <MenuItem value={value.id}>{value.name}</MenuItem>
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </Box> : null}
                                </Paper>
                            </Grid>
                            {/* <Grid item xs={4}>
                                <Paper className={classes.paper}>
                                    <Box display="flex" justifyContent="flex-start" m={1} p={1} bgcolor="background.paper">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                margin="normal"
                                                id="date-pick12er-dialog11"
                                                label="Start Date"
                                                format="dd/MM/yyyy"
                                                value={this.state.from_date}
                                                onChange={handleStart}
                                                fullWidth
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Box>
                                    <Box display="flex" justifyContent="flex-start" m={1} p={1} bgcolor="background.paper">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                margin="normal"
                                                id="date-pick12er-dialog12"
                                                label="End Date"
                                                format="dd/MM/yyyy"
                                                value={this.state.to_date}
                                                onChange={handleTo}
                                                fullWidth
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Box>
                                    <Box display="flex" justifyContent="flex-start" m={1} p={1} bgcolor="background.paper">
                                        <TextField fullWidth id="crm" label="CRM"
                                            value={this.state.crm}
                                            disabled
                                            onChange={handleCrm} />
                                    </Box>
                                    <Box display="flex" justifyContent="flex-start" m={1} p={1} bgcolor="background.paper">
                                        <TextField fullWidth id="client" label="Client"
                                            disabled={true}
                                            value={this.state.client}
                                            onChange={handleClient} />
                                    </Box>
                                    <Box display="flex" justifyContent="flex-start" m={1} p={1} bgcolor="background.paper">
                                        <TextField fullWidth id="bid_lead" label="Bid Lead"
                                            disabled={true}
                                            value={this.state.bid_lead}
                                        />
                                    </Box>
                                    <Box display="flex" justifyContent="flex-start" m={1} p={1} bgcolor="background.paper">
                                        <TextField fullWidth id="lead_engineer" label="Lead Engineer"
                                            disabled={true}
                                            value={this.state.lead_engineer}
                                        />
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid item xs={4}>
                                <Paper className={classes.paper}>
                                    <Box display="flex" justifyContent="flex-start" m={1} p={1} bgcolor="background.paper">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                margin="normal"
                                                id="date-pick12er-dialog1"
                                                label="EOI/ITT Received"
                                                format="dd/MM/yyyy"
                                                value={this.state.eoidate}
                                                onChange={handleEoiDate}
                                                fullWidth
                                                disabled
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Box>
                                    <Box display="flex" justifyContent="flex-start" m={1} p={1} bgcolor="background.paper">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                margin="normal"
                                                id="date-pi23cker-dialog2"
                                                label="Stage Gate 3"
                                                format="dd/MM/yyyy"
                                                value={this.state.stagedate}
                                                onChange={handleStageGate}
                                                fullWidth
                                                disabled
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Box>
                                    <Box display="flex" justifyContent="flex-start" m={1} p={1} bgcolor="background.paper">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                margin="normal"
                                                id="date-pic45ker-dialog34"
                                                label="Contract Award"
                                                format="dd/MM/yyyy"
                                                value={this.state.awarddate}
                                                onChange={handleAward}
                                                fullWidth
                                                disabled
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Box>
                                    <Box display="flex" justifyContent="flex-start" m={1} p={1} bgcolor="background.paper">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                margin="normal"
                                                id="date-picke56r-dialog35"
                                                label="Contract Completion"
                                                format="dd/MM/yyyy"
                                                value={this.state.completiondate}
                                                onChange={handleCompletion}
                                                fullWidth
                                                disabled
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Box>
                                    <Box display="flex" justifyContent="flex-start" m={1} p={1} bgcolor="background.paper">
                                        <TextField fullWidth id="tender" label="Contract Value"
                                            value={this.state.contract_value}
                                            disabled
                                            onChange={handleContractValue} />
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid item xs={4}>
                                <Paper className={classes.paper}>

                                    <Box display="flex" justifyContent="flex-start" m={1} p={1} bgcolor="background.paper">
                                        <Autocomplete
                                            id="prepared_by"
                                            options={this.state.usersData}
                                            value={(this.state.prepared_by === "") ? (null) : (this.state.prepared_by)}
                                            getOptionLabel={(option) => option.first_name + " " + option.last_name}
                                            style={{ width: '100%' }}
                                            onChange={this.onTags2Change}
                                            getOptionSelected={(option, { multiple, value }) => {
                                                if (!multiple) {
                                                   
                                                    return (option.id === this.state.prepared_by.id);
                                                }

                                                return false;
                                            }}
                                            renderInput={(params) => <TextField {...params} label="Prepared By" variant="outlined" />}
                                        />
                                    </Box>

                                    <Box display="flex" justifyContent="flex-start" m={1} p={1} bgcolor="background.paper">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                margin="normal"
                                                id="date-pic67ker-dialog35"
                                                label="Prepared Date"
                                                format="dd/MM/yyyy"
                                                value={this.state.prepdate}
                                                onChange={handlePreparedDate}
                                                fullWidth
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Box>
                                    <Box display="flex" justifyContent="flex-start" m={1} p={1} bgcolor="background.paper">
                                        <Autocomplete
                                            id="reviewed_by"
                                            options={this.state.usersData}
                                            value={(this.state.reviewed_by === "") ? (null) : (this.state.reviewed_by)}
                                            getOptionLabel={(option) => option.first_name + " " + option.last_name}
                                            style={{ width: '100%' }}
                                            onChange={this.onTags2Change}
                                            getOptionSelected={(option, { multiple, value }) => {
                                                if (!multiple) {
                                                    
                                                    return (option.id === this.state.reviewed_by.id);
                                                }

                                                return false;
                                            }}
                                            renderInput={(params) => <TextField {...params} label="Reviewed By" variant="outlined" />}
                                        />
                                    </Box>
                                    <Box display="flex" justifyContent="flex-start" m={1} p={1} bgcolor="background.paper">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                margin="normal"
                                                id="date-pick78er-dialog35"
                                                label="Reviewed Date"
                                                format="dd/MM/yyyy"
                                                value={this.state.reviewdate}
                                                onChange={handleReviewedDate}
                                                fullWidth
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Box>
                                    <Box display="flex" justifyContent="flex-start" m={1} p={1} bgcolor="background.paper">
                                        <Autocomplete
                                            id="approved_by"
                                            options={this.state.usersData}
                                            value={(this.state.approved_by === "") ? (null) : (this.state.approved_by)}
                                            getOptionLabel={(option) => option.first_name + " " + option.last_name}
                                            style={{ width: '100%' }}
                                            disabled={true}
                                            onChange={this.onTags2Change}
                                            getOptionSelected={(option, { multiple, value }) => {
                                                if (!multiple) {
                                                    
                                                    return (option.id === this.state.approved_by.id);
                                                }

                                                return false;
                                            }}
                                            renderInput={(params) => <TextField {...params} label="DoE Approval" variant="outlined" />}
                                        />
                                    </Box>
                                    <Box display="flex" justifyContent="flex-start" m={1} p={1} bgcolor="background.paper">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                margin="normal"
                                                id="date-pick89er-dialog35"
                                                label="Approved date"
                                                format="dd/MM/yyyy"
                                                disabled={true}
                                                value={this.state.doedate}
                                                onChange={handleApprovedDate}
                                                fullWidth
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Box>
                                </Paper>
                            </Grid> */}
                            {/* <Grid item xs={6}>
                                    <Paper className={classes.paper}>
                                        <Box display="flex" justifyContent="flex-start" m={1} p={1} bgcolor="background.paper">
                                            <TextField fullWidth id="ref" label="Our Ref"
                                                value={this.state.ourRef}
                                                onChange={handleOurref} />
                                        </Box>
                                        <Box display="flex" justifyContent="flex-start" m={1} p={1} bgcolor="background.paper">
                                            <TextField fullWidth id="principal-designer" label="Principal Designer"
                                                value={this.state.principal_designer}
                                                onChange={handlePricipalDesigner} />
                                        </Box>
                                    </Paper>
                                </Grid> */}

                            {/* <Grid item xs={6}>
                                    <Paper className={classes.paper}>


                                        <Box display="flex" justifyContent="flex-start" m={1} p={1} bgcolor="background.paper">
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    margin="normal"
                                                    id="date-pic34ker-dialog3"
                                                    label="Tender Return Date"
                                                    format="dd/MM/yyyy"
                                                    value={this.state.returndate}
                                                    onChange={handleReturnDate}
                                                    fullWidth
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Box>
                                    </Paper>
                                </Grid> */}
                            {/* <Grid item xs={6}>
                                    <Paper className={classes.paper}>
                                        
                                    </Paper>
                                </Grid> */}
                            {/* <Grid item xs={12} >
                                    <MaterialTable
                                        title=""
                                        columns={this.sta4te.columns}
                                        data={this.state.data}
                                        editable={{
                                            onRowUpdate: (newData, oldData) =>
                                                new Promise(resolve => {
                                                    setTimeout(() => {
                                                        resolve();
                                                        if (oldData) {
                                                            this.setState(prevState => {
                                                                const data = [...prevState.data];
                                                                data[data.indexOf(oldData)] = newData;
                                                                return { ...prevState, data };
                                                            });
                                                        }
                                                    }, 60);
                                                }),
                                        }}
                                    />
                                </Grid> */}
                            {/* <Grid item xs={4}>
                                    <Paper className={classes.paper}>


                                    </Paper>
                                </Grid>
                                <Grid item xs={4}>
                                    <Paper className={classes.paper}>


                                    </Paper>
                                </Grid>
                                <Grid item xs={4}>
                                    <Paper className={classes.paper}>


                                    </Paper>
                                </Grid> */}
                            <Grid item xs={12}>
                                <TextField
                                    id="outlined-multili45ne-static"
                                    label="Scope Summary"
                                    multiline
                                    fullWidth
                                    rows={4}
                                    variant="outlined"
                                    onChange={handleScopeSummary}
                                    value={this.state.scope_summary}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Box display="flex" flexDirection="row" justifyContent="center" p={1} m={1} bgcolor="background.paper">
                                    {/* {(this.props.value !== 'null') ? (
                                            <FormControl style={{ minWidth: '100px', marginRight: '15px' }}>
                                                <InputLabel id="demo-simple-select-label1">Budget/Change</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select1"
                                                    value={this.state.audit_status}
                                                    onChange={handleAuditStatus}
                                                >
                                                    <MenuItem value={0}>Gate 2 - In Development</MenuItem>
                                                    <MenuItem value={1}>Gate 2 - Awaiting Approval</MenuItem>
                                                    <MenuItem value={2}>Gate 2 - Approved</MenuItem>
                                                    <MenuItem value={3}>Gate 3 - Submitted</MenuItem>
                                                    <MenuItem value={4}>Gate 3a - Re-Printing</MenuItem>
                                                    <MenuItem value={5}>Gate 3a - Awaiting Approval</MenuItem>
                                                    <MenuItem value={6}>Gate 3a - Approved</MenuItem>
                                                    <MenuItem value={7}>Gate 3a - Submitted</MenuItem>
                                                    <MenuItem value={8}>Gate 4 - Awarded</MenuItem>
                                                    <MenuItem value={9}>Gate 4 - Lost</MenuItem>
                                                    <MenuItem value={10}>Gate 5 - Mobilised</MenuItem>
                                                </Select>
                                            </FormControl>
                                        ) : (
                                                null
                                            )} */}
                                    <Button size='small' variant="contained" onClick={e => this.postData()} color="primary">Submit</Button>
                                </Box>
                            </Grid>


                        </Grid>
                    </div>
                )}
            </div>
        )
    }
}

export default withStyles(useStyles)(withSnackbar(Coversheet));