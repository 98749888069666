import React, { Component } from 'react'
import { withSnackbar } from 'notistack';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import MaterialTable from 'material-table'
// import BalfourBeattyHeader from '../CommonTips/BalfourBeattyHeader';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { getDarkMode } from '../../../Helper/CommonThings';
import moment from 'moment';

const bbColorTheme = createTheme({
    palette: {
        primary: {
            main: '#00b300',
            contrastText: '#fff',
        },
        secondary: {
            main: '#cca300',
            contrastText: '#fff',
        },
        error: {
            main: "#ff0000",
            contrastText: '#fff',
        },
        type: getDarkMode(),
    },
})

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
}))(TableRow);

const useStyles = (theme) => ({
});


class TimesheetTemplate0 extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: props.data,
            toDia: props.toDia,
            toDia2: props.toDia2,
        }
    }

    componentDidMount() {
        // console.log(this.state.toDia2)
    }

    render() {

        return (
            <div>
                <MaterialTable
                    key={1}
                    title={"Timesheet booked by " + (this.state.toDia[1][0] ? this.state.toDia[1][0].uname : "") + " for week [" + (this.state.toDia[0][1] ? moment(this.state.toDia[0][1].title).format('DD/MM/YYYY') : "") + " - " + (this.state.toDia[0][7] ? moment(this.state.toDia[0][7].title).format("DD/MM/YYYY") : "") + "]"}
                    columns={this.state.toDia2[0]}
                    data={this.state.toDia2[1]}
                    options={{
                        padding: "dense",
                        filtering: true,
                        exportButton: { csv: true },
                        pageSize: this.state.toDia2[1].length < 11 ? this.state.toDia2[1].length : 10,
                        pageSizeOptions: [10, 20, 50],
                    }}
                />

                <ThemeProvider theme={bbColorTheme} >
                    <TableContainer style={{ marginTop: '25px' }} component={Paper}>
                        <Table aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    {this.state.toDia[0].map((row) => (
                                        <StyledTableCell key={row.title} align="center">{row.title}</StyledTableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.toDia[1].map((row) => (
                                    <StyledTableRow key={row.uname}>
                                        <StyledTableCell component="th" align="center" scope="row">
                                            {row.uname}
                                        </StyledTableCell>
                                        {this.state.data.comment ? <StyledTableCell component="th" align="center" scope="row">
                                            {this.state.data.comment}
                                        </StyledTableCell> : null}
                                        <StyledTableCell align="center">
                                            <Typography >{row[this.state.toDia[0][this.props.mini_table_data_start].field]}</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <Typography >{row[this.state.toDia[0][this.props.mini_table_data_start + 1].field]}</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <Typography >{row[this.state.toDia[0][this.props.mini_table_data_start + 2].field]}</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <Typography >{row[this.state.toDia[0][this.props.mini_table_data_start + 3].field]}</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <Typography >{row[this.state.toDia[0][this.props.mini_table_data_start + 4].field]}</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <Typography >{row[this.state.toDia[0][this.props.mini_table_data_start + 5].field]}</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <Typography >{row[this.state.toDia[0][this.props.mini_table_data_start + 6].field]}</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <Typography >{row["total"]}</Typography>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </ThemeProvider>
            </div>

        )
    }
}

export default withStyles(useStyles)(withSnackbar(TimesheetTemplate0));





