import React, { Component } from 'react'
import { Avatar, Box, Card, Tooltip, IconButton, Button, Grid, DialogContentText } from '@mui/material';
import 'react-multi-carousel/lib/styles.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { screenSizeCheck } from '../../../ScreensizeCheck/ScreensizeCheck';
import { getBlackAndWhite, getBrowncolor, getGreencolor, getGreyColor, getLaterDuecolor, getOrangecolor, getredcolor, getSkyBluecolor } from '../../../ColorTheme/ColorTheme';
import moment from "moment";
import UserHoursRequest from '../../../../HoursRequest/UserHoursRequest';
import { getCookie } from '../../../../Helper/CookieHelper';
import Link from '@mui/material/Link';
import { withSnackbar } from 'notistack';
import Service from '../../../../Networking/networkutils'
import { errorMessage } from '../../../../Helper/ErrorMessage';
import WorkIcon from '@mui/icons-material/Work';
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import ProjectStackedProgressbar from '../../../Charts/ProjectStackedProgressbar';
import SubContractLevelTabs from '../../../../SecuredWorks/SubContractActual/SubContractLevelTabs';
// import TaskLevelTabs from '../../../../SecuredWorks/TaskLevelAllocation/TaskLevelTabs';
import { CoffeeLoading } from 'react-loadingg';
import { getPrimaryColor } from '../../../../Helper/CommonThings';
import 'date-fns';
import WebIcon from '@material-ui/icons/Web';
import CloseIcon from '@material-ui/icons/Close';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Slide from '@material-ui/core/Slide';
import MainTenderSubTasks from '../../../../TenderSubTasks/MainTenderSubTasks';
import SubTenderProgressBar from '../../../../TenderSubTasks/SubTenderProgressBar/SunTenderProgressBar';
import { CsvBuilder } from 'filefy';
import { Autocomplete } from '@material-ui/lab';
import Allocation from '../../../../Allocation/Allocation';

const token = getCookie("bb_ts_token");

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    if (props.slideCount <= props.data + props.currentSlide) {
        return <div></div>
    } else {
        return (
            <div
                className={className}
                style={{ ...style, display: "block", borderRadius: '50px', background: "grey" }}
                onClick={onClick}
            />
        );
    }
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    if (props.currentSlide <= 0) {
        return <div></div>
    } else {
        return (
            <div
                className={className}
                style={{ ...style, display: "block", borderRadius: '50px', background: "grey" }}
                onClick={onClick}
            />
        );
    }
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

var cardMaxHeight = 0
var boxHeight = 0

const user = JSON.parse(getCookie("bb_ts_user"));

class ProjectCardList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cardHeight: null,
            openRequest: false,
            dialogData: null,
            openTaskUpdate: false,
            perTaskLoading: false,
            taskPercent: 0,
            perTaskData: null,
            data: [],
            allData: null,
            projectLook: null,
            allRoles: null,
            isLoading: false,
            boxHeight: null,
            scrollHeight: 1800,
            openTaskLevelAllocation: false,
            usersData: this.props.usersData,
            openCoffee: false,
            ds: null,
            rates: null,
            openCompleteTask: false,
            openDateDialog: false,
            startDate: null,
            endDate: null,
            openSubTender: false,
            subTenderData: null,
            openTaskDownload: false,
            dialogLoader: false,
            reasonDelayDialog: false,
            dateChangeNouns: [],
            dateChangeAdjectives: [],
            selectedNoun: null,
            selectedAdjective: null
        }
    }

    componentDidMount() {
        boxHeight = 0
        cardMaxHeight = 0
        this.taskSearch(this.props.presentSearch)
        setTimeout(() => {
            this.setState({
                cardHeight: cardMaxHeight,
                boxHeight: boxHeight,
            })
        }, 200)
        this.fetchNounsAndAdjectives()
    }

    fetchNounsAndAdjectives() {
        Promise.all([
            this.getNouns(),
            this.getAdjectives()
        ])
            .then(res => {
                this.setState({ dateChangeNouns: res[0], dateChangeAdjectives: res[1] })
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                })
            })
    }

    getNouns() {
        return new Promise((resolve, reject) => {
            Service.get("/bb/api/task/date/change/nouns/", {
                headers: {
                    Authorization: "Token " + token,
                },
            }).then(res => resolve(res.data)).catch(error => reject(error))
        })
    }

    getAdjectives() {
        return new Promise((resolve, reject) => {
            Service.get("/bb/api/task/date/change/adjective/", {
                headers: {
                    Authorization: "Token " + token,
                },
            }).then(res => resolve(res.data)).catch(error => reject(error))
        })
    }

    getHeight(ref) {
        if (ref && cardMaxHeight < ref.clientHeight) {
            cardMaxHeight = ref.clientHeight
        }
    }

    boxHeight(ref) {
        if (ref && boxHeight < ref.clientHeight) {
            boxHeight = ref.clientHeight
            // return boxHeight
        }
    }

    UserHoursRequest(data) {
        this.setState({ openRequest: true, dialogData: data })
    }

    fetchPerTaskData(rowData, type) {
        this.setState({ perTaskData: null })
        Service.get('/bb/api/tender/wbs/tasks/' + rowData.id + '/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res.data)
                if (type === 'handleTaskPercent') {
                    this.setState({
                        perTaskLoading: false,
                        perTaskData: res.data,
                        taskPercent: res.data.comp_percnt,
                    })
                } else if (type === 'handleDate') {
                    this.setState({
                        openDateDialog: true,
                        perTaskData: res.data,
                        startDate: res.data.act_exp_start,
                        endDate: res.data.act_exp_finish
                    })
                }

            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    fetchPerTaskData2(rowData) {
        this.setState({
            openCoffee: true
        })
        Service.get('/bb/api/tender/wbs/tasks/' + rowData.id + '/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({
                    openTaskLevelAllocation: true,
                    allData: res.data,
                    openCoffee: false,
                    ds: res.data.t_wbs_data.pid_data,
                }, () => {
                    if ((this.state.ds && this.state.ds.contract_type)) {
                        Service.get('/bb/api/tender/rate/?pid=' + rowData.ds_id, {
                            headers: {
                                Authorization: "Token " + token,
                            },
                        })
                            .then(res2 => {
                                this.setState({ rates: res2.data })
                            })
                            .catch(error => {
                                errorMessage(this.props, error)
                            });
                    }
                })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    handleTaskComp(key) {

        if (this.state.taskPercent === 100 && key === 'proceed_not_if_100') {
            this.setState({
                openCompleteTask: true
            })
            return
        }

        this.setState({
            openTaskUpdate: false,
        })
        var data = this.state.perTaskData;

        var historydata = null

        if (data.comp_percnt !== this.state.taskPercent) {
            Service.post('/bb/api/tender/tasks/history/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: {
                    "t_pid": data.t_pid,
                    "t_wbs": data.t_ohlwbs,
                    "t_tasks": data.id,
                    "prev_task_status": data.comp_percnt,
                    "task_status": this.state.taskPercent,
                    "created_by": user.id
                },
            })
                .then(res => {
                    historydata = res.data
                    data.comp_percnt = this.state.taskPercent;
                    Service.put('/bb/api/tender/wbs/tasks/' + this.state.perTaskData.id + '/', {
                        headers: {
                            Authorization: "Token " + token,
                        },
                        data: data,
                    })
                        .then(res1 => {
                            var tempData = this.state.data
                            // console.log(tempData)
                            for (let i = 0; i < tempData.length; i++) {
                                const element = tempData[i];
                                if (element.id === this.state.perTaskData.id) {
                                    element.complete_percent = this.state.taskPercent;
                                    break
                                }
                            }


                            // if (this.state.taskPercent === 100) {
                            //     this.props.refreshData();
                            //     Service.patch('/bb/api/tender/wbs/tasks/complete_task/', {
                            //         headers: {
                            //             Authorization: "Token " + token,
                            //         },
                            //         data: {
                            //             "ttids": [data.id]
                            //         }
                            //     })
                            //         .catch(error => {
                            //             errorMessage(this.props, error)
                            //         });
                            // }


                            this.setState({
                                perTaskLoading: false,
                                taskPercent: 0,
                                openTaskUpdate: false,
                                data: tempData,
                                openCompleteTask: false
                            })
                            this.props.enqueueSnackbar('Task status updated!', {
                                variant: 'success'
                            });

                        })
                        .catch(error => {
                            errorMessage(this.props, error)
                            if (historydata) {
                                Service.delete('/bb/api/tender/tasks/history/' + historydata.id + '/', {
                                    headers: {
                                        Authorization: "Token " + token,
                                    },
                                })
                                    .catch(error => {
                                        errorMessage(this.props, error)
                                    });
                            }
                        });
                })
                .catch(error => {
                    errorMessage(this.props, error)
                });
        }
    }

    handleTaskPercent(value) {
        this.fetchPerTaskData(value, "handleTaskPercent");
        this.setState({ openTaskUpdate: true, taskPercent: value.complete_percent, })
    }

    taskSearch(data) {
        const data_lowercase = data.toLowerCase()
        var search_words = data_lowercase.split(" ");
        search_words = search_words.filter(n => n)
        var filtered_data = []
        if (search_words.length === 0) {
            filtered_data = this.props.data

        }
        for (let i = 0; i < this.props.data.length; i++) {
            const element = this.props.data[i];
            var num_of_passed = false
            for (let j = 0; j < search_words.length; j++) {
                const el = search_words[j];
                num_of_passed = element.search_key.includes(el)
                if (!num_of_passed) {
                    break
                }
            }
            if (num_of_passed) {
                filtered_data.push(element)
            }
        }

        this.setState({ data: filtered_data })
        return filtered_data
    }

    refCallback = el => {
        if (el) {
            this.setState({
                scrollHeight: window.innerHeight - el.getBoundingClientRect().y - 28
            }, () => {
                this.props.childHeight(this.state.scrollHeight)
            })
        }
    };

    setUsers(data) {
        this.setState({ usersData: data })
    }

    handleDate(value) {
        this.fetchPerTaskData(value, "handleDate");
        // this.setState({ openDateDialog: true, startDate: start, endDate: end })
    }

    dateSubmit() {
        if (this.state.startDate && this.state.endDate) {
            let date = !this.state.perTaskData.act_exp_finish ? this.state.perTaskData.to_date : this.state.perTaskData.act_exp_finish
            if (moment(date) < moment(this.state.endDate)) {
                this.setState({ reasonDelayDialog: true, selectedAdjective: null, selectedNoun: null })
            } else {
                let data = {
                    start: moment(this.state.startDate).format('YYYY-MM-DD'),
                    end: moment(this.state.endDate).format('YYYY-MM-DD'),
                    task_id: this.state.perTaskData.id
                }
                this.dateUpdate(data)
            }
        } else {
            this.props.enqueueSnackbar('Fill both the dates!', {
                variant: 'warning'
            });
        }

    }

    setReasonDelay() {
        if (!this.state.selectedNoun || !this.state.selectedAdjective) {
            this.props.enqueueSnackbar('Please select the fields!', {
                variant: 'warning'
            });
        } else {
            let data = {
                start: moment(this.state.startDate).format('YYYY-MM-DD'),
                end: moment(this.state.endDate).format('YYYY-MM-DD'),
                task_id: this.state.perTaskData.id,
                noun: this.state.selectedNoun.id,
                adjective: this.state.selectedAdjective.id
            }
            this.dateUpdate(data)
        }
    }

    dateUpdate(data) {
        if (!data) {
            this.props.enqueueSnackbar('Something Went Wrong!!', {
                variant: 'error'
            });
        } else {
            Service.put('/bb/api/tender/wbs/tasks/task_date_update/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: data
            })
                .then(res => {
                    this.updateDate()
                    this.props.enqueueSnackbar('Dates updated successfully', {
                        variant: 'success'
                    });
                    this.setState({ reasonDelayDialog: false })
                })
                .catch(error => {
                    console.log(error)
                    if (error && error.status === 406) {
                        this.props.enqueueSnackbar(
                            error.data, { variant: 'warning' });
                        this.setState({ openDateDialog: false, reasonDelayDialog: false })
                    } else {
                        errorMessage(this.props, error)
                        this.setState({ openDateDialog: false, reasonDelayDialog: false })
                    }

                });
        }
    }

    updateDate() {
        var oldData = this.state.data
        for (let i = 0; i < oldData.length; i++) {
            const element = oldData[i];
            if (element.id === this.state.perTaskData.id) {
                element.start_date = this.state.startDate
                element.end_date = this.state.endDate
            }
        }
        this.setState({ data: oldData, openDateDialog: false, })
    }

    openSubTender(data) {
        this.setState({ subTenderData: data }, () => { this.setState({ openSubTender: true }) })
    }

    openTaskDownload() {
        this.setState({ openTaskDownload: true })
    }

    downloadSubtask() {
        var data = this.state.data
        var task_ids = []
        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            if (element) {
                task_ids.push(element.id)
            }
        }
        if (task_ids.length !== 0) {
            this.downloadData(task_ids)
        } else {
            this.props.enqueueSnackbar('No Tasks to download!', {
                variant: 'warning'
            });
        }
    }

    downloadData(ids) {
        Service.get(`/bb/api/tender/subtask/download/?tid=${ids.toString()}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.exportSubtaskCsv(res.data)
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    exportSubtaskCsv(initialData) {
        this.setState({ dialogLoader: true })
        const columns = ['Project Name', 'DS Name', 'WBS Name', 'Task ID', 'Task Name', 'Baseline Start', 'Baseline Finish', '% Complete', 'Actual Start', 'Actual Finish', 'Planner Task Name', 'Progress', 'Priority', 'Planner Task Start', 'Planner Task Finish', 'Planner Description', 'Members Assigned', 'Subtask Name', 'Subtask Progress', 'Subtask Priority', 'Subtask Start', 'Subtask Finish', 'Subtask Description', 'Subtask Members Assigned']

        const data = initialData.map(rowData =>
            columns.map(columnDef => {
                if (columnDef === 'Project Name') {
                    return rowData.maintask.tender_name
                } else if (columnDef === 'DS Name') {
                    return rowData.maintask.ds_name
                } else if (columnDef === 'WBS Name') {
                    return rowData.maintask.wbs_name
                } else if (columnDef === 'Task ID') {
                    return rowData.maintask.ohlwbs_tasks
                } else if (columnDef === 'Task Name') {
                    return rowData.maintask.task_name
                } else if (columnDef === 'Baseline Start') {
                    return moment(rowData.maintask.baseline_start).format('DD-MM-YYYY')
                } else if (columnDef === 'Baseline Finish') {
                    return moment(rowData.maintask.baseline_finish).format('DD-MM-YYYY')
                } else if (columnDef === '% Complete') {
                    return rowData.maintask.percentage_comp
                } else if (columnDef === 'Actual Start') {
                    return moment(rowData.maintask.act_start).format('DD-MM-YYYY')
                } else if (columnDef === 'Actual Finish') {
                    return moment(rowData.maintask.act_finish).format('DD-MM-YYYY')
                } else if (columnDef === 'Planner Task Name') {
                    return rowData.maintask.title
                } else if (columnDef === 'Progress') {
                    return rowData.maintask.progress
                } else if (columnDef === 'Priority') {
                    return rowData.maintask.priority
                } else if (columnDef === 'Planner Task Start') {
                    return rowData.maintask.planned_start
                } else if (columnDef === 'Planner Task Finish') {
                    return rowData.maintask.planned_end
                } else if (columnDef === 'Planner Description') {
                    return rowData.maintask.description
                } else if (columnDef === 'Members Assigned') {
                    let users = []
                    for (let i = 0; i < rowData.maintask.users.length; i++) {
                        const element = rowData.maintask.users[i];
                        if (element) {
                            users.push(element.last_name + ", " + element.first_name)
                        }
                    }
                    return users.toString()
                } else if (columnDef === 'Subtask Name') {
                    return rowData.subtask.title ? rowData.subtask.title : null
                } else if (columnDef === 'Subtask Progress') {
                    return rowData.subtask.progress ? rowData.subtask.progress : null
                } else if (columnDef === 'Subtask Priority') {
                    return rowData.subtask.priority ? rowData.subtask.priority : null
                } else if (columnDef === 'Subtask Start') {
                    return rowData.subtask.planned_start ? rowData.subtask.planned_start : null
                } else if (columnDef === 'Subtask Finish') {
                    return rowData.subtask.planned_end ? rowData.subtask.planned_end : null
                } else if (columnDef === 'Subtask Description') {
                    return rowData.subtask.description ? rowData.subtask.description : null
                } else if (columnDef === 'Subtask Members Assigned') {
                    if (rowData.subtask.users) {
                        let users = []
                        for (let i = 0; i < rowData.subtask.users.length; i++) {
                            const element = rowData.subtask.users[i];
                            if (element) {
                                users.push(element.last_name + ", " + element.first_name)
                            }
                        }
                        return users.toString()
                    }
                }
                return null
            })
        );

        const builder = new CsvBuilder("Subtask-" + moment().format('DD-MM-YYYY hh:mm') + '.csv');
        builder
            .setDelimeter(',')
            .setColumns(columns.map(columnDef => columnDef))
            .addRows(data)
            .exportFile();

        this.setState({ openTaskDownload: false, dialogLoader: false })
    };

    downloadTask() {
        var datas = this.state.data
        if (datas.length !== 0) {
            this.setState({ dialogLoader: true })
            const columns = ['Project Name', 'DS Name', 'WBS Name', 'Task ID', 'Task Name', 'Actual Start Date', 'Actual End Date', '% Complete', 'Members Assigned', 'Subtask Total', 'SubTask Done', 'Actual', 'Assigned', 'Remaining', 'Total']

            const data = datas.map(rowData =>
                columns.map(columnDef => {
                    if (columnDef === 'Project Name') {
                        return this.props.currentProject ? this.props.currentProject.tender_name : null
                    } else if (columnDef === 'DS Name') {
                        return rowData.ds_name
                    } else if (columnDef === 'WBS Name') {
                        return rowData.wbs_name
                    } else if (columnDef === 'Task ID') {
                        return rowData.id
                    } else if (columnDef === 'Task Name') {
                        return rowData.name
                    } else if (columnDef === 'Actual Start Date') {
                        return moment(rowData.start_date).format('DD-MM-YYYY')
                    } else if (columnDef === 'Actual End Date') {
                        return moment(rowData.end_date).format('DD-MM-YYYY')
                    } else if (columnDef === '% Complete') {
                        return rowData.complete_percent
                    } else if (columnDef === 'Members Assigned') {
                        let users = []
                        for (let i = 0; i < rowData.remaining_users.length; i++) {
                            const element = rowData.remaining_users[i];
                            if (element) {
                                users.push(element.last_name + ", " + element.first_name)
                            }
                        }
                        return users.toString()
                    } else if (columnDef === 'Subtask Total') {
                        return rowData.subtasks_total
                    } else if (columnDef === 'SubTask Done') {
                        return rowData.subtasks_done
                    } else if (columnDef === 'Actual') {
                        return rowData.is_approved
                    } else if (columnDef === 'Assigned') {
                        return rowData.total_hours_planned
                    } else if (columnDef === 'Remaining') {
                        return (rowData.total_hours - rowData.total_hours_planned) % 1 === 0 ? (rowData.total_hours - rowData.total_hours_planned) : (rowData.total_hours - rowData.total_hours_planned).toFixed(1)
                    } else if (columnDef === 'Total') {
                        return rowData.total_hours
                    }
                    return null
                })
            );

            const builder = new CsvBuilder("Task-" + moment().format('DD-MM-YYYY hh:mm') + '.csv');
            builder
                .setDelimeter(',')
                .setColumns(columns.map(columnDef => columnDef))
                .addRows(data)
                .exportFile();

            this.setState({ openTaskDownload: false, dialogLoader: false })
        } else {
            this.props.enqueueSnackbar('No Tasks to download!', {
                variant: 'warning'
            });
        }
    }

    onNounChange = (event, values) => {
        this.setState({
            selectedNoun: values
        })
    }

    onAdjectiveChange = (event, values) => {
        this.setState({
            selectedAdjective: values
        })
    }

    render() {

        const settings = {
            centerMode: false,
            infinite: false,
            slidesToShow: screenSizeCheck() ? 4 : 6,
            slidesToScroll: screenSizeCheck() ? 4 : 6,
            speed: 300,
            rows: screenSizeCheck() ? 1 : 1,
            arrows: true,
            nextArrow: <SampleNextArrow data={screenSizeCheck() ? 5 : 6} />,
            prevArrow: <SamplePrevArrow data={screenSizeCheck() ? 5 : 6} />,
            adaptiveHeight: true,
            accessibility: true,
        };

        const handleRequestClose = () => {
            this.setState({ openRequest: false, })
        };

        const handleTaskClose = () => {
            this.setState({ openTaskUpdate: false, })
        };

        const handlePercChange = (e) => {
            this.setState({ taskPercent: e.target.value })
        };

        const handleCompleteTaskClose = () => {
            this.setState({ openCompleteTask: false, });
        };

        const handleDateDialogClose = () => {
            this.setState({ openDateDialog: false, });
        };

        const handleSubTenderClose = () => {
            this.setState({ openSubTender: false, subTenderData: null })
            this.props.refreshData()
        };

        const handleTaskDownloadClose = () => {
            this.setState({ openTaskDownload: false, })
        };

        const reasondelayDialogClose = () => {
            this.setState({ reasonDelayDialog: false, })
        };
        return (
            <div ref={this.refCallback} style={{ height: this.state.scrollHeight + "px", overflowY: 'scroll' }}>
                {this.state.data.length === 0 ?
                    <Box mt={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 1, fontWeight: 'bold', height: this.state.scrollHeight }}>No Tasks Available for your Search Keyword</Box> :
                    <Grid container spacing={1.5}>
                        {this.state.data.map((value, i) =>
                            <Grid item xs={screenSizeCheck() ? 12 / (this.props.maxCards - 1) : 12 / this.props.maxCards}>
                                <Box m={.2} ref={(r) => { this.boxHeight(r) }} sx={{
                                    height: '100%',
                                    alignItems: 'stretch',
                                }} >
                                    <Tooltip title={<div>DS Name : {value.ds_name}<br /> Wbs Name : {value.wbs_name}<br />Task Id : {value.id}<br />Task Name : {value.name}<br /></div>}>
                                        <Card sx={{ borderRadius: '20px 20px 20px 20px', height: '100%' }} elevation={2} >
                                            <div style={{ height: '100%' }}>
                                                <Box m={2} sx={{
                                                    alignItems: 'stretch',
                                                    flexDirection: 'row',
                                                }}>
                                                    <Box ref={(r) => { this.getHeight(r) }} sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        height: this.state.cardHeight ? this.state.cardHeight : '100%',
                                                    }}>
                                                        <Box sx={{
                                                            flexGrow: 1,
                                                            flexWrap: 'wrap'
                                                        }}>
                                                            <Box fontSize={'16px'} color={getPrimaryColor()} margin='2px' fontWeight={'bold'}>
                                                                {value.ds_name}
                                                            </Box>
                                                            <Box fontSize={'14px'} color={getGreyColor()} margin='2px' >
                                                                {value.name + " - "}
                                                                {
                                                                    <Link
                                                                        component="button"
                                                                        variant="body2"
                                                                        onClick={() => this.handleTaskPercent(value)}>
                                                                        {value.complete_percent + "%"}
                                                                    </Link>
                                                                }
                                                                {value.inc_evp === 0 ? ' - Non Billable' : ' - Billable'}
                                                            </Box>
                                                        </Box>
                                                        <Box sx={{
                                                            height: '100%',
                                                            flexWrap: 'wrap',
                                                        }}   >
                                                            <Box display='flex' flexDirection='column'>
                                                                <Tooltip title="Task Allocation">
                                                                    <IconButton size='small' onClick={(() => this.fetchPerTaskData2(value))}>
                                                                        <WorkIcon fontSize='small' />
                                                                    </IconButton>
                                                                </Tooltip>

                                                            </Box>
                                                        </Box>
                                                    </Box>

                                                    <Box sx={{ alignSelf: 'flex-end' }}>
                                                        <Box sx={{
                                                            display: 'flex',
                                                            justifyContent: 'flex-start',
                                                            borderRadius: 1,
                                                        }}>
                                                            <Box fontSize={screenSizeCheck() ? '10px' : '12px'} color={getGreencolor()} fontWeight={'bold'} marginRight={value.task_hold === 1 ? '10px' : '15px'}>
                                                                Start: <Link
                                                                    color={getGreencolor()}
                                                                    component="button"
                                                                    variant="body2"
                                                                    onClick={() => this.handleDate(value)}>
                                                                    {moment(value.start_date).format('DD-MM-YYYY')}
                                                                </Link>
                                                            </Box>

                                                            <Box fontSize={screenSizeCheck() ? '10px' : '12px'} fontWeight={'bold'} marginRight={value.task_hold === 1 ? '10px' : '15px'}>
                                                                End: <Link
                                                                    color={getBlackAndWhite()}
                                                                    component="button"
                                                                    variant="body2"
                                                                    onClick={() => this.handleDate(value)}>
                                                                    {moment(value.end_date).format('DD-MM-YYYY')}
                                                                </Link>
                                                            </Box>
                                                            <Tooltip title={value.task_stand === 1 ? "Start soon" : value.task_stand === 2 ? "Ready to start" : value.task_stand === 3 ? "Soon due" : value.task_stand === 4 ? "Over due" : value.task_stand === 6 ? "Later due" : 'No date info'}>
                                                                <Box marginRight={value.task_hold === 1 ? '10px' : '0px'} fontSize={screenSizeCheck() ? '10px' : '12px'} onClick={() => this.props.handleChange(value.task_stand)} fontWeight={'bold'} style={{ cursor: 'pointer' }} color={value.task_stand === 0 ? '#4DB6AC' : value.task_stand === 1 ? '#FFF176' : value.task_stand === 2 ? '#DCE775' : value.task_stand === 3 ? '#FFB74D' : value.task_stand === 4 ? '#EF9A9A' : value.task_stand === 6 ? getLaterDuecolor() : 'primary'}>
                                                                    {value.task_stand === 1 ? "Start soon" : value.task_stand === 2 ? "Ready to start" : value.task_stand === 3 ? "Soon due" : value.task_stand === 4 ? "Over due" : value.task_stand === 6 ? "Later due" : 'No date info'}
                                                                </Box>
                                                            </Tooltip>
                                                            {value.task_hold === 1 ?
                                                                <Tooltip title='This task is ON HOLD'>
                                                                    <Box fontSize={screenSizeCheck() ? '11px' : '13px'} fontWeight={'bold'} color={getredcolor()}>
                                                                        ON HOLD
                                                                    </Box>
                                                                </Tooltip>
                                                                : null}
                                                        </Box>
                                                        <Box
                                                            mt={1}
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                            }}>
                                                            <Tooltip title="Planner">
                                                                <IconButton size='small' onClick={(() => this.openSubTender(value))}>
                                                                    <WebIcon fontSize='small' />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Box sx={{
                                                                flexGrow: 1,
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                borderRadius: 1,
                                                                marginRight: '5px',
                                                                cursor: 'pointer'
                                                            }} onClick={(() => this.openSubTender(value))}>
                                                                {/* <MultiProgress
                                                                    transitionTime={1.2}
                                                                    elements={[
                                                                        {
                                                                            value: (value.subtasks_done / value.subtasks_total) * 100,
                                                                            color: getGreencolor(),
                                                                        },
                                                                        {
                                                                            value: 100 - ((value.subtasks_done / value.subtasks_total) * 100),
                                                                            color: getOrangecolor(),
                                                                        },

                                                                    ]}
                                                                    height={10}
                                                                    backgroundColor={getProgBgColor()}
                                                                /> */}
                                                                <SubTenderProgressBar subtasks_done={value.subtasks_done} subtasks_total={value.subtasks_total} />
                                                            </Box>
                                                            <Box fontSize={screenSizeCheck() ? '10px' : '12px'}> {value.subtasks_done} of {value.subtasks_total} sub-task completed</Box>
                                                        </Box>
                                                        <Box
                                                            m={2}
                                                            sx={{
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                borderRadius: 1,
                                                            }}>
                                                            <Slider {...settings}>
                                                                {value.remaining_users.length > 0 ?
                                                                    value.remaining_users.map((name, i) =>
                                                                        <Box key={i}
                                                                            sx={{
                                                                                justifyContent: 'center',
                                                                                alignItems: 'center',
                                                                                borderRadius: 1,
                                                                                width: '100%',
                                                                            }} >
                                                                            <Tooltip title={this.props.is_teamtasks ? name.last_name + ", " + name.first_name + '(' + name.planed_hours + ')' : name.last_name + ", " + name.first_name}>
                                                                                <Avatar sx={{ bgcolor: '#99CFBB' }} ><Box fontWeight={'bold'} fontSize={'18px'}>{name.first_name.charAt(0) + name.last_name.charAt(0)}</Box></Avatar>
                                                                            </Tooltip>
                                                                        </Box>
                                                                    ) :
                                                                    <Box
                                                                        sx={{
                                                                            justifyContent: 'center',
                                                                            alignItems: 'center',
                                                                            borderRadius: 1,
                                                                            width: '100%',
                                                                        }} >
                                                                        <Tooltip title={"Not Available"}>
                                                                            <Avatar sx={{ bgcolor: '#c8c8c8' }} ><Box fontWeight={'bold'} fontSize={'18px'}>NA</Box></Avatar>
                                                                        </Tooltip>
                                                                    </Box>
                                                                }
                                                            </Slider>
                                                        </Box>
                                                        <Box sx={{
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            borderRadius: 1,
                                                            flexDirection: 'row',
                                                            display: 'flex',
                                                            width: '100%',
                                                            height: '100%'
                                                        }} >
                                                            <ProjectStackedProgressbar actual={value.is_approved} assigned={value.total_hours_planned} total={value.total_hours} />
                                                        </Box>
                                                        <Box mt={1} sx={{
                                                            display: 'flex',
                                                            justifyContent: 'space-evenly',
                                                            borderRadius: 1,
                                                        }}>
                                                            <Box fontSize={screenSizeCheck() ? '10px' : '12px'} color={getGreencolor()} fontWeight={'bold'}>
                                                                Actual: {value.is_approved}
                                                            </Box>
                                                            <Box fontSize={screenSizeCheck() ? '10px' : '12px'} color={getOrangecolor()} fontWeight={'bold'}>
                                                                Assigned: {value.total_hours_planned}
                                                            </Box>
                                                            <Box fontSize={screenSizeCheck() ? '10px' : '12px'} color={getBrowncolor()} fontWeight={'bold'}>
                                                                Remain: {(value.total_hours - value.total_hours_planned) % 1 === 0 ? (value.total_hours - value.total_hours_planned) : (value.total_hours - value.total_hours_planned).toFixed(1)}
                                                            </Box>
                                                            <Box fontSize={screenSizeCheck() ? '10px' : '12px'} color={getSkyBluecolor()} fontWeight={'bold'}>
                                                                Total: {value.total_hours}
                                                            </Box>
                                                        </Box>
                                                    </Box>

                                                </Box>
                                            </div>
                                        </Card>
                                    </Tooltip>
                                </Box >
                            </Grid>
                        )}
                    </Grid>
                }

                <Dialog onClose={handleRequestClose} fullWidth={true} aria-labelledby="customized-dialog-title" open={this.state.openRequest}>
                    <DialogTitle id="customized-dialog-title1" onClose={handleRequestClose}>
                        {this.state.dialogData ? (this.state.dialogData.decs ? this.state.dialogData.decs : this.state.dialogData.name) : null}
                    </DialogTitle>
                    <DialogContent dividers>
                        <UserHoursRequest value={this.state.dialogData} uid={user.id} tender={this.state.dialogData ? this.state.dialogData.project_id : null} onCloseCommand={handleRequestClose} />
                    </DialogContent>
                </Dialog>

                <Dialog fullWidth
                    open={this.state.openTaskUpdate}
                    onClose={handleTaskClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle>{this.state.perTaskData ? this.state.perTaskData.name : null}</DialogTitle>
                    <DialogContent>
                        {(this.state.perTaskLoading) ? (null) : (
                            <FormControl fullWidth >
                                <InputLabel fullWidth id="demo-simple-select-label">Select Percentage Complete</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    fullWidth
                                    value={this.state.taskPercent}
                                    onChange={handlePercChange}>
                                    <MenuItem value={0}>0% - Not started.</MenuItem>
                                    <MenuItem value={5}>5% - Preparation of documents/drawings.</MenuItem>
                                    <MenuItem value={10}>10% - Preparation of documents/drawings.</MenuItem>
                                    <MenuItem value={20}>20% - Preparation of documents/drawings.</MenuItem>
                                    <MenuItem value={30}>30% - Preparation of documents/drawings.</MenuItem>
                                    <MenuItem value={40}>40% - Preparation of documents/drawings.</MenuItem>
                                    <MenuItem value={50}>50% - Preparation of documents/drawings.</MenuItem>
                                    <MenuItem value={60}>60% - Checked within Department.</MenuItem>
                                    <MenuItem value={65}>65% - Approved within Department.</MenuItem>
                                    <MenuItem value={70}>70% - Site comments received, reviewed.</MenuItem>
                                    <MenuItem value={80}>80% - Multi-disciplinary Reviewed & Commented.</MenuItem>
                                    <MenuItem value={90}>90% - Design verified.</MenuItem>
                                    <MenuItem value={100}>100% - Design assured.</MenuItem>
                                </Select>
                            </FormControl>
                        )}

                    </DialogContent>
                    <DialogActions>
                        <Button size='small' onClick={handleTaskClose} color="primary">
                            Close
                        </Button>
                        <Button size='small' onClick={() => this.handleTaskComp("proceed_not_if_100")} disabled={!this.state.perTaskData} color="primary">
                            Submit
                        </Button>

                    </DialogActions>
                </Dialog>
                <Dialog
                    fullScreen
                    open={this.state.openTaskLevelAllocation}
                    onClose={() => this.setState({ openTaskLevelAllocation: false })}>
                    <AppBar sx={{ position: 'relative' }} color='primary'>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={() => this.setState({ openTaskLevelAllocation: false })}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" >
                                {(this.state.allData) ? this.state.allData.id + " " + this.state.allData.name : null}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        <div style={{ marginTop: '60px' }}>
                            {(this.state.ds && this.state.ds.contract_type) ? (
                                <SubContractLevelTabs value={this.state.allData} ds={this.state.ds} rates={this.state.rates} />
                            ) : (
                                <Allocation value={this.state.allData} usersData={this.state.usersData} />
                                // <TaskLevelTabs value={this.state.allData} usersData={this.state.usersData} />
                            )}
                        </div>
                    </DialogContent>
                </Dialog>

                <Dialog maxWidth="sm" open={this.state.openCoffee} aria-labelledby="form-dialog-title">
                    {/* <DialogTitle id="customized-dialog-title" onClose={handleFeedbackClose}>
                                    Additional Hours Request
                                </DialogTitle> */}
                    <DialogContent>
                        <Box m={5} p={2}>
                            <CoffeeLoading />
                        </Box>
                    </DialogContent>
                </Dialog>

                <Dialog
                    open={this.state.openCompleteTask}
                    // fullWidth={true}
                    maxWidth={'md'}
                    onClose={handleCompleteTaskClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-title">{"You have updated task(s) to 100% complete.."}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            This process will mark:<br />
                            &nbsp;&nbsp;&nbsp;1. The task to completed status<br />
                            &nbsp;&nbsp;&nbsp;2. All the allocated remaining hours to the individuals in this task will be zeroed out<br />
                            &nbsp;&nbsp;&nbsp;3. All the CTC hours of the Roles will be set to zero<br />
                            &nbsp;&nbsp;&nbsp;4. All the actual hours will be equalled to allocated hours<br />
                            <pre />Note : If the user has done his timesheet but pending submission, they can still send it to you for approval. If the user has not initiated the timesheet for the hours allocated to them – those hours will be set to zero and will not be able to see the task in their calendar.

                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button size='small' onClick={handleCompleteTaskClose} color="primary">
                            Close
                        </Button>
                        <Button size='small' onClick={() => { this.handleTaskComp('proceed_if_100') }} color="primary">
                            Proceed
                        </Button>

                    </DialogActions>
                </Dialog>

                <Dialog
                    fullWidth
                    open={this.state.openDateDialog}
                    onClose={handleDateDialogClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle>{this.state.perTaskData ? (this.state.perTaskData.decs ? this.state.perTaskData.decs : this.state.perTaskData.name) : null}</DialogTitle>
                    <DialogContent>
                        <Grid container justifyContent="space-around">
                            <TextField
                                id="startdate"
                                label="Actual/Expected Start"
                                type="date"
                                autoFocus
                                style={{ width: '40%' }}
                                variant="outlined"
                                defaultValue={(this.state.perTaskData && this.state.perTaskData.act_exp_start) ? moment(this.state.perTaskData.act_exp_start).format('YYYY-MM-DD') : null}
                                onChange={(e) => {
                                    this.setState({ startDate: e.target.value });
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                            <TextField
                                id="enddate"
                                label="Actual/Expected Finish"
                                type="date"
                                style={{ width: '40%' }}
                                variant="outlined"
                                defaultValue={(this.state.perTaskData && this.state.perTaskData.act_exp_finish) ? moment(this.state.perTaskData.act_exp_finish).format('YYYY-MM-DD') : null}
                                onChange={(e) => {
                                    this.setState({ endDate: e.target.value });
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button size='small' onClick={handleDateDialogClose} color="primary">
                            Close
                        </Button>
                        <Button size='small' color="primary" onClick={() => this.dateSubmit()} disabled={!this.state.perTaskData}>
                            Submit
                        </Button>

                    </DialogActions>
                </Dialog>

                <Dialog fullScreen open={this.state.openSubTender} aria-labelledby="customized-dialog-title" TransitionComponent={Transition}>
                    <AppBar position="static">
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={handleSubTenderClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" >
                                {this.state.subTenderData ? this.state.subTenderData.name + " (" + this.props.currentProject.tender_name + " -> " + this.state.subTenderData.ds_name + ") " : null}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    {/* <DialogContent> */}
                    <MainTenderSubTasks task_id={this.state.subTenderData ? this.state.subTenderData.id : null} />
                    {/* </DialogContent> */}
                </Dialog>

                <Dialog
                    fullWidth
                    TransitionComponent={Transition}
                    open={this.state.openTaskDownload}
                    onClose={handleTaskDownloadClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle>Task Download</DialogTitle>
                    <DialogActions>
                        {this.state.dialogLoader ? <CircularProgress /> :
                            <>
                                <Button size='small' onClick={() => this.downloadTask()} color="primary">
                                    task download
                                </Button>
                                <Button size='small' onClick={() => this.downloadSubtask()} color="primary">
                                    subtask download
                                </Button>
                                <Button size='small' onClick={handleTaskDownloadClose} color="primary">
                                    cancel
                                </Button>
                            </>
                        }
                    </DialogActions>
                </Dialog>

                <Dialog
                    fullWidth
                    TransitionComponent={Transition}
                    open={this.state.reasonDelayDialog}
                    onClose={reasondelayDialogClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle>Select the reason for delay</DialogTitle>
                    <DialogContent>
                        <Autocomplete
                            id={"nouns"}
                            fullWidth
                            style={{ marginTop: '15px' }}
                            value={this.state.selectedNoun}
                            options={this.state.dateChangeNouns}
                            getOptionLabel={(option) => option.name}
                            onChange={(e, v) => this.onNounChange(e, v)}
                            renderInput={(params) => <TextField {...params} label="Select Noun" variant="outlined" />}
                        />
                        <Autocomplete
                            id={"adjectives"}
                            fullWidth
                            style={{ marginTop: '15px' }}
                            value={this.state.selectedAdjective}
                            options={this.state.dateChangeAdjectives}
                            getOptionLabel={(option) => option.name}
                            onChange={(e, v) => this.onAdjectiveChange(e, v)}
                            renderInput={(params) => <TextField {...params} label="Select Adjective" variant="outlined" />}
                        />

                    </DialogContent>
                    <DialogActions>
                        <Button size='small' color="primary" onClick={reasondelayDialogClose}>
                            cancel
                        </Button>
                        <Button size='small' color="primary" onClick={() => this.setReasonDelay()}>
                            submit
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default withSnackbar(ProjectCardList)