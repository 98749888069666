import React, { Component } from 'react'
import MaterialTable from 'material-table';
import Service from '../../Networking/networkutils'
import { withSnackbar } from 'notistack';
import { getCookie } from '../../Helper/CookieHelper';
import { errorMessage } from '../../Helper/ErrorMessage';
import { getDarkMode } from '../../Helper/CommonThings';
import { Button, Dialog, DialogActions, DialogContent, Slide } from '@mui/material';
import { Box } from '@mui/system';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

var token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));

class RolesGroupDetailPanel extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: [],
            isLoading: true,
            openDialogue: false,
            roles: [],
            role: null
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData() {
        Service.get('/bb/api/role/group/link/?role_group=' + this.props.data.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                console.log(res.data)
                this.setState({ data: res.data, isLoading: false })
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    postData() {
        var fields = "";
        if (!this.state.role) {
            fields += "Roles";
        }

        if (fields) {
            this.props.enqueueSnackbar(fields + ' is Empty!', {
                variant: 'warning'
            });
        } else {
            const data = {
                role: this.state.role.id,
                role_group: this.props.data.id,
                created_by: user.id
            }
            Service.post('/bb/api/role/group/link/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: data
            })
                .then(res => {
                    this.setState({ openDialogue: false })
                    this.fetchData();
                    this.props.enqueueSnackbar('Roles Added Successfully', {
                        variant: 'success'
                    });
                })
                .catch(error => {
                    console.log(error)
                    errorMessage(this.props, error)
                });
        }
    }

    openDialog() {
        this.setState({ openDialogue: true })
        Service.get(`/bb/api/role/group/link/get_other_role/`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({
                    roles: res.data,
                })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    handleRolesChange(val) {
        this.setState({ role: val })
    }

    deleteData(data) {
        Service.delete('/bb/api/role/group/link/' + data.id + '/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.props.enqueueSnackbar('Deleted Successfully!', {
                    variant: 'success'
                });
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });


    }
    render() {

        const handleDialogueClose = () => {
            this.setState({ openDialogue: false, openDialogue1: false })
        };

        return (
            <div>
                <MaterialTable
                    title={this.props.data.name + " Roles"}
                    columns={[
                        {
                            title: 'Name', field: 'name',
                            headerStyle: { backgroundColor: getDarkMode() === 'dark' ? '#4f7e76' : '#AFD6CB' },
                            render: rowData => rowData.role_data.name,
                            customFilterAndSearch: (term, rowData) => {
                                return (rowData.role_data.name).toLowerCase().includes(term.toLowerCase())
                            },
                        },
                    ]}
                    style={{ backgroundColor: getDarkMode() === 'dark' ? '#4f7e76' : '#AFD6CB' }}
                    data={this.state.data}
                    isLoading={this.state.isLoading}
                    options={{
                        paging: false,
                        actionsColumnIndex: -1,
                        search: true,
                        headerStyle: {
                            backgroundColor: getDarkMode() === 'dark' ? '#4f7e76' : '#AFD6CB',
                        }
                    }}
                    editable={{
                        onRowDelete: oldData =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    this.setState(prevState => {
                                        const data = [...prevState.data];
                                        data.splice(data.indexOf(oldData), 1);
                                        this.deleteData(oldData)
                                        return { ...prevState, data };
                                    });
                                }, 600);
                            }),
                    }}
                    actions={[
                        {
                            icon: 'add',
                            tooltip: 'Add Roles',
                            isFreeAction: true,
                            onClick: (event, rowData) => this.openDialog(),
                        },
                    ]}
                />
                <Dialog open={this.state.openDialogue} onClose={handleDialogueClose} TransitionComponent={Transition} >
                    <DialogContent>

                        <Box marginTop="30px" fontSize={18} flexDirection="row" display="flex" justifyContent="center" fontWeight="bold">
                            Add Roles
                        </Box>

                        <Box marginTop="30px" flexDirection="row" display="flex" justifyContent="center">
                            <Autocomplete
                                id="roles"
                                options={this.state.roles}
                                getOptionLabel={(option) => option.name}
                                type="name"
                                style={{ width: 450 }}
                                value={this.state.role}
                                onChange={(e, val) => this.handleRolesChange(val)}
                                renderInput={(params) => <TextField {...params} label="Select Roles" variant="outlined" />} />
                        </Box>

                    </DialogContent>

                    <DialogActions>
                        <Box display="flex" justifyContent="center" marginTop="10px" marginBottom="10px">

                            <Button size='small' style={{ marginLeft: '5px', marginRight: '5px', color: "#007681" }} onClick={e => this.postData(e)}>Add</Button>

                            <Button size='small' style={{ marginLeft: '5px', marginRight: '5px', color: "#007681" }} onClick={handleDialogueClose}>Cancel</Button>

                        </Box>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default withSnackbar(RolesGroupDetailPanel)