import React, { Component } from 'react'
import { withSnackbar } from 'notistack'
import Moment from 'react-moment';
import Service from "../../Networking/networkutils";
import { getCookie } from '../../Helper/CookieHelper';
import MaterialTable from 'material-table';
import { errorMessage } from '../../Helper/ErrorMessage';

const token = getCookie("bb_ts_token");
const access = JSON.parse(getCookie("bb_ts_access"));
class Notes extends Component {


    constructor(props) {
        super(props)
    
        this.state = {
             data: (this.props.value.tracker_data && this.props.value.tracker_data.notes) ? (this.props.value.tracker_data.notes) : ([]),
        }
    }
    

    putData(data) {
        
        if (this.props.value.tracker) {
            this.props.value.tracker_data.notes = data;
            Service.put(`/bb/api/tender/opportunity/tracker/` + this.props.value.tracker, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: this.props.value.tracker_data,
            })
                .then(res => {
                    this.props.value.tracker = res.data.id;
                    this.props.value.tracker_data = res.data;
                    this.props.enqueueSnackbar('Data updated successfully!', {
                        variant: 'success'
                    });
                })
                .catch(error => {
                    errorMessage(this.props, error)
                });
        } else {
            this.props.value.tracker_data = {};
            this.props.value.tracker_data.notes = data;
            Service.post(`/bb/api/tender/opportunity/tracker/`, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: this.props.value.tracker_data,
            })
                .then(res => {
                    // console.log(res.data)
                    this.props.value.tracker = res.data.id;
                    this.props.value.tracker_data = res.data;
                    Service.put(`/bb/api/tender/bidtracker/` + this.props.value.id, {
                        headers: {
                            Authorization: "Token " + token,
                        },
                        data: this.props.value,
                    })
                        .then(res1 => {
                            this.props.enqueueSnackbar('Data updated successfully!', {
                                variant: 'success'
                            });
                        })
                        .catch(error => {
                            errorMessage(this.props, error)
                        });

                })
                .catch(error => {
                    errorMessage(this.props, error)
                });
        }
    }

    render() {
        return (
            <div>
                <MaterialTable
                    key={3}
                    title="Notes"
                    columns={[
                        {
                            title: 'Date', field: 'date', type: 'date',
                            render: rowData => <Moment local format="DD/MM/YYYY" >{rowData.from_date}</Moment>
                        },
                        {
                            title: 'Notes', field: 'notes',
                        },
                    ]}
                    data={this.state.data}
                    options={{
                        padding: "dense",
                    }}

                    editable={(access && access.access && access.access.l8a.l8a_b) ? ({
                        onRowAdd: newData =>
                            new Promise(resolve => {

                                setTimeout(() => {
                                    resolve();
                                    this.setState(prevState => {
                                        const data = [...prevState.data];
                                        data.push(newData);
                                        this.putData(data)
                                        return { ...prevState, data };
                                    });
                                }, 600);
                            }),
                        onRowUpdate: (newData, oldData) =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    if (oldData) {
                                        this.setState(prevState => {
                                            const data = [...prevState.data];
                                            data[data.indexOf(oldData)] = newData;
                                            this.putOps(data)
                                            return { ...prevState, data };
                                        });
                                    }
                                }, 600);
                            }),
                    }) : (null)}
                />
            </div>
        )
    }
}

export default withSnackbar(Notes);
