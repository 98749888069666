import { withSnackbar } from 'notistack'
import React, { Component } from 'react'
import Service from "../Networking/networkutils";
import { getCookie } from '../Helper/CookieHelper';
import { errorMessage } from '../Helper/ErrorMessage';
import MaterialTable from 'material-table';
import moment from 'moment';
import { onlyUniqueArr } from '../Helper/CommonThings';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { Box, Button, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';

const token = getCookie("bb_ts_token");
const access = JSON.parse(getCookie("bb_ts_access"));
var count = 0
// const user = JSON.parse(getCookie("bb_ts_user"));

class LMSLeaveSubmit extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: [],
            isLoading: true,
            approvalList: [],
            gids: [],
            teamLeads: [],
            processedData: [],
            open: false,
            defaultPlan: null,
            leaveDates: [],
            submitDisabled: false
        }
    }

    componentDidMount() {
        if (access.access && access.access.l18a && access.access.l18a.l18a_a) {
            this.fetchOps()
        }

    }

    fetchOps() {
        Service.get("/bb/api/team/tasks/?uid=" + this.props.uid + "&is_submitted=0&tid=66,67,77,78", {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res.data)
                var gids = []
                // var teamData = []
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    gids.push(element.gid)
                }
                gids = gids.filter(onlyUniqueArr)
                this.setState({
                    data: res.data,
                    isLoading: false,
                    gids: gids
                })
                if (gids.length) {
                    Service.get("/bb/api/team/access/?gid=" + gids + "&team_role=1,2", {
                        headers: {
                            Authorization: "Token " + token,
                        },
                    })
                        .then(resUserAccess => {

                            // console.log("teamaccess", resUserAccess)
                            this.setState({
                                teamLeads: resUserAccess.data,
                            })


                        })
                        .catch(error => {
                            // console.log(error)
                            errorMessage(this.props, error)
                        });
                }

            })
            .catch(error => {
                // console.log(error)
                errorMessage(this.props, error)
            });
    }

    processPreSubmit() {
        var processedData = []
        var gids = []
        var leaveDates = []
        for (let i = 0; i < this.state.approvalList.length; i++) {
            const element = this.state.approvalList[i];
            gids.push(element.gid)
            leaveDates.push(moment(element.start).format('YYYY-MM-DD'))
        }
        this.setState({ leaveDates })
        gids = gids.filter(onlyUniqueArr)
        for (let i = 0; i < gids.length; i++) {
            const element = gids[i];
            for (let j = 0; j < this.state.approvalList.length; j++) {
                const element1 = this.state.approvalList[j];
                if (element1.gid === element) {
                    if (processedData.length === i) {
                        processedData.push({
                            "id": element1.gid,
                            "name": element1.gname,
                            "tasks": [],
                            "requested_to": null
                        })
                    }
                    processedData[i].tasks.push(element1)
                }
            }
        }
        this.setState({
            processedData: processedData,
            open: true
        })
    }

    getLeadsList(gid) {
        var returnData = []
        for (let i = 0; i < this.state.teamLeads.length; i++) {
            const element = this.state.teamLeads[i];
            returnData.push(element.uid_data)
        }
        return returnData
    }

    onLeadChange = (event, values, index) => {
        // console.log(values, 0)
        var temp = this.state.processedData
        temp[index].requested_to = values
        this.setState({
            processedData: temp
        })
    }

    submitData() {

        this.setState({
            isLoading: true,
            open: false,
        })
        this.agreePlan(this.state.leaveDates)
        var fail = false
        for (let i = 0; i < this.state.processedData.length; i++) {
            const element = this.state.processedData[i];
            if (element.requested_to) {
                for (let j = 0; j < element.tasks.length; j++) {
                    const element1 = element.tasks[j];
                    element1.requested_to = element.requested_to.id
                }
            } else {
                this.props.enqueueSnackbar('Select a lead for ' + element.name + ' team', {
                    variant: 'warning'
                });
                fail = true
            }
        }
        if (fail) {
            return null
        } else {
            for (let i = 0; i < this.state.processedData.length; i++) {
                const element = this.state.processedData[i];
                this.apiCall(element.tasks)
            }
        }
    }

    agreePlan(leaveDates) {
        Service.put(`/bb/api/user/work/plan/work_plan_change_bulk_date_api/`, {
            headers: {
                Authorization: "Token " + token,
            },
            data: {
                user_id: this.props.uid,
                date: leaveDates,

            },
        })
            .then(res => {

            })
            .catch(error => {
                if (error && error.status === 406) {
                    this.props.enqueueSnackbar('Not allowed', {
                        variant: 'warning'
                    });
                } else {
                    errorMessage(this.props, error)
                    console.log(error)
                }
            })
    }

    apiCall(tasks) {
        this.setState({ submitDisabled: true })
        Service.post("/bb/api/lms/approval/leave_request/", {
            headers: {
                Authorization: "Token " + token,
            },
            data: tasks
        })
            .then(res => {
                count += 1
                // console.log("teamaccess", resUserAccess)
                if (count === this.state.processedData.length) {
                    count = 0
                    this.props.enqueueSnackbar('Approval Request sent successfully', {
                        variant: 'success'
                    });
                    this.setState({
                        isLoading: false,
                        submitDisabled: false,
                        approvalList: []
                    })
                    this.fetchOps()
                }

            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
                this.setState({
                    isLoading: false,
                    submitDisabled: false,
                    approvalList: []
                })
            });
    }

    render() {

        const handleClose = () => {

            this.setState({ open: false })
        };

        return (
            <div>
                <MaterialTable
                    title="Select Leaves to Submit"
                    columns={[
                        {
                            title: 'Team', field: 'gname',
                            // render: rowData => (moment(rowData.end).diff(moment(rowData.start), 'hours') / 8)
                        },
                        {
                            title: 'Projects', field: 'ttender_name', headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
                            render: rowData => (rowData.proj_name ? rowData.proj_name : rowData.ttender_name)
                        },
                        {
                            title: 'Tasks', field: 'tname', headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
                            render: rowData => (rowData.tname ? rowData.tname : (rowData.ttdecs ? rowData.ttdecs : rowData.ttname))
                        },

                        // { title: 'Tasks', field: 'tname', headerStyle: { minWidth: 325 }, cellStyle: { minWidth: 325 }, },
                        { title: 'Comment', field: 'comments', headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 }, },
                        {
                            title: 'Date', field: 'start', type: 'date'
                            // render: rowData => (moment(rowData.end).diff(moment(rowData.start), 'hours') / 8)
                        },
                        {
                            title: 'Hours', field: 'actual_hours',
                            render: rowData => (moment.duration(moment(rowData.end).diff(moment(rowData.start))).asMinutes() / 60).toFixed(2)
                        }
                    ]}
                    data={this.state.data}
                    isLoading={this.state.isLoading}
                    style={{ margin: '10px' }}
                    options={{
                        padding: "dense",
                        actionsColumnIndex: -1,
                        selection: true,
                        paging: false
                    }}
                    actions={[

                    ]}
                    onSelectionChange={(rows) => this.setState({
                        approvalList: rows
                    })}
                />
                <Box display="flex" justifyContent="center" m={1} p={1}>
                    <Box p={1}>
                        <Button size='small' variant="contained" color="primary"
                            disabled={this.state.approvalList.length === 0 || this.state.submitDisabled}
                            onClick={() => this.processPreSubmit()}
                        >
                            Submit
                        </Button>
                    </Box>
                </Box>
                <Box display="flex" justifyContent="center" >
                    <Box p={1}>
                        powered by PaRaM Leave Management System
                    </Box>
                </Box>
                <Dialog open={this.state.open} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Submit your Leave</DialogTitle>
                    <DialogContent>

                        {(this.state.processedData.length !== 0) ? (
                            <div style={{ width: '100%' }}>

                                <List>
                                    {
                                        this.state.processedData.map((value, index) =>
                                            <ListItem>
                                                <ListItemIcon>
                                                    <PersonIcon />
                                                </ListItemIcon>

                                                <ListItemText
                                                    primary={"Team - " + value.name}
                                                    secondary={
                                                        <React.Fragment>
                                                            {value.tasks.map((item) =>
                                                                <Typography
                                                                    component="span"
                                                                    variant="body2"
                                                                    // className={classes.inline}
                                                                    color="textPrimary"
                                                                >
                                                                    {moment(item.start).format('DD-MM-YYYY') + " - " + (moment.duration(moment(item.end).diff(moment(item.start))).asMinutes() / 60).toFixed(2) + " hours"}
                                                                </Typography>
                                                            )}

                                                            <Autocomplete
                                                                id={"combo-" + index}
                                                                fullWidth
                                                                style={{ marginTop: '15px', width: '350px' }}
                                                                value={value.requested_to}
                                                                options={this.getLeadsList(value.id)}
                                                                getOptionLabel={(option) => option.last_name + ", " + option.first_name}
                                                                getOptionSelected={(option, value) => value !== '' && option.id === value.id}
                                                                onChange={(e, v) => this.onLeadChange(e, v, index)}
                                                                renderInput={(params) => <TextField {...params} label="Request to" variant="outlined" />}
                                                            />
                                                        </React.Fragment>
                                                    }
                                                />

                                            </ListItem>
                                        )
                                    }

                                </List>
                            </div>
                        ) : (null)}

                    </DialogContent>
                    <DialogActions>

                        <Button size='small' onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button size='small' disabled={this.state.submitDisabled} onClick={e => this.submitData()} color="primary">
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default withSnackbar(LMSLeaveSubmit)