import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import Service from "../../Networking/networkutils";
import { Box, Button, TextField, Typography } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import { LoopCircleLoading } from 'react-loadingg';
import Moment from 'react-moment';
import { getCookie } from '../../Helper/CookieHelper';
import ReactGA from 'react-ga';
import { errorMessage } from '../../Helper/ErrorMessage';
import moment from 'moment';
import Alert from '@material-ui/lab/Alert';
import { getPrimaryColor } from '../../Helper/CommonThings';

const useStyles = (theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
});

const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));

// var count = 0

class Rate extends Component {

    constructor(props) {
        super()

        this.state = {
            selectedDate: new Date(),
            columns: [
                {
                    title: 'Grade', field: 'grade', editable: 'never',
                    render: rowData => rowData.grade.toUpperCase()
                },
                { title: 'Designation', field: 'desig', editable: 'never' },
                { title: '£ Internal Cost to Contract/Tender UK', field: 'costuk', type: 'numeric' },
                { title: '£ Internal Cost to Contract/Tender INDIA/SRILANKA', field: 'costin', type: 'numeric' },
                { title: 'Remarks', field: 'remark', },
            ],
            data: [

            ],
            data1: {},
            isLoading: true,
            remarks: '',
            toSave: false,
        }
    }

    componentDidMount() {
        this.fetchOps();
    }

    fetchOps() {

        var pageView = window.location.pathname + "/#TenderAddAndEdit";
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);


        Service.get('/bb/api/tender/rate/?pid=' + this.props.value.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res.data)

                // console.log(res.data)
                if (res.data.length === 0) {
                    Service.get('/bb/api/tender/template/ratecard/?year=' + moment().year(), {
                        headers: {
                            Authorization: "Token " + token,
                        },
                    })
                        .then(res1 => {
                            Service.post("/bb/api/tender/rate/", {
                                headers: {
                                    Authorization: "Token " + token,
                                },
                                data: {
                                    t_pid: this.props.value.id,
                                    t_pname: this.props.value.name,
                                    created_by: user.email,
                                    t_rates: res1.data,
                                    default: true,
                                    year: moment().year(),
                                    remarks: [
                                        {
                                            date: new Date(),
                                            remark: 'default rates (initiated for project)'
                                        }
                                    ]
                                },
                            })
                                .then(res => {
                                    this.setState({
                                        data1: res.data,
                                        data: res.data.t_rates,
                                        isLoading: false,
                                    });
                                })
                        })
                        .catch(error => {
                            console.log(error)
                            errorMessage(this.props, error)
                        });

                } else {
                    for (let i = 0; i < res.data[0].t_rates.length; i++) {
                        const element = res.data[0].t_rates[i];
                        if (!element.remark) {
                            element.remark = ''
                        }
                    }

                    this.setState({

                        data1: res.data[0],
                        data: res.data[0].t_rates,
                        isLoading: false,
                    });
                }
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });



    }

    postData() {

        var pageView = window.location.pathname + "/#TenderAddAndEdit";
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);

        if (!this.state.toSave) {
            this.props.enqueueSnackbar('Rate card not changed!', {
                variant: 'warning'
            });
        } else if (this.state.remarks === '') {
            this.props.enqueueSnackbar('Give a remark about your change!', {
                variant: 'warning'
            });
        } else {
            // this.setState({ isLoading: true });
            var mutData = this.state.data1;
            mutData.t_rates = this.state.data;
            mutData.remarks.push({
                date: new Date(),
                remark: this.state.remarks,
                user_id: user.id,
                user: user.first_name + " " + user.last_name
            })
            Service.put("/bb/api/tender/rate/" + this.state.data1.id, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: mutData,
            })
                .then(res => {
                    // console.log(res.data)
                    this.setState({
                        remarks: '',
                        data1: res.data,
                        data: res.data.t_rates,
                        toSave: false
                        // isLoading: false,
                    });
                    // this.props.nextBlock(false)
                    this.props.enqueueSnackbar('Data saved successfully!', {
                        variant: 'success'
                    });
                })
                .catch(error => {
                    console.log(error)
                    errorMessage(this.props, error)
                });
        }

    }

    render() {
        // const { classes } = this.props;

        // const handleDateChange = (date) => {
        //     this.setState({ selectedDate: date })
        // };

        return (
            <div>
                {(this.state.toSave) ? (
                    <Alert severity="warning"
                        action={
                            <Button color="inherit" size="small" onClick={() => { this.postData() }}>
                                Save
                            </Button>
                        }
                    >
                        Updated Rate Card is not saved. Please submit after filling remark for the change.
                    </Alert>
                ) : (
                    null
                )}
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                    <div>
                        <Grid container spacing={3}>

                            <Grid item xs={12} >
                                <MaterialTable
                                    key={3}
                                    title="Default Rates for Estimate"
                                    columns={[
                                        {
                                            title: 'Grade', field: 'grade', editable: 'never',
                                            render: rowData => rowData.grade.toUpperCase()
                                        },
                                        { title: 'Designation', field: 'desig', editable: 'never' },
                                        { title: '£ Internal Cost to Contract/Tender UK', field: 'costuk', type: 'numeric' },
                                        { title: '£ Internal Cost to Contract/Tender INDIA/SRILANKA', field: 'costin', type: 'numeric' },
                                        { title: 'Remarks', field: 'remark', },
                                    ]}
                                    data={this.state.data}
                                    editable={{
                                        onRowUpdate: (newData, oldData) =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    resolve();
                                                    if (oldData && newData && (oldData.costuk !== newData.costuk || oldData.costin !== newData.costin || oldData.remark !== newData.remark)) {
                                                        this.setState(prevState => {
                                                            const data = [...prevState.data];
                                                            data[data.indexOf(oldData)] = newData;
                                                            this.setState({ toSave: true });
                                                            return { ...prevState, data };
                                                        });
                                                    }
                                                }, 60);
                                            }),
                                    //     onRowUpdate: (newData, oldData) =>
                                    //         new Promise(resolve => {
                                    //             // count += 1
                                    //             // if (count % 3 === 0) {
                                    //             //     this.setState({
                                    //             //         isLoading: true
                                    //             //     })
                                    //             // }
                                    //             if () {
                                    //                 this.setState({
                                    //                     toSave: true
                                    //                 })
                                    //                 this.props.nextBlock(true)
                                    //             }

                                    //             setTimeout(() => {

                                    //                 resolve();
                                    //                 if (oldData) {
                                    //                     this.setState(prevState => {
                                    //                         const data = [...prevState.data];
                                    //                         // isLoading = false
                                    //                         data[data.indexOf(oldData)] = newData;
                                    //                         return { ...prevState, data };
                                    //                     });
                                    //                 }
                                    //             }, 100);
                                    //         }),
                                    }}
                                    options={{
                                        // actionsColumnIndex: -1,
                                        padding: "dense",
                                        pageSize: 10,
                                        pageSizeOptions: [10, 25, 50],
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <Typography>Remarks :</Typography>
                                {this.state.data1.remarks.map((text, index) => (

                                    <Box key={index} m={1} p={1}>
                                        <Typography key={"date" + index}>Date : <Moment format="DD/MM/YYYY"  local>{text.date}</Moment></Typography>
                                        <Typography key={"remark" + index}>Remark : {text.remark}</Typography>
                                        {(text.user) ? (<Typography key={"remark" + index}>Updated By : {text.user}</Typography>) : (null)}

                                    </Box>

                                ))}
                            </Grid>
                            <Grid item xs={12} >
                                <TextField id="outlined-basic" label="Remark" multiline fullWidth variant="outlined"
                                    onChange={e => { this.setState({ remarks: e.target.value }) }}
                                    value={this.state.remarks} />
                            </Grid>
                        </Grid>
                        <Box display="flex" flexDirection="row" justifyContent="center" p={1} m={1} bgcolor="background.paper">
                            <Button size='small' variant="contained" onClick={e => this.postData()} color="primary">Submit</Button>
                        </Box>
                    </div>
                )}

            </div>
        )
    }
}

export default withStyles(useStyles)(withSnackbar(Rate));