import React, { Component } from 'react'
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Service from "../Networking/networkutils"
import { withSnackbar } from 'notistack';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Typography, Box, FormControl, InputLabel, Select, MenuItem, CircularProgress, LinearProgress, Divider, List, ListItem, ListItemAvatar, Avatar, ListItemText, Tooltip } from '@material-ui/core';
import Box5 from '@mui/material/Box';
// import Moment from 'react-moment';
import Link from '@material-ui/core/Link';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { LoopCircleLoading } from 'react-loadingg';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import Autocomplete from '@mui/material/Autocomplete';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { getCookie } from '../Helper/CookieHelper';
import ReactGA from 'react-ga';
import { getDarkMode, getPrimaryColor, sortByProperty } from '../Helper/CommonThings';
import { errorMessage } from '../Helper/ErrorMessage';
import CalenderCustomToolbar from './CalenderCustomToolbar';
import UserHoursRequest from '../HoursRequest/UserHoursRequest';
import FormGroup from '@material-ui/core/FormGroup';
import { getSubTaskStatus } from '../Helper/KanbanHelper';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Chip from '@material-ui/core/Chip';
import Week from "react-big-calendar/lib/Week";
import TimeGrid from "react-big-calendar/lib/TimeGrid";
import PropTypes from "prop-types";
import TimesheetSettings from '../Settings/TimesheetSettings/TimesheetSettings';
import { BootstrapDialogTitleV4, BootstrapDialogV4 } from '../Helper/DialogTitleM5';
import MaterialTable from 'material-table';
import { Alert } from '@mui/material';
import PostAddIcon from '@mui/icons-material/PostAdd';
import DateChangeRequest from '../DateChangeRequest/DateChangeRequest';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
// import SearchIcon from '@mui/icons-material/Search';
// import Paper from '@material-ui/core/Paper';
// import ClearIcon from '@material-ui/icons/Clear';

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);
const token = getCookie("bb_ts_token");
const access = JSON.parse(getCookie("bb_ts_access"));
const user = JSON.parse(getCookie("bb_ts_user"));
var lastweeklastday = moment().subtract(3, 'weeks').endOf('isoWeek').subtract(1, 'days')
const minTime = new Date();
minTime.setHours(6, 0, 0);
const maxTime = new Date();
maxTime.setHours(22, 0, 0);

const bbColorTheme = createTheme({
    palette: {
        primary: {
            main: '#FF0000',
        },
        secondary: {
            main: '#FF0000',
        },
        type: getDarkMode(),
    }
})

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

// const AntSwitch = withStyles((theme) => ({
//     root: {
//         width: 28,
//         height: 16,
//         padding: 0,
//         display: 'flex',
//     },
//     switchBase: {
//         padding: 2,
//         color: theme.palette.grey[500],
//         '&$checked': {
//             transform: 'translateX(12px)',
//             color: theme.palette.common.white,
//             '& + $track': {
//                 opacity: 1,
//                 backgroundColor: theme.palette.primary.main,
//                 borderColor: theme.palette.primary.main,
//             },
//         },
//     },
//     thumb: {
//         width: 12,
//         height: 12,
//         boxShadow: 'none',
//     },
//     track: {
//         border: `1px solid ${theme.palette.grey[500]}`,
//         borderRadius: 16 / 2,
//         opacity: 1,
//         backgroundColor: theme.palette.common.white,
//     },
//     checked: {},
// }))(Switch);

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

var userdub = null
var selected_event = null
var copyStart = ''
var copyEnd = ''

class Calender extends Component {
    constructor(props) {
        super(props)
        this.state = {
            events: [],
            eventsCopy: [],
            isLoading: true,
            openAdd: false,
            selectTime1: new Date(),
            selectTime2: new Date(),
            selectDate1: new Date(),
            selectDate2: moment(new Date()).add(1, 'days'),
            addName: '',
            addId: 0,
            addData: null,
            addTask: '',
            addTaskId: 0,
            addWork: '',
            addComment: '',
            actualHours: 0,
            openInfo: false,
            info: {},
            checkedB: false,
            checkedSat: false,
            checkedSun: false,
            checkedWFH: false,
            projects: [],
            tasks: [],
            hours: 0,
            hour: 0,
            min: 0,
            isLoading2: true,
            openSplit: false,
            noComment: false,
            tenderMainProj: [],
            tenderProj: [],
            tenderwbs: [],
            tenderTask: [],
            tenderSwitch: true,
            tenderShowDs: [],
            tenderShowWbs: [],
            tenderShowTasks: [],
            selectedProjectData: null,
            selectedDSData: null,
            selectedWBSData: null,
            selectedTaskData: null,
            calender_view: 'work_week',
            start_date: moment().add(1, 'days').startOf('isoWeek').subtract(1, 'days'),
            end_date: moment().add(1, 'days').endOf('isoWeek').subtract(2, 'days'),
            selectedTeam: null,
            taskPercent: 0,
            openTaskUpdate: false,
            perTaskData: {},
            perTaskLoading: false,
            hoursUtilizedData: null,
            hoursUtilizedData2: null,
            openAddShowMenu: true,
            taskAddMinDate: moment().subtract(3, 'weeks').endOf('isoWeek').subtract(1, 'days'),
            taskAddMaxDate: moment().add(2, 'months').endOf('isoWeek').subtract(1, 'days'),
            taskStatus: {
                0: "0% - Not started.",
                5: "5% - Preparation of documents/drawings.",
                10: "10% - Preparation of documents/drawings.",
                20: "20% - Preparation of documents/drawings.",
                30: "30% - Preparation of documents/drawings.",
                40: "40% - Preparation of documents/drawings.",
                50: "50% - Preparation of documents/drawings.",
                60: "60% - Checked within Department.",
                65: "65% - Approved within Department.",
                70: "70% - Site comments received, reviewed and any changes made.",
                80: "80% - Multi-disciplinary Reviewed & Commented.",
                90: "90% - Design verified.",
                100: "100% - Design assured.",
            },
            bankHolidays: [],
            openRequest: false,
            delBtnDisabled: false,
            saveBtnDisabled: false,
            openLinkPlanner: false,
            subTasksData: [],
            selectedSubTask: null,
            plannerLoader: false,
            addTaskDisabled: false,
            taskcategory: [],
            categorySwitch: 1,
            allProj: [],
            selectedAllProj: null,
            categoryLoading: false,
            selectedOverheadCategory: null,
            selectedOverheadTask: null,
            teamLeads: [],
            teamLeadsDialog: false,
            timesheetOption: this.props.timesheetOption,
            openTimesheetSettings: false,
            openPreview: false,
            previewData: [],
            previewLoading: true,
            copyDisabled: false,
            copyLists: [],
            openDateDialog: false,
            teamDiasabled: false,
            copyWithQty: false,
            copyQty: 1,
            qtyDisabled: false,
            // copyStart: moment().add(1, 'days').startOf('isoWeek').subtract(1, 'days'),
            // copyEnd: moment().add(1, 'days').endOf('isoWeek').subtract(2, 'days'),
        }
        this.toolbarRef = React.createRef();
        this.copyFromLastWeek = this.copyFromLastWeek.bind(this)
        this.openTimesheetSettings = this.openTimesheetSettings.bind(this)
        this.getTimesheetOption = this.getTimesheetOption.bind(this)
    }

    componentDidMount() {
        if (this.props.timesheetOption === 1) {
            this.setState({
                start_date: this.state.start_date.subtract(1, 'days'),
                end_date: this.state.end_date.subtract(1, 'days'),
                // copyStart: this.state.start_date.subtract(1, 'days'),
                // copyEnd: this.state.end_date.subtract(1, 'days'),
            })
        }

        if (this.props.myteams && this.props.myteams !== undefined) {
            this.props.myteams.sort(sortByProperty('name'))
            if (this.props.myteams.length === 1) {
                var selectedTeam = this.props.myteams[0];
                // console.log(selectedTeam)
                this.setState({ selectedTeam: selectedTeam, teamDiasabled: false })
            }
        }

        if (this.getTeamRole()) {
            this.setState({ taskAddMinDate: moment().subtract(1, 'years').endOf('isoWeek'), taskAddMaxDate: moment().add(1, 'years').endOf('isoWeek').subtract(1, 'days'), })
        }

        var pageView = window.location.pathname + "/#Calender";
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);

        if (!getCookie("bb_ts_token")) {
            window.location.href = '/'
        }

        var userdubtemp = {
            uid: user.id,
            name: user.last_name + ', ' + user.first_name,
            email: user.email,
        }

        userdub = (this.props.value) ? (JSON.parse(localStorage.getItem("staff_to_see"))) : (userdubtemp);

        if (this.props.openPrevWeek) {
            var start_date = this.state.start_date.subtract(1, 'weeks')
            var end_date = this.state.end_date.subtract(1, 'weeks')
            // var copyStart = this.state.copyStart.subtract(1, 'weeks')
            // var copyEnd = this.state.copyEnd.subtract(1, 'weeks')
            this.fetchOps(start_date, end_date);
            this.setState({
                start_date: start_date,
                end_date: end_date,
                // copyStart: copyStart,
                // copyEnd: copyEnd,
            })
        } else {
            this.fetchOps(this.state.start_date, this.state.end_date);
        }
        this.fetchDatas();
        this.fetchAllProj()
        this.fetchOverheadTasks()
        // Service.get(`/bb/api/projects/?is_active=1`, {
        //     headers: {
        //         Authorization: "Token " + token,
        //     },
        // })
        //     .then(res => {
        //         var data = [];
        //         for (let i = 0; i < res.data.length; i++) {
        //             const element = res.data[i];
        //             if (element.is_active) {
        //                 data.push(element)
        //             }
        //         }
        //         this.setState({ projects: data, })
        //     })
        //     .catch(error => {
        //         console.log(error)
        //         errorMessage(this.props, error)
        //     });

    }

    fetchDatas() {
        this.setState({ categoryLoading: true })
        Service.get(`/bb/api/team/tasks/get_tasks/?uid=${userdub.uid}&productivity_key=${this.state.categorySwitch}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                var tenderSwitch = this.state.tenderSwitch
                if (this.state.categorySwitch === 1 || this.state.categorySwitch === 0) {
                    tenderSwitch = false
                    this.setState({ addId: '', selectedProjectData: null, addName: "" })
                } else {
                    tenderSwitch = true
                    this.setState({ addId: 11, selectedProjectData: { id: 11, tender_name: 'Non Billable', is_overhead: true }, addName: "Non Billable" })
                }
                // if (this.state.categorySwitch === 0) {
                //     res.data.project.push({ id: 11, tender_name: 'Non Billable', is_overhead: true })
                // }

                this.setState({
                    tenderMainProj: res.data.project,
                    tenderProj: res.data.ds,
                    tenderwbs: res.data.wbs,
                    tenderTask: res.data.task,
                    categoryLoading: false,
                    tenderSwitch: tenderSwitch,
                    openAddShowMenu: true,
                }, () => {
                    if (this.state.selectedAllProj) {
                        for (let i = 0; i < this.state.tenderMainProj.length; i++) {
                            const element = this.state.tenderMainProj[i];
                            if (element.id === this.state.selectedAllProj?.project_id) {
                                this.setState({ selectedProjectData: element }, () => {
                                    this.onTDsproj(null, element)
                                })
                                break
                            }
                        }
                    }
                });
            })
            .catch(e => {
                errorMessage(this.props, e)
                console.log(e)
            })
    }

    fetchOverheadTasks() {
        Service.get(`/bb/api/task/category/company/task_category_non_billable/?pid=${11}&uid=${user.id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ taskcategory: res.data, openAddShowMenu: true }, () => {
                    if (this.state.selectedAllProj) {
                        for (let i = 0; i < this.state.taskcategory.length; i++) {
                            const element = this.state.taskcategory[i];
                            if (element.id === this.state.selectedAllProj?.task_category_id) {
                                this.onTaskCategoryChange(null, element)
                                break
                            }
                        }
                    }
                })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    fetchAllProj() {
        Service.get(`/bb/api/team/tasks/user_tasks/?uid=${userdub.uid}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {

                this.setState({
                    allProj: res.data,
                });
            })
            .catch(e => {
                errorMessage(this.props, e)
                console.log(e)
            })
    }

    fetchOps(start_date, end_date) {
        // console.log(userdub)

        var userdubtemp = {
            uid: user.id,
            name: user.last_name + ', ' + user.first_name,
            email: user.email,
            uid_data: user
        }
        // console.log(JSON.parse(localStorage.getItem("staff_to_see")))
        userdub = (this.props.value) ? (JSON.parse(localStorage.getItem("staff_to_see"))) : (userdubtemp);
        // var url = "/bb/api/team/tasks/";
        var url = "/bb/api/team/tasks/get_team_task_details/";
        var start_date1 = moment(start_date).utc()
        var end_date1 = moment(end_date).utc()
        // start_date1.subtract(1, 'days')
        end_date1.add(1, 'days')
        // let time = moment('23:59', 'HH:mm');
        // let time1 = moment('23:59', 'HH:mm');
        // start_date1.set({
        //     hour: time.get('hour'),
        //     minute: time.get('minute'),
        //     // second: time.get('second')
        // });
        // end_date1.set({
        //     hour: time1.get('hour'),
        //     minute: time1.get('minute'),
        //     // second: time1.get('second')
        // });
        // console.log(start_date1.format('YYYY-MM-DD HH:mm:ss'))
        // console.log(end_date1.format('YYYY-MM-DD HH:mm:ss'))
        if (this.props.value && this.props.value.id) {
            url = "/bb/api/team/tasks/get_team_task_details/?gid=" + this.props.value.id + "&uid=" + userdub.uid + "&start_date=" + start_date1.format('DD-MM-YYYY HH:mm:ss') + "&end_date=" + end_date1.format('DD-MM-YYYY HH:mm:ss');
        } else {
            url = "/bb/api/team/tasks/get_team_task_details/?uid=" + userdub.uid + "&start_date=" + start_date1.format('DD-MM-YYYY HH:mm:ss') + "&end_date=" + end_date1.format('DD-MM-YYYY HH:mm:ss');
        }
        // console.log(url)
        Service.get(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res.data)
                var datapull = [];
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    var date = moment(element.start).format('DD/MM/YYYY')
                    var whours = moment.duration(moment(element.end).diff(moment(element.start)));
                    var whours1 = ''
                    if (parseFloat(whours.asHours()) < 0.9) {
                        whours1 = "00:" + whours.format('HH:mm'); //parseInt(whours.asHours()) + ":" + (parseInt(whours.asMinutes()) % 60);
                    } else {
                        whours1 = whours.format('HH:mm'); //parseInt(whours.asHours()) + ":" + (parseInt(whours.asMinutes()) % 60);
                    }
                    var act_whours = (whours.asHours() % 1 === 0) ? (whours.asHours()) : (whours.asHours().toFixed(1))
                    var status = '';
                    if (element.is_approved === 1) {
                        status = "[Approved]"
                    } else if (element.is_approved === 2) {
                        status = "[Rejected]"
                    } else if (element.is_approved === 0 && element.is_submitted === 1) {
                        status = "[Submited]"
                    }
                    // console.log(element.ttstatus)
                    if (element.is_tender === 1) {
                        datapull.push({
                            id: element.id,
                            uid: element.uid,
                            gid: element.gid,
                            tid: element.tid,
                            pid: element.pid,
                            ttid: element.ttid,
                            tpid: element.tpid,
                            ttenderid: element.ttenderid,
                            twbsid: element.twbsid,
                            trolesid: element.trolesid,
                            tmapid: element.tmapid,
                            uemail: element.uemail,
                            uname: element.uname_first + " " + element.uname_last,
                            gname: element.gname,
                            tname: element.tname,
                            tproj_name: element.tproj_name,
                            ttname: element.ttdecs ? element.ttdecs : element.ttname,
                            tuemail: element.tuemail,
                            twbsname: element.twbsname,
                            trolename: element.trolename,
                            ttender_name: element.ttender_name,
                            pdm_name: element.pdm_last_name + ", " + element.pdm_first_name,
                            status: status,
                            ttstatus: element.ttstatus,
                            title: status + "(" + act_whours + " hours) " + element.ttender_name + " - " + element.tproj_name + "(" + element.twbsname + " - " + (element.ttdecs ? element.ttdecs : element.ttname) + (element.tender_subtask ? " - " + element.subtask_name : "") + ") [" + element.uname_first + " " + element.uname_last + "]",
                            proj_name: element.proj_name,
                            work_detail: element.work_detail,
                            comments: element.comments,
                            date: date,
                            start: new Date(element.start),
                            end: new Date(element.end),
                            is_locked: element.is_locked,
                            is_submitted: element.is_submitted,
                            is_approved: element.is_approved,
                            strstart: element.tt_as ? element.tt_as : element.tt_bs,
                            strend: element.tt_af ? element.tt_af : element.tt_bf,
                            estimated_hours: element.estimated_hours,
                            created_by: element.created_by,
                            whours: whours1,
                            allDay: element.allDay,
                            actual_hours: element.actual_hours,
                            is_tender: element.is_tender,
                            in_office_item: element.in_office_item,
                            subtask_name: element.subtask_name,
                            tender_subtask: element.tender_subtask,
                            tcid: element.tcid,
                            tcname: element.tcname,
                        });
                    } else if (element.is_tender === 0) {
                        element.proj_name = !element.proj_name ? 'Non Billable' : element.proj_name
                        datapull.push({
                            id: element.id,
                            uid: element.uid,
                            gid: element.gid,
                            tid: element.tid,
                            pid: element.pid,
                            ttid: element.ttid,
                            ttenderid: element.ttenderid,
                            tpid: element.tpid,
                            twbsid: element.twbsid,
                            trolesid: element.trolesid,
                            tmapid: element.tmapid,
                            uemail: element.uemail,
                            uname: element.uname_first + " " + element.uname_last,
                            gname: element.gname,
                            tname: element.tname,
                            tproj_name: element.tproj_name,
                            ttname: element.ttdecs ? element.ttdecs : element.ttname,
                            tuemail: element.tuemail,
                            twbsname: element.twbsname,
                            trolename: element.trolename,
                            ttender_name: element.ttender_name,
                            status: status,
                            title: status + "(" + act_whours + " hours) ->" + element.proj_name + "(" + element.tname + (element.tender_subtask ? " - " + element.subtask_name : "") + ") [" + element.uname_first + " " + element.uname_last + "]",
                            proj_name: element.proj_name,
                            work_detail: element.work_detail,
                            comments: element.comments,
                            date: date,
                            start: new Date(element.start),
                            end: new Date(element.end),
                            is_locked: element.is_locked,
                            is_submitted: element.is_submitted,
                            is_approved: element.is_approved,
                            strstart: element.start,
                            strend: element.end,
                            estimated_hours: element.estimated_hours,
                            created_by: element.created_by,
                            whours: whours1,
                            allDay: element.allDay,
                            actual_hours: element.actual_hours,
                            is_tender: element.is_tender,
                            in_office_item: element.in_office_item,
                            subtask_name: element.subtask_name,
                            tender_subtask: element.tender_subtask,
                            tcid: element.tcid,
                            tcname: element.tcname,
                            hours_control: element.hours_control,
                        });
                    }

                }
                this.setState({ events: datapull, eventsCopy: JSON.parse(JSON.stringify(datapull)), isLoading: false, isLoading2: false }, () => {
                    if (selected_event) {
                        for (let i = 0; i < this.state.events.length; i++) {
                            const element = this.state.events[i];
                            if (element.id === selected_event.id) {
                                selected_event = element
                                this.setState({ info: element })
                                break
                            }
                        }
                    }
                });
                this.getBankHolidays()
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });

    }

    getBankHolidays() {
        // console.log(userdub)
        Service.get(`/bb/api/bank/holiday/get_bank_holiday_with_work_plan/?year=` + moment().year() + '&uid=' + userdub.uid, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({
                    bankHolidays: res.data
                })
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    postTask(postData) {

        var pageView = window.location.pathname + '/#Calender';
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);

        if (!getCookie("bb_ts_token")) {
            window.location.href = '/'
        }

        Service.post("/bb/api/team/tasks/", {
            headers: {
                Authorization: "Token " + token,
            },
            data: postData,
        })
            .then(res => {
                this.props.enqueueSnackbar('Task successfully added', {
                    variant: 'success'
                });
                this.setState({ isLoading2: true })
                this.fetchOps(this.state.start_date, this.state.end_date);
            })
            .catch(error => {
                errorMessage(this.props, error)
                this.setState({ isLoading2: true })
                this.fetchOps(this.state.start_date, this.state.end_date);
            });
    }

    getTeamRole() {
        if (!this.props.value) {
            return false;
        }
        try {
            for (let i = 0; i < this.props.useraccess.length; i++) {
                const element = this.props.useraccess[i];
                if (element.gid === this.props.value.id && (element.team_role === 1 || element.team_role === 2 || element.team_role === 3)) {
                    return true;
                }
            }
        } catch (ignore) {

        }

        return false;
    }

    putTask(putData) {

        // console.log(putData)

        var pageView = window.location.pathname + '/#Calender';
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);

        if (!getCookie("bb_ts_token")) {
            window.location.href = '/'
        }

        putData.is_admin = this.getTeamRole();
        Service.patch("/bb/api/team/tasks/put_update/", {
            headers: {
                Authorization: "Token " + token,
            },
            data: [putData],
        })
            .then(res => {

                this.props.enqueueSnackbar('Task successfully added', {
                    variant: 'success'
                });
                // this.setState({ isLoading2: true })
                // this.fetchOps(this.state.start_date, this.state.end_date);

            })
            .catch(error => {
                if (error && error.status === 406) {
                    alert(error.data.msg)
                }
                this.props.enqueueSnackbar('Data Not Saved!', {
                    variant: 'error'
                });
                this.props.enqueueSnackbar('Changing to old data!', {
                    variant: 'warning'
                });
                this.setState({ isLoading2: true })
                this.fetchOps(this.state.start_date, this.state.end_date);
            });
    }

    deleteTask(id) {

        var pageView = window.location.pathname + '/#Calender';
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);

        if (!getCookie("bb_ts_token")) {
            window.location.href = '/'
        }

        this.setState({ delBtnDisabled: true })

        Service.delete("/bb/api/team/tasks/" + id + "/", {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.props.enqueueSnackbar('Task successfully deleted', {
                    variant: 'success'
                });
                selected_event = null
                this.setState({ isLoading2: true, openInfo: false, delBtnDisabled: false })
                this.fetchOps(this.state.start_date, this.state.end_date);

            })
            .catch(error => {
                this.props.enqueueSnackbar('Data Not Deleted!', {
                    variant: 'error'
                });
                this.props.enqueueSnackbar('Changing to old data!', {
                    variant: 'warning'
                });
                this.setState({ isLoading2: true, delBtnDisabled: false })
                this.fetchOps(this.state.start_date, this.state.end_date);
            });
    }

    onEventResize = ({ event, start, end, allDay }) => {
        var lastweeklast = moment().subtract(3, 'weeks').endOf('isoWeek').subtract(1, 'days')

        if (event.ttid && moment(moment(start).format('YYYY-MM-DD')).isAfter(moment(event.strend).add(15, 'days').format('YYYY-MM-DD'))) {
            alert("Expected Finish date (" + moment(event.strend).format('DD-MM-YYYY') + ") for this date has passed. Please contact your Team Lead / EM (" + event.pdm_name + ") to update the dates and then try adding the time.")
        } else if (event.is_locked !== 1 || this.getTeamRole()) {
            var dataMod = {}
            var start1 = moment(start)
            var whours = moment.duration(moment(end).diff(moment(start)));
            var whours1 = ''
            if (parseFloat(whours.asHours()) < 0.9) {
                whours1 = "00:" + whours.format('HH:mm'); //parseInt(whours.asHours()) + ":" + (parseInt(whours.asMinutes()) % 60);
            } else {
                whours1 = whours.format('HH:mm'); //parseInt(whours.asHours()) + ":" + (parseInt(whours.asMinutes()) % 60);
            }
            event.actual_hours = whours.asHours();
            var act_hours_up = (whours.asHours() % 1 === 0) ? (whours.asHours()) : (whours.asHours().toFixed(1))
            if (this.getTeamRole() && whours.asHours() < 12.1) {

                dataMod = this.state.events;
                for (let i = 0; i < dataMod.length; i++) {
                    const element = dataMod[i];
                    if (element.id === event.id) {
                        // console.log(element.allDay)
                        dataMod[i].allDay = false;
                        dataMod[i].start = start;
                        dataMod[i].end = end;
                        if (element.is_tender === 1) {
                            dataMod[i].title = element.status + "(" + act_hours_up + " hours) " + element.ttender_name + " - " + element.tproj_name + "(" + element.twbsname + " - " + element.ttname + ") [" + element.uname + "]"

                        } else {
                            dataMod[i].title = element.status + "(" + act_hours_up + " hours) ->" + element.proj_name + "(" + element.tname + ") [" + element.uname + "]"
                        }
                        dataMod[i].whours = whours1;
                        dataMod[i].actual_hours = act_hours_up;
                        this.putTask(element);
                        break;
                    }
                }

                this.setState({
                    events: dataMod,
                })
                return { events: dataMod };
            } else if (whours.asHours() < 12.1) {
                //console.log(lastweeklast)
                if (start1 <= lastweeklast) {
                    alert('Please Contact your lead for editing tasks to past weeks!')
                } else {

                    dataMod = this.state.events;
                    for (let i = 0; i < dataMod.length; i++) {
                        const element = dataMod[i];
                        if (element.id === event.id) {
                            // console.log(element.allDay)
                            dataMod[i].allDay = false;
                            dataMod[i].start = start;
                            dataMod[i].end = end;
                            if (element.is_tender === 1) {
                                dataMod[i].title = element.status + "(" + act_hours_up + " hours) " + element.ttender_name + " - " + element.tproj_name + "(" + element.twbsname + " - " + element.ttname + ") [" + element.uname + "]"

                            } else {
                                dataMod[i].title = element.status + "(" + act_hours_up + " hours) ->" + element.proj_name + "(" + element.tname + ") [" + element.uname + "]"
                            }
                            dataMod[i].whours = whours1;
                            dataMod[i].actual_hours = act_hours_up;
                            this.putTask(element);
                            break;
                        }
                    }

                    this.setState({
                        events: dataMod,
                    })
                    return { events: dataMod };
                }
            } else {
                this.props.enqueueSnackbar("This operation not alloweded!", {
                    variant: 'warning'
                });
            }

        } else {
            this.props.enqueueSnackbar("Approved tasks can't be edited again!", {
                variant: 'warning'
            });
        }

    };

    notMore12(start, end) {
        // alert((timeToDecimal(msToTime(moment.duration(moment(end).diff(moment(start)))))))
        if (((timeToDecimal(msToTime(moment.duration(moment(end).diff(moment(start)))))) ? true : moment(start).toDate() === moment(end).toDate()) &&
            (timeToDecimal(msToTime(moment.duration(moment(end).diff(moment(start)))))) <= 12) {

            return true

        } else {
            return false
        }
    }

    onEventDrop = ({ event, start, end, allDay }) => {

        // if (event.allDay) {
        //     end = moment(end).toDate()
        //     start = moment(start).subtract(30, "minutes").toDate()
        // }
        // console.log(start.toLocaleDateString("en-US"), end.toLocaleDateString("en-US"))

        // console.log(moment(start).format("HH:mm"))
        if (event.ttid && moment(moment(start).format('YYYY-MM-DD')).isAfter(moment(event.strend).add(15, 'days').format('YYYY-MM-DD'))) {
            alert("Expected Finish date (" + moment(event.strend).format('DD-MM-YYYY') + ") for this date has passed. Please contact your Team Lead / EM (" + event.pdm_name + ") to update the dates and then try adding the time.")
        } else if (event.is_locked !== 1 || this.getTeamRole()) {
            var dataMod = {}
            var start1 = moment(start)

            var lastweeklast = moment().subtract(3, 'weeks').endOf('isoWeek').subtract(1, 'days')
            if (this.getTeamRole()) {

                dataMod = this.state.events;
                for (let i = 0; i < dataMod.length; i++) {
                    const element = dataMod[i];
                    if (element.id === event.id) {
                        element.start = this.notMore12(start, end) ? start : new Date(start.getTime() - (30 * 60000));
                        element.end = this.notMore12(start, end) ? end : start;
                        element.allDay = false;
                        var whours = moment.duration(moment(element.end).diff(moment(element.start)));
                        var whours1 = ''
                        if (parseFloat(whours.asHours()) < 0.9) {
                            whours1 = "00:" + whours.format('HH:mm'); //parseInt(whours.asHours()) + ":" + (parseInt(whours.asMinutes()) % 60);
                        } else {
                            whours1 = whours.format('HH:mm'); //parseInt(whours.asHours()) + ":" + (parseInt(whours.asMinutes()) % 60);
                        }
                        event.actual_hours = whours.asHours();
                        var act_hours_up = (whours.asHours() % 1 === 0) ? (whours.asHours()) : (whours.asHours().toFixed(1))
                        if (element.is_tender === 1) {
                            dataMod[i].title = element.status + "(" + act_hours_up + " hours) " + element.ttender_name + " - " + element.tproj_name + "(" + element.twbsname + " - " + element.ttname + ") [" + element.uname + "]"

                        } else {
                            dataMod[i].title = element.status + "(" + act_hours_up + " hours) ->" + element.proj_name + "(" + element.tname + ") [" + element.uname + "]"
                        }
                        dataMod[i].whours = whours1;
                        dataMod[i].actual_hours = act_hours_up;

                        this.putTask(element);

                        break;
                    }
                }

                this.setState({
                    events: dataMod,
                })
                return { events: dataMod };
            } else {
                if (start1 <= lastweeklast) {
                    alert('Please Contact your lead for editing tasks to past weeks!')
                } else {

                    dataMod = this.state.events;
                    for (let i = 0; i < dataMod.length; i++) {
                        const element = dataMod[i];
                        if (element.id === event.id) {
                            element.start = this.notMore12(start, end) ? start : new Date(start.getTime() - (30 * 60000));
                            element.end = this.notMore12(start, end) ? end : start;
                            element.allDay = false;
                            var whours2 = moment.duration(moment(element.end).diff(moment(element.start)));
                            var whours11 = ''
                            if (parseInt(whours2.asHours()) === 0) {
                                whours11 = "00:" + whours2.format('HH:mm'); //parseInt(whours.asHours()) + ":" + (parseInt(whours.asMinutes()) % 60);
                            } else {
                                whours11 = whours2.format('HH:mm'); //parseInt(whours.asHours()) + ":" + (parseInt(whours.asMinutes()) % 60);
                            }
                            event.actual_hours = whours2.asHours();
                            var act_hours_up1 = (whours2.asHours() % 1 === 0) ? (whours2.asHours()) : (whours2.asHours().toFixed(1))
                            if (element.is_tender === 1) {
                                dataMod[i].title = element.status + "(" + act_hours_up1 + " hours) " + element.ttender_name + " - " + element.tproj_name + "(" + element.twbsname + " - " + element.ttname + ") [" + element.uname + "]"

                            } else {
                                dataMod[i].title = element.status + "(" + act_hours_up1 + " hours) ->" + element.proj_name + "(" + element.tname + ") [" + element.uname + "]"
                            }
                            dataMod[i].whours = whours11;
                            dataMod[i].actual_hours = act_hours_up1;
                            this.putTask(element);
                            break;
                        }
                    }

                    this.setState({
                        events: dataMod,
                    })
                    return { events: dataMod };
                }
            }

        } else {
            this.props.enqueueSnackbar("Approved tasks can't be edited again!", {
                variant: 'warning'
            });
        }
        // console.log(event)

    };

    handleSelect = ({ event, start, end }) => {
        var leave_on_date = this.getDateForCalendar(start)
        if (leave_on_date && !leave_on_date.id) {
            alert("Timesheet Booking for Week-Off is not necessary.")
            return null
        }
        var start1 = moment(start)
        var end1 = moment(end)
        var allDay = (end - start) / (3600 * 1000)
        if (allDay >= 23) {
            return null
        }
        // let time = moment('00:00', 'HH:mm');
        // start1.set({
        //     hour: time.get('hour'),
        //     minute: time.get('minute'),
        //     second: time.get('second')
        // });
        // end1.set({
        //     hour: time.get('hour'),
        //     minute: time.get('minute'),
        //     second: time.get('second')
        // });
        var lastweeklast = moment().subtract(3, 'weeks').endOf('isoWeek').subtract(1, 'days')
        if (this.getTeamRole()) {
            if (this.props.myteams && this.props.myteams !== undefined) {
                this.props.myteams.sort(sortByProperty('name'))
                if (this.props.myteams.length === 1) {
                    var selectedTeam = this.props.myteams[0];
                    // console.log(selectedTeam)
                    this.setState({ selectedTeam: selectedTeam })
                }
            }
            if (this.state.tenderSwitch) {
                if (this.state.projects.length === 1) {
                    var values = this.state.projects[0]
                    this.setState({
                        addName: values.name,
                        addId: values.id,
                        addData: values
                    })
                    Service.get(`/bb/api/task/category/company/task_category_non_billable/?pid=${values.id}&uid=${user.id}`, {
                        headers: {
                            Authorization: "Token " + token,
                        },
                    })
                        .then(res => {
                            this.setState({ taskcategory: res.data, })
                        })
                        .catch(error => {
                            errorMessage(this.props, error)
                        });
                    // Service.get("/bb/api/tasks/?pid=" + values.id, {
                    //     headers: {
                    //         Authorization: "Token " + token,
                    //     },
                    // })
                    //     .then(res => {
                    //         var data = [];
                    //         for (let i = 0; i < res.data.length; i++) {
                    //             const element = res.data[i];
                    //             if (element.is_active) {
                    //                 data.push(element)
                    //             }
                    //         }
                    //         this.setState({ tasks: data, })
                    //     })
                    //     .catch(error => {
                    //         errorMessage(this.props, error)
                    //     });
                }
                this.setState({
                    openAdd: true, addTaskId: '', addTask: '', addWork: '', tenderShowDs: [], tenderShowTasks: [], tenderShowWbs: [], selectedDSData: null, selectedWBSData: null, selectedTaskData: null,
                    selectedOverheadCategory: null, selectedOverheadTask: null, categorySwitch: 2, selectedAllProj: null, selectedProjectData: null,
                    selectTime1: start1, selectTime2: end1, selectDate1: start1, selectDate2: moment(start1).add(1, 'days'), tasks: [], teamDiasabled: false
                }, () => { this.fetchDatas() })
            } else {
                this.setState({
                    openAdd: true, addId: '', addTaskId: '', addName: '', addTask: '', addWork: '', tenderShowDs: [], tenderShowTasks: [], tenderShowWbs: [], selectedDSData: null, selectedWBSData: null, selectedTaskData: null,
                    selectedOverheadCategory: null, selectedOverheadTask: null, categorySwitch: 1, selectedAllProj: null, selectedProjectData: null,
                    selectTime1: start1, selectTime2: end1, selectDate1: start1, selectDate2: moment(start1).add(1, 'days'), tasks: [], teamDiasabled: false
                }, () => { this.fetchDatas() })
            }
        } else {
            // console.log(lastweeklast)
            if (start1 <= lastweeklast) {
                alert('Please Contact your lead for adding tasks to past weeks!')
            } else {
                if (this.props.myteams && this.props.myteams !== undefined) {
                    this.props.myteams.sort(sortByProperty('name'))
                    if (this.props.myteams.length === 1) {
                        var selectedTeam1 = this.props.myteams[0];
                        // console.log(selectedTeam)
                        this.setState({ selectedTeam: selectedTeam1 })
                    }

                }
                if (this.state.tenderSwitch) {
                    if (this.state.projects.length === 1) {
                        var values1 = this.state.projects[0]
                        this.setState({
                            addName: values1.name,
                            addId: values1.id,
                            addData: values1
                        })
                        Service.get(`/bb/api/task/category/company/task_category_non_billable/?pid=${values1.id}&uid=${user.id}`, {
                            headers: {
                                Authorization: "Token " + token,
                            },
                        })
                            .then(res => {
                                this.setState({ taskcategory: res.data, })
                            })
                            .catch(error => {
                                errorMessage(this.props, error)
                            });
                        // Service.get("/bb/api/tasks/?pid=" + values1.id, {
                        //     headers: {
                        //         Authorization: "Token " + token,
                        //     },
                        // })
                        //     .then(res => {
                        //         var data = [];
                        //         for (let i = 0; i < res.data.length; i++) {
                        //             const element = res.data[i];
                        //             if (element.is_active) {
                        //                 data.push(element)
                        //             }
                        //         }
                        //         this.setState({ tasks: data, })
                        //     })
                        //     .catch(error => {
                        //         errorMessage(this.props, error)
                        //     });
                    }
                    this.setState({
                        openAdd: true, addTaskId: '', addTask: '', addWork: '', tenderShowDs: [], tenderShowTasks: [], tenderShowWbs: [], selectedDSData: null, selectedWBSData: null, selectedTaskData: null,
                        selectedOverheadCategory: null, selectedOverheadTask: null, categorySwitch: 2, selectedAllProj: null, selectedProjectData: null,
                        selectTime1: start1, selectTime2: end1, selectDate1: start1, selectDate2: moment(start1).add(1, 'days'), tasks: [], teamDiasabled: false
                    }, () => { this.fetchDatas() })
                } else {
                    this.setState({
                        selectedOverheadCategory: null, selectedOverheadTask: null, categorySwitch: 1, selectedAllProj: null, selectedProjectData: null,
                        openAdd: true, addId: '', addTaskId: '', addName: '', addTask: '', addWork: '', tenderShowDs: [], tenderShowTasks: [], tenderShowWbs: [], selectedDSData: null, selectedWBSData: null, selectedTaskData: null,
                        selectTime1: start1, selectTime2: end1, selectDate1: start1, selectDate2: moment(start1).add(1, 'days'), tasks: [], teamDiasabled: false
                    }, () => { this.fetchDatas() })
                }

            }
        }
    }

    handleAddTask() {
        var userdubtemp = {
            uid: user.id,
            name: user.last_name + ', ' + user.first_name,
            email: user.email,
        }

        var userdub = (this.props.value) ? (JSON.parse(localStorage.getItem("staff_to_see"))) : (userdubtemp);
        var value = (this.props.value) ? (this.props.value) : (this.state.selectedTeam);
        var date1 = moment(this.state.selectDate1).format('DD/MM/YYYY');
        var date2 = moment(this.state.selectDate2).format('DD/MM/YYYY');
        // console.log(this.state.addName, this.state.addTask, this.state.addTaskId, this.state.addId)
        // debugger
        if ((this.state.tenderSwitch) && this.state.addName !== "" && this.state.addTask !== "" && value && this.state.addTaskId !== null && this.state.addTaskId !== "" && this.state.addTaskId !== 0) {
            if (this.state.checkedB && moment(date1, "DD/MM/YYYY") < moment(date2, "DD/MM/YYYY")) {
                var date11 = moment(date1, "DD/MM/YYYY");
                var date22 = moment(date2, "DD/MM/YYYY");
                var postData = [];
                for (let i = 0; date22 > date11; i++) {
                    if (i === 0) {
                        var toS = moment(this.state.selectTime1).add(i, 'days');
                        var toE = moment(this.state.selectTime2).add(i, 'days');
                        // console.log(toS.day())
                        if (toS.isoWeekday() === 7 && this.state.checkedSun) {
                            postData.push({
                                uid: userdub.uid,
                                gid: value.id,
                                pid: this.state.addId,
                                tid: this.state.addTaskId,
                                tname: this.state.addTask,
                                uemail: userdub.email,
                                uname: userdub.name,
                                gname: value.name,
                                title: this.state.addName + "(" + this.state.addTask + ") [" + userdub.name + "]",
                                proj_name: this.state.addName,
                                work_detail: this.state.addWork,
                                start: toS,
                                end: toE,
                                estimated_hours: this.state.hours,
                                created_by: user.email,
                            });
                        } else if (toS.isoWeekday() === 6 && this.state.checkedSat) {
                            postData.push({
                                uid: userdub.uid,
                                gid: value.id,
                                pid: this.state.addId,
                                tid: this.state.addTaskId,
                                tname: this.state.addTask,
                                uemail: userdub.email,
                                uname: userdub.name,
                                gname: value.name,
                                title: this.state.addName + "(" + this.state.addTask + ") [" + userdub.name + "]",
                                proj_name: this.state.addName,
                                work_detail: this.state.addWork,
                                start: toS,
                                end: toE,
                                estimated_hours: this.state.hours,
                                created_by: user.email,
                            });
                        } else if (toS.isoWeekday() <= 5) {

                            postData.push({
                                uid: userdub.uid,
                                gid: value.id,
                                pid: this.state.addId,
                                tid: this.state.addTaskId,
                                tname: this.state.addTask,
                                uemail: userdub.email,
                                uname: userdub.name,
                                gname: value.name,
                                title: this.state.addName + "(" + this.state.addTask + ") [" + userdub.name + "]",
                                proj_name: this.state.addName,
                                work_detail: this.state.addWork,
                                start: toS,
                                end: toE,
                                estimated_hours: this.state.hours,
                                created_by: user.email,
                            });
                        }

                    } else {

                        date11.add(1, 'days');
                        var toS1 = moment(this.state.selectTime1).add(i, 'days');
                        var toE1 = moment(this.state.selectTime2).add(i, 'days');
                        //console.log(date11)
                        // console.log(toS1.day())
                        // console.log(toS1.isoWeekday() === 7 && this.state.checkedSun)
                        if (toS1.isoWeekday() === 7 && this.state.checkedSun) {
                            postData.push({
                                uid: userdub.uid,
                                gid: value.id,
                                pid: this.state.addId,
                                tid: this.state.addTaskId,
                                tname: this.state.addTask,
                                uemail: userdub.email,
                                uname: userdub.name,
                                gname: value.name,
                                title: this.state.addName + "(" + this.state.addTask + ") [" + userdub.name + "]",
                                proj_name: this.state.addName,
                                work_detail: this.state.addWork,
                                start: toS1,
                                end: toE1,
                                estimated_hours: this.state.hours,
                                created_by: user.email,
                            });
                        } else if (toS1.isoWeekday() === 6 && this.state.checkedSat) {
                            postData.push({
                                uid: userdub.uid,
                                gid: value.id,
                                pid: this.state.addId,
                                tid: this.state.addTaskId,
                                tname: this.state.addTask,
                                uemail: userdub.email,
                                uname: userdub.name,
                                gname: value.name,
                                title: this.state.addName + "(" + this.state.addTask + ") [" + userdub.name + "]",
                                proj_name: this.state.addName,
                                work_detail: this.state.addWork,
                                start: toS1,
                                end: toE1,
                                estimated_hours: this.state.hours,
                                created_by: user.email,
                            });
                        } else if (toS1.isoWeekday() <= 5) {
                            postData.push({
                                uid: userdub.uid,
                                gid: value.id,
                                pid: this.state.addId,
                                tid: this.state.addTaskId,
                                tname: this.state.addTask,
                                uemail: userdub.email,
                                uname: userdub.name,
                                gname: value.name,
                                title: this.state.addName + "(" + this.state.addTask + ") [" + userdub.name + "]",
                                proj_name: this.state.addName,
                                work_detail: this.state.addWork,
                                start: toS1,
                                end: toE1,
                                estimated_hours: this.state.hours,
                                created_by: user.email,
                            });
                        }
                    }

                }
                //console.log(postData)
                postData.allDay = false
                this.postTask(postData);
                this.setState({ openAdd: false, addName: "", addWork: "", addTask: "", tasks: [], checkedB: false, checkedSun: false, checkedSat: false, })
            } else {
                var postData1 = {
                    uid: userdub.uid,
                    gid: value.id,
                    pid: this.state.addId,
                    tid: this.state.addTaskId,
                    tname: this.state.addTask,
                    uemail: userdub.email,
                    uname: userdub.name,
                    gname: value.name,
                    title: this.state.addName + "(" + this.state.addTask + ") [" + userdub.name + "]",
                    proj_name: this.state.addName,
                    work_detail: this.state.addWork,
                    start: this.state.selectTime1,
                    end: this.state.selectTime2,
                    estimated_hours: this.state.hours,
                    created_by: user.email,
                };
                postData1.allDay = false
                this.postTask(postData1);
                this.setState({ openAdd: false, addName: "", addWork: "", addTask: "", selectedTeam: null, tasks: [], checkedB: false, checkedSun: false, checkedSat: false, })

            }

        } else if (!this.state.tenderSwitch && this.state.selectedTaskData && this.state.selectedTaskData.id && value) {

            //tender POST data ready part

            // console.log(this.state.selectedTaskData)
            // console.log(moment(date1, "DD/MM/YYYY") < moment(date2, "DD/MM/YYYY"))
            var pdm_data = this.state.selectedProjectData.tenderbid_data.pdm_data
            if (moment(moment(this.state.selectDate1).format('YYYY-MM-DD')).isAfter(moment(this.state.selectedTaskData.act_exp_finish ? this.state.selectedTaskData.act_exp_finish : this.state.selectedTaskData.to_date).add(15, 'days').format('YYYY-MM-DD'))) {
                alert("Expected Finish date (" + moment(this.state.selectedTaskData.act_exp_finish ? this.state.selectedTaskData.act_exp_finish : this.state.selectedTaskData.to_date).format('DD-MM-YYYY') +
                    ") for this date has passed. Please contact your Team Lead / EM (" + pdm_data.last_name + ", " + pdm_data.first_name + ") to update the dates and then try adding the time.")
            } else if (this.state.checkedB && moment(date1, "DD/MM/YYYY") < moment(date2, "DD/MM/YYYY")) {

                date11 = moment(date1, "DD/MM/YYYY");
                date22 = moment(date2, "DD/MM/YYYY");
                postData = [];
                for (let i = 0; date22 > date11; i++) {
                    // console.log(i)
                    if (i === 0) {
                        toS = moment(this.state.selectTime1).add(i, 'days');
                        toE = moment(this.state.selectTime2).add(i, 'days');
                        // console.log(toS.day())
                        if (toS.isoWeekday() === 7 && this.state.checkedSun) {
                            postData.push({
                                uid: userdub.uid,
                                gid: value.id,
                                pid: null,
                                tid: null,
                                ttid: this.state.selectedTaskData.id,
                                ttenderid: this.state.selectedTaskData.t_wbs_data.pid_data.subproject_pid,
                                tpid: this.state.selectedTaskData.t_wbs_data.pid_data.id,
                                twbsid: this.state.selectedTaskData.t_wbs_data.id,
                                trolesid: this.state.selectedTaskData.tender_role_id,
                                tmapid: this.state.selectedTaskData.tender_map_id,
                                is_tender: 1,
                                tname: null,
                                uemail: userdub.email,
                                uname: userdub.name,
                                gname: value.name,
                                title: this.state.addName + "(" + this.state.addTask + ") [" + userdub.name + "]",
                                proj_name: null,
                                work_detail: this.state.addWork,
                                start: toS,
                                end: toE,
                                estimated_hours: this.state.hours,
                                created_by: user.email,
                            });
                        } else if (toS.isoWeekday() === 6 && this.state.checkedSat) {
                            postData.push({
                                uid: userdub.uid,
                                gid: value.id,
                                pid: null,
                                tid: null,
                                ttid: this.state.selectedTaskData.id,
                                ttenderid: this.state.selectedTaskData.t_wbs_data.pid_data.subproject_pid,
                                tpid: this.state.selectedTaskData.t_wbs_data.pid_data.id,
                                twbsid: this.state.selectedTaskData.t_wbs_data.id,
                                trolesid: this.state.selectedTaskData.tender_role_id,
                                tmapid: this.state.selectedTaskData.tender_map_id,
                                is_tender: 1,
                                tname: null,
                                uemail: userdub.email,
                                uname: userdub.name,
                                gname: value.name,
                                title: this.state.addName + "(" + this.state.addTask + ") [" + userdub.name + "]",
                                proj_name: null,
                                work_detail: this.state.addWork,
                                start: toS,
                                end: toE,
                                estimated_hours: this.state.hours,
                                created_by: user.email,
                            });
                        } else if (toS.isoWeekday() <= 5) {

                            postData.push({
                                uid: userdub.uid,
                                gid: value.id,
                                pid: null,
                                tid: null,
                                ttid: this.state.selectedTaskData.id,
                                ttenderid: this.state.selectedTaskData.t_wbs_data.pid_data.subproject_pid,
                                tpid: this.state.selectedTaskData.t_wbs_data.pid_data.id,
                                twbsid: this.state.selectedTaskData.t_wbs_data.id,
                                trolesid: this.state.selectedTaskData.tender_role_id,
                                tmapid: this.state.selectedTaskData.tender_map_id,
                                is_tender: 1,
                                tname: null,
                                uemail: userdub.email,
                                uname: userdub.name,
                                gname: value.name,
                                title: this.state.addName + "(" + this.state.addTask + ") [" + userdub.name + "]",
                                proj_name: null,
                                work_detail: this.state.addWork,
                                start: toS,
                                end: toE,
                                estimated_hours: this.state.hours,
                                created_by: user.email,
                            });
                        }

                    } else {

                        date11.add(1, 'days');
                        toS1 = moment(this.state.selectTime1).add(i, 'days');
                        toE1 = moment(this.state.selectTime2).add(i, 'days');
                        //console.log(date11)
                        // console.log(toS1.day())
                        // console.log(toS1.isoWeekday() === 7 && this.state.checkedSun)
                        if (toS1.isoWeekday() === 7 && this.state.checkedSun) {
                            postData.push({
                                uid: userdub.uid,
                                gid: value.id,
                                pid: null,
                                tid: null,
                                ttid: this.state.selectedTaskData.id,
                                ttenderid: this.state.selectedTaskData.t_wbs_data.pid_data.subproject_pid,
                                tpid: this.state.selectedTaskData.t_wbs_data.pid_data.id,
                                twbsid: this.state.selectedTaskData.t_wbs_data.id,
                                trolesid: this.state.selectedTaskData.tender_role_id,
                                tmapid: this.state.selectedTaskData.tender_map_id,
                                is_tender: 1,
                                tname: null,
                                uemail: userdub.email,
                                uname: userdub.name,
                                gname: value.name,
                                title: this.state.addName + "(" + this.state.addTask + ") [" + userdub.name + "]",
                                proj_name: null,
                                work_detail: this.state.addWork,
                                start: toS1,
                                end: toE1,
                                estimated_hours: this.state.hours,
                                created_by: user.email,
                            });
                        } else if (toS1.isoWeekday() === 6 && this.state.checkedSat) {
                            postData.push({
                                uid: userdub.uid,
                                gid: value.id,
                                pid: null,
                                tid: null,
                                ttid: this.state.selectedTaskData.id,
                                ttenderid: this.state.selectedTaskData.t_wbs_data.pid_data.subproject_pid,
                                tpid: this.state.selectedTaskData.t_wbs_data.pid_data.id,
                                twbsid: this.state.selectedTaskData.t_wbs_data.id,
                                trolesid: this.state.selectedTaskData.tender_role_id,
                                tmapid: this.state.selectedTaskData.tender_map_id,
                                is_tender: 1,
                                tname: null,
                                uemail: userdub.email,
                                uname: userdub.name,
                                gname: value.name,
                                title: this.state.addName + "(" + this.state.addTask + ") [" + userdub.name + "]",
                                proj_name: null,
                                work_detail: this.state.addWork,
                                start: toS1,
                                end: toE1,
                                estimated_hours: this.state.hours,
                                created_by: user.email,
                            });
                        } else if (toS1.isoWeekday() <= 5) {
                            postData.push({
                                uid: userdub.uid,
                                gid: value.id,
                                pid: null,
                                tid: null,
                                ttid: this.state.selectedTaskData.id,
                                ttenderid: this.state.selectedTaskData.t_wbs_data.pid_data.subproject_pid,
                                tpid: this.state.selectedTaskData.t_wbs_data.pid_data.id,
                                twbsid: this.state.selectedTaskData.t_wbs_data.id,
                                trolesid: this.state.selectedTaskData.tender_role_id,
                                tmapid: this.state.selectedTaskData.tender_map_id,
                                is_tender: 1,
                                tname: null,
                                uemail: userdub.email,
                                uname: userdub.name,
                                gname: value.name,
                                title: this.state.addName + "(" + this.state.addTask + ") [" + userdub.name + "]",
                                proj_name: null,
                                work_detail: this.state.addWork,
                                start: toS1,
                                end: toE1,
                                estimated_hours: this.state.hours,
                                created_by: user.email,
                            });
                        }
                    }

                }
                //console.log(postData)
                postData.allDay = false
                this.postTask(postData);
                this.setState({ openAdd: false, addName: "", addWork: "", addTask: "", selectedTeam: null, tasks: [], checkedB: false, checkedSun: false, checkedSat: false, })
            } else {

                postData1 = {
                    uid: userdub.uid,
                    gid: value.id,
                    pid: null,
                    tid: null,
                    ttid: this.state.selectedTaskData.id,
                    ttenderid: this.state.selectedTaskData.t_wbs_data.pid_data.subproject_pid,
                    tpid: this.state.selectedTaskData.t_wbs_data.pid_data.id,
                    twbsid: this.state.selectedTaskData.t_wbs_data.id,
                    trolesid: this.state.selectedTaskData.tender_role_id,
                    tmapid: this.state.selectedTaskData.tender_map_id,
                    is_tender: 1,
                    tname: null,
                    uemail: userdub.email,
                    uname: userdub.name,
                    gname: value.name,
                    title: this.state.addName + "(" + this.state.addTask + ") [" + userdub.name + "]",
                    proj_name: null,
                    work_detail: this.state.addWork,
                    start: this.state.selectTime1,
                    end: this.state.selectTime2,
                    estimated_hours: this.state.hours,
                    created_by: user.email,
                };
                postData1.allDay = false
                this.postTask(postData1);
                this.setState({ openAdd: false, addName: "", addWork: "", addTask: "", selectedTeam: null, tasks: [], checkedB: false, checkedSun: false, checkedSat: false, })

            }
        } else {
            this.props.enqueueSnackbar('Fill all the details !', {
                variant: 'error'
            });
        }
    }

    handleSplitAddTask() {

        // console.log(this.state.info)

        var start1 = moment(this.state.selectDate1)
        var end1 = moment(this.state.selectDate2)
        let time = moment('00:00', 'HH:mm');
        start1.set({
            hour: time.get('hour'),
            minute: time.get('minute'),
            second: time.get('second')
        });
        end1.set({
            hour: time.get('hour'),
            minute: time.get('minute'),
            second: time.get('second')
        });
        this.setState({ openSplit: false, selectTime1: start1, selectTime2: end1, selectDate1: start1, selectDate2: end1 }, () => {

            var date1 = moment(this.state.selectDate1).format('DD/MM/YYYY');
            var date2 = moment(this.state.selectDate2).format('DD/MM/YYYY');

            if (this.state.info.is_tender === 0) {

                if (this.state.checkedB && moment(date1, "DD/MM/YYYY") < moment(date2, "DD/MM/YYYY")) {
                    var date11 = moment(date1, "DD/MM/YYYY");
                    var date22 = moment(date2, "DD/MM/YYYY");
                    var postData = [];
                    for (let i = 0; date22 > date11; i++) {
                        if (i === 0) {
                            var toS = moment(this.state.selectTime1).add(i, 'days');
                            var toE = moment(this.state.selectTime1).add(i, 'days');
                            // console.log(toS.day())
                            if (toS.isoWeekday() === 7 && this.state.checkedSun) {
                                postData.push({
                                    uid: this.state.info.uid,
                                    gid: this.state.info.gid,
                                    pid: this.state.info.pid,
                                    tid: this.state.info.tid,
                                    tname: this.state.info.tname,
                                    uemail: this.state.info.uemail,
                                    uname: this.state.info.uname,
                                    gname: this.state.info.gname,
                                    title: this.state.info.title,
                                    proj_name: this.state.info.proj_name,
                                    work_detail: this.state.info.work_detail,
                                    start: toS,
                                    end: toE,
                                    allDay: false,
                                    estimated_hours: this.state.info.hours,
                                    created_by: this.state.info.created_by,
                                });
                            } else if (toS.isoWeekday() === 6 && this.state.checkedSat) {
                                postData.push({
                                    uid: this.state.info.uid,
                                    gid: this.state.info.gid,
                                    pid: this.state.info.pid,
                                    tid: this.state.info.tid,
                                    tname: this.state.info.tname,
                                    uemail: this.state.info.uemail,
                                    uname: this.state.info.uname,
                                    gname: this.state.info.gname,
                                    title: this.state.info.title,
                                    proj_name: this.state.info.proj_name,
                                    work_detail: this.state.info.work_detail,
                                    start: toS,
                                    end: toE,
                                    allDay: false,
                                    estimated_hours: this.state.info.hours,
                                    created_by: this.state.info.created_by,
                                });
                            } else if (toS.isoWeekday() <= 5) {

                                postData.push({
                                    uid: this.state.info.uid,
                                    gid: this.state.info.gid,
                                    pid: this.state.info.pid,
                                    tid: this.state.info.tid,
                                    tname: this.state.info.tname,
                                    uemail: this.state.info.uemail,
                                    uname: this.state.info.uname,
                                    gname: this.state.info.gname,
                                    title: this.state.info.title,
                                    proj_name: this.state.info.proj_name,
                                    work_detail: this.state.info.work_detail,
                                    start: toS,
                                    end: toE,
                                    allDay: false,
                                    estimated_hours: this.state.info.hours,
                                    created_by: this.state.info.created_by,
                                });
                            }

                        } else {

                            date11.add(1, 'days');
                            var toS1 = moment(this.state.selectTime1).add(i, 'days');
                            var toE1 = moment(this.state.selectTime1).add(i, 'days');
                            //console.log(date11)
                            // console.log(toS1.day())
                            // console.log(toS1.isoWeekday() === 7 && this.state.checkedSun)
                            if (toS1.isoWeekday() === 7 && this.state.checkedSun) {
                                postData.push({
                                    uid: this.state.info.uid,
                                    gid: this.state.info.gid,
                                    pid: this.state.info.pid,
                                    tid: this.state.info.tid,
                                    tname: this.state.info.tname,
                                    uemail: this.state.info.uemail,
                                    uname: this.state.info.uname,
                                    gname: this.state.info.gname,
                                    title: this.state.info.title,
                                    proj_name: this.state.info.proj_name,
                                    work_detail: this.state.info.work_detail,
                                    start: toS1,
                                    end: toE1,
                                    allDay: false,
                                    estimated_hours: this.state.info.hours,
                                    created_by: this.state.info.created_by,
                                });
                            } else if (toS1.isoWeekday() === 6 && this.state.checkedSat) {
                                postData.push({
                                    uid: this.state.info.uid,
                                    gid: this.state.info.gid,
                                    pid: this.state.info.pid,
                                    tid: this.state.info.tid,
                                    tname: this.state.info.tname,
                                    uemail: this.state.info.uemail,
                                    uname: this.state.info.uname,
                                    gname: this.state.info.gname,
                                    title: this.state.info.title,
                                    proj_name: this.state.info.proj_name,
                                    work_detail: this.state.info.work_detail,
                                    start: toS1,
                                    end: toE1,
                                    allDay: false,
                                    estimated_hours: this.state.info.hours,
                                    created_by: this.state.info.created_by,
                                });
                            } else if (toS1.isoWeekday() <= 5) {
                                postData.push({
                                    uid: this.state.info.uid,
                                    gid: this.state.info.gid,
                                    pid: this.state.info.pid,
                                    tid: this.state.info.tid,
                                    tname: this.state.info.tname,
                                    uemail: this.state.info.uemail,
                                    uname: this.state.info.uname,
                                    gname: this.state.info.gname,
                                    title: this.state.info.title,
                                    proj_name: this.state.info.proj_name,
                                    work_detail: this.state.info.work_detail,
                                    start: toS1,
                                    end: toE1,
                                    allDay: false,
                                    estimated_hours: this.state.info.hours,
                                    created_by: this.state.info.created_by,
                                });
                            }
                        }

                    }

                    //console.log(postData)
                    this.postTask(postData);
                    this.setState({ openAdd: false, addName: "", addWork: "", addTask: "", tasks: [], checkedB: false, checkedSun: false, checkedSat: false, })

                } else {
                    var postData1 = {
                        uid: this.state.info.uid,
                        gid: this.state.info.gid,
                        pid: this.state.info.pid,
                        tid: this.state.info.tid,
                        tname: this.state.info.tname,
                        uemail: this.state.info.uemail,
                        uname: this.state.info.uname,
                        gname: this.state.info.gname,
                        title: this.state.info.title,
                        proj_name: this.state.info.proj_name,
                        work_detail: this.state.info.work_detail,
                        start: this.state.selectTime1,
                        end: this.state.selectTime1,
                        allDay: false,
                        estimated_hours: this.state.info.hours,
                        created_by: this.state.info.created_by,
                    };
                    this.postTask(postData1);
                    this.setState({ openAdd: false, addName: "", addWork: "", addTask: "", tasks: [], checkedB: false, checkedSun: false, checkedSat: false, })

                }

            } else if (this.state.info.is_tender === 1) {

                // tender split operations

                if (this.state.checkedB && moment(date1, "DD/MM/YYYY") < moment(date2, "DD/MM/YYYY")) {
                    date11 = moment(date1, "DD/MM/YYYY");
                    date22 = moment(date2, "DD/MM/YYYY");
                    postData = [];
                    for (let i = 0; date22 > date11; i++) {
                        if (i === 0) {
                            toS = moment(this.state.selectTime1).add(i, 'days');
                            toE = moment(this.state.selectTime1).add(i, 'days');
                            // console.log(toS.day())
                            if (toS.isoWeekday() === 7 && this.state.checkedSun) {
                                postData.push({
                                    uid: this.state.info.uid,
                                    gid: this.state.info.gid,
                                    pid: this.state.info.pid,
                                    tid: this.state.info.tid,
                                    ttid: this.state.info.ttid,
                                    ttenderid: this.state.info.ttenderid,
                                    tpid: this.state.info.tpid,
                                    twbsid: this.state.info.twbsid,
                                    trolesid: this.state.info.trolesid,
                                    tmapid: this.state.info.tmapid,
                                    is_tender: this.state.info.is_tender,
                                    tname: this.state.info.tname,
                                    uemail: this.state.info.uemail,
                                    uname: this.state.info.uname,
                                    gname: this.state.info.gname,
                                    title: this.state.info.title,
                                    proj_name: this.state.info.proj_name,
                                    work_detail: this.state.info.work_detail,
                                    start: toS,
                                    end: toE,
                                    allDay: false,
                                    estimated_hours: this.state.info.hours,
                                    created_by: this.state.info.created_by,
                                });
                            } else if (toS.isoWeekday() === 6 && this.state.checkedSat) {
                                postData.push({
                                    uid: this.state.info.uid,
                                    gid: this.state.info.gid,
                                    pid: this.state.info.pid,
                                    tid: this.state.info.tid,
                                    ttid: this.state.info.ttid,
                                    tpid: this.state.info.tpid,
                                    ttenderid: this.state.info.ttenderid,
                                    twbsid: this.state.info.twbsid,
                                    trolesid: this.state.info.trolesid,
                                    tmapid: this.state.info.tmapid,
                                    is_tender: this.state.info.is_tender,
                                    tname: this.state.info.tname,
                                    uemail: this.state.info.uemail,
                                    uname: this.state.info.uname,
                                    gname: this.state.info.gname,
                                    title: this.state.info.title,
                                    proj_name: this.state.info.proj_name,
                                    work_detail: this.state.info.work_detail,
                                    start: toS,
                                    end: toE,
                                    allDay: false,
                                    estimated_hours: this.state.info.hours,
                                    created_by: this.state.info.created_by,
                                });
                            } else if (toS.isoWeekday() <= 5) {

                                postData.push({
                                    uid: this.state.info.uid,
                                    gid: this.state.info.gid,
                                    pid: this.state.info.pid,
                                    tid: this.state.info.tid,
                                    ttid: this.state.info.ttid,
                                    ttenderid: this.state.info.ttenderid,
                                    tpid: this.state.info.tpid,
                                    twbsid: this.state.info.twbsid,
                                    trolesid: this.state.info.trolesid,
                                    tmapid: this.state.info.tmapid,
                                    is_tender: this.state.info.is_tender,
                                    tname: this.state.info.tname,
                                    uemail: this.state.info.uemail,
                                    uname: this.state.info.uname,
                                    gname: this.state.info.gname,
                                    title: this.state.info.title,
                                    proj_name: this.state.info.proj_name,
                                    work_detail: this.state.info.work_detail,
                                    start: toS,
                                    end: toE,
                                    allDay: false,
                                    estimated_hours: this.state.info.hours,
                                    created_by: this.state.info.created_by,
                                });
                            }

                        } else {

                            date11.add(1, 'days');
                            toS1 = moment(this.state.selectTime1).add(i, 'days');
                            toE1 = moment(this.state.selectTime1).add(i, 'days');
                            //console.log(date11)
                            // console.log(toS1.day())
                            // console.log(toS1.isoWeekday() === 7 && this.state.checkedSun)
                            if (toS1.isoWeekday() === 7 && this.state.checkedSun) {
                                postData.push({
                                    uid: this.state.info.uid,
                                    gid: this.state.info.gid,
                                    pid: this.state.info.pid,
                                    tid: this.state.info.tid,
                                    ttid: this.state.info.ttid,
                                    tpid: this.state.info.tpid,
                                    ttenderid: this.state.info.ttenderid,
                                    twbsid: this.state.info.twbsid,
                                    trolesid: this.state.info.trolesid,
                                    tmapid: this.state.info.tmapid,
                                    is_tender: this.state.info.is_tender,
                                    tname: this.state.info.tname,
                                    uemail: this.state.info.uemail,
                                    uname: this.state.info.uname,
                                    gname: this.state.info.gname,
                                    title: this.state.info.title,
                                    proj_name: this.state.info.proj_name,
                                    work_detail: this.state.info.work_detail,
                                    start: toS1,
                                    end: toE1,
                                    allDay: false,
                                    estimated_hours: this.state.info.hours,
                                    created_by: this.state.info.created_by,
                                });
                            } else if (toS1.isoWeekday() === 6 && this.state.checkedSat) {
                                postData.push({
                                    uid: this.state.info.uid,
                                    gid: this.state.info.gid,
                                    pid: this.state.info.pid,
                                    tid: this.state.info.tid,
                                    ttid: this.state.info.ttid,
                                    tpid: this.state.info.tpid,
                                    ttenderid: this.state.info.ttenderid,
                                    twbsid: this.state.info.twbsid,
                                    trolesid: this.state.info.trolesid,
                                    tmapid: this.state.info.tmapid,
                                    is_tender: this.state.info.is_tender,
                                    tname: this.state.info.tname,
                                    uemail: this.state.info.uemail,
                                    uname: this.state.info.uname,
                                    gname: this.state.info.gname,
                                    title: this.state.info.title,
                                    proj_name: this.state.info.proj_name,
                                    work_detail: this.state.info.work_detail,
                                    start: toS1,
                                    end: toE1,
                                    allDay: false,
                                    estimated_hours: this.state.info.hours,
                                    created_by: this.state.info.created_by,
                                });
                            } else if (toS1.isoWeekday() <= 5) {
                                postData.push({
                                    uid: this.state.info.uid,
                                    gid: this.state.info.gid,
                                    pid: this.state.info.pid,
                                    tid: this.state.info.tid,
                                    ttid: this.state.info.ttid,
                                    tpid: this.state.info.tpid,
                                    ttenderid: this.state.info.ttenderid,
                                    twbsid: this.state.info.twbsid,
                                    trolesid: this.state.info.trolesid,
                                    tmapid: this.state.info.tmapid,
                                    is_tender: this.state.info.is_tender,
                                    tname: this.state.info.tname,
                                    uemail: this.state.info.uemail,
                                    uname: this.state.info.uname,
                                    gname: this.state.info.gname,
                                    title: this.state.info.title,
                                    proj_name: this.state.info.proj_name,
                                    work_detail: this.state.info.work_detail,
                                    start: toS1,
                                    end: toE1,
                                    allDay: false,
                                    estimated_hours: this.state.info.hours,
                                    created_by: this.state.info.created_by,
                                });
                            }
                        }

                    }
                    postData.allDay = false
                    //console.log(postData)
                    this.postTask(postData);
                    this.setState({ openAdd: false, addName: "", addWork: "", addTask: "", tasks: [], checkedB: false, checkedSun: false, checkedSat: false, })

                } else {
                    postData1 = {
                        uid: this.state.info.uid,
                        gid: this.state.info.gid,
                        pid: this.state.info.pid,
                        tid: this.state.info.tid,
                        ttid: this.state.info.ttid,
                        ttenderid: this.state.info.ttenderid,
                        tpid: this.state.info.tpid,
                        twbsid: this.state.info.twbsid,
                        trolesid: this.state.info.trolesid,
                        tmapid: this.state.info.tmapid,
                        is_tender: this.state.info.is_tender,
                        tname: this.state.info.tname,
                        uemail: this.state.info.uemail,
                        uname: this.state.info.uname,
                        gname: this.state.info.gname,
                        title: this.state.info.title,
                        proj_name: this.state.info.proj_name,
                        work_detail: this.state.info.work_detail,
                        start: this.state.selectTime1,
                        end: this.state.selectTime1,
                        allDay: false,
                        estimated_hours: this.state.info.hours,
                        created_by: this.state.info.created_by,
                    };
                    postData1.allDay = false
                    this.postTask(postData1);
                    this.setState({ openAdd: false, addName: "", addWork: "", addTask: "", tasks: [], checkedB: false, checkedSun: false, checkedSat: false, })

                }

            }
        })


    }

    onTagsChange = (event, values) => {
        // console.log(values)
        if (values) {
            this.setState({
                addName: values.name,
                addId: values.id,
                addData: values
            })
            Service.get(`/bb/api/task/category/company/task_category_non_billable/?pid=${values.id}&uid=${user.id}`, {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    this.setState({ taskcategory: res.data, })
                })
                .catch(error => {
                    errorMessage(this.props, error)
                });
            // Service.get("/bb/api/tasks/?pid=" + values.id, {
            //     headers: {
            //         Authorization: "Token " + token,
            //     },
            // })
            //     .then(res => {
            //         var data = [];
            //         for (let i = 0; i < res.data.length; i++) {
            //             const element = res.data[i];
            //             if (element.is_active) {
            //                 data.push(element)
            //             }
            //         }
            //         this.setState({ tasks: data, })
            //     })
            //     .catch(error => {
            //         errorMessage(this.props, error)
            //     });
        } else {
            this.setState({
                addName: '',
                addId: '',
                addData: null
            })
        }

    }

    onTaskCategoryChange = (event, values) => {
        // console.log(values)
        if (values) {
            Service.get("/bb/api/tasks/?task_category=" + values.id, {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    var data = [];
                    for (let i = 0; i < res.data.length; i++) {
                        const element = res.data[i];
                        if (element.is_active) {
                            data.push(element)
                        }
                    }
                    this.setState({ addName: 'Non Billable', selectedOverheadCategory: values, tasks: data, addTaskId: null, hoursUtilizedData2: null, selectedOverheadTask: null }, () => {
                        if (this.state.selectedAllProj) {
                            for (let i = 0; i < this.state.tasks.length; i++) {
                                const element = this.state.tasks[i];
                                if (element.id === this.state.selectedAllProj?.id) {
                                    this.onTasksChange(null, element)
                                    break
                                }
                            }
                        } else if (this.state.tasks.length === 1) {
                            this.setState({
                                selectedOverheadTask: this.state.tasks[0],
                                addTaskId: this.state.tasks[0].id,
                                addTask: this.state.tasks[0].name,
                            })
                        }
                    })
                })
                .catch(error => {
                    errorMessage(this.props, error)
                });
        } else {
            this.setState({
                selectedOverheadCategory: null,
                selectedOverheadTask: null,
                addTaskId: null,
                addName: "",
                addTask: "",
                selectedAllProj: null,
                tasks: [],
                teamDiasabled: false
            })
        }
    }

    onTproj = (event, values) => {
        if (values) {
            var tproj = [];
            for (let i = 0; i < this.state.tenderwbs.length; i++) {
                const element = this.state.tenderwbs[i];
                if (values.id === element.pid) {
                    tproj.push(element)
                }
            }
            tproj.sort(sortByProperty("name"))
            this.setState({ tenderShowWbs: tproj, selectedDSData: values });
            if (this.state.selectedAllProj && (tproj.length > 1)) {
                this.setState({ tenderShowWbs: tproj })
                for (let i = 0; i < tproj.length; i++) {
                    const element = tproj[i];
                    if (element.id === this.state.selectedAllProj?.ohlwbs_id) {
                        this.onTwbs(null, element)
                        this.setState({ selectedWBSData: element })
                        break
                    }
                }
            } else if (tproj.length === 1) {
                this.setState({
                    selectedWBSData: tproj[0]
                })
                this.onTwbs(null, tproj[0])
            } else {
                this.setState({
                    selectedWBSData: null, selectedTaskData: null
                })
            }
        } else {
            this.setState({
                tenderShowWbs: [],
                tenderShowTasks: [],
                selectedDSData: null,
                selectedWBSData: null,
                selectedTaskData: null
            });
        }
    }

    onAllProjChange = (event, values) => {
        if (values) {
            this.setState({ categoryLoading: true })
            if (values.is_overhead) {
                this.setState({ selectedAllProj: values, categorySwitch: 2, selectedProjectData: { id: 11, tender_name: 'Non Billable', is_overhead: true } }, () => {
                    this.fetchDatas();
                    this.onTDsproj(null, this.state.selectedProjectData)
                })
            } else {
                this.setState({
                    selectedAllProj: values,
                    categorySwitch: values.inc_evp,
                }, () => { this.fetchDatas() })
            }
        } else {
            this.setState({
                selectedAllProj: null,
                selectedProjectData: null,
                tenderShowWbs: '',
                selectedWBSData: null,
                selectedTaskData: null,
                selectedDSData: null,
                selectedOverheadCategory: null,
                selectedOverheadTask: null,
                addName: "",
                addTask: "",
            });
        }
    }

    onTDsproj = (event, values) => {
        // debugger
        if (values && !values.is_overhead) {
            this.setState({
                tenderSwitch: false, openAddShowMenu: true, addName: "",
                addWork: "", addTask: "",
                selectedDSData: null, selectedWBSData: null, selectedTaskData: null, tasks: []
            })
            var tproj = [];
            for (let i = 0; i < this.state.tenderProj.length; i++) {
                const element = this.state.tenderProj[i];
                if (values.id === element.subproject_pid) {
                    tproj.push(element)
                }
            }
            tproj.sort(sortByProperty("name"))
            if (this.state.selectedAllProj && (tproj.length > 1)) {
                this.setState({ tenderShowDs: tproj, selectedProjectData: values })
                for (let i = 0; i < tproj.length; i++) {
                    const element = tproj[i];
                    if (element.id === this.state.selectedAllProj?.ds_id) {
                        this.onTproj(null, element)
                        this.setState({ selectedDSData: element })
                        break
                    }
                }
            } else if (tproj.length === 1) {
                this.setState({
                    selectedDSData: tproj[0],
                    selectedProjectData: values,
                    tenderShowDs: tproj,
                })
                this.onTproj(null, tproj[0])
            } else {
                this.setState({
                    tenderShowDs: tproj,
                    selectedDSData: null,
                    selectedWBSData: null,
                    selectedTaskData: null,
                    selectedProjectData: values
                })
            }
        } else if (values && values.is_overhead) {
            this.setState({
                addName: values.tender_name,
                addId: values.id,
                addData: values,
                tenderSwitch: true, openAddShowMenu: false,
                addWork: "", addTask: "",
                selectedProjectData: values,
                selectedDSData: null, selectedWBSData: null, selectedTaskData: null, tasks: []
            })
            Service.get(`/bb/api/task/category/company/task_category_non_billable/?pid=${values.id}&uid=${user.id}`, {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    this.setState({ taskcategory: res.data, openAddShowMenu: true }, () => {
                        if (this.state.selectedAllProj) {
                            for (let i = 0; i < this.state.taskcategory.length; i++) {
                                const element = this.state.taskcategory[i];
                                if (element.id === this.state.selectedAllProj?.task_category_id) {
                                    this.onTaskCategoryChange(null, element)
                                    break
                                }
                            }
                        }
                    })
                })
                .catch(error => {
                    errorMessage(this.props, error)
                });
        } else {
            this.setState({
                tenderShowDs: [],
                tenderShowWbs: [],
                tenderShowTasks: [],
                selectedDSData: null,
                selectedWBSData: null,
                selectedTaskData: null,
                selectedProjectData: null,
                selectedOverheadCategory: null,
                selectedOverheadTask: null,
                addName: "",
                addTask: "",
                selectedAllProj: null,
            });
        }
    }

    onTwbs = (event, values) => {
        // console.log(values)
        var holdExist = false
        if (values) {
            var tproj = [];
            for (let i = 0; i < this.state.tenderTask.length; i++) {
                const element = this.state.tenderTask[i];
                if (values.id === element.t_ohlwbs) {
                    if (element.task_hold) {
                        holdExist = true
                        element.task_name = "(ON HOLD) " + (element.decs ? element.decs : element.name)
                    } else {
                        element.task_name = (element.decs ? element.decs : element.name)
                    }
                    // console.log(element)
                    tproj.push(element)
                }
            }
            tproj.sort(sortByProperty("name"))
            this.setState({ tenderShowTasks: tproj, hoursUtilizedData2: null, selectedWBSData: values });

            if (this.state.selectedAllProj && (tproj.length > 1) && !holdExist) {
                this.setState({ tenderShowTasks: tproj })
                for (let i = 0; i < tproj.length; i++) {
                    const element = tproj[i];
                    if (element.id === this.state.selectedAllProj?.ohlwbs_task_id) {
                        this.onTtask(null, element)
                        this.setState({ selectedTaskData: element })
                        break
                    }
                }
            } else if (tproj.length === 1 && !holdExist) {
                this.setState({
                    selectedTaskData: tproj[0]
                })
                this.onTtask(null, tproj[0])
            } else {
                this.setState({
                    selectedTaskData: null,
                })
            }

        } else {
            this.setState({
                tenderShowTasks: [],
                selectedTaskData: null,
                selectedWBSData: null
            });
        }

    }

    onTtask = (event, values) => {
        if (values && values.task_hold) {
            this.props.enqueueSnackbar('This task is “ON HOLD”, you can’t add it to your timesheet. Contact your EM for details.', {
                variant: 'warning',
                autoHideDuration: 3000,
            });
            this.setState({ selectedTaskData: '', hoursUtilizedData2: null });
        } else {
            if (values) {
                this.setState({ selectedTaskData: values, hoursUtilizedData2: null });
            } else {
                this.setState({ selectedTaskData: '', hoursUtilizedData2: null });
            }

            var userdubtemp = {
                uid: user.id,
                name: user.last_name + ', ' + user.first_name,
                email: user.email,
            }

            userdub = (this.props.value) ? (JSON.parse(localStorage.getItem("staff_to_see"))) : (userdubtemp);

            if (values) {
                Service.patch("/bb/api/team/tasks/get_utilized_hours/", {
                    headers: {
                        Authorization: "Token " + token,
                    },
                    data: {
                        uid: userdub.uid,
                        ttid: values.id
                    },
                })
                    .then(res => {
                        // console.log(res.data)
                        this.setState({
                            hoursUtilizedData2: res.data
                        })
                    })
                    .catch(error => {
                        this.props.enqueueSnackbar('Hours utilized data unavailable.', {
                            variant: 'error'
                        });

                    });
            }
        }


    }

    onTeamSelect = (event, values) => {
        this.setState({ selectedTeam: values });
    }

    onSaveComment() {
        if (this.state.addComment.length > 500) {
            this.props.enqueueSnackbar('Comments should be less than 500 characters.', {
                variant: 'warning'
            });
        } else {
            var dataPatch = this.state.info
            dataPatch.comments = this.state.addComment
            dataPatch.actual_hours = this.state.actualHours
            dataPatch.in_office_item = this.state.checkedWFH === true ? 1 : 0
            this.setState({ saveBtnDisabled: true })
            Service.patch("/bb/api/team/tasks/put_update/", {
                headers: {
                    Authorization: "Token " + token,
                },
                data: [dataPatch]
            })
                .then(res => {
                    this.props.enqueueSnackbar('Comment Saved', {
                        variant: 'success'
                    });
                    var newMod = this.state.events;
                    for (let i = 0; i < newMod.length; i++) {
                        const element = newMod[i];
                        if (element.id === this.state.info.id) {
                            newMod[i].comments = this.state.addComment;
                            newMod[i].actual_hours = this.state.actualHours;
                            break;
                        }
                    }
                    selected_event = null
                    this.setState({ openInfo: false, events: newMod, checkedWFH: false, saveBtnDisabled: false })
                })
                .catch(error => {
                    errorMessage(this.props, error)
                    this.setState({ saveBtnDisabled: false })
                    console.log(error)
                });
        }
    }

    onTasksChange = (event, values) => {
        if (values) {
            this.setState({
                selectedOverheadTask: values,
                addTask: values.name,
                addTaskId: values.id,
                hoursUtilizedData2: null,

            })
            if (values.hours_control) {
                Service.patch("/bb/api/team/tasks/get_utilized_hours/", {
                    headers: {
                        Authorization: "Token " + token,
                    },
                    data: {
                        uid: userdub.uid,
                        tid: values.id,
                        year: this.state.selectDate1.year()
                    },
                })
                    .then(res => {
                        // console.log(res.data)
                        if (this.props.myteams) {
                            for (let i = 0; i < this.props.myteams.length; i++) {
                                const element = this.props.myteams[i];
                                if (element.primary_member) {
                                    this.setState({
                                        selectedTeam: element,
                                        teamDiasabled: true
                                    })
                                }
                            }
                        }
                        this.setState({
                            hoursUtilizedData2: res.data
                        })
                    })
                    .catch(error => {
                        this.props.enqueueSnackbar('Hours utilized data unavailable.', {
                            variant: 'error'
                        });
                    });
            } else {
                if (this.props.myteams && this.props.myteams !== undefined) {
                    this.props.myteams.sort(sortByProperty('name'))
                    if (this.props.myteams.length === 1) {
                        var selectedTeam = this.props.myteams[0];
                        // console.log(selectedTeam)
                        this.setState({ selectedTeam: selectedTeam, teamDiasabled: false })
                    }
                }
            }

            // if (values.id === 68) {
            //     this.setState({ addTaskDisabled: true })
            //     Service.get(`/bb/api/bank/holiday/bank_holiday_count/?uid=${userdub.uid}`, {
            //         headers: {
            //             Authorization: "Token " + token,
            //         },
            //     })
            //         .then(res => {
            //             if (res.data.remaining_bank_holidays <= 0) {
            //                 this.props.enqueueSnackbar(`You have reached the maximum limit(${res.data.total_bank_holidays} days) of bank holidays for this year. please contact HR for more details`, {
            //                     variant: 'warning'
            //                 });
            //                 this.setState({ addTaskDisabled: true })
            //             } else {
            //                 this.setState({ addTaskDisabled: false })
            //             }
            //         })
            //         .catch(e => {
            //             errorMessage(this.props, e)
            //             console.log(e)
            //         })
            // } else {
            //     this.setState({ addTaskDisabled: false })
            // }
        } else {
            this.setState({
                addTask: '',
                addTaskId: '',
                addTaskDisabled: false,
                teamDiasabled: false,
                selectedOverheadTask: null,
            })
        }
    }

    onViewChange = (view) => {
        this.setState({ calender_view: view })
    }

    onRangeChange = (range) => {
        // console.log(range)
        if (Array.isArray(range)) {
            this.setState({
                start_date: moment(range[0]),
                end_date: moment(range[range.length - 1]),
            });
            this.setState({ isLoading2: true })
            this.fetchOps(moment(range[0]), moment(range[range.length - 1]))

        } else {
            this.setState({
                start_date: moment(range.start),
                end_date: moment(range.end),
            });
            this.setState({ isLoading2: true })
            this.fetchOps(moment(range.start), moment(range.end))
        }

    }

    noAccess() {
        alert('No access for this user')
    }

    fetchPerTaskData() {
        Service.get('/bb/api/tender/wbs/tasks/' + this.state.info.ttid + '/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({
                    perTaskLoading: false,
                    perTaskData: res.data,
                    taskPercent: res.data.comp_percnt,
                })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    openLeadChat(leadData) {

        var email = leadData.email
        if (email) {
            this.setState({ teamLeadsDialog: false })
            window.open("https://teams.microsoft.com/l/chat/0/0?users=" + email, "_blank")
        } else {
            this.props.enqueueSnackbar('Something went wrong!', {
                variant: 'error'
            });
        }
        // Service.get('/bb/api/tasks/estimate/user_primary_team_lead/?user=' + userdub.uid, {
        //     headers: {
        //         Authorization: "Token " + token,
        //     },
        // })
        //     .then(res => {
        //         var emailArr = []
        //         for (let i = 0; i < res.data.length; i++) {
        //             const element = res.data[i];
        //             emailArr.push(element.user_email)
        //         }

        // })
        // .catch(error => {
        //     errorMessage(this.props, error)
        // });
    }

    findLead() {
        var userdubtemp = {
            uid: user.id,
            name: user.last_name + ', ' + user.first_name,
            email: user.email,
        }

        userdub = (this.props.value) ? (JSON.parse(localStorage.getItem("staff_to_see"))) : (userdubtemp);
        Service.get('/bb/api/user/report/view/get_lead_data_from_user/?uid=' + userdub.uid, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                if (res.data.length === 1) {
                    this.openLeadChat(res.data[0])
                } else {
                    this.setState({ teamLeadsDialog: true, teamLeads: res.data })
                }
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    handleTaskComp() {
        this.setState({

            openTaskUpdate: false,
        })
        var data = this.state.perTaskData;

        var historydata = null

        if (data.comp_percnt !== this.state.taskPercent) {
            Service.post('/bb/api/tender/tasks/history/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: {
                    "t_pid": data.t_pid,
                    "t_wbs": data.t_ohlwbs,
                    "t_tasks": data.id,
                    "prev_task_status": data.comp_percnt,
                    "task_status": this.state.taskPercent,
                    "created_by": user.id
                },
            })
                .then(res => {
                    historydata = res.data
                    data.comp_percnt = this.state.taskPercent;
                    Service.put('/bb/api/tender/wbs/tasks/' + this.state.info.ttid + '/', {
                        headers: {
                            Authorization: "Token " + token,
                        },
                        data: data,
                    })
                        .then(res1 => {

                            var dataTemp = this.state.events
                            for (let i = 0; i < dataTemp.length; i++) {
                                const element = dataTemp[i];
                                if (element.ttid === this.state.info.ttid) {
                                    element.ttstatus = this.state.taskPercent
                                }
                            }
                            this.setState({

                                perTaskLoading: false,
                                events: dataTemp,
                                openTaskUpdate: false,
                            })
                            this.props.enqueueSnackbar('Task status updated!', {
                                variant: 'success'
                            });
                        })
                        .catch(error => {
                            errorMessage(this.props, error)
                            if (historydata) {
                                Service.delete('/bb/api/tender/tasks/history/' + historydata.id + '/', {
                                    headers: {
                                        Authorization: "Token " + token,
                                    },

                                })
                                    .catch(error => {
                                        errorMessage(this.props, error)
                                    });
                            }


                        });
                })
                .catch(error => {
                    errorMessage(this.props, error)
                });
        }
        // console.log(data)

    }

    getDateForCalendar(propsDate) {
        var leave_element = null
        for (let i = 0; i < this.state.bankHolidays.length; i++) {
            const element = this.state.bankHolidays[i];
            if (moment(propsDate).format('YYYY-MM-DD') === element.leave_date) {
                leave_element = element
            }
        }
        return leave_element
    }

    handleBack() {
        // this.setState({
        copyStart = moment(copyStart).subtract(1, 'weeks')
        copyEnd = moment(copyEnd).subtract(1, 'weeks')
        // }, () => {
        this.copyTimesheet(copyStart, copyEnd)
        console.log(this.state.start_date, this.state.end_date)
        // })
    }
    handleFront() {
        // this.setState({
        copyStart = moment(copyStart).add(1, 'weeks')
        copyEnd = moment(copyEnd).add(1, 'weeks')
        // }, () => {
        this.copyTimesheet(copyStart, copyEnd)
        console.log(this.state.start_date, this.state.end_date)
        // })
    }

    copyFromLastWeek() {
        var lastweeklast = moment().subtract(3, 'weeks').endOf('isoWeek').subtract(1, 'days')

        if (this.state.start_date <= lastweeklast) {
            alert('Please Contact your lead for adding tasks to past weeks!')
            return null
        }
        copyStart = new Date(this.state.start_date)
        copyEnd = new Date(this.state.end_date)
        // debugger
        copyStart = moment(copyStart).subtract(1, 'weeks')
        copyEnd = moment(copyEnd).subtract(1, 'weeks')

        // this.setState({
        //     copyStart: start_date,
        //     copyEnd: end_date,
        // }, () => {
        this.copyTimesheet(copyStart, copyEnd)
        // })
    }

    copyTimesheet(start, end) {
        var url = `/bb/api/team/tasks/get_anyweek_timesheet_data/`
        var start_date = start.format('DD-MM-YYYY')
        var end_date = end.format('DD-MM-YYYY')
        var start_date1 = this.state.start_date.format('DD-MM-YYYY')
        var end_date1 = this.state.end_date.format('DD-MM-YYYY')
        if (this.props.value && userdub.uid) {
            url += `?uid=${userdub.uid}&copy_week_start=${start_date}&copy_week_end=${end_date}&start=${start_date1}&end=${end_date1}&gid=${this.props.value.id}`
        } else {
            url += `?copy_week_start=${start_date}&copy_week_end=${end_date}&start=${start_date1}&end=${end_date1}`
        }
        this.getPreviewData(url)
    }

    getPreviewData(url) {
        this.setState({ openPreview: true, previewLoading: true, copyLists: [] })
        Service.get(url, {
            headers: { Authorization: "Token " + token, },
        })
            .then(res => {
                this.setState({ previewData: res.data, previewLoading: false })
            })
            .catch(error => {
                console.log(error)
                this.setState({ previewLoading: false })
                errorMessage(this.props, error)
            });
    }

    copyOps() {
        this.setState({ copyDisabled: true })
        var url = `/bb/api/team/tasks/copy_anyweek_timesheet/`
        var postData = {}
        postData.start = this.state.start_date.format('DD-MM-YYYY')
        postData.end = this.state.end_date.format('DD-MM-YYYY')
        postData.item_ids = this.state.copyLists.map((item) => item.id)
        // debugger
        if (this.props.value && userdub.uid) {
            postData.uid = userdub.uid
            postData.gid = this.props.value.id
        }
        Service.post(url, {
            headers: { Authorization: "Token " + token, },
            data: postData
        })
            .then(res => {
                this.props.enqueueSnackbar('Timesheet Copied Successfully!', {
                    variant: 'success'
                });
                this.fetchOps(this.state.start_date, this.state.end_date)
                this.setState({
                    openPreview: false,
                    isLoading2: true,
                    previewData: [],
                    copyDisabled: false,
                    copyLists: []
                })
            })
            .catch(error => {
                console.log(error)
                this.setState({ copyDisabled: false })
                errorMessage(this.props, error)
            });
    }

    openSubtasks() {
        this.setState({ openLinkPlanner: true, plannerLoader: true })
        Service.get(`/bb/api/tender/subtask/get_subtask_for_calendar/?task_id=${this.state.info.ttid}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ subTasksData: res.data, plannerLoader: false })
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    linkPlanner() {
        if (!this.state.selectedSubTask) {
            this.props.enqueueSnackbar('Please Select the Subtask!', {
                variant: 'warning'
            });
        } else {
            Service.get(`/bb/api/tender/subtask/assign_subtask_to_calendar/?team_task_id=${this.state.info.id}&subtask_id=${this.state.selectedSubTask.id}`, {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    this.fetchOps(this.state.start_date, this.state.end_date);
                    this.props.enqueueSnackbar('Subtask Linked Successfully!', {
                        variant: 'success'
                    });
                    this.setState({ openLinkPlanner: false })
                })
                .catch(error => {
                    console.log(error)
                    errorMessage(this.props, error)
                });
        }
    }

    removeSubtask() {
        Service.get(`/bb/api/tender/subtask/remove_subtask_from_calendar/?team_task_id=${this.state.info.id}&subtask_id=${this.state.info.tender_subtask}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.props.enqueueSnackbar('Subtask removed Successfully!', {
                    variant: 'success'
                });
                this.fetchOps(this.state.start_date, this.state.end_date);
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    openTimesheetSettings() {
        this.setState({ openTimesheetSettings: true })
    }

    getTimesheetOption(data) {
        if (data) {
            if (data.value === 1) {
                this.setState({
                    start_date: this.state.start_date.subtract(1, 'days'),
                    end_date: this.state.end_date.subtract(1, 'days')
                }, () => {
                    this.setState({ timesheetOption: data.value, isLoading: true }, () => {
                        this.setState({ isLoading: false })
                        this.fetchOps(this.state.start_date, this.state.end_date)
                    })
                })
            } else {
                this.setState({
                    timesheetOption: data.value,
                    isLoading: true,
                    start_date: this.state.start_date.add(1, 'days'),
                    end_date: this.state.end_date.add(1, 'days')
                }, () => {
                    this.setState({ isLoading: false })
                    this.fetchOps(this.state.start_date, this.state.end_date)
                })
            }

            if (this.props.getTimesheetOption) {
                this.props.getTimesheetOption(data.value)
            }

            if (this.toolbarRef.current) {
                this.toolbarRef.current.viewChange('week')
            }
        }
    }

    calendarDefaultDate() {
        if (this.props.timesheetOption === 1) {
            return moment(this.state.start_date).add(1, 'days')
        } else {
            return this.state.start_date
        }
    }

    copyQtyOps() {
        this.setState({ qtyDisabled: true })
        Service.post('/bb/api/team/tasks/copy_current_week_timesheet_item/', {
            headers: { Authorization: "Token " + token, },
            data: {
                item_id: this.state.info.id,
                qty: this.state.copyQty,
            }
        })
            .then(res => {
                this.props.enqueueSnackbar('Timesheet Copied Successfully!', {
                    variant: 'success'
                });
                this.setState({
                    copyQty: 1,
                    copyWithQty: false,
                    openInfo: false,
                    qtyDisabled: false,
                })
                this.fetchOps(this.state.start_date, this.state.end_date)
            })
            .catch(error => {
                if (error && error.status === 406) {
                    this.props.enqueueSnackbar(error.data.message, {
                        variant: 'warning'
                    });
                    this.setState({
                        // copyQty: 1,
                        // copyWithQty: false,
                        qtyDisabled: false,
                    })
                } else {
                    this.setState({
                        copyQty: 1,
                        copyWithQty: false,
                        qtyDisabled: false,
                    })
                    console.log(error)
                    errorMessage(this.props, error)
                }
            });
    }

    render() {

        const handleCategorySwitchChange = (event, value) => {
            if (value !== null) {
                var tenderSwitch = this.state.tenderSwitch
                if (value === 1 || value === 0) {
                    tenderSwitch = false
                    this.setState({ selectedProjectData: null, addName: '', addId: '' })
                }
                if (value === 2) {
                    tenderSwitch = true
                    this.setState({ addId: 11, selectedProjectData: { id: 11, tender_name: 'Non Billable', is_overhead: true }, addName: "Non Billable" })
                }
                this.setState({
                    selectedAllProj: null,
                    categorySwitch: value,
                    selectedDSData: null,
                    selectedWBSData: null,
                    selectedTaskData: null,
                    selectedProjectData: null,
                    tenderSwitch,
                    teamDiasabled: false,
                }, () => {
                    this.fetchDatas()
                })
            }
        };

        const handleWFHChange = (event) => {
            this.setState({ checkedWFH: event.target.checked });
        };

        const handleClose = () => {
            this.setState({
                openAdd: false,
                selectedTaskData: null,
                selectedProjectData: null,
                selectedDSData: null,
                selectedWBSData: null,
                selectedOverheadCategory: null,
                selectedOverheadTask: null
            })
        };
        const handleSpltClose = () => {
            this.setState({ openSplit: false, })
        };

        const handleMultiCheck = (e) => {
            this.setState({ checkedB: e.target.checked, })
        };
        // const handleSatCheck = (e) => {
        //     this.setState({ checkedSat: e.target.checked, })
        // };
        // const handleSunCheck = (e) => {
        //     this.setState({ checkedSun: e.target.checked, })
        // };

        const handleInfoSave = () => {
            this.onSaveComment();
        };

        const handleInfoClose = () => {
            selected_event = null
            this.setState({ openInfo: false, checkedWFH: false })
        };

        const handleTaskClose = () => {
            this.setState({ openTaskUpdate: false, })
        };

        const handleEventInfo = (e) => {
            var comm = '';
            var act_hours = 0;
            var start1 = moment(e.start)
            if (start1 <= lastweeklastday) {
                this.setState({ noComment: true, })
            } else {
                this.setState({ noComment: false, })
            }
            if (e.comments === null || e.comments === 'null') {
                comm = '';
            } else {
                comm = e.comments;
            }

            if (e.in_office_item === 1) {
                this.setState({ checkedWFH: true })
            } else {
                this.setState({ checkedWFH: false })
            }
            act_hours = e.actual_hours;
            // console.log(e) 
            selected_event = e
            this.setState({ openInfo: true, hoursUtilizedData: null, info: e, addComment: comm, actualHours: act_hours }, () => { console.log(this.state.info) })

            if (e.is_tender === 1) {
                Service.patch("/bb/api/team/tasks/get_utilized_hours/", {
                    headers: {
                        Authorization: "Token " + token,
                    },
                    data: e,
                })
                    .then(res => {
                        // console.log(res.data)
                        this.setState({
                            hoursUtilizedData: res.data
                        })
                    })
                    .catch(error => {
                        this.props.enqueueSnackbar('Hours utilized data unavailable.', {
                            variant: 'error'
                        });

                    });
            } else {
                if (e.hours_control) {
                    Service.patch("/bb/api/team/tasks/get_utilized_hours/", {
                        headers: {
                            Authorization: "Token " + token,
                        },
                        data: {
                            uid: userdub.uid,
                            tid: e.tid,
                            year: moment().year()
                        }
                    })
                        .then(res => {
                            // console.log(res.data)
                            this.setState({
                                hoursUtilizedData: res.data
                            })
                        })
                        .catch(error => {
                            this.props.enqueueSnackbar('Hours utilized data unavailable.', {
                                variant: 'error'
                            });

                        });
                }
            }

        };

        // const handleWork = (e) => {
        //     this.setState({ addWork: e.target.value, })
        // };

        // const handleActualHours = (e) => {
        //     this.setState({ actualHours: e.target.value, })
        // };
        const handleComment = (e) => {
            this.setState({ addComment: e.target.value, })
        };

        // const handleDateChange = (date) => { 
        //    // console.log(this.state.selectTime1)
        //     this.setState({ selectTime1: date, selectDate1: date });
        // };

        const handleDate3Change = (date) => {
            // console.log(this.state.selectTime1)
            this.setState({ selectTime1: date, selectTime2: date, selectDate1: date });
        };

        const handleDate4Change = (date) => {
            // console.log(date)
            this.setState({ selectDate2: date });
        };

        // const handleDate2Change = (date) => {
        //     //console.log(this.state.selectTime2)
        //     this.setState({ selectTime2: date, selectDate1: date });
        // };

        const handlePercChange = (e) => {
            this.setState({ taskPercent: e.target.value })
        };
        // const handleHours = (e) => {
        //     this.setState({ hours: e.target.value })
        // };

        // const handleTenderSwitch = (e) => {

        //     this.setState({ tenderSwitch: e.target.checked, addName: "", addWork: "", addTask: "", selectedTeam: null, openAddShowMenu: false, selectedDSData: null, selectedWBSData: null, selectedTaskData: null, tasks: [] }, () => {
        //         if (this.props.myteams && this.props.myteams !== undefined) {
        //             // this.props.myteams.sort(sortByProperty('name'))
        //             if (this.props.myteams.length === 1) {
        //                 var selectedTeam = this.props.myteams[0];
        //                 // console.log(selectedTeam)
        //                 this.setState({ selectedTeam: selectedTeam })
        //             }

        //         }
        //         if (this.state.projects.length === 1) {
        //             var values = this.state.projects[0]
        //             // console.log(values)
        //             this.setState({
        //                 addName: values.name,
        //                 addId: values.id,
        //                 addData: values
        //             })

        //             Service.get("/bb/api/task/category/?pid=" + values.id, {
        //                 headers: {
        //                     Authorization: "Token " + token,
        //                 },
        //             })
        //                 .then(res => {
        //                     this.setState({ taskcategory: res.data, })
        //                 })
        //                 .catch(error => {
        //                     errorMessage(this.props, error)
        //                 });

        //             // Service.get("/bb/api/tasks/?pid=" + values.id, {
        //             //     headers: {
        //             //         Authorization: "Token " + token,
        //             //     },
        //             // })
        //             //     .then(res => {
        //             //         var data = [];
        //             //         for (let i = 0; i < res.data.length; i++) {
        //             //             const element = res.data[i];
        //             //             if (element.is_active) {
        //             //                 data.push(element)
        //             //             }
        //             //         }
        //             //         this.setState({ tasks: data, })
        //             //     })
        //             //     .catch(error => {
        //             //         errorMessage(this.props, error)
        //             //     });
        //         }
        //     })
        //     setTimeout(() => { this.setState({ openAddShowMenu: true, }) }, 600);
        // };

        const calendarStyle = (date) => {
            var BankLeaveDates = this.state.bankHolidays
            for (let i = 0; i < BankLeaveDates.length; i++) {
                const element = BankLeaveDates[i]
                var date1 = moment(date).format('YYYY-MM-DD')
                if (date1 === element.leave_date) {
                    if (element.id === null) {
                        return {
                            style: {
                                backgroundColor: getDarkMode() === 'dark' ? '#584141' : '#f5d6d6',
                            },
                        }
                    } else {
                        return {
                            style: {
                                backgroundColor: getDarkMode() === 'dark' ? '#694e4e' : '#ffe6e6',
                            },
                        }
                    }
                }
            }
            return null
        }

        const handleRequestClose = () => {
            this.setState({ openRequest: false, })
        };

        const handleLinkPlannerClose = () => {
            this.setState({ openLinkPlanner: false, })
        };

        const onSubTaskChange = (event, values) => {
            this.setState({ selectedSubTask: values, });
        }

        const filterOptions = (options, state) => {
            const data_lowercase = state.inputValue.toLowerCase()
            var search_words = data_lowercase.split(" ");
            search_words = search_words.filter(n => n)
            var filtered_data = []
            if (search_words.length === 0) {
                filtered_data = options
            }

            for (let i = 0; i < options.length; i++) {
                const element = options[i];
                var num_of_passed = false
                for (let j = 0; j < search_words.length; j++) {
                    const el = search_words[j];
                    num_of_passed = element.search_key.includes(el)
                    if (!num_of_passed) {
                        break
                    }
                }
                if (num_of_passed) {
                    filtered_data.push(element)
                }
            }
            return filtered_data;
        };

        const handleTeamLeadsClose = () => {
            this.setState({ teamLeadsDialog: false })
        }

        const handleTimesheetSettingtClose = () => {
            this.setState({ openTimesheetSettings: false })
        }

        const handlePreviewClose = () => {
            this.setState({ openPreview: false, previewData: [], copyLists: [] })
        }

        const handleDateDialogClose = () => {
            this.setState({ openDateDialog: false });
        };

        const handleCopyQtyClose = () => {
            this.setState({ copyWithQty: false, copyQty: 1 });
        };

        const handleQtyChange = (event) => {
            this.setState({ copyQty: event.target.value });
        };

        return (
            <div>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                    <div>
                        {(this.state.isLoading2) ? (
                            <LoopCircleLoading color={getPrimaryColor()} />
                        ) : (null)}
                        <DnDCalendar
                            selectable={true}
                            allDayAccessor
                            onSelectSlot={(access && access.access && access.access.l1a && access.access.l1a.l1a_b) ? (this.handleSelect) : (this.noAccess)}
                            defaultDate={this.calendarDefaultDate().toDate()}
                            defaultView={!localStorage.getItem('default_view') ? Views.WORK_WEEK : localStorage.getItem('default_view')}
                            // views={[Views.MONTH, Views.WORK_WEEK, MyWorkWeek, Views.DAY]}
                            views={{
                                day: true,
                                week: parseInt(this.state.timesheetOption) === 1 ? MyWorkWeek : true,
                                work_week: true,
                                month: true,
                            }}
                            min={minTime}
                            max={maxTime}
                            events={this.state.events}
                            localizer={localizer}
                            onEventDrop={(access && access.access && access.access.l1a && access.access.l1a.l1a_b) ? (this.onEventDrop) : (this.noAccess)}
                            onEventResize={(access && access.access && access.access.l1a && access.access.l1a.l1a_b) ? (this.onEventResize) : (this.noAccess)}
                            onSelectEvent={(events, date) => handleEventInfo(events)}
                            onRangeChange={(range) => this.onRangeChange(range)}
                            onView={(view) => this.onViewChange(view)}
                            resizable
                            getNow={() => null}
                            components={{
                                toolbar: props => <CalenderCustomToolbar
                                    ref={this.toolbarRef}
                                    {...props}
                                    user={userdub}
                                    holiday={this.state.bankHolidays}
                                    copyFromLastWeek={this.copyFromLastWeek}
                                    currentPlan={this.currentPlan}
                                    showTimesheetSetting={this.props.showTimesheetSetting}
                                    openTimesheetSettings={this.openTimesheetSettings} />,
                                month: {
                                    dateHeader: (props) => (
                                        (this.getDateForCalendar(props.date)) ? (
                                            <div>{moment(props.date).format('DD')} ({this.getDateForCalendar(props.date).name + (this.getDateForCalendar(props.date).is_optional ? ' - Optional' : "")})</div>
                                        ) : (
                                            <div>{moment(props.date).format('DD')}</div>
                                        )
                                    )
                                },
                                week: {
                                    header: (props) => (
                                        (this.getDateForCalendar(props.date)) ? (
                                            <div>{moment(props.date).format('DD ddd')} ({this.getDateForCalendar(props.date).name + (this.getDateForCalendar(props.date).is_optional ? ' - Optional' : "")})</div>
                                        ) : (
                                            <div>{moment(props.date).format('DD ddd')}</div>
                                        )
                                    )
                                },
                                work_week: {
                                    header: (props) => (
                                        (this.getDateForCalendar(props.date)) ? (
                                            <div>{moment(props.date).format('DD ddd')} ({this.getDateForCalendar(props.date).name + (this.getDateForCalendar(props.date).is_optional ? ' - Optional' : "")})</div>
                                        ) : (
                                            <div>{moment(props.date).format('DD ddd')}</div>
                                        )
                                    )
                                },
                            }}
                            // style={{ margin: '1vh', height: "92vh" }}
                            style={{
                                // backgroundColor: "#000000",
                                // borderRadius: 0,
                                margin: '.5vh', height: "93vh",
                                // border: "none",
                                //opacity: opacity,
                                color: getDarkMode() === "dark" ? "#d5d1ce" : null,

                                //display: 'block'
                            }}
                            dayPropGetter={calendarStyle}
                            eventPropGetter={event => (event.is_approved === 1) ? (
                                {
                                    style: {
                                        backgroundColor: '#00aa39',
                                    },
                                }
                            ) : (event.is_submitted === 1) ? (
                                {
                                    style: {
                                        backgroundColor: '#f09e00',
                                    },
                                }
                            ) : (event.is_approved === 2) ? (
                                {
                                    style: {
                                        backgroundColor: '#585858',
                                    },
                                }
                            ) : (event.allDay) ? (
                                {
                                    style: {
                                        backgroundColor: '#b800ff',
                                    },
                                }
                            ) : (null)}
                        />

                        <Dialog open={this.state.openAdd} onClose={handleClose} fullWidth aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">Add Task</DialogTitle>
                            <DialogContent>
                                <Box>
                                    <Autocomplete
                                        size="small"
                                        id="country-select-demo"
                                        style={{ margin: '10px' }}
                                        options={this.state.allProj}
                                        autoHighlight
                                        value={this.state.selectedAllProj}
                                        onChange={this.onAllProjChange}
                                        filterOptions={filterOptions}
                                        getOptionLabel={(option) => option.task_name}
                                        renderOption={(option) => (
                                            <Box display={'flex'} flexDirection={'column'} width={'100%'}>
                                                <Box display={'flex'} flexDirection={'row'} alignItems={'center'} width={'100%'}>
                                                    {!option.is_overhead ? <Box flexGrow={1} fontSize={'14px'}> <b>Project: </b>{option.project_name}</Box> : <Box flexGrow={1}> <b>Category: </b>{option.task_category_name}</Box>}
                                                    <Box display='flex' flexDirection="row" justifyContent="center" fontSize='10px' borderRadius='3px' style={{ backgroundColor: option.is_overhead ? "#EEBE86" : option.inc_evp === 0 ? '#EEBE86' : '#007681', color: (option?.inc_evp === 1) ? 'white' : 'black', padding: '3px' }}>
                                                        {option.is_overhead ? "Non-Billable" : option.inc_evp === 0 ? 'Non Billable' : 'Billable'}
                                                    </Box>
                                                </Box>
                                                {!option.is_overhead ? <Box fontSize={'14px'}> <b>DS: </b>{option.ds_name}</Box> : null}
                                                {!option.is_overhead ? <Box fontSize={'14px'}> <b>WBS: </b>{option.wbs_name}</Box> : null}
                                                <Box fontSize={'14px'}> <b>Task: </b>{option.task_name}</Box>
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Search For Task"
                                                variant="outlined"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                    />
                                </Box>
                                <Box margin={'10px'} display={'flex'} flexDirection={'row'} justifyContent={'center'} width={'100%'} alignItems={'center'}>
                                    <Divider variant="middle" width={'40%'} />
                                    <Chip color="primary" label="OR" />
                                    <Divider variant="middle" width={'40%'} />
                                </Box>

                                <ToggleButtonGroup
                                    color="primary"
                                    size='small'
                                    value={this.state.categorySwitch}
                                    exclusive
                                    onChange={handleCategorySwitchChange}
                                    aria-label="Platform"
                                    style={{ marginLeft: '10px' }}
                                >
                                    <ToggleButton value={1}>Billable Projects</ToggleButton>
                                    <ToggleButton value={0} color='secondary'>Non Billable Projects</ToggleButton>
                                    <ToggleButton value={2} color='secondary'>Non Billable</ToggleButton>
                                </ToggleButtonGroup>
                                {this.state.categoryLoading ?
                                    <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                                        <CircularProgress size={20} color='primary' />
                                    </Box> :
                                    <>

                                        {(!this.state.tenderSwitch && this.state.openAddShowMenu) ? (
                                            <div>
                                                <Autocomplete
                                                    size="small"
                                                    id="combo-box-demot1"
                                                    value={this.state.selectedProjectData}
                                                    options={this.state.tenderMainProj}
                                                    getOptionLabel={(option) => (option.tender_name) ? (option.tender_name) : (option.name)}
                                                    style={{ margin: '10px' }}
                                                    onChange={this.onTDsproj}
                                                    renderInput={(params) => <TextField {...params} label="Select Project" variant="outlined" />}
                                                />
                                                <Autocomplete
                                                    size="small"
                                                    id="combo-box-demot1"
                                                    options={this.state.tenderShowDs}
                                                    value={this.state.selectedDSData}
                                                    getOptionLabel={(option) => (option.tender_name) ? (option.tender_name) : (option.name)}
                                                    style={{ margin: '10px' }}
                                                    onChange={this.onTproj}
                                                    getOptionSelected={(option, { multiple, value }) => {
                                                        if (!multiple) {
                                                            /*
                                                             * PROPOSAL for single selection, be able to provide own logic.
                                                             */
                                                            return (option.id === this.state.selectedDSData.id);
                                                        }

                                                        return false;
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="Select Deliverable Schedule" variant="outlined" />}
                                                />
                                                <Autocomplete
                                                    size="small"
                                                    id="combo-box-demot2"
                                                    options={this.state.tenderShowWbs}
                                                    value={this.state.selectedWBSData}
                                                    getOptionLabel={(option) => option.name}
                                                    style={{ margin: '10px' }}
                                                    onChange={this.onTwbs}
                                                    getOptionSelected={(option, { multiple, value }) => {
                                                        if (!multiple) {
                                                            /*
                                                             * PROPOSAL for single selection, be able to provide own logic.
                                                             */
                                                            return (option.id === this.state.selectedWBSData.id);
                                                        }

                                                        return false;
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="Select WBS" variant="outlined" />}
                                                />
                                                <Autocomplete
                                                    size="small"
                                                    id="combo-box-demot3"
                                                    options={this.state.tenderShowTasks}
                                                    value={this.state.selectedTaskData}
                                                    getOptionLabel={(option) => (option.task_name)}
                                                    style={{ margin: '10px', }}
                                                    onChange={this.onTtask}
                                                    getOptionSelected={(option, { multiple, value }) => {
                                                        if (!multiple) {
                                                            /*
                                                             * PROPOSAL for single selection, be able to provide own logic.
                                                             */
                                                            return (option.id === this.state.selectedTaskData.id);
                                                        }

                                                        return false;
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="Select Task" variant="outlined" />}
                                                />
                                                {(this.state.tenderShowTasks !== "" && this.state.tenderShowTasks.length !== 0 && this.state.selectedTaskData && this.state.selectedTaskData !== "" && this.state.hoursUtilizedData2) ? (
                                                    <Typography component="div" style={{ margin: '10px' }}>
                                                        <Box display={'flex'} flexDirection={'row'}>
                                                            <Box5 sx={{ flexGrow: 1, color: (this.state.hoursUtilizedData2.event_hours) >= this.state.hoursUtilizedData2.allowed_hours ? "error.main" : (this.state.hoursUtilizedData2.event_hours + 20) >= this.state.hoursUtilizedData2.allowed_hours ? "warning.main" : null }} display="flex" flexDirection="row" fontSize={14}>
                                                                <Box fontWeight="fontWeightBold">{this.state.hoursUtilizedData2.event_hours}</Box>&nbsp;of&nbsp;
                                                                <Box fontWeight="fontWeightBold">{this.state.hoursUtilizedData2.allowed_hours}</Box>&nbsp;hours is utilized for this task - {this.state.selectedTaskData.inc_evp ? "Billable" : "Non-Billable"}&nbsp;
                                                            </Box5>
                                                            <Box display={'flex'} flexDirection={'column'}>
                                                                <Link
                                                                    component="button"
                                                                    variant="body2"
                                                                    onClick={() => this.setState({ openRequest: true })}>
                                                                    (Request Hours)
                                                                </Link>
                                                                <Link
                                                                    component="button"
                                                                    variant="body2"
                                                                    onClick={() => this.setState({ openDateDialog: true })}>
                                                                    (Date Change Request)
                                                                </Link>
                                                            </Box>
                                                        </Box>

                                                    </Typography>
                                                ) : (null)}

                                            </div>

                                        ) : (this.state.openAddShowMenu) ? (
                                            <div>

                                                <Autocomplete
                                                    size="small"
                                                    id="combo-box-demo23"
                                                    options={this.state.taskcategory}
                                                    value={this.state.selectedOverheadCategory}
                                                    getOptionLabel={(option) => option.name}
                                                    style={{ margin: '10px' }}
                                                    onChange={this.onTaskCategoryChange}
                                                    renderInput={(params) => <TextField {...params} label="Select Category" variant="outlined" />}
                                                />
                                                <Autocomplete
                                                    size="small"
                                                    id="combo-box-demo2"
                                                    value={this.state.selectedOverheadTask}
                                                    options={this.state.tasks}
                                                    getOptionLabel={(option) => option.id + " - " + option.name}
                                                    style={{ margin: '10px' }}
                                                    onChange={this.onTasksChange}
                                                    getOptionSelected={(option, { multiple, value }) => {
                                                        if (!multiple) {
                                                            /*
                                                             * PROPOSAL for single selection, be able to provide own logic.
                                                             */
                                                            return (option.id === this.state.addTaskId);
                                                        }

                                                        return false;
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="Select Task" variant="outlined" />}
                                                />
                                                {!this.state.selectedOverheadTask?.description ? null :
                                                    <Alert severity="info" style={{ margin: '10px' }}>{this.state.selectedOverheadTask.description}</Alert>
                                                }
                                                {(this.state.addTaskId && this.state.hoursUtilizedData2) ? (
                                                    <Typography component="div" style={{ margin: '10px' }}>
                                                        <Box5 sx={{ color: (this.state.hoursUtilizedData2.event_hours) >= this.state.hoursUtilizedData2.allowed_hours ? "error.main" : (this.state.hoursUtilizedData2.event_hours + 20) >= this.state.hoursUtilizedData2.allowed_hours ? "warning.main" : null }} display="flex" flexDirection="row" fontSize={14}>
                                                            <Box fontWeight="fontWeightBold">{this.state.hoursUtilizedData2.event_hours}</Box>&nbsp;of&nbsp;<Box fontWeight="fontWeightBold">{this.state.hoursUtilizedData2.allowed_hours}</Box>&nbsp;hours is utilized for this task - {"Non-Billable"}&nbsp;
                                                            <Link
                                                                component="button"
                                                                variant="body2"
                                                                onClick={() => this.findLead()}>
                                                                (Contact Team Lead)
                                                            </Link>
                                                        </Box5>
                                                    </Typography>
                                                ) : (null)}

                                            </div>

                                        ) : (<LinearProgress />)}

                                        {(!this.props.value) ? (
                                            <Autocomplete
                                                size="small"
                                                id="combo-box-demot4"
                                                disabled={this.state.teamDiasabled}
                                                options={this.props.myteams}
                                                value={this.state.selectedTeam}
                                                getOptionLabel={(option) => option.name}
                                                style={{ margin: '10px' }}
                                                onChange={this.onTeamSelect}
                                                getOptionSelected={(option, { multiple, value }) => {
                                                    if (!multiple) {
                                                        /*
                                                         * PROPOSAL for single selection, be able to provide own logic.
                                                         */
                                                        return (option.id === this.state.selectedTeam.id);
                                                    }

                                                    return false;
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Select Team" variant="outlined" />}
                                            />
                                        ) : (null)}
                                        {/* <TextField required id="outlined-basic2" label="Task Detail" style={{ margin: '5px' }} fullWidth multiline variant="outlined" value={this.state.addWork} onChange={e => handleWork(e)} /> */}

                                        <Grid container style={{ marginLeft: '10px' }}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    margin="normal"
                                                    id="date-picker-dialog"
                                                    label="From Date"
                                                    format="dd/MM/yyyy"
                                                    minDate={this.state.taskAddMinDate}
                                                    maxDate={this.state.taskAddMaxDate}
                                                    value={this.state.selectDate1}
                                                    onChange={handleDate3Change}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                />
                                            </MuiPickersUtilsProvider>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={this.state.checkedB}
                                                        onChange={handleMultiCheck}
                                                        name="checkedB"
                                                        color="primary"
                                                    />
                                                }
                                                label="Add For Multiple Days"
                                            />
                                            {/* <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.checkedSat}
                                                    onChange={handleSatCheck}
                                                    name="checkedB"
                                                    color="primary"
                                                />
                                            }
                                            label="Include Saturdays"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.checkedSun}
                                                    onChange={handleSunCheck}
                                                    name="checkedB"
                                                    color="primary"
                                                />
                                            }
                                            label="Include Sundays"
                                        /> */}
                                        </Grid>
                                        {
                                            (this.state.checkedB) ? (
                                                <Grid container style={{ marginLeft: '10px' }}>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <KeyboardDatePicker
                                                            margin="normal"
                                                            id="date-picker-dialog2"
                                                            label="To Date"
                                                            format="dd/MM/yyyy"
                                                            minDate={this.state.taskAddMinDate}
                                                            maxDate={this.state.taskAddMaxDate}
                                                            value={this.state.selectDate2}
                                                            onChange={handleDate4Change}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change date',
                                                            }}
                                                        />
                                                    </MuiPickersUtilsProvider>

                                                </Grid>
                                            ) : (
                                                null
                                            )
                                        }
                                    </>}
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="primary">
                                    Cancel
                                </Button>
                                <Button disabled={this.state.addTaskDisabled} onClick={e => this.handleAddTask()} color="primary">
                                    Add Task
                                </Button>
                            </DialogActions>
                        </Dialog>

                        {/* split dialog */}
                        <Dialog open={this.state.openSplit} maxWidth={'md'} fullWidth onClose={handleSpltClose} aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">Add Task</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    All your tasks are viewable by your team lead and managers
                                </DialogContentText>
                                {(this.state.info.is_tender === 1) ? (
                                    <div>
                                        <Typography component="div" ><Box display="flex" flexDirection="row"><Box fontWeight="fontWeightBold">Project : </Box>&nbsp;{this.state.info.ttender_name}</Box></Typography>
                                        <Typography component="div"><Box display="flex" flexDirection="row"><Box fontWeight="fontWeightBold">EM : </Box>&nbsp;{this.state.info.pdm_name}</Box></Typography>
                                        <Typography component="div"><Box display="flex" flexDirection="row"><Box fontWeight="fontWeightBold">Deliverables Schedule : </Box>&nbsp;{this.state.info.tproj_name}</Box></Typography>
                                        <Typography component="div"><Box display="flex" flexDirection="row"><Box fontWeight="fontWeightBold">WBS : </Box>&nbsp;{this.state.info.twbsname}</Box></Typography>
                                        <Typography component="div"><Box display="flex" flexDirection="row"><Box fontWeight="fontWeightBold">Task : </Box>&nbsp;{this.state.info.ttdecs ? this.state.info.ttdecs : this.state.info.ttname}</Box></Typography>
                                        <Typography component="div"><Box display="flex" flexDirection="row"><Box fontWeight="fontWeightBold">Task Status : </Box>&nbsp;{this.state.taskStatus[this.state.info.ttstatus]}</Box></Typography>

                                        {(!this.props.value) ? (<Typography component="div"><Box display="flex" flexDirection="row"><Box fontWeight="fontWeightBold">Team : </Box>&nbsp;{this.state.info.gname}</Box></Typography>) : (null)}
                                        {/* <Typography component="div"><Box fontWeight="fontWeightBold">Task Detail : </Box>{this.state.info.work_detail}</Typography> */}

                                    </div>

                                ) : (
                                    <div>
                                        <Typography component="div"><Box display="flex" flexDirection="row"><Box fontWeight="fontWeightBold">Project : </Box>&nbsp;{this.state.info.proj_name}</Box></Typography>
                                        <Typography component="div"><Box display="flex" flexDirection="row"><Box fontWeight="fontWeightBold">Task : </Box>&nbsp;{this.state.info.tname}</Box></Typography>
                                        {(!this.props.value) ? (<Typography component="div"><Box display="flex" flexDirection="row"><Box fontWeight="fontWeightBold">Team : </Box>&nbsp;{this.state.info.gname}</Box></Typography>) : (null)}
                                        {/* <Typography component="div"><Box fontWeight="fontWeightBold">Task Detail : </Box>{this.state.info.work_detail}</Typography> */}
                                        <Typography component="div"><Box display="flex" flexDirection="row"><Box fontWeight="fontWeightBold">Est Hours : </Box>&nbsp;{this.state.info.estimated_hours}</Box></Typography>

                                    </div>

                                )}

                                <Grid container justify="space-around">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            margin="normal"
                                            id="date-picker-dialog"
                                            label="From Date"
                                            format="dd/MM/yyyy"
                                            minDate={this.state.taskAddMinDate}
                                            maxDate={this.state.taskAddMaxDate}
                                            value={this.state.selectDate1}
                                            onChange={handleDate3Change}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.checkedB}
                                                onChange={handleMultiCheck}
                                                name="checkedB"
                                                color="primary"
                                            />
                                        }
                                        label="Add For Multiple Days"
                                    />
                                    {/* <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.checkedSat}
                                                    onChange={handleSatCheck}
                                                    name="checkedB"
                                                    color="primary"
                                                />
                                            }
                                            label="Include Saturdays"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.checkedSun}
                                                    onChange={handleSunCheck}
                                                    name="checkedB"
                                                    color="primary"
                                                />
                                            }
                                            label="Include Sundays"
                                        /> */}
                                </Grid>
                                {
                                    (this.state.checkedB) ? (
                                        <Grid container justify="space-around">
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    margin="normal"
                                                    id="date-picker-dialog2"
                                                    label="To Date"
                                                    format="dd/MM/yyyy"
                                                    minDate={this.state.taskAddMinDate}
                                                    maxDate={this.state.taskAddMaxDate}
                                                    value={this.state.selectDate2}
                                                    onChange={handleDate4Change}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                />
                                            </MuiPickersUtilsProvider>

                                        </Grid>
                                    ) : (
                                        null
                                    )
                                }


                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleSpltClose} color="primary">
                                    Cancel
                                </Button>
                                <Button onClick={e => this.handleSplitAddTask()} color="primary">
                                    Add Task
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog
                            open={this.state.openInfo}
                            onClose={handleInfoClose}
                            fullWidth
                            maxWidth={'md'}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="customized-title" onClose={handleInfoClose}>
                                Task Info
                            </DialogTitle>

                            <DialogContent>
                                {(this.state.info.is_tender === 1) ? (
                                    <div>
                                        <Typography component="div" style={{ marginBottom: '7px' }}><Box display="flex" flexDirection="row"><Box fontWeight="fontWeightBold">Project : </Box>&nbsp;{this.state.info.ttender_name}</Box></Typography>
                                        <Typography component="div" style={{ marginBottom: '7px' }}><Box display="flex" flexDirection="row"><Box fontWeight="fontWeightBold">EM : </Box>&nbsp;{this.state.info.pdm_name}</Box></Typography>
                                        <Typography component="div" style={{ marginBottom: '7px' }}><Box display="flex" flexDirection="row"><Box fontWeight="fontWeightBold">Deliverables Schedule : </Box>&nbsp;{this.state.info.tproj_name}</Box></Typography>
                                        <Typography component="div" style={{ marginTop: '7px', marginBottom: '7px' }}><Box display="flex" flexDirection="row"><Box fontWeight="fontWeightBold">WBS : </Box>&nbsp;{this.state.info.twbsname}</Box></Typography>
                                        <Typography component="div" style={{ marginTop: '7px', marginBottom: '7px' }}><Box display="flex" flexDirection="row"><Box fontWeight="fontWeightBold">Task : </Box>&nbsp;{this.state.info.ttdecs ? this.state.info.ttdecs : this.state.info.ttname}</Box></Typography>
                                        <Typography component="div" style={{ marginTop: '7px', marginBottom: '7px' }}><Box display="flex" flexDirection="row"><Box fontWeight="fontWeightBold">Task Status : </Box>&nbsp;{this.state.taskStatus[this.state.info.ttstatus] + " "}&nbsp;
                                            {(this.state.info.ttstatus <= 90) ? (
                                                <Link
                                                    component="button"
                                                    variant="body2"
                                                    color="primary"
                                                    onClick={() => {
                                                        this.fetchPerTaskData();
                                                        this.setState({
                                                            perTaskLoading: true,
                                                            taskPercent: 0,
                                                            openTaskUpdate: true,
                                                            perTaskData: null
                                                        })
                                                    }}
                                                >
                                                    (Update Status)
                                                </Link>
                                            ) : (null)}
                                        </Box>
                                        </Typography>
                                    </div>
                                ) : (
                                    <div>
                                        <Typography component="div" style={{ marginBottom: '7px' }}><Box display="flex" flexDirection="row"><Box fontWeight="fontWeightBold">Project : </Box>&nbsp;{this.state.info.proj_name}</Box></Typography>
                                        <Typography component="div" style={{ marginBottom: '7px' }}><Box display="flex" flexDirection="row"><Box fontWeight="fontWeightBold">Category : </Box>&nbsp;{this.state.info.tcname}</Box></Typography>
                                        <Typography component="div" style={{ marginTop: '7px', marginBottom: '7px' }}><Box display="flex" flexDirection="row"><Box fontWeight="fontWeightBold">Task : </Box>&nbsp;{this.state.info.tname}</Box></Typography>
                                    </div>
                                )}
                                {(!this.props.value) ? (<Typography component="div"><Box display="flex" flexDirection="row"><Box fontWeight="fontWeightBold">Team : </Box>&nbsp;{this.state.info.gname}</Box></Typography>) : (null)}
                                {/* <Typography component="div" style={{ marginTop: '7px', marginBottom: '7px' }}><Box fontWeight="fontWeightBold">Task Detail : </Box>{this.state.info.work_detail}</Typography> */}
                                {(this.state.info.is_tender === 1) ? (
                                    <div>
                                        <Typography component="div" style={{ marginTop: '7px', marginBottom: '7px' }}><Box display="flex" flexDirection="row"><Box fontWeight="fontWeightBold">Start Date : </Box>&nbsp;{moment(this.state.info.strstart).format('DD-MM-YYYY')}</Box></Typography>
                                        <Typography component="div" style={{ marginTop: '7px', marginBottom: '7px' }}><Box display="flex" flexDirection="row"><Box fontWeight="fontWeightBold">End Date : </Box>&nbsp;{moment(this.state.info.strend).format('DD-MM-YYYY')}</Box></Typography>
                                    </div>
                                ) : (null)}

                                {(this.state.info.is_tender === 1) ? (
                                    <Grid alignItems="center" container spacing={3}>

                                        {/* <Grid item xs={(this.state.info.is_tender === 1) ? (6) : (12)}>
                                                {((this.state.info.is_locked !== 1) || this.getTeamRole()) ? (
                                                    ((this.state.noComment && !this.getTeamRole()) || (access && access.access && access.access.l1a && !access.access.l1a.l1a_b)) ? (
                                                        <Typography component="div" style={{ marginTop: '7px', marginBottom: '7px' }}><Box display="flex" flexDirection="row"><Box fontWeight="fontWeightBold">Hours Expended : </Box>&nbsp;{this.state.actualHours}</Box></Typography>
                                                    ) : (
                                                            <TextField
                                                                id="actual_hours"
                                                                label="Hours Expended"
                                                                type="number"
                                                                fullWidth
                                                                variant="outlined"
                                                                value={this.state.actualHours}
                                                                onChange={handleActualHours} />
                                                        )

                                                ) : (
                                                        <Typography component="div" style={{ marginTop: '7px', marginBottom: '7px' }}><Box display="flex" flexDirection="row"><Box fontWeight="fontWeightBold">Hours Expended : </Box>&nbsp;{this.state.actualHours}</Box></Typography>
                                                    )}

                                            </Grid> */}

                                        <Grid item xs={12}>
                                            {(this.state.hoursUtilizedData && this.state.info.is_tender === 1) ? (
                                                <Typography component="div">
                                                    <Box display="flex" flexDirection="row"><Box fontWeight="fontWeightBold">{this.state.hoursUtilizedData.event_hours}</Box>&nbsp;of&nbsp;<Box fontWeight="fontWeightBold">{this.state.hoursUtilizedData.allowed_hours}</Box>&nbsp;hours is utilized for this task</Box>
                                                </Typography>
                                            ) : (this.state.info.is_tender === 1) ? (<CircularProgress style={{ width: '20px', height: '20px' }} />) : (null)}
                                        </Grid>

                                    </Grid>
                                ) : (null)}

                                {(this.state.info.is_tender === 0 && this.state.hoursUtilizedData) ?
                                    <Typography component="div" style={{ marginTop: '7px', marginBottom: '7px' }}>
                                        <Box5 sx={{ color: (this.state.hoursUtilizedData.event_hours) >= this.state.hoursUtilizedData.allowed_hours ? "error.main" : (this.state.hoursUtilizedData.event_hours + 20) >= this.state.hoursUtilizedData.allowed_hours ? "warning.main" : null }} display="flex" flexDirection="row" fontSize={14}>
                                            <Box fontWeight="fontWeightBold">{this.state.hoursUtilizedData.event_hours}</Box>&nbsp;of&nbsp;
                                            <Box fontWeight="fontWeightBold">{this.state.hoursUtilizedData.allowed_hours}</Box>&nbsp;hours is utilized for this task - Non-Billable&nbsp;
                                        </Box5>
                                    </Typography> : null}

                                {(user.office_loc === 1) && !([66, 67, 68, 78].includes(this.state.info.tid)) ?
                                    <FormGroup row style={{ marginRight: '15px' }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.checkedWFH}
                                                    onChange={handleWFHChange}
                                                    name="checkedB"
                                                    color="primary"
                                                    disabled={[1].includes(this.state.info.is_approved)}
                                                />
                                            }
                                            label="Work From Home"
                                        />
                                    </FormGroup> : null}

                                {this.state.info.ttid ? (
                                    !this.state.info.tender_subtask ?
                                        <Link component="button" variant="body2" underline='always' color="primary" onClick={() => this.openSubtasks()}>
                                            Link to Planner
                                        </Link>
                                        :
                                        <Box display={'flex'} flexDirection='row' alignItems={'center'}>
                                            <Typography>{this.state.info.subtask_name}</Typography>
                                            <Divider orientation="vertical" flexItem style={{ marginLeft: '10px', marginRight: '5px' }} />
                                            <Button color='primary' onClick={() => this.removeSubtask()}>Remove</Button>
                                        </Box>
                                ) : null}

                                {((this.state.info.is_locked !== 1) || this.getTeamRole()) ? (
                                    ((this.state.noComment && !this.getTeamRole()) || (access && access.access && access.access.l1a && !access.access.l1a.l1a_b)) ? (
                                        <Typography component="div" style={{ marginTop: '7px', marginBottom: '7px' }}>
                                            <Box display="flex" flexDirection={'row'} flexWrap={'nowrap'}>
                                                <Box fontWeight="fontWeightBold">Comment: </Box>
                                                <Box sx={{ wordWrap: 'break-word', width: '90%' }}>
                                                    {this.state.info.comments}
                                                </Box>
                                            </Box>
                                        </Typography>
                                    ) : (
                                        <TextField
                                            id="comment"
                                            label="Comment"
                                            multiline
                                            fullWidth
                                            variant="outlined"
                                            style={{ marginTop: '15px', marginBottom: '15px' }}
                                            value={this.state.addComment}
                                            onChange={handleComment} />
                                    )

                                ) : (
                                    <Typography component="div" style={{ marginTop: '7px', marginBottom: '7px' }}>
                                        <Box display="flex" flexDirection={'row'} flexWrap={'nowrap'}>
                                            <Box fontWeight="fontWeightBold">Comment: </Box>
                                            <Box sx={{ wordWrap: 'break-word', width: '90%' }}>
                                                {this.state.info.comments}
                                            </Box>
                                        </Box>
                                    </Typography>
                                )}

                            </DialogContent>
                            <DialogActions>
                                {(access && access.access && access.access.l1a && access.access.l1a.l1a_b && (((this.state.info.is_submitted === 0 || this.state.info.is_approved === 2) && !(this.state.info.start <= lastweeklastday)) || this.getTeamRole())) ? (
                                    <ThemeProvider theme={bbColorTheme}>
                                        <Button disabled={this.state.delBtnDisabled} onClick={() => this.deleteTask(this.state.info.id)} color="secondary">
                                            Delete
                                        </Button>
                                    </ThemeProvider>
                                ) : (
                                    null
                                )}
                                {this.state.hoursUtilizedData && this.state.info.tcid !== 1 ?
                                    <Button onClick={() => this.setState({ copyWithQty: true })} color="primary">
                                        Copy
                                    </Button> : null}
                                {(access && access.access && access.access.l1a && access.access.l1a.l1a_b) ? (
                                    <Button onClick={() => this.setState({ openSplit: true, openInfo: false }, () => { selected_event = null })} color="primary">
                                        Split Task
                                    </Button>
                                ) : (
                                    null
                                )}

                                {((this.state.noComment && !this.getTeamRole()) || (access && access.access && access.access.l1a && !access.access.l1a.l1a_b)) ? (
                                    <Button onClick={handleInfoClose} color="primary">
                                        Close
                                    </Button>
                                ) : (
                                    <Button disabled={this.state.saveBtnDisabled} onClick={handleInfoSave} color="primary">
                                        Save & Close
                                    </Button>
                                )}
                            </DialogActions>
                        </Dialog>

                        <Dialog
                            open={this.state.openTaskUpdate}
                            onClose={handleTaskClose}
                            fullWidth
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <DialogTitle>{this.state.info.ttname}</DialogTitle>
                            <DialogContent>
                                {(this.state.perTaskLoading) ? (null) : (
                                    <FormControl fullWidth >
                                        <InputLabel fullWidth id="demo-simple-select-label">Select Percentage Complete</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            fullWidth
                                            value={this.state.taskPercent}
                                            onChange={handlePercChange}
                                        >
                                            <MenuItem value={0}>0% - Not started.</MenuItem>
                                            <MenuItem value={5}>5% - Preparation of documents/drawings.</MenuItem>
                                            <MenuItem value={10}>10% - Preparation of documents/drawings.</MenuItem>
                                            <MenuItem value={20}>20% - Preparation of documents/drawings.</MenuItem>
                                            <MenuItem value={30}>30% - Preparation of documents/drawings.</MenuItem>
                                            <MenuItem value={40}>40% - Preparation of documents/drawings.</MenuItem>
                                            <MenuItem value={50}>50% - Preparation of documents/drawings.</MenuItem>
                                            <MenuItem value={60}>60% - Checked within Department.</MenuItem>
                                            <MenuItem value={65}>65% - Approved within Department.</MenuItem>
                                            <MenuItem value={70}>70% - Site comments received, reviewed.</MenuItem>
                                            <MenuItem value={80}>80% - Multi-disciplinary Reviewed & Commented.</MenuItem>
                                            <MenuItem value={90}>90% - Design verified.</MenuItem>
                                        </Select>
                                    </FormControl>
                                )}

                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleTaskClose} color="primary">
                                    Close
                                </Button>
                                <Button onClick={() => this.handleTaskComp()} disabled={!this.state.perTaskData} color="primary">
                                    Submit
                                </Button>

                            </DialogActions>
                        </Dialog>

                        <Dialog onClose={handleRequestClose} fullWidth={true} aria-labelledby="customized-dialog-title" open={this.state.openRequest}>
                            <DialogTitle id="customized-dialog-title1" onClose={handleRequestClose}>
                                {this.state.selectedTaskData ? (this.state.selectedTaskData.decs ? this.state.selectedTaskData.decs : this.state.selectedTaskData.name) : null}
                            </DialogTitle>
                            <DialogContent dividers>
                                <UserHoursRequest value={this.state.selectedTaskData} uid={userdub.uid} tender={this.state.selectedProjectData ? this.state.selectedProjectData.id : null} onCloseCommand={handleRequestClose} />
                            </DialogContent>
                        </Dialog>

                        <Dialog
                            open={this.state.openLinkPlanner}
                            onClose={handleLinkPlannerClose}
                            maxWidth={'sm'}
                            fullWidth
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description">
                            {/* <DialogTitle id="customized-dialog-title1" onClose={handleLinkPlannerClose}>
                                {this.state.selectedTaskData ? (this.state.selectedTaskData.decs ? this.state.selectedTaskData.decs : this.state.selectedTaskData.name) : null}
                            </DialogTitle> */}
                            {this.state.plannerLoader ? <LoopCircleLoading color={getPrimaryColor()} /> :
                                <>
                                    <DialogContent>
                                        <Autocomplete
                                            id="selected_subtask"
                                            options={this.state.subTasksData}
                                            value={(this.state.selectedSubTask === "") ? (null) : (this.state.selectectedSubTask)}
                                            getOptionLabel={(option) => option.name + " (" + getSubTaskStatus()[option.subtask_status_id] + ")"}
                                            style={{ width: '100%' }}
                                            onChange={onSubTaskChange}
                                            renderInput={(params) => <TextField {...params} label="Select Subtask" variant="outlined" />}
                                        />
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleLinkPlannerClose} color="primary">
                                            Close
                                        </Button>
                                        <Button color="primary" onClick={() => this.linkPlanner()}>
                                            Link
                                        </Button>
                                    </DialogActions>
                                </>
                            }
                        </Dialog>

                        <Dialog open={this.state.teamLeadsDialog} onClose={handleTeamLeadsClose} maxWidth={'sm'} aria-labelledby="customized-dialog-title" aria-describedby="alert-dialog-slide-description">
                            <DialogTitle>
                                Select Team Lead to Contact
                            </DialogTitle>
                            <DialogContent>
                                <List>
                                    {this.state.teamLeads.sort(sortByProperty("name")).map((text, index) => (
                                        <ListItem button key={index} onClick={() => this.openLeadChat(text)}>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    {text.first_name.charAt(0) + '' + text.last_name.charAt(0)}
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary={text.last_name + ", " + text.first_name} secondary={text.team_name.join(', ')} />
                                        </ListItem>
                                    ))}
                                </List>
                            </DialogContent>
                        </Dialog>

                        <BootstrapDialogV4 fullWidth maxWidth={'xs'} open={this.state.openTimesheetSettings} onClose={handleTimesheetSettingtClose} aria-labelledby="customized-dialog-title" aria-describedby="alert-dialog-slide-description">
                            <BootstrapDialogTitleV4 onClose={handleTimesheetSettingtClose}>
                                TimeSheet Setting Option
                            </BootstrapDialogTitleV4>
                            <DialogContent>
                                <TimesheetSettings settingsKey={'calender_view_option'} getTimesheetOption={this.getTimesheetOption} />
                            </DialogContent>
                        </BootstrapDialogV4>

                        <Dialog maxWidth={'xl'} open={this.state.openPreview} onClose={handlePreviewClose} fullWidth={true} aria-labelledby="customized-dialog-title" aria-describedby="alert-dialog-slide-description">
                            <DialogTitle id="customized-dialog-title" onClose={handlePreviewClose}>
                                Timesheet Preview
                            </DialogTitle>
                            <DialogContent >
                                <Alert severity="info">
                                    Only Project related time items will be copied depending upon the remaining hours available for the respective tasks.
                                </Alert>
                                <MaterialTable
                                    title={
                                        <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                            <Box>
                                                <b>{(copyStart && copyEnd) ? (moment(copyStart)?.format('DD-MM-YYYY') + " - " + moment(copyEnd)?.format('DD-MM-YYYY')) : null}</b>
                                            </Box>
                                            <Box marginLeft={'10px'}>
                                                <Button
                                                    size='small'
                                                    variant='contained'
                                                    color='primary'
                                                    onClick={() => this.handleBack()}
                                                    startIcon={<ArrowBackIosIcon fontSize='small' />}>
                                                    Prev week
                                                </Button>
                                            </Box>
                                            <Box marginLeft={'10px'}>
                                                <Button
                                                    size='small'
                                                    variant='contained'
                                                    color='primary'
                                                    onClick={() => this.handleFront()}
                                                    endIcon={<ArrowForwardIosIcon fontSize='small' />}>
                                                    next week
                                                </Button>
                                            </Box>
                                        </Box>

                                    }
                                    columns={[
                                        { title: 'Project', field: 'project_name' },
                                        { title: 'DS', field: 'ds_name' },
                                        { title: 'WBS', field: 'wbs_name' },
                                        {
                                            title: 'Task', field: 'name',
                                            render: rowData => !rowData.task_description ? rowData.task_name : rowData.task_description
                                        },
                                        { title: 'Date', field: 'date' },
                                        {
                                            title: "Last week hours", field: 'last_week_hours', width: '5%',
                                            headerStyle: { textAlign: 'center', width: 5, }, cellStyle: { textAlign: 'center', width: 5, },
                                        },
                                        {
                                            title: "Copyable hours", field: 'copy_hours', width: '5%',
                                            headerStyle: { textAlign: 'center', width: 5, }, cellStyle: { textAlign: 'center', width: 5, },
                                            render: rowData => !rowData.reason ? rowData.copy_hours : rowData.copy_hours + " (" + rowData.reason + ")"
                                        },
                                        {
                                            title: "Actions", field: 'task_percentage', width: '5%',
                                            hidden: (user.id !== userdub.uid),
                                            headerStyle: { textAlign: 'center', width: 5 }, cellStyle: { textAlign: 'center', width: 5 },
                                            render: rowData => <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} >
                                                <Tooltip title={'Hours Request'}>
                                                    <IconButton disabled={rowData.task_percentage >= 90}>
                                                        <PostAddIcon onClick={() => {
                                                            var selectedTask = {
                                                                id: rowData.ttid,
                                                                name: rowData.task_name,
                                                                decs: rowData.task_description,
                                                            }
                                                            this.setState({
                                                                openRequest: true,
                                                                selectedTaskData: selectedTask
                                                            })
                                                        }} />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title={'Date Change Request'}>
                                                    <IconButton style={{ marginLeft: '5px' }}>
                                                        <DateRangeIcon onClick={() => {
                                                            var selectedTask = {
                                                                id: rowData.ttid,
                                                                name: rowData.task_name,
                                                                decs: rowData.task_description,
                                                            }
                                                            this.setState({
                                                                openDateDialog: true,
                                                                selectedTaskData: selectedTask
                                                            })
                                                        }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        }
                                    ]}
                                    data={this.state.previewData}
                                    isLoading={this.state.previewLoading}
                                    options={{
                                        search: false,
                                        paging: false,
                                        padding: "dense",
                                        actionsColumnIndex: -1,
                                        selection: true,
                                        selectionProps: rowData => ({
                                            disabled: rowData.copy_hours <= 0,
                                        }),
                                        // selectionProps: rowData => {
                                        //     rowData.tableData.disabled = rowData.copy_hours <= 0;
                                        //     if (rowData.tableData.disabled) {
                                        //         return {
                                        //             disabled: rowData.copy_hours <= 0,
                                        //         };
                                        //     } else {
                                        //         return {
                                        //             // disabled: rowData.copy_hours <= 0,
                                        //         };
                                        //     }
                                        // },
                                        // toolbar: false,
                                    }}
                                    onSelectionChange={(rows) => this.setState({
                                        copyLists: rows
                                    })}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handlePreviewClose} color="primary">
                                    Close
                                </Button>
                                <Button disabled={(this.state.copyLists.length === 0) || this.state.copyDisabled} onClick={() => this.copyOps()} color="primary">
                                    Copy
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog
                            fullWidth
                            maxWidth="xs"
                            open={this.state.openDateDialog}
                            onClose={handleDateDialogClose}
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description">
                            <DialogTitle>{this.state.selectedTaskData ? (this.state.selectedTaskData.decs ? this.state.selectedTaskData.decs : this.state.selectedTaskData.name) : null}</DialogTitle>
                            <DialogContent>
                                <DateChangeRequest value={this.state.selectedTaskData} onCloseCommand={handleDateDialogClose} uid={userdub.uid} />
                            </DialogContent>
                        </Dialog>

                        <Dialog
                            fullWidth
                            maxWidth="xs"
                            open={this.state.copyWithQty}
                            onClose={handleCopyQtyClose}
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description">
                            <DialogTitle>{this.state.info.is_tender === 1 ? (this.state.info.ttdecs ? this.state.info.ttdecs : this.state.info.ttname) : this.state.info.tname}</DialogTitle>
                            <DialogContent>
                                <Alert severity="info">
                                    Only Project related time items will be copied depending upon the remaining hours available for the respective tasks.
                                </Alert>
                                <Box>
                                    {(this.state.hoursUtilizedData) ? (
                                        <Typography component="div">
                                            <Box display="flex" flexDirection="row"><Box fontWeight="fontWeightBold">{this.state.hoursUtilizedData.event_hours}</Box>&nbsp;of&nbsp;<Box fontWeight="fontWeightBold">{this.state.hoursUtilizedData.allowed_hours}</Box>&nbsp;hours is utilized for this task</Box>
                                        </Typography>
                                    ) : (null)}
                                </Box>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Quantity</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={this.state.copyQty}
                                        label="Quantity"
                                        onChange={handleQtyChange}
                                    >
                                        <MenuItem value={1}>1</MenuItem>
                                        <MenuItem value={2}>2</MenuItem>
                                        <MenuItem value={3}>3</MenuItem>
                                        <MenuItem value={4}>4</MenuItem>
                                        <MenuItem value={5}>5</MenuItem>
                                    </Select>
                                </FormControl>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCopyQtyClose} color="primary">
                                    Close
                                </Button>
                                <Button disabled={this.state.qtyDisabled} onClick={() => this.copyQtyOps()} color="primary">
                                    Copy
                                </Button>
                            </DialogActions >
                        </Dialog>
                    </div >
                )
                }
            </div >
        )
    }
}

function timeToDecimal(t) {
    var arr = t.split(':');
    var dec = parseInt((arr[1] / 6) * 10, 10);

    return parseFloat(parseInt(arr[0], 10) + '.' + (dec < 10 ? '0' : '') + dec);
}

function msToTime(duration) {
    var milliseconds = parseInt((duration % 1000) / 100),
        seconds = Math.floor((duration / 1000) % 60),
        minutes = Math.floor((duration / (1000 * 60)) % 60),
        hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds + "." + milliseconds;
}

export default withSnackbar(Calender);


function workWeekRange(date, options) {
    let minusdate = date
    if (minusdate.getDay() !== 6) {
        minusdate = moment(minusdate).subtract(1, 'days').toDate();
    }
    let range = []
    for (let i = 0; i < 7; i++) {
        range.push(moment(minusdate).add(i, 'days').toDate())
    }
    return range
    // let minusdate = moment(date).subtract(2, 'days');
    // let range = Week.range(date, options).filter(
    //     (d) => [6].indexOf(d.getDay()) === -1
    // );
    // range.splice(0, 0, minusdate.toDate());
    // return range
}
export class MyWorkWeek extends React.Component {
    render() {
        let { date, ...props } = this.props;
        let range = workWeekRange(date, this.props);

        return <TimeGrid {...props} range={range} eventOffset={15} />;
    }
}

MyWorkWeek.propTypes = {
    date: PropTypes.instanceOf(Date).isRequired
};

MyWorkWeek.defaultProps = TimeGrid.defaultProps;

MyWorkWeek.range = workWeekRange;

MyWorkWeek.navigate = Week.navigate;

MyWorkWeek.title = (date, { localizer }) => {
    let [start, ...rest] = workWeekRange(date, { localizer });
    return localizer.format({ start, end: rest.pop() }, "dayRangeHeaderFormat");
};

