import React, { Component } from 'react'
import { getPrimaryColor } from '../Helper/CommonThings';
import { getCookie } from '../Helper/CookieHelper';
import { errorMessage } from '../Helper/ErrorMessage';
import Service from '../Networking/networkutils'
import { LoopCircleLoading } from 'react-loadingg';
import { withSnackbar } from 'notistack';
import { Box, Button, TextField, Typography } from '@material-ui/core';
import HoeGate6Table from './HoeGate6Table';
import { Autocomplete } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { AppBar, Tab, Tabs } from '@mui/material';
// import { ToggleButton, ToggleButtonGroup } from '@mui/material';

const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}


class HoeGate6 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            hoeList: [],
            selectedHoe: [],
            value: 0,
            availableWorkbookData: [],
            estimateEfoortData: [],
            isReRender: false,
            submitdisable: true
        }
        this.getHoe = React.createRef()
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData() {
        Service.get2(`/bb/api/region/hoe_users/`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({
                    hoeList: res.data,
                    isLoading: false,
                })
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    onHoeChange = (event, values) => {
        this.setState({ selectedHoe: values }, () => {
            if (this.state.selectedHoe.length !== 0) {
                this.setState({ submitdisable: false })
            } else {
                this.setState({ submitdisable: true })
            }
            // var ids = []
            // for (let i = 0; i < this.state.selectedHoe.length; i++) {
            //     const element = this.state.selectedHoe[i];
            //     ids.push(element.id)
            // }
            // if (this.getHoe && this.getHoe.current && this.state.selectedHoe.length !== 0) {
            //     this.getHoe.current.fetchData(ids.toString())
            // } else if (this.getHoe && this.getHoe.current && this.state.selectedHoe.length === 0) {
            //     this.getHoe.current.emptyData()
            // }
        })
    }

    reload() {
        if (this.getHoe && this.getHoe.current && user) {
            this.getHoe.current.fetchData(user.id)
        }
    }

    submitHoe() {
        var ids = []
        for (let i = 0; i < this.state.selectedHoe.length; i++) {
            const element = this.state.selectedHoe[i];
            ids.push(element.id)
        }
        if (this.getHoe && this.getHoe.current && this.state.selectedHoe.length !== 0) {
            this.getHoe.current.fetchData(ids.toString())
        } else if (this.getHoe && this.getHoe.current && this.state.selectedHoe.length === 0) {
            this.getHoe.current.emptyData()
        }

    }

    render() {

        const handleChange = (event, newValue) => {
            this.setState({ value: newValue }, () => {
                if (this.props.isHoe) {
                    var ids = []
                    for (let i = 0; i < this.state.selectedHoe.length; i++) {
                        const element = this.state.selectedHoe[i];
                        ids.push(element.id)
                    }
                    if (this.getHoe && this.getHoe.current && this.state.selectedHoe.length !== 0) {
                        this.getHoe.current.fetchData(ids.toString())
                    } else if (this.getHoe && this.getHoe.current && this.state.selectedHoe.length === 0) {
                        this.getHoe.current.emptyData()
                    }
                }
            });
        };

        return (
            <div>{this.state.isLoading ? <LoopCircleLoading color={getPrimaryColor()} /> :
                <Box display='flex' flexDirection={'column'} justifyContent='center'>
                    {this.props.isHoe ?
                        <Box width='100%' display='flex' flexDirection={'row'} justifyContent={'center'}>
                            <Autocomplete
                                multiple
                                id="combo-box-demo"
                                options={this.state.hoeList}
                                disableCloseOnSelect
                                style={{ minWidth: 500 }}
                                onChange={this.onHoeChange}
                                getOptionLabel={(option) => option.last_name + ", " + option.first_name}
                                renderInput={(params) => <TextField autoFocus {...params} label="Select HOE" variant="outlined" />}
                            />
                            <Button onClick={() => this.submitHoe()} disabled={this.state.submitdisable} variant='contained' color='primary' size='small' style={{ margin: '5px' }}>Submit</Button>
                        </Box> : null}
                    <Box mt={this.props.isHoe ? 2 : 0} >
                        <AppBar position='static' color='secondary'>
                            <Tabs value={this.state.value} style={{ marginTop: '5px', marginLeft: '8px', marginRight: '8px' }} onChange={handleChange} aria-label="simple tabs example"
                                indicatorColor="primary"
                                variant="fullWidth"
                                scrollButtons="auto"
                            >
                                <Tab label="Available Workbook" {...a11yProps(0)} />
                                <Tab label="Estimate Effort" {...a11yProps(1)} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={this.state.value} index={0}>
                            <HoeGate6Table ref={this.getHoe} value={this.state.value} isHoe={this.props.isHoe} />
                        </TabPanel>
                        <TabPanel value={this.state.value} index={1}>
                            <HoeGate6Table ref={this.getHoe} value={this.state.value} isHoe={this.props.isHoe} />
                        </TabPanel>
                    </Box>
                </Box>
            }
            </div>
        )
    }
}
export default withSnackbar(HoeGate6)
