import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import { withStyles, Box, Menu, MenuItem, Tooltip, Popover } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import CardMedia from '@material-ui/core/CardMedia';
import Service from "../Networking/networkutils";
// import StaffManage from '../Manage/StaffManage';
import { withSnackbar } from 'notistack';
// import AdminManage from '../Manage/AdminManage';
import EstimatorManage from '../Manage/EstimatorManage';
import HRManage from '../Manage/HRManage';
import PasswordChange from '../Login/PasswordChange';
import Profile from '../Profile/Profile';
import { LoopCircleLoading } from 'react-loadingg';
// import Teams from '../Teams/Teams';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import TuneIcon from '@material-ui/icons/Tune';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import Help from '../Help/Help';
// import AllInboxIcon from '@material-ui/icons/AllInbox';
import WorkHours from '../WorkHours/WorkHours';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ViewTimeSheets from '../TimeSheet/ViewTimeSheets';
import ReceiptIcon from '@material-ui/icons/Receipt';
// import LeadManage from '../Manage/LeadManage';
import SubjectIcon from '@material-ui/icons/Subject';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import { Media } from 'react-breakpoints'
import PersonIcon from '@material-ui/icons/Person';
import Avatar from '@material-ui/core/Avatar';
import { deepOrange } from '@material-ui/core/colors';
// import moment from "moment";
import LockIcon from '@material-ui/icons/Lock';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import UserManage from '../Manage/UserManage';
import GroupManage from '../Manage/GroupManage';
import { getCookie, deleteCookie, setCookie } from '../Helper/CookieHelper';
// import ManageEDCTenders from '../Manage/ManageEDCTenders';
import ReactGA from 'react-ga';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import SecuredWorks from '../SecuredWorks/SecuredWorks';
import DescriptionIcon from '@material-ui/icons/Description';
import SupportManager from '../Help/SupportManager';
// import { Link } from 'react-router-dom';
import AssessmentIcon from '@material-ui/icons/Assessment';
import DashboardIcon from '@material-ui/icons/Dashboard';
import UserDetail from '../Manage/UserDetail/UserDetail';
import { errorMessage } from '../Helper/ErrorMessage';
import FeedbackIcon from '@material-ui/icons/Feedback';
import { accessReloadOrRefresh, getDarkMode, getPrimaryColor } from '../Helper/CommonThings';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import DesignTracker from '../DesignManagementTracker/DesignTracker';
import FeedbackView from '../Feedback/FeedbackView';
import ManageTimeSheetMain from '../Manage/ManageTimeSheetMain';
import FutureBudgetMain from '../FutureBudget/FutureBudgetMain';
import EmojiSymbolsIcon from '@material-ui/icons/EmojiSymbols';
import MainDashBoard from '../MainDashBoard/MainDashBoard';
import Tutorials from '../Tutorials/Tutorials';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import ManageTutorials from '../Tutorials/ManageTutorials';
import VideocamIcon from '@material-ui/icons/Videocam';
import { getMode } from '../MainDashBoard/ColorTheme/ColorTheme';
import ManageFeedback from '../Feedback/ManageFeedback';
import { Link } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsLog from '../Notifications/NotificationsLog';
import Badge from '@mui/material/Badge';
import ManageTaskCategory from '../Manage/ManageTaskCategory';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import UserReg from '../UserReg/UserReg';
import UserRequestForm from '../UserRequest/UserRequestForm';
import ProjectRequestForm from '../RequestForms/ProjectRequestForm';
// import RequirementRequestForm from '../RequestForms/RequirementRequestForm';
// import DefectsRequestForm from '../RequestForms/DefectsRequestForm';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import HelpIcon from '@material-ui/icons/Help';
import DefectsAndReqForm from '../Help/DefectsAndReqForm';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import NotificationPermission from '../Helper/NotificationPermission';

var pjson = require('../../package.json');
// console.log(pjson.version);
const drawerWidth = 240;
// const feedbackKey = 'feedback'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = (theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        width: `calc(100% - ${drawerWidth}px)`,
        padding: theme.spacing(3),
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    orange: {
        color: theme.palette.getContrastText(deepOrange[500]),
        backgroundColor: deepOrange[500],
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
    tutorial: {
        width: theme.spacing(4.5),
        height: theme.spacing(4.5),
    },
    active: {
        backgroundColor: "red"
    }
});

const StyledListItem = withStyles({
    root: {
        "&.Mui-selected": {
            backgroundColor: "#CFE9F8",
            color: '#000'
        },
        "&:hover": {
            color: getMode() ? '#fff' : '#000'
        }
    }
})(ListItem);

var token = getCookie("bb_ts_token");
var access = JSON.parse(getCookie("bb_ts_access"));
// console.log(access)
// const loadergot = localStorage.getItem("load_got_user");
var email = '';

// var installPromt = null

// var displayMode = null

class Home extends Component {

    constructor(props) {
        super()
        this.feedback = React.createRef();
        this.state = {
            openDialog: false,
            firstname: '',
            lastname: '',
            subButton: true,
            openDrawer: false,
            userData: {},
            viewClass: '',
            isLoading: true,
            anchorEl: null,
            data: [],
            openRating: false,
            valueRating: -1,
            hover: -1,
            feedbackComment: '',
            userAccess: {},
            menus: [],
            installPromt: null,
            displayMode: null,
            openTutorial: false,
            notificationsLog: false,
            notificationsCount: 0
        }
        this.refreshNotifications = React.createRef()
        this.notificationPermission = React.createRef()
    }

    componentDidMount() {
        window.addEventListener('beforeinstallprompt', (e) => {
            // alert("qwerty")
            // Prevent the mini-infobar from appearing on mobile
            e.preventDefault();
            // Stash the event so it can be triggered later.
            // installPromt = e
            this.setState({
                installPromt: e
            })
            console.log(e)
        });

        window.addEventListener('DOMContentLoaded', () => {
            let displayMode = 'browser tab';
            if (navigator.standalone) {
                displayMode = 'standalone';
            }
            if (window.matchMedia('(display-mode: standalone)').matches) {
                displayMode = 'standalone';
            }
            // Log launch display mode to analytics
            this.setState({
                displayMode: displayMode,
            })
            console.log('DISPLAY_MODE_LAUNCH:', displayMode);
        });

        window.addEventListener('appinstalled', (evt) => {
            // Log install to analytics
            this.setState({
                displayMode: null,
                installPromt: null
            })
            console.log('INSTALL: Success');
        });

        token = getCookie("bb_ts_token");
        access = JSON.parse(getCookie("bb_ts_access"));

        // console.log(getCookie("bb_ts_access"))

        var pageView = window.location.pathname + "/#Dashboard";
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);

        if (!getCookie("bb_ts_token")) {
            window.location.href = '/'
        } else {
            if (!getCookie("bb_ts_user")) {
                Service.get(`/rest-auth/user/`, {
                    headers: {
                        Authorization: "Token " + token,
                    },
                })
                    .then(res => {
                        // console.log(res.data)
                        setCookie('bb_ts_user', JSON.stringify(res.data), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'None', 'path': '/', });
                        setCookie('bb_ts_user', JSON.stringify(res.data), { secure: true, 'max-age': 604800, 'domain': 'faqs.' + window.location.host, 'samesite': 'None', 'path': '/', });

                        email = res.data.email;
                        var names = false;
                        if ((res.data.first_name === "" && res.data.last_name === "") || (res.data.first_name === null || res.data.last_name === null)) {
                            names = true;
                        } else {
                            // this.setFeedbackInfo();
                            this.props.enqueueSnackbar('Welcome back ' + res.data.first_name + '!', {
                                variant: 'info'
                            });
                        }

                        this.createMenu(res.data);

                        this.setState({
                            isLoading: false,
                            userData: res.data,
                            openDialog: names,
                        }, () => { this.checkNotify(); this.getNoficationsCount() });

                    })
                    .catch(error => {
                        errorMessage(this.props, error)
                    });

                this.getAccess();
            } else {
                // localStorage.removeItem("load_got_user");

                const user = JSON.parse(getCookie("bb_ts_user"));
                email = user.email;
                var names = false;
                if ((user.first_name === "" && user.last_name === "") || (user.first_name === null || user.last_name === null)) {
                    names = true;
                } else {
                    // this.setFeedbackInfo();
                    this.props.enqueueSnackbar('Welcome back ' + user.first_name + '!', {
                        variant: 'info'
                    });
                }

                this.createMenu(user);
                this.getNoficationsCount()
                this.setState({
                    isLoading: false,
                    userData: user,
                    openDialog: names,
                });

            }
        }
    }

    checkNotify() {
        if (this.notificationPermission && this.notificationPermission.current) {
            this.notificationPermission.current.checkNotify();
        }
    }

    getNoficationsCount() {
        const user = JSON.parse(getCookie("bb_ts_user"));

        Service.get(`/bb/api/push/notification/token/get_notification_count/?user_id=${user.id}`, {
            headers: {
                Authorization: "Token " + getCookie("bb_ts_token"),
            },
        })
            .then(res => {
                if (!this.getCount) {
                    this.getCount = setInterval(() => {
                        this.getNoficationsCount()
                    }, 15000);
                }
                if (token) {
                    if (this.state.notificationsCount !== res.data) {
                        if (this.refreshNotifications && this.refreshNotifications.current) {
                            this.refreshNotifications.current.fetchNotifications()
                        }
                        this.setState({ notificationsCount: res.data })
                    }
                }
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    getAccess() {
        Service.get(`/bb/api/token/access/`, {
            headers: {
                Authorization: "Token " + getCookie("bb_ts_token"),
            },
        })
            .then(res => {
                // console.log(res.data)
                // console.log(access.last_updated +"  "+ res.data[0].last_updated)
                if (res.data && res.data[0]) {
                    if (access) {
                        if (access.last_updated !== res.data[0].last_updated) {
                            setCookie('bb_ts_access', JSON.stringify(res.data[0]), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'None', 'path': '/', });
                            accessReloadOrRefresh();
                        }
                    } else {
                        setCookie('bb_ts_access', JSON.stringify(res.data[0]), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'None', 'path': '/', });
                        accessReloadOrRefresh();
                    }

                } else {
                    setCookie('bb_ts_access', JSON.stringify({}), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'None', 'path': '/', });
                    // alert("This user doesn't have basic access")
                }

            })
            .catch(error => {
                setCookie('bb_ts_access', JSON.stringify({}), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'None', 'path': '/', });
            });
    }

    createMenu(userData) {
        // console.log(access.access)
        var accessBuild = [];
        var defaultView = '';
        var hashDone = false;

        var viewHash = window.location.hash.split('?')[0].replace('#', '').replace('%20', ' ').replace('%20', ' ');
        // console.log(viewHash)

        if (access && access.access) {
            // console.log(access)

            if (access.access.l0a && access.access.l0a.l0a_a) {
                if (viewHash === 'Dashboard' || viewHash === 'Workload Report' || viewHash === 'My Timesheets') {
                    hashDone = true;
                    this.setState({
                        viewClass: viewHash,
                    })
                }
                accessBuild.push(['Dashboard', 'Workload Report', 'My Timesheets'])
                defaultView = 'Dashboard'
            } else if (access.access.l0a && access.access.l0a.l0a_b) {
                window.location.href = '/estimate'
                accessBuild.push([])
            } else {
                accessBuild.push([])
            }
            if (access.access.l13a && access.access.l13a.l13a_a) {
                if (viewHash === 'Manage Teams') {
                    hashDone = true;
                    this.setState({
                        viewClass: viewHash,
                    })
                }
                accessBuild.push(['Manage Teams'])
                if (defaultView === '') {
                    defaultView = 'Manage Teams'
                }
            } else {
                accessBuild.push([])
            }

            var menuTimeSheet = []
            if (access.access.l2a) {
                if (access.access.l2a.l2a_a) {
                    if (viewHash === 'Your Portfolio') {
                        hashDone = true;
                        this.setState({
                            viewClass: viewHash,
                        })
                    }

                    if (defaultView === '') {
                        defaultView = 'Your Portfolio'
                    }
                    menuTimeSheet.push('Your Portfolio')
                }
                // if (access.access.l2a.l2a_d) {
                //     if (viewHash === 'Timesheet Report') {
                //         hashDone = true;
                //         this.setState({
                //             viewClass: viewHash,
                //         })
                //     }

                //     if (defaultView === '') {
                //         defaultView = 'Timesheet Report'
                //     }
                //     menuTimeSheet.push('Timesheet Report')
                // }

                accessBuild.push(menuTimeSheet)

            } else {
                accessBuild.push([])
            }
            var proj = [];
            if (access.access.l4a || access.access.l3a || access.access.l17a) {
                if (access.access.l4a && access.access.l4a.l4a_a) {
                    if (viewHash === 'Available Workbook') {
                        hashDone = true;
                        this.setState({
                            viewClass: viewHash,
                        })
                    }
                    proj.push('Available Workbook')
                    if (defaultView === '') {
                        defaultView = 'Available Workbook'
                    }
                }
                if (access.access.l17a && access.access.l17a.l17a_a) {
                    if (viewHash === 'Design Tracker') {
                        hashDone = true;
                        this.setState({
                            viewClass: viewHash,
                        })
                    }
                    proj.push('Design Tracker')
                    if (defaultView === '') {
                        defaultView = 'Design Tracker'
                    }
                }
                if (access.access.l3a && access.access.l3a.l3a_a) {
                    if (viewHash === 'Non Billable') {
                        hashDone = true;
                        this.setState({
                            viewClass: viewHash,
                        })
                    }
                    proj.push('Non Billable')
                    if (defaultView === '') {

                        defaultView = 'Non Billable'
                    }
                }

                accessBuild.push(proj)
            } else {
                accessBuild.push([])
            }
            if (access.access.l5a && access.access.l5a.l5a_a) {
                accessBuild.push(['Work Winning'])
            } else {
                accessBuild.push([])
            }
            if ((access.access.l15a && access.access.l15a.l15a_a) || (access.access.l19a && access.access.l19a.l19a_a)) {
                var reportMenu = [];
                if (access.access.l15a && access.access.l15a.l15a_a) {
                    reportMenu.push('Reports')
                }
                if (access.access.l19a && access.access.l19a.l19a_a) {
                    if (viewHash === 'Future Budget') {
                        hashDone = true;
                        this.setState({
                            viewClass: viewHash,
                        })
                    }
                    reportMenu.push('Future Budget')
                    if (defaultView === '') {

                        defaultView = 'Future Budget'
                    }
                }
                accessBuild.push(reportMenu)
            } else {
                accessBuild.push([])
            }
            if ((userData.is_hr || userData.is_superuser) || (access.access.l16a && access.access.l16a.l16a_i) || (access.access.l16a && access.access.l16a.l16a_a) || (access && access.access && access.access.l16a && (access.access.l16a.l16a_c || access.access.l16a.l16a_d || access.access.l16a.l16a_e || access.access.l16a.l16a_j))) {
                var manageuser = []
                if ((userData.is_hr || userData.is_superuser || (access && access.access && access.access.l16a && (access.access.l16a.l16a_c || access.access.l16a.l16a_d || access.access.l16a.l16a_e || access.access.l16a.l16a_j)))) {
                    if (viewHash === 'Manage User') {
                        hashDone = true;
                        this.setState({
                            viewClass: viewHash,
                        })
                    }
                    manageuser.push('Manage User')
                }
                if (access.access.l16a && access.access.l16a.l16a_i) {
                    if (viewHash === 'User Reg') {
                        hashDone = true;
                        this.setState({
                            viewClass: viewHash,
                        })
                    }
                    manageuser.push('User Reg')
                }
                if (access.access.l16a && access.access.l16a.l16a_a) {
                    if (viewHash === 'Employee Data') {
                        hashDone = true;
                        this.setState({
                            viewClass: viewHash,
                        })
                    }
                    manageuser.push('Employee Data')
                }
                accessBuild.push(manageuser)
            } else {
                accessBuild.push([])
            }
            if ((userData.is_superuser)) {
                if (viewHash === 'Manage HR') {
                    hashDone = true;
                    this.setState({
                        viewClass: viewHash,
                    })
                }

                if (viewHash === 'Manage Tutorials') {
                    hashDone = true;
                    this.setState({
                        viewClass: viewHash,
                    })
                }
                accessBuild.push(['Manage HR', 'Manage Tutorials'])
            } else {
                accessBuild.push([])
            }
            if ((access.access.l111a && access.access.l111a.l111a_b)) {
                if (viewHash === 'Support Manager') {
                    hashDone = true;
                    this.setState({
                        viewClass: viewHash,
                    })
                }
                if (viewHash === 'Manage Feedback') {
                    hashDone = true;
                    this.setState({
                        viewClass: viewHash,
                    })
                }
                accessBuild.push(['Support Manager', 'Manage Feedback'])
            } else {
                accessBuild.push([])
            }

        } else {
            accessBuild.push([])
            accessBuild.push([])
            accessBuild.push([])
            accessBuild.push([])
            accessBuild.push([])
            accessBuild.push([])
            accessBuild.push([])
            accessBuild.push([])
            accessBuild.push([])
        }

        // console.log(accessBuild)
        if (hashDone) {
            this.setState({
                menus: accessBuild,
            })
        } else {
            this.setState({
                menus: accessBuild,
                viewClass: defaultView,
            })
        }


    }

    handleTextChange(e) {
        if (e.target.id === 'firstname') {
            this.setState({
                firstname: e.target.value,
            })
        } else if (e.target.id === 'lastname') {
            this.setState({
                lastname: e.target.value,
            })
        }
        if (this.state.firstname !== '' && this.state.lastname !== '') {
            this.setState({
                subButton: false,
            })
        } else {
            this.setState({
                subButton: true,
            })
        }
    }

    handleClose(e) {
        this.setState({
            subButton: false,
        })
        const json = {
            email: email,
            first_name: this.state.firstname,
            last_name: this.state.lastname,
        }

        Service.put(`/rest-auth/user/`, {
            headers: {
                Authorization: "Token " + token,
            },
            data: json,
        })
            .then(res => {
                deleteCookie("bb_ts_user");
                window.location.href = '/'
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    // setFeedbackInfo(hasSubmitted) {
    //     let feedbackInfo = {};
    //     if (localStorage.getItem(feedbackKey)) {
    //         feedbackInfo = JSON.parse(localStorage.getItem(feedbackKey));
    //         var minMonth = moment(feedbackInfo.lastViewed, "YYYYMMDD").add(30, 'day');
    //         var min = moment(feedbackInfo.lastViewed, "YYYYMMDD").add(30, 'day');
    //         if ((!feedbackInfo.hasSubmitted) && (min < moment())) {
    //             this.setState({ openRating: true })
    //         } else if (minMonth < moment()) {
    //             feedbackInfo = {
    //                 lastViewed: new Date(),
    //                 rating: '',
    //                 comment: '',
    //                 hasSubmitted: false
    //             }
    //             this.setState({ openRating: true });
    //         } else {
    //             this.setState({ openRating: false })
    //         }
    //     } else {
    //         feedbackInfo = {
    //             lastViewed: new Date(),
    //             rating: '',
    //             comment: '',
    //             hasSubmitted: false
    //         }
    //     }
    //     localStorage.setItem(feedbackKey, (JSON.stringify(feedbackInfo)));
    // }

    // submitFeedback() {
    //     if (this.state.feedbackComment) {
    //         this.props.enqueueSnackbar('Add comment!', {
    //             variant: 'warning'
    //         })
    //     } else if (this.state.valueRating === -1) {
    //         this.props.enqueueSnackbar('Add Rating!', {
    //             variant: 'warning'
    //         })
    //     } else {
    //         this.setState({ openRating: false })
    //         this.setState({ isLoading: true, });
    //         var json = {
    //             uid: this.state.userData.id,
    //             rating: this.state.valueRating,
    //             feedback: this.state.feedbackComment,
    //             created_by: this.state.userData.email,
    //         }
    //         this.setState({ openRating: false, feedbackComment: '' });
    //         // console.log(json)
    //         Service.post("/bb/api/feedback/", {
    //             headers: {
    //                 Authorization: "Token " + token,
    //             },
    //             data: json,
    //         })
    //             .then(res => {
    //                 // console/.log(res.data)
    //                 let feedbackInfo = {};
    //                 if (localStorage.getItem(feedbackKey)) {
    //                     feedbackInfo = JSON.parse(localStorage.getItem(feedbackKey));
    //                     feedbackInfo = {
    //                         lastViewed: new Date(),
    //                         rating: json.valueRating,
    //                         comment: json.feedbackComment,
    //                         hasSubmitted: true
    //                     }
    //                 }
    //                 localStorage.setItem(feedbackKey, (JSON.stringify(feedbackInfo)));
    //                 this.setState({ isLoading: false });
    //                 this.props.enqueueSnackbar('Feedback submitted successfully', {
    //                     variant: 'success'
    //                 });
    //             })
    //             .catch(error => {
    //                 this.setState({ isLoading: false });
    //                 errorMessage(this.props, error)
    //             });
    //     }
    // }
    //Feedback Dialog
    handleFeedbackClose = (e) => {
        this.setState({ openRating: false })
        // let feedbackInfo = {};
        // localStorage.getItem(feedbackKey);
        // feedbackInfo = JSON.parse(localStorage.getItem(feedbackKey));
        // feedbackInfo.lastViewed = new Date();
        // localStorage.setItem(feedbackKey, (JSON.stringify(feedbackInfo)));
    }

    render() {
        const { classes } = this.props;

        const handleDrawerOpen = () => {
            this.setState({
                openDrawer: true,
            })
        };

        const handleDrawerClose = () => {
            this.setState({
                openDrawer: false,
            })
        };

        const handleViewChange = (e, val) => {
            var pageView = window.location.pathname + "/#" + val;
            ReactGA.set({ page: pageView, });
            ReactGA.pageview(pageView);
            if (!getCookie("bb_ts_token")) {
                window.location.href = '/'
            }
            if (val === "Sign Out") {
                deleteCookie("bb_ts_token");
                deleteCookie("bb_ts_user");
                deleteCookie("bb_ts_access");
                window.location.href = "/login";
            } else if (val === "Work Winning") {
                window.location.href = "/estimate";
            } else if (val === "Reports") {
                window.location.href = "/reports";
            } else {
                window.location.hash = val;
                this.setState({
                    viewClass: val,
                })
            }
        };

        const handleAvatarClick = (event) => {
            // this.setState({ anchorEl: event.currentTarget })
            this.setState({ viewClass: 'Profile', })
        };

        const handleClose = () => {
            this.setState({ anchorEl: null })
        };

        const handleMenuClick = (e) => {
            if (e.target.id === 'profile') {
                this.setState({ viewClass: 'Profile', anchorEl: null });
            } else if (e.target.id === 'change_passsword') {
                this.setState({ viewClass: 'Change Password', anchorEl: null });
            } else {
                this.setState({ anchorEl: null });
                deleteCookie("bb_ts_token");
                deleteCookie("bb_ts_user");
                window.location.href = "/login";
            }
        };

        const handleTutorialClose = () => {
            this.setState({ openTutorial: false })
        };

        const toggleDrawer = (event) => {
            this.setState({ notificationsLog: false })
        };

        return (

            <>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                    <div className={classes.root}>
                        <NotificationPermission ref={this.notificationPermission} />
                        <CssBaseline />
                        <AppBar
                            position="fixed"
                            className={clsx(classes.appBar, {
                                [classes.appBarShift]: this.state.openDrawer,
                            })}
                        >
                            <Toolbar>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={handleDrawerOpen}
                                    edge="start"
                                    className={clsx(classes.menuButton, {
                                        [classes.hide]: this.state.openDrawer,
                                    })}
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Typography variant="h6" noWrap className={classes.title}>
                                    PaRaM - Project & Resource Management
                                </Typography>
                                <Media>
                                    {({ breakpoints, currentBreakpoint }) =>
                                        (breakpoints[currentBreakpoint] > breakpoints.tablet && !((this.state.userData.first_name === "" && this.state.userData.last_name === "") || (this.state.userData.first_name === null || this.state.userData.last_name === null))) ? (

                                            <Box alignItems="center" display="flex" flexDirection="row">
                                                <Box>
                                                    <Link
                                                        component="button"
                                                        variant="body2"
                                                        color={"#fff"}
                                                        onClick={() => window.open('https://heritage.bbparam.com')}>
                                                        heritage.bbparam.com
                                                    </Link>
                                                </Box>
                                                <Box>
                                                    <IconButton onClick={() => { this.setState({ openRating: true }) }} style={{ color: 'white' }} >
                                                        <Tooltip title="Give us Feedback" aria-label="faqs">
                                                            <FeedbackIcon />
                                                        </Tooltip>
                                                    </IconButton>
                                                </Box>
                                                <Box display={'flex'} flexDirection={'row'}>
                                                    <IconButton onClick={() => { window.open("https://faqs.bbparam.com") }} style={{ color: 'white' }} >
                                                        <Tooltip title="FAQ's" aria-label="faqs">
                                                            <QuestionAnswerIcon />
                                                        </Tooltip>
                                                    </IconButton>
                                                    <PopupState variant="popover" popupId="demo-popup-popover">
                                                        {(popupState) => (
                                                            <div>
                                                                <Tooltip title="Raise a Request">
                                                                    <IconButton {...bindTrigger(popupState)} style={{ color: 'white' }}>
                                                                        <HelpIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                {/* <Button size='small' variant="contained" color="primary" {...bindTrigger(popupState)} style={{ marginBottom: '10px' }}>Add</Button> */}
                                                                <Popover {...bindPopover(popupState)} anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'left',
                                                                }}
                                                                    transformOrigin={{
                                                                        vertical: 'top',
                                                                        horizontal: 'right',
                                                                    }}>
                                                                    <Box margin={1}>
                                                                        {(access && access.access && access.access.l5a.l5a_a) ? <MenuItem onClick={() => { popupState.close(); this.setState({ projectDialog: true }) }}>Setting Up Project</MenuItem> : null}
                                                                        {/* <MenuItem onClick={() => { popupState.close(); this.setState({ requirementsDialog: true }) }}>Requirements, Developments & Improvements</MenuItem> */}
                                                                        <MenuItem onClick={() => { popupState.close(); window.open('https://balfourbeatty-dwp.onbmc.com/dwp/app/#/itemprofile/22206') }}>Requirements, Developments & Improvements</MenuItem>
                                                                        {/* <MenuItem onClick={() => { popupState.close(); this.setState({ defectsDialog: true }) }}>Defects/Error/Other Issues</MenuItem> */}
                                                                        <MenuItem onClick={() => { popupState.close(); window.open('https://balfourbeatty-dwp.onbmc.com/dwp/app/#/itemprofile/22206') }}>Defects/Error/Other Issues</MenuItem>
                                                                        {(access && access.access && access.access.l2a.l2a_a) ? <MenuItem onClick={() => { popupState.close(); this.setState({ useRequestDialog: true }) }}>New User Request</MenuItem> : null}
                                                                    </Box>
                                                                </Popover>
                                                            </div>
                                                        )}
                                                    </PopupState>
                                                </Box>
                                                <Box>
                                                    <IconButton onClick={() => {
                                                        setCookie('theme_mode', ((getDarkMode() === 'dark') ? 'light' : 'dark'), { secure: true, 'max-age': 6048000, 'domain': window.location.host, 'samesite': 'None', 'path': '/', }); window.location.reload()
                                                    }} style={{ color: 'white' }} >
                                                        <Tooltip title="Toggle light/dark theme" aria-label="theme_mode">
                                                            {getDarkMode() === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                                                        </Tooltip>
                                                    </IconButton>
                                                </Box>
                                                <Box>
                                                    <Tooltip title="Tutorials" aria-label="tutorials">
                                                        <IconButton onClick={() => { this.setState({ openTutorial: true }) }} style={{ color: 'white' }}><VideocamIcon /></IconButton>
                                                    </Tooltip>
                                                </Box>
                                                <Box>
                                                    <Tooltip title="Notifications" aria-label="notifications">
                                                        <IconButton onClick={() => { this.setState({ notificationsLog: true }) }} style={{ marginRight: '10px', color: 'white' }}><Badge badgeContent={this.state.notificationsCount} max={99} color="error"><NotificationsIcon /></Badge></IconButton>
                                                    </Tooltip>
                                                </Box>
                                                <Box>
                                                    <Avatar style={{ marginRight: '5px' }} onClick={handleAvatarClick} className={classes.orange}><Box fontSize={12}>{this.state.userData.first_name.charAt(0) + this.state.userData.last_name.charAt(0)}</Box></Avatar>
                                                    <Menu
                                                        id="simple-menu"
                                                        anchorEl={this.state.anchorEl}
                                                        keepMounted
                                                        open={Boolean(this.state.anchorEl)}
                                                        onClose={handleClose}
                                                    >
                                                        <MenuItem id="profile" onClick={handleMenuClick}>Profile</MenuItem>
                                                        {/* <MenuItem id="change_passsword" onClick={handleMenuClick}>Change Password</MenuItem> */}
                                                        {/* <MenuItem id="signout" onClick={handleMenuClick}>Sign Out</MenuItem> */}
                                                    </Menu>
                                                </Box>

                                            </Box>

                                        ) : (
                                            null
                                        )
                                    }
                                </Media>
                            </Toolbar>
                        </AppBar>
                        <Drawer
                            variant="permanent"
                            className={clsx(classes.drawer, {
                                [classes.drawerOpen]: this.state.openDrawer,
                                [classes.drawerClose]: !this.state.openDrawer,
                            })}
                            classes={{
                                paper: clsx({
                                    [classes.drawerOpen]: this.state.openDrawer,
                                    [classes.drawerClose]: !this.state.openDrawer,
                                }),
                            }}
                        >
                            <div className={classes.toolbar}>
                                <CardMedia
                                    component="img"
                                    alt="Balfour Beatty"
                                    image={process.env.PUBLIC_URL + '/bb.png'}
                                    title="Balfour Beatty"
                                    style={{ width: '170px' }}
                                />
                                <IconButton onClick={handleDrawerClose}>
                                    <ChevronLeftIcon />
                                </IconButton>
                            </div>
                            <Divider />
                            {(access && access.access && access.access.l0a && access.access.l0a.l0a_a) ? (
                                <List>
                                    {this.state.menus[0].map((text, index) => (
                                        <Tooltip title={text} aria-label={text} key={text}>
                                            <StyledListItem button key={text} onClick={e => handleViewChange(e, text)} selected={this.state.viewClass === text} >
                                                <ListItemIcon>{index === 0 ? <DashboardIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> : index === 1 ? <WatchLaterIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> : index === 2 ? <ListAltIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> : index === 3 ? <ContactSupportIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> : <InboxIcon color={this.state.viewClass === text ? "primary" : 'inherit'} />}</ListItemIcon>
                                                <ListItemText primary={text} />
                                            </StyledListItem>
                                        </Tooltip>

                                    ))}
                                </List>
                            ) : (null)}

                            {(access && access.access && access.access.l13a && access.access.l13a.l13a_a) ? (
                                <Divider />
                            ) : (null)}
                            {
                                (access && access.access && access.access.l13a && access.access.l13a.l13a_a) ? (
                                    <List>
                                        {this.state.menus[1].map((text, index) => (
                                            <Tooltip title={text} aria-label={text} key={text}>
                                                <StyledListItem button key={text} onClick={e => handleViewChange(e, text)} selected={this.state.viewClass === text} >
                                                    <ListItemIcon>{index % 2 === 0 ? <TuneIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> : <TuneIcon color={this.state.viewClass === text ? "primary" : 'inherit'} />}</ListItemIcon>
                                                    <ListItemText primary={text} />
                                                </StyledListItem>
                                            </Tooltip>

                                        ))}
                                    </List>
                                ) : (null)}

                            {
                                (access && access.access && access.access.l2a && access.access.l2a.l2a_a) ? (
                                    <Divider />
                                ) : (null)}


                            {
                                (access && access.access && access.access.l2a && access.access.l2a.l2a_a) ? (
                                    <List>
                                        {this.state.menus[2].map((text, index) => (
                                            <Tooltip title={text} aria-label={text} key={text}>
                                                <StyledListItem button key={text} onClick={e => handleViewChange(e, text)} selected={this.state.viewClass === text} >
                                                    <ListItemIcon>{index === 0 ? <ReceiptIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> : index === 1 ? <DescriptionIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> : <DescriptionIcon color={this.state.viewClass === text ? "primary" : 'inherit'} />}</ListItemIcon>
                                                    <ListItemText primary={text} />
                                                </StyledListItem>
                                            </Tooltip>

                                        ))}
                                    </List>
                                ) : (null)}

                            {
                                ((access && access.access && access.access.l4a && access.access.l4a.l4a_a) || (access && access.access && access.access.l4a && access.access.l4a.l4a_a)) ? (
                                    <Divider />
                                ) : (null)}

                            {
                                ((access && access.access && access.access.l3a && access.access.l3a.l3a_a) || (access && access.access && access.access.l4a && access.access.l4a.l4a_a)) ? (
                                    <List>
                                        {this.state.menus[3].map((text, index) => (
                                            <Tooltip title={text} aria-label={text} key={text}>
                                                <StyledListItem button key={text} onClick={e => handleViewChange(e, text)} selected={this.state.viewClass === text} >
                                                    <ListItemIcon>{text === 'Available Workbook' ? <MeetingRoomIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> : text === 'Design Tracker' ? <TrackChangesIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> : text === 'Non Billable' ? <AccountTreeIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> : index === 2 ? <SubjectIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> : <TrendingUpIcon color={this.state.viewClass === text ? "primary" : 'inherit'} />}</ListItemIcon>
                                                    <ListItemText primary={text} />
                                                </StyledListItem>
                                            </Tooltip>

                                        ))}
                                    </List>
                                ) : (null)}

                            {
                                (access && access.access && (access.access.l5a && access.access.l5a.l5a_a)) ? (
                                    <Divider />
                                ) : (null)}


                            {
                                (access && access.access && (access.access.l5a && access.access.l5a.l5a_a)) ? (
                                    <List>
                                        {this.state.menus[4].map((text, index) => (
                                            <Tooltip title={text} aria-label={text} key={text}>
                                                <StyledListItem button key={text} onClick={e => handleViewChange(e, text)} selected={this.state.viewClass === text} >
                                                    <ListItemIcon>{index === 0 ? <TrendingUpIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> : <TrendingUpIcon color={this.state.viewClass === text ? "primary" : 'inherit'} />}</ListItemIcon>
                                                    <ListItemText primary={text} />
                                                </StyledListItem>
                                            </Tooltip>

                                        ))}
                                    </List>
                                ) : (null)}

                            {
                                (access && access.access && (access.access.l15a && access.access.l15a.l15a_a)) ? (
                                    <Divider />
                                ) : (null)}


                            {
                                (access && access.access && (access.access.l15a && access.access.l15a.l15a_a)) ? (
                                    <List>
                                        {this.state.menus[5].map((text, index) => (
                                            <Tooltip title={text} aria-label={text} key={text}>
                                                <StyledListItem button key={text} onClick={e => handleViewChange(e, text)} selected={this.state.viewClass === text} >
                                                    <ListItemIcon>{text === 'Reports' ? <AssessmentIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> : text === 'Future Budget' ? <EmojiSymbolsIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> : <TrendingUpIcon color={this.state.viewClass === text ? "primary" : 'inherit'} />}</ListItemIcon>
                                                    <ListItemText primary={text} />
                                                </StyledListItem>
                                            </Tooltip>

                                        ))}
                                    </List>
                                ) : (null)}

                            {
                                (!this.state.isLoading && (this.state.userData.is_admin || this.state.userData.is_superuser)) ? (
                                    <Divider />
                                ) : (null)}


                            {
                                (!this.state.isLoading && ((this.state.userData.is_hr || this.state.userData.is_superuser) || (access.access.l16a && access.access.l16a.l16a_i) || (access.access.l16a && access.access.l16a.l16a_a) || (access && access.access && access.access.l16a && (access.access.l16a.l16a_c || access.access.l16a.l16a_d || access.access.l16a.l16a_e || access.access.l16a.l16a_j)))) ? (
                                    <List>
                                        {this.state.menus[6].map((text, index) => (
                                            <Tooltip title={text} aria-label={text} key={text}>
                                                <StyledListItem button key={text} onClick={e => handleViewChange(e, text)} selected={this.state.viewClass === text} >
                                                    <ListItemIcon>{text === "Manage User" ? <RecentActorsIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> : text === 'User Reg' ? <VerifiedUserIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> : <SupervisorAccountIcon color={this.state.viewClass === text ? "primary" : 'inherit'} />}</ListItemIcon>
                                                    <ListItemText primary={text} />
                                                </StyledListItem>
                                            </Tooltip>

                                        ))}
                                    </List>
                                ) : (null)}

                            {
                                (!this.state.isLoading && ((this.state.userData.is_hr || this.state.userData.is_superuser) || (access && access.access && access.access.l16a && access.access.l16a.l16a_a))) ? (
                                    <Divider />
                                ) : (null)}

                            {
                                (!this.state.isLoading && (access && access.access && (access.access.l111a && access.access.l111a.l111a_b))) ? (
                                    <List>
                                        {this.state.menus[8].map((text, index) => (
                                            <Tooltip title={text} aria-label={text} key={text}>
                                                <StyledListItem button key={text} onClick={e => handleViewChange(e, text)} selected={this.state.viewClass === text} >
                                                    <ListItemIcon>{index % 2 === 0 ? <ContactSupportIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> : <FeedbackIcon color={this.state.viewClass === text ? "primary" : 'inherit'} />}</ListItemIcon>
                                                    <ListItemText primary={text} />
                                                </StyledListItem>
                                            </Tooltip>

                                        ))}
                                    </List>
                                ) : (null)}

                            {
                                (!this.state.isLoading && (access && access.access && (access.access.l111a && access.access.l111a.l111a_b))) ? (
                                    <Divider />
                                ) : (null)}

                            {
                                (!this.state.isLoading && (this.state.userData.is_superuser)) ? (
                                    <List>
                                        {this.state.menus[7].map((text, index) => (
                                            <Tooltip title={text} aria-label={text} key={text}>
                                                <StyledListItem button key={text} onClick={e => handleViewChange(e, text)} selected={this.state.viewClass === text} >
                                                    <ListItemIcon>{text === 'Manage HR' ? <SupervisorAccountIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> : <VideocamIcon color={this.state.viewClass === text ? "primary" : 'inherit'} />}</ListItemIcon>
                                                    <ListItemText primary={text} />
                                                </StyledListItem>
                                            </Tooltip>

                                        ))}
                                    </List>
                                ) : (null)}

                            {
                                (!this.state.isLoading && (this.state.userData.is_superuser)) ? (
                                    <Divider />
                                ) : (null)}

                            {
                                <List>
                                    <Media>
                                        {({ breakpoints, currentBreakpoint }) =>
                                            breakpoints[currentBreakpoint] > breakpoints.tablet ? (
                                                null
                                            ) : (
                                                // change password and sign out removed from list
                                                ['Profile'].map((text, index) => (
                                                    <ListItem button key={text} onClick={e => handleViewChange(e, text)}>
                                                        <ListItemIcon>{index === 0 ? <PersonIcon /> : index === 1 ? <LockIcon /> : <ExitToAppIcon />}</ListItemIcon>
                                                        <ListItemText primary={text} />
                                                    </ListItem>
                                                ))
                                            )
                                        }
                                    </Media>

                                </List>
                            }
                            {({ breakpoints, currentBreakpoint }) =>
                                breakpoints[currentBreakpoint] > breakpoints.tablet ? (
                                    null
                                ) : (
                                    // change password and sign out removed from list
                                    <Divider />
                                )
                            }

                        </Drawer>

                        <Drawer anchor={'right'} open={this.state.notificationsLog} onClose={toggleDrawer}>
                            <div style={{ width: window.innerWidth / 3.5 + 'px' }}>
                                <Box display={'flex'} flexDirection={'column'} justifyContent={'center'}>
                                    <Box style={{ width: '100%', marginTop: "10px", }} fontSize={'20px'} fontWeight={500} display={'flex'} flexDirection={'row'} justifyContent={'center'}                       >
                                        Notifications
                                    </Box>
                                    <Box p={1} style={{ width: '100%' }}>
                                        <Divider style={{ width: '100%' }} textAlign='center'>
                                        </Divider>
                                    </Box>
                                    <Box>
                                        <NotificationsLog ref={this.refreshNotifications} userData={this.state.userData} />
                                    </Box>
                                </Box>
                            </div>
                        </Drawer>
                        <main className={classes.content}>
                            <div className={classes.toolbar} />
                            {
                                (!this.state.isLoading) ? (
                                    (this.state.viewClass === 'Dashboard') ? (
                                        <MainDashBoard />
                                    ) : (this.state.viewClass === 'Workload Report') ? (
                                        <WorkHours />
                                        // null
                                    ) : (this.state.viewClass === 'My Timesheets') ? (
                                        <ViewTimeSheets />
                                    ) : (this.state.viewClass === 'Help and Support') ? (
                                        <Help />
                                    ) : (this.state.viewClass === 'Manage Teams') ? (
                                        <GroupManage />
                                    ) : (this.state.viewClass === 'Non Billable') ? (
                                        <div>
                                            <ManageTaskCategory value={{
                                                id: 11,
                                                name: "Non Billable"
                                            }} />
                                        </div>
                                    ) : (this.state.viewClass === 'Future Budget') ? (
                                        <FutureBudgetMain />
                                    ) : (this.state.viewClass === 'Available Workbook') ? (
                                        <div>
                                            <SecuredWorks />

                                        </div>
                                    ) : (this.state.viewClass === 'Design Tracker') ? (
                                        <DesignTracker />
                                    ) : (this.state.viewClass === 'Your Portfolio') ? (
                                        <ManageTimeSheetMain />
                                    ) : (this.state.viewClass === 'Manage Estimator') ? (
                                        <EstimatorManage />
                                    ) : (this.state.viewClass === 'Manage HR') ? (
                                        <HRManage />
                                    ) : (this.state.viewClass === 'Manage User') ? (
                                        <UserManage />
                                    ) : (this.state.viewClass === 'User Reg') ? (
                                        <UserReg />
                                    ) : (this.state.viewClass === 'Change Password') ? (
                                        <PasswordChange />
                                    ) : (this.state.viewClass === 'Profile') ? (
                                        <Profile />
                                    ) : (this.state.viewClass === 'Support Manager') ? (
                                        <SupportManager />
                                    ) : (this.state.viewClass === 'Employee Data') ? (
                                        <UserDetail />
                                    ) : (this.state.viewClass === 'Manage Tutorials') ? (
                                        <ManageTutorials />
                                    ) : (this.state.viewClass === 'Manage Feedback') ? (
                                        <ManageFeedback />
                                    ) :
                                        (null)
                                ) : (
                                    null
                                )

                            }
                        </main>

                        <Dialog open={this.state.openDialog} aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">User Detail Form</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    You need to fill below fields to continue.
                                </DialogContentText>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="firstname"
                                    label="First Name"
                                    type="name"
                                    onChange={e => this.handleTextChange(e)}
                                    fullWidth
                                />
                                <TextField
                                    margin="dense"
                                    id="lastname"
                                    label="Last Name"
                                    type="name"
                                    onChange={e => this.handleTextChange(e)}
                                    fullWidth
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button size='small' onClick={e => this.handleClose(e)} disabled={this.state.subButton} color="primary">
                                    Continue
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog open={this.state.openRating} fullWidth onClose={this.handleFeedbackClose} aria-labelledby="form-dialog-title">
                            <DialogTitle style={{ paddingTop: '25px' }} id="form-dialog-title">Feedback</DialogTitle>
                            <DialogContent>
                                <DialogContentText style={{ paddingTop: '5px' }} id="alert-dialog-slide-description">
                                    Provide your feedback and experience with the application.
                                </DialogContentText>
                                <FeedbackView value={0} ref={this.feedback} handleClose={this.handleFeedbackClose} />
                            </DialogContent>
                            <DialogActions>
                                <Button size='small' onClick={this.handleFeedbackClose} color="primary">
                                    Cancel
                                </Button>
                                <Button size='small' onClick={e => {
                                    this.feedback.current.submitFeedback()
                                }} color="primary">
                                    Submit
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog fullScreen open={this.state.openTutorial} onClose={handleTutorialClose} TransitionComponent={Transition}>
                            <AppBar position='relative' >
                                <Toolbar>
                                    <IconButton edge="start" color="inherit" onClick={handleTutorialClose} aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                    <Typography variant="h6" className={classes.title}>
                                        Tutorials
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            <Tutorials />
                        </Dialog>
                        <div>
                            <Dialog open={this.state.projectDialog} onClose={() => { this.setState({ projectDialog: false }) }} TransitionComponent={Transition}>
                                <DialogTitle><Box display={'flex'} flexDirection={'row'} width={'100%'}>
                                    <Box flexGrow={1}>
                                        <Typography variant='h5'>Setting Up Project</Typography>
                                    </Box>
                                    <Box>
                                        <Link
                                            component="button"
                                            variant="body2"
                                            onClick={() => {
                                                window.open('https://faqs.bbparam.com/paramfaqs/paramforms')
                                            }}
                                            color={getMode() ? 'secondary' : 'primary'}
                                        >
                                            FAQ's
                                        </Link>
                                    </Box>
                                </Box></DialogTitle>
                                <DialogContent>
                                    <ProjectRequestForm reloadProp={true} reload={() => { this.setState({ projectDialog: false }) }} />
                                </DialogContent>
                            </Dialog>
                        </div>
                        <div>
                            <Dialog open={this.state.requirementsDialog} onClose={() => { this.setState({ requirementsDialog: false }) }} TransitionComponent={Transition}>
                                <DialogTitle><Box display={'flex'} flexDirection={'row'} width={'100%'}>
                                    <Box flexGrow={1}>
                                        <Typography variant='h5'>Requirements, Developments & Improvements</Typography>
                                    </Box>
                                    <Box>
                                        <Link
                                            component="button"
                                            variant="body2"
                                            onClick={() => {
                                                window.open('https://faqs.bbparam.com/paramfaqs/paramforms')
                                            }}
                                            color={getMode() ? 'secondary' : 'primary'}
                                        >
                                            FAQ's
                                        </Link>
                                    </Box>
                                </Box></DialogTitle>
                                <DefectsAndReqForm option={'requirements'} close={() => { this.setState({ requirementsDialog: false }) }} />
                            </Dialog>
                        </div>
                        <div>
                            <Dialog open={this.state.defectsDialog} onClose={() => { this.setState({ defectsDialog: false }) }} TransitionComponent={Transition}>
                                <DialogTitle><Box display={'flex'} flexDirection={'row'} width={'100%'}>
                                    <Box flexGrow={1}>
                                        <Typography variant='h5'>Defects/Error/Other Issues</Typography>
                                    </Box>
                                    <Box>
                                        <Link
                                            component="button"
                                            variant="body2"
                                            onClick={() => {
                                                window.open('https://faqs.bbparam.com/paramfaqs/paramforms')
                                            }}
                                            color={getMode() ? 'secondary' : 'primary'}
                                        >
                                            FAQ's
                                        </Link>
                                    </Box>
                                </Box></DialogTitle>
                                <DefectsAndReqForm option={'defects'} close={() => { this.setState({ defectsDialog: false }) }} />
                            </Dialog>
                        </div>
                        <div>
                            <Dialog open={this.state.useRequestDialog} onClose={() => { this.setState({ useRequestDialog: false }) }} TransitionComponent={Transition}>
                                <DialogTitle><Box display={'flex'} flexDirection={'row'} width={'100%'}>
                                    <Box flexGrow={1}>
                                        <Typography variant='h5'>New User Request</Typography>
                                    </Box>
                                    <Box>
                                        <Link
                                            component="button"
                                            variant="body2"
                                            onClick={() => {
                                                window.open('https://faqs.bbparam.com/paramfaqs/paramforms')
                                            }}
                                            color={getMode() ? 'secondary' : 'primary'}
                                        >
                                            FAQ's
                                        </Link>
                                    </Box>
                                </Box>
                                </DialogTitle>
                                <DialogContentText >
                                    <Box marginLeft={2}>
                                        Could you please be cautious when entering the information as the email field cannot be modified once the invitation to access the database has been sent.
                                    </Box>
                                </DialogContentText>
                                <DialogContent>
                                    <UserRequestForm dialogClose={() => this.setState({ useRequestDialog: false })} />
                                </DialogContent>
                            </Dialog>
                        </div>

                    </div>
                )
                }

                <div style={{
                    position: 'absolute',
                    top: '0px',
                    right: '5px',
                    padding: '0px',
                    marginTop: '71px'
                }}>
                    <Box fontSize={10} fontWeight="fontWeightLight" >
                        {"v" + pjson.version}
                        {(this.state.viewClass === 'Dashboard' && this.state.displayMode && this.state.displayMode === "standalone") ? (null) : (this.state.viewClass === 'Dashboard' && this.state.installPromt) ? (<button style={{ pointer: 'cursor', marginLeft: '5px', backgroundColor: '#b3ffb3', fontSize: '10px' }} onClick={() => this.state.installPromt.prompt()}>Install PaRaM to Desktop</button>) : (null)}
                        {localStorage.getItem('push-denied') === "true" ? (<button style={{ pointer: 'cursor', marginLeft: '5px', backgroundColor: '#b3ffb3', fontSize: '10px' }} onClick={() => this.checkNotify()}>Enable push notification</button>) : null}
                    </Box>
                </div>

            </>

        )
    }
}


export default withStyles(useStyles)(withSnackbar(Home));