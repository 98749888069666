import React, { Component } from 'react'
import MaterialTable from 'material-table';
import Service from "../../Networking/networkutils";
import { LoopCircleLoading } from 'react-loadingg';
import { getCookie } from '../../Helper/CookieHelper';
import { withSnackbar } from 'notistack';
import { errorMessage } from '../../Helper/ErrorMessage';
import { getPrimaryColor } from '../../Helper/CommonThings';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';

const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));
const access = JSON.parse(getCookie("bb_ts_access"));

class Clients extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: [],
            data1: [],
            columns: [
                { title: 'Client ID', field: 'id', editable: 'never', },
                { title: 'Client', field: 'name', },
                { title: 'Client Name', field: 'client_name', },
            ],
            isLoading: true,
        }
    }

    componentDidMount() {
        this.fetchOps();
    }

    fetchOps() {
        Service.get('/bb/api/clients/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                var data = [];
                var data1 = [];

                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    if (element.client_section === 'edc') {
                        data1.push(element)
                    } else {
                        data.push(element)
                    }
                }

                this.setState({ isLoading: false, data: data, data1: data1 })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    postOps(data) {
        if (data.name === undefined || data.name === '') {
            setTimeout(() => {
                this.setState({ isLoading: true, })
                this.fetchOps();
                this.props.enqueueSnackbar('Client field is empty!', {
                    variant: 'error'
                });
            }, 200)

        } else {
            Service.post('/bb/api/clients/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: data,
            })
                .then(res => {
                    this.setState({ isLoading: true, })
                    this.fetchOps();
                    this.props.enqueueSnackbar('Client added successfully', {
                        variant: 'success'
                    });
                })
                .catch(error => {
                    errorMessage(this.props, error)
                });
        }
    }

    putOps(data) {
        if (data.name === undefined || data.name === '') {
            setTimeout(() => {
                this.setState({ isLoading: true, })
                this.fetchOps();
                this.props.enqueueSnackbar('Client field is empty!', {
                    variant: 'error'
                });
            }, 200)

        } else {
            Service.put('/bb/api/clients/' + data.id, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: {
                    name: data.name,
                },
            })
                .then(res => {
                    this.props.enqueueSnackbar('Client updated successfully', {
                        variant: 'success'
                    });
                })
                .catch(error => {
                    this.setState({ isLoading: true, })
                    errorMessage(this.props, error)
                    this.fetchOps();
                });
        }
    }

    render() {
        return (
            <div>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                    <div>
                        <MaterialTable
                            key={3}
                            title="Clients"
                            columns={[
                                { title: 'Client ID', field: 'id', editable: 'never', },
                                { title: 'Client', field: 'name', },
                                { title: 'Client Name', field: 'client_name', },
                            ]}
                            data={this.state.data}
                            options={{
                                pageSize: 20,
                                pageSizeOptions: [10, 20, 50],
                                padding: "dense",
                                addRowPosition: "first",
                            }}
                            icons={{
                                Add: props => <Button size='small' color='primary' variant="contained" startIcon={<AddIcon />}>
                                    Add client
                                </Button>
                            }}
                            editable={
                                ((access && access.access && !access.access.l12a.l12a_b) && (access && access.access && access.access.l12a.l12a_c)) ? (
                                    {

                                        onRowUpdate: (newData, oldData) =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    resolve();
                                                    if (oldData) {
                                                        this.setState(prevState => {
                                                            const data = [...prevState.data];
                                                            data[data.indexOf(oldData)] = newData;
                                                            this.putOps(newData)
                                                            return { ...prevState, data };
                                                        });
                                                    }
                                                }, 600);
                                            }),
                                    }
                                ) : ((access && access.access && access.access.l12a.l12a_b) && (access && access.access && !access.access.l12a.l12a_c)) ? (
                                    {
                                        onRowAdd: newData =>
                                            new Promise(resolve => {

                                                setTimeout(() => {
                                                    resolve();
                                                    this.setState(prevState => {
                                                        const data = [...prevState.data];
                                                        newData.created_by = user.email;
                                                        this.postOps(newData);
                                                        data.push(newData);
                                                        // this.postData(newData)
                                                        return { ...prevState, data };
                                                    });
                                                }, 600);
                                            }),

                                    }
                                ) : ((access && access.access && access.access.l12a.l12a_b) && (access && access.access && access.access.l12a.l12a_c)) ? (
                                    {
                                        onRowAdd: newData =>
                                            new Promise(resolve => {

                                                setTimeout(() => {
                                                    resolve();
                                                    this.setState(prevState => {
                                                        const data = [...prevState.data];
                                                        newData.created_by = user.email;
                                                        this.postOps(newData);
                                                        data.push(newData);
                                                        // this.postData(newData)
                                                        return { ...prevState, data };
                                                    });
                                                }, 600);
                                            }),
                                        onRowUpdate: (newData, oldData) =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    resolve();
                                                    if (oldData) {
                                                        this.setState(prevState => {
                                                            const data = [...prevState.data];
                                                            data[data.indexOf(oldData)] = newData;
                                                            this.putOps(newData)
                                                            return { ...prevState, data };
                                                        });
                                                    }
                                                }, 600);
                                            }),
                                    }
                                ) : (null)}
                        />
                        <br />
                        {(user.office_loc === 1) ? (
                            <MaterialTable
                                key={3}
                                title="EDC Clients"
                                columns={[
                                    { title: 'Client ID', field: 'id', editable: 'never', },
                                    { title: 'Client', field: 'name', },
                                    { title: 'Client Name', field: 'client_name', },
                                ]}
                                data={this.state.data1}
                                options={{
                                    pageSize: 20,
                                    pageSizeOptions: [10, 20, 50],
                                    padding: "dense",
                                    addRowPosition: "first",
                                }}
                                icons={{
                                    Add: props => <Button size='small' color='primary' variant="contained" startIcon={<AddIcon />}>
                                        Add EDC Client
                                    </Button>
                                }}
                                editable={
                                    ((access && access.access && !access.access.l12a.l12a_b) && (access && access.access && access.access.l12a.l12a_c)) ? (
                                        {

                                            onRowUpdate: (newData, oldData) =>
                                                new Promise(resolve => {
                                                    setTimeout(() => {
                                                        resolve();
                                                        if (oldData) {
                                                            this.setState(prevState => {
                                                                const data1 = [...prevState.data1];
                                                                data1[data1.indexOf(oldData)] = newData;
                                                                this.putOps(newData)
                                                                return { ...prevState, data1 };
                                                            });
                                                        }
                                                    }, 600);
                                                }),
                                        }
                                    ) : ((access && access.access && access.access.l12a.l12a_b) && (access && access.access && !access.access.l12a.l12a_c)) ? (
                                        {
                                            onRowAdd: newData =>
                                                new Promise(resolve => {

                                                    setTimeout(() => {
                                                        resolve();
                                                        this.setState(prevState => {
                                                            const data1 = [...prevState.data1];
                                                            newData.created_by = user.email;
                                                            newData.client_section = 'edc'
                                                            this.postOps(newData);
                                                            data1.push(newData);
                                                            // this.postData(newData)
                                                            return { ...prevState, data1 };
                                                        });
                                                    }, 600);
                                                }),

                                        }
                                    ) : ((access && access.access && access.access.l12a.l12a_b) && (access && access.access && access.access.l12a.l12a_c)) ? (
                                        {
                                            onRowAdd: newData =>
                                                new Promise(resolve => {

                                                    setTimeout(() => {
                                                        resolve();
                                                        this.setState(prevState => {
                                                            const data1 = [...prevState.data1];
                                                            newData.created_by = user.email;
                                                            newData.client_section = 'edc'
                                                            this.postOps(newData);
                                                            data1.push(newData);
                                                            // this.postData(newData)
                                                            return { ...prevState, data1 };
                                                        });
                                                    }, 600);
                                                }),
                                            onRowUpdate: (newData, oldData) =>
                                                new Promise(resolve => {
                                                    setTimeout(() => {
                                                        resolve();
                                                        if (oldData) {
                                                            this.setState(prevState => {
                                                                const data1 = [...prevState.data1];
                                                                data1[data1.indexOf(oldData)] = newData;
                                                                this.putOps(newData)
                                                                return { ...prevState, data1 };
                                                            });
                                                        }
                                                    }, 600);
                                                }),
                                        }
                                    ) : (null)}
                            />
                        ) : (
                            null
                        )}

                    </div>
                )}
            </div>
        )
    }
}

export default withSnackbar(Clients);