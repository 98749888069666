import { Button } from '@material-ui/core';
import copy from 'copy-to-clipboard';
import MaterialTable from 'material-table';
import { withSnackbar } from 'notistack';
import React, { Component } from 'react'
import { onlyUniqueArr } from '../Helper/CommonThings';
import { getCookie } from '../Helper/CookieHelper';
import { errorMessage } from '../Helper/ErrorMessage';
import Service from "../Networking/networkutils";

const token = getCookie("bb_ts_token");
const access = JSON.parse(getCookie("bb_ts_access"));

class DesignTracker extends Component {

    constructor(props) {
        super()

        this.state = {
            isLoading: true,
            data: [],
            allEmEmails: "",
        }
    }

    componentDidMount() {
        Service.get(`/bb/api/tender/project/?audit_status=10&tender_notnull=1`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                var data = []
                var allEmEmails = []
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];

                    if ((element.tenderbid_data.lead_dicipline === 'ohl') || (element.tenderbid_data.lead_dicipline === 'cabling')
                        || (element.tenderbid_data.lead_dicipline === 'substation')
                        || (element.tenderbid_data.lead_dicipline === 'multi_utilities')
                        || (element.tenderbid_data.lead_dicipline === 'technical')) {
                        element.design_tracker = null
                        if (element.tenderbid_data.pdm_data) {
                            allEmEmails.push(element.tenderbid_data.pdm_data.email)
                        }
                        element.em_name = (element.tenderbid_data && element.tenderbid_data.pdm_data) ? (element.tenderbid_data.pdm_data.last_name + ", " + element.tenderbid_data.pdm_data.first_name) : (null)
                        data.push(element)

                    }
                }
                allEmEmails = allEmEmails.filter(onlyUniqueArr)
                this.setState({
                    allEmEmails: allEmEmails.join(";"),
                    data: data,

                }, () => {
                    this.fetchOps1()
                })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    fetchOps1() {
        Service.get(`/bb/api/tender/design/tracker/`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res1 => {
                for (let i = 0; i < this.state.data.length; i++) {
                    const element = this.state.data[i];
                    for (let j = 0; j < res1.data.length; j++) {
                        const element1 = res1.data[j];
                        if (element.id === element1.tender_id) {
                            element.design_tracker = element1
                            break;
                        }
                    }
                }

                this.setState({ ...this.state.data, isLoading: false })

            })
            .catch(error => {
                errorMessage(this.props, error)
                // this.fetchOps1(
            });
    }

    putOps(newData, oldData) {

        this.setState({
            isLoading: true
        })

        if (oldData.design_tracker && oldData.design_tracker.tender_id) {

            var putData = newData.design_tracker
            Service.put(`/bb/api/tender/design/tracker/` + putData.id + '/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: putData
            })
                .then(res => {
                    this.setState({ isLoading: false })
                    this.props.enqueueSnackbar('Data saved successfully!', {
                        variant: 'success'
                    });

                })
                .catch(error => {
                    errorMessage(this.props, error)
                    this.fetchOps1()
                });
        } else {
            var postData = newData.design_tracker
            postData.tender_id = oldData.id
            // postData.demp = oldData.id
            // console.log(postData)

            Service.post(`/bb/api/tender/design/tracker/`, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: postData
            })
                .then(res => {
                    this.fetchOps1()
                    // this.setState({isLoading: false})
                    this.props.enqueueSnackbar('Data saved successfully!', {
                        variant: 'success'
                    });

                })
                .catch(error => {
                    errorMessage(this.props, error)
                    this.fetchOps1()
                });
        }


    }


    render() {
        return (
            <div>
                <MaterialTable
                    title="Design Management Tracker"
                    columns={[
                        {
                            title: 'Lead Discipline', field: 'tenderbid_data.lead_dicipline',
                            headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 },
                            lookup: { 'ohl': 'OHL', 'cabling': 'Cabling', 'substation': 'Substation', 'multi_utilities': 'Multi Utilities', 'technical': 'Technical' }
                        },
                        {
                            title: 'Project', field: 'tender_name'
                        },
                        {
                            title: 'EM', field: 'em_name',
                            // render: rowData => (rowData.tenderbid_data && rowData.tenderbid_data.pdm_data) ? (rowData.tenderbid_data.pdm_data.last_name + ", " + rowData.tenderbid_data.pdm_data.first_name) : (null)
                        },
                        {
                            title: 'DEMP', field: 'design_tracker.demp',
                            lookup: { 1: 'Yes', 0: 'NA', 2: 'No' }
                        },

                        {
                            title: 'Monthly review in place', field: 'design_tracker.monthly_review',
                            lookup: { 1: 'Yes', 0: 'NA', 2: 'No' }
                        },
                        {
                            title: 'Visual Management', field: 'design_tracker.visual_management',
                            lookup: { 1: 'Yes', 0: 'NA', 2: 'No' }
                        },
                        {
                            title: 'Gate 5 checklist', field: 'design_tracker.gare5_checklist',
                            lookup: { 1: 'Yes', 0: 'NA', 2: 'No' }
                        },
                        {
                            title: 'AFC Handover', field: 'design_tracker.afc_handover',
                            lookup: { 1: 'Yes', 0: 'NA', 2: 'No' }
                        },

                    ]}

                    data={this.state.data}
                    isLoading={this.state.isLoading}

                    options={{
                        padding: "dense",
                        pageSize: 30,
                        pageSizeOptions: [30, 50, 100],
                        actionsColumnIndex: -1,
                        search: true,
                        filtering: true
                    }}
                    editable={{
                        isEditable: rowData => access.access && access.access.l17a && access.access.l17a.l17a_b,
                        onRowUpdate: (newData, oldData) =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    this.putOps(newData, oldData)
                                    this.setState(prevState => {
                                        const data = [...prevState.data];
                                        data[data.indexOf(oldData)] = newData;

                                        return { ...prevState, data };
                                    });

                                }, 1000);
                            }),
                    }}
                />
                <Button size='small' onClick={() => {
                    copy(this.state.allEmEmails)
                    this.props.enqueueSnackbar('Emails copied to clipboard!', {
                        variant: 'success'
                    });
                }}>Copy All EM Email ID's</Button>
            </div>
        )
    }
}

export default withSnackbar(DesignTracker);