import React, { Component } from 'react'
import { Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import LoopCircleLoading from 'react-loadingg/lib/LoopCircleLoading'
import MaterialTable from 'material-table';
import { withSnackbar } from 'notistack';
import Alert from '@material-ui/lab/Alert';
import Service from "../../Networking/networkutils";
import { getCookie } from '../../Helper/CookieHelper';
import ReactGA from 'react-ga';
import { errorMessage } from '../../Helper/ErrorMessage';
import { getPrimaryColor } from '../../Helper/CommonThings';

const token = getCookie("bb_ts_token");
const access = JSON.parse(getCookie("bb_ts_access"));
// const user = JSON.parse(getCookie("bb_ts_user"));
var autocompdata = '';

class WBSRoles extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: this.props.value.roles,
            rolesData: [],
            openAdd: false,
            isLoading: true,
            addHours: 0,
            addEmp: 0,
            area: 'in',
            roles: {},
            saved: true,
            columns: [
                { title: 'Role ID', field: 'id', editable: 'never', },
                { title: 'Role', field: 'name', editable: 'never', },
                {
                    title: 'Grade', field: 'grade',
                    lookup: { 'a': 'A', 'b': 'B', 'c': 'C', 'd': 'D', 'e': 'E', 'f': 'F', 'g': 'G' },
                },
                // { title: 'No of employees', field: 'no_off', type: 'numeric' },
                { title: 'Hours', field: 'hours', type: 'numeric' },
                {
                    title: 'Country', field: 'country',
                    lookup: { 'in': 'India', 'uk': 'UK', 'sl': "Srilanka" },
                },
            ],
        }
    }

    componentDidMount() {
        this.fetchOps();

    }

    fetchOps() {

        var pageView = window.location.pathname + "/#Deliverables Template";
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);

        Service.get('/bb/api/roles/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res1 => {
                this.setState({ rolesData: res1.data, isLoading: false });
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    onTagsChange = (event, values) => {
        // console.log(values)
        autocompdata = values;
    }

    addData() {

        var pageView = window.location.pathname + "/#Deliverables Template";
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);

        if (autocompdata !== '') {
            var data1 = this.state.data;
            data1.push({
                id: autocompdata.id,
                name: autocompdata.name,
                grade: autocompdata.grade,
                hours: this.state.addHours,
                country: this.state.area,
            });
            // console.log(data1)
            this.setState({ data: data1, isLoading: true, })
            setTimeout(function () { //Start the timer
                this.setState({
                    isLoading: false,
                    openAdd: false,
                    saved: false,
                })
                autocompdata = '';
            }.bind(this), 500)
        } else {
            this.props.enqueueSnackbar('Select Role to Add', {
                variant: 'warning'
            });
        }

    }

    render() {
        const handleClose = (e) => {
            this.setState({ openAdd: false })
        };
        const handleAddHours = (e) => {
            this.setState({ addHours: e.target.value })
        };

        return (
            <div>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                    <div>
                        {(this.state.saved) ? (
                            null
                        ) : (
                            <Box display="flex" flexDirection="row-reverse">
                                <Alert style={{ maxWidth: '300px' }} severity="info" >Data is not saved. Click save button below.</Alert>
                            </Box>

                        )}
                        <Box p={3}>
                            <MaterialTable
                                key={3}
                                title="Roles"
                                columns={[
                                    { title: 'Role ID', field: 'id', editable: 'never', },
                                    { title: 'Role', field: 'name', editable: 'never', },
                                    {
                                        title: 'Grade', field: 'grade',
                                        lookup: { 'a': 'A', 'b': 'B', 'c': 'C', 'd': 'D', 'e': 'E', 'f': 'F', 'g': 'G' },
                                    },
                                    // { title: 'No of employees', field: 'no_off', type: 'numeric' },
                                    { title: 'Hours', field: 'hours', type: 'numeric' },
                                    {
                                        title: 'Country', field: 'country',
                                        lookup: { 'in': 'India', 'uk': 'UK', 'sl': 'Srilanka' },
                                    },
                                ]}
                                data={this.state.data}
                                options={{
                                    padding: "dense",
                                    pageSize: 20,
                                    pageSizeOptions: [10, 20, 50],
                                    actionsColumnIndex: -1
                                }}
                                actions={((access && access.access && !access.access.l9a.l9a_b) && (access && access.access && access.access.l9a.l9a_c)) ? ([
                                    {
                                        icon: 'save',
                                        tooltip: 'Save Data',
                                        isFreeAction: true,
                                        disabled: this.state.save,
                                        onClick: () => this.props.onSaveReq(this.state.data),
                                    },

                                ]) : ((access && access.access && access.access.l9a.l9a_b) && (access && access.access && !access.access.l9a.l9a_c)) ? (
                                    [
                                        {
                                            icon: 'save',
                                            tooltip: 'Save Data',
                                            isFreeAction: true,
                                            disabled: this.state.save,
                                            onClick: () => this.props.onSaveReq(this.state.data),
                                        },
                                        {
                                            icon: 'add_box',
                                            tooltip: 'Add Role',
                                            isFreeAction: true,
                                            disabled: this.state.save,
                                            onClick: () => this.setState({ openAdd: true }),
                                        },
                                    ]
                                ) : ((access && access.access && access.access.l9a.l9a_b) && (access && access.access && access.access.l9a.l9a_c)) ? (
                                    [
                                        {
                                            icon: 'save',
                                            tooltip: 'Save Data',
                                            isFreeAction: true,
                                            disabled: this.state.save,
                                            onClick: () => this.props.onSaveReq(this.state.data),
                                        },
                                        {
                                            icon: 'add_box',
                                            tooltip: 'Add Role',
                                            isFreeAction: true,
                                            disabled: this.state.save,
                                            onClick: () => this.setState({ openAdd: true }),
                                        },
                                    ]
                                ) : (null)}
                                editable={(access && access.access.l9a.l9a_c) ? (
                                    {
                                        onRowUpdate: (newData, oldData) =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    resolve();
                                                    if (oldData) {
                                                        this.setState({ saved: false, })
                                                        this.setState(prevState => {
                                                            const data = [...prevState.data];
                                                            data[data.indexOf(oldData)] = newData;
                                                            return { ...prevState, data };
                                                        });
                                                    }
                                                }, 600);
                                            }),
                                        onRowDelete: oldData =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    resolve();
                                                    this.setState({ saved: false });
                                                    this.setState(prevState => {
                                                        const data = [...prevState.data];
                                                        data.splice(data.indexOf(oldData), 1);
                                                        // this.deleteData(oldData)
                                                        return { ...prevState, data };
                                                    });
                                                }, 600);
                                            }),
                                    }
                                ) : (null)}
                            />
                        </Box>
                        <Dialog open={this.state.openAdd} fullWidth onClose={handleClose} aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">Add Roles</DialogTitle>
                            <DialogContent>
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={this.state.rolesData}
                                    getOptionLabel={(option) => option.name}
                                    onChange={this.onTagsChange}
                                    style={{ marginTop: '15px' }}
                                    renderInput={(params) => <TextField {...params} label="Select Role" variant="outlined" />}
                                />
                                <TextField id="outlined-basic22" label="Hours" variant="outlined"
                                    type="numeric" value={this.state.addHours} fullWidth style={{ marginTop: '15px' }}
                                    onChange={handleAddHours} />
                                <FormControl variant="outlined" style={{ marginTop: '15px' }}>
                                    <InputLabel id="demo-simple-select-outlined-label">Country</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={this.state.area}
                                        onChange={e => { this.setState({ area: e.target.value }) }}
                                        label="Country"
                                    >
                                        <MenuItem value="in">India</MenuItem>
                                        <MenuItem value="uk">UK</MenuItem>
                                        <MenuItem value="sl">Srilanka</MenuItem>
                                    </Select>
                                </FormControl>
                            </DialogContent>
                            <DialogActions>
                                <Button size='small' onClick={handleClose} color="primary">
                                    Cancel
                                </Button>
                                <Button size='small' onClick={e => { this.addData() }} color="primary">
                                    Add Role
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                )}
            </div>

        )
    }
}

export default withSnackbar(WBSRoles)