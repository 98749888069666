import { AppBar, Box, Button, Dialog, DialogContent, DialogTitle, Typography, FormControl, InputLabel, MenuItem, Select, DialogActions, TextField } from '@material-ui/core';
import React, { Component } from 'react';
import { LoopCircleLoading } from 'react-loadingg';
import { getPrimaryColor } from '../../Helper/CommonThings';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/styles';
import { withSnackbar } from 'notistack';
import { getCookie } from '../../Helper/CookieHelper';
import Service from '../../Networking/networkutils';
import { errorMessage } from '../../Helper/ErrorMessage';
import TenderRateByYear from './TenderRateByYear';
import { BootstrapDialog, BootstrapDialogTitle } from '../../Helper/DialogTitleM5';
import RateCardTemplateTab from '../Template/RateCardTemplateTab';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = (theme) => ({
    root: {
        flexGrow: 1,
    },
});

const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));

class AddTenderRate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            isLoading: true,
            open: false,
            value: 0,
            availableYears: [],
            selectedYears: 0,
            tenderRateName: '',
            description: '',
            openDefaultRateCard: false
        }
    }

    componentDidMount() {
        this.fetchData()
        Service.get(`/bb/api/tender/ratecard/template/rate_card_year_available/`, {
            headers: {
                Authorization: "Token " + token,
            }
        })
            .then(res => {
                this.setState({
                    availableYears: res.data
                })
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    fetchData() {
        Service.get(`/bb/api/tender/ratecard/template/?tender=${this.props.tender.id}`, {
            headers: {
                Authorization: "Token " + token,
            }
        })
            .then(res => {
                // var start_year = parseInt(moment().subtract(2, 'year').format("YYYY"))
                // var end_year = parseInt(moment().add(2, 'year').format("YYYY"))
                // var availableYears = []
                // for (let i = start_year; i <= end_year; i++) {
                //     const element = i;
                //     if (element) {
                //         availableYears.push(element)
                //     }
                // }
                this.setState({
                    data: res.data.sort((a, b) => a.year - b.year),
                    isLoading: false,
                    // availableYears: availableYears
                })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    addTenderYear() {
        var fields = ''
        if (!this.state.tenderRateName) {
            fields += 'Name'
        }
        if (!this.state.description) {
            fields += !fields ? 'Description' : ', description'
        }
        if (!this.state.selectedYears) {
            fields += !fields ? 'Year' : ", year"
        }

        if (!fields) {
            Service.post(`/bb/api/tender/ratecard/template/`, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: {
                    tender: this.props.tender.id,
                    name: this.state.tenderRateName,
                    year: this.state.selectedYears,
                    created_by: user.id,
                    description: this.state.description
                }
            })
                .then(res => {
                    this.addRate(res.data)
                    this.setState({ open: false, selectedYears: 0, tenderRateName: '' })
                    this.props.enqueueSnackbar('Data added successfully!', {
                        variant: 'success'
                    });
                    this.fetchData()
                })
                .catch(error => {
                    errorMessage(this.props, error)
                });
        } else {
            this.props.enqueueSnackbar(fields + " is Empty!", {
                variant: 'warning'
            });
        }
    }

    addRate(res) {
        Service.get(`/bb/api/tender/ratecard/template/tender_rate_creation/?ratecard_id=${res.id}`, {
            headers: {
                Authorization: "Token " + token,
            }
        })
            .then(res => {
                // this.setState({
                //     // data: res.data,
                //     // isLoading: false,
                // })
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    render() {

        const { classes } = this.props;

        const handleChange = (event, newValue) => {
            this.setState({
                value: newValue
            })
        };

        const handleCloseDeafultRateCard = () => {
            this.setState({
                openDefaultRateCard: false
            })
        };

        const handleClose = () => {
            this.setState({
                open: false,
                selectedYears: 0,
                tenderRateName: '',
            })
        };

        const handleSelectChange = (event) => {
            this.setState({
                selectedYears: event.target.value
            })

        };

        return (
            <div>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                    <div>
                        <Box display="flex" flexDirection="row-reverse" p={1} m={1} >

                            <Button size='small' style={{ marginLeft: '10px' }} variant="outlined" color="primary" onClick={() => {
                                this.setState({
                                    openDefaultRateCard: true
                                })
                            }}>Show Default Rate Card</Button>

                            <Button size='small' style={{ marginLeft: '10px' }} variant="outlined" color="primary" onClick={() => {
                                this.setState({
                                    open: true
                                })
                            }}>Add Custom Rate Card</Button>

                        </Box>
                        {this.state.data.length !== 0 ?
                            <AppBar position="static" color="default">
                                <Tabs
                                    value={this.state.value}
                                    onChange={handleChange}
                                    aria-label="simple tabs example"
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="scrollable"
                                    scrollButtons="auto"
                                >
                                    {this.state.data.map((value, index) =>
                                        <Tab label={this.state.data[index].name + " - " + this.state.data[index].year} {...a11yProps(index)} />
                                    )}

                                </Tabs>
                            </AppBar> : null}
                        {this.state.data.map((value, index) =>
                            <TabPanel value={this.state.value} index={index}>
                                <TenderRateByYear value={this.state.data[index]} />
                            </TabPanel>
                        )}
                    </div>
                )}
                <Dialog fullWidth={true} maxWidth={'xs'} open={this.state.open} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title"> Select the year & name of rate card </DialogTitle>
                    <DialogContent>
                        {/* <DialogContentText>
                            Select the year of rate card template to import
                        </DialogContentText> */}
                        <Box display={'flex'} flexDirection='column' justifyContent={'center'}>
                            <Box marginBottom={'10px'}>
                                <TextField
                                    autoFocus
                                    id="name"
                                    label="Name"
                                    type="name"
                                    onChange={e => { this.setState({ tenderRateName: e.target.value }) }}
                                    fullWidth
                                    variant='outlined'
                                />
                            </Box>
                            <Box marginBottom={'10px'}>
                                <TextField
                                    id="description"
                                    label="Description"
                                    type="name"
                                    onChange={e => { this.setState({ description: e.target.value }) }}
                                    fullWidth
                                    variant='outlined'
                                />
                            </Box>
                            <FormControl className={classes.formControl}>
                                <InputLabel id="demo-simple-select-helper-label">Select Import Year</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={this.state.selectedYears}
                                    onChange={handleSelectChange}
                                >
                                    {this.state.availableYears.map((value, index) =>
                                        <MenuItem value={value}>{value}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Box>

                    </DialogContent>
                    <DialogActions>
                        <Button size='small' onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button size='small' onClick={() => this.addTenderYear()} color="primary">
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>


                <BootstrapDialog fullWidth={true} maxWidth={'lg'} open={this.state.openDefaultRateCard} onClose={handleCloseDeafultRateCard} aria-labelledby="form-dialog-title">
                    <BootstrapDialogTitle id="form-dialog-title"> Select the year & name of rate card </BootstrapDialogTitle>
                    <DialogContent>
                       <RateCardTemplateTab editable={false} />
                    </DialogContent>
                </BootstrapDialog>
            </div>
        );
    }
}

export default withStyles(useStyles)(withSnackbar(AddTenderRate));
