import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { CsvBuilder } from 'filefy';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles, Box, CircularProgress, Toolbar, Tooltip } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { getDarkMode } from '../../../Helper/CommonThings';
import { withSnackbar } from 'notistack';
import TimesheetTemplateRow2 from './TimesheetTemplateRow2';

import moment from 'moment';

const bbColorTheme = createTheme({
    palette: {
        primary: {
            main: '#00b300',
            contrastText: '#fff',
        },
        secondary: {
            main: '#cca300',
            contrastText: '#fff',
        },
        error: {
            main: "#ff0000",
            contrastText: '#fff',
        },
        type: getDarkMode(),
    },
})

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
}))(TableRow);
class TimesheetTemplate2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props.data,
            rowLoading: true,
            dateColumns: [],
            columns: [],
            detailPanelColumns: [],
        }
        this.handleSelect = this.handleSelect.bind(this)
        this.handleIndivSelect = this.handleIndivSelect.bind(this)
        this.handleOpen = this.handleOpen.bind(this)
    }

    componentDidMount() {
        this.setState({ rowLoading: false })
        let dateColumns = []
        var start = this.state.data.from_date
        var end = this.state.data.to_date

        while (moment(start).isSameOrBefore(end)) {
            dateColumns.push({
                title: moment(start).format('DD MMM (ddd)'),
                field: moment(start).format('DD/MM/YYYY'),
            })
            start = moment(start).add(1, 'days')
        }

        var columns = [
            {
                title: 'Projects', field: 'ttender_name', headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 },
                render: rowData => (rowData.ttender_name ? rowData.ttender_name : rowData.tname)
            },
        ];

        var detailPanelColumns = [
            {
                title: 'DS', field: 'tproj_name', headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 },
            },
            {
                title: 'Tasks', field: 'tname', headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 },
                render: rowData => (rowData.tname ? rowData.tname : rowData.ttdecs ? rowData.ttdecs : rowData.ttname)
            },
            {
                title: 'Status', field: 'task_hold',
                render: rowData => (!rowData.task_hold && rowData.task_hold !== 0) ? "-" : rowData.task_hold ? <h4 style={{ color: 'red' }}>On Hold</h4> : tenderTaskStatus(rowData.ttstatus)
            },
            { title: 'Comment', field: 'comments', headerStyle: { minWidth: 325 }, cellStyle: { minWidth: 325 }, },
            { title: 'Hours Expended', field: 'actual_hours' },
            {
                title: 'Approval Status', field: 'timesheet_approval_status',
                render: rowData => this.getStatus(rowData)
            }
        ]

        columns = columns.concat(dateColumns)
        columns.push({ title: 'Total', field: 'total' })
        detailPanelColumns = detailPanelColumns.concat(dateColumns)

        this.setState({ dateColumns: dateColumns, columns: columns, detailPanelColumns: detailPanelColumns })

    }

    getTotal(data) {
        var total = 0
        for (let i = 0; i < data.modifiedData.length; i++) {
            const element = data.modifiedData[i];
            if (element.total) {
                total += element.total
            }
        }
        return total
    }

    handleSelect(e, index) {
        if (this.props.handleSelect) {
            this.props.handleSelect(e, index)
        }
    }

    handleIndivSelect(e, index, panelIndex) {
        if (this.props.handleIndivSelect) {
            this.props.handleIndivSelect(e, index, panelIndex)
        }
    }

    handleOpen(i) {
        if (this.props.handleOpen) {
            this.props.handleOpen(i)
        }
    }

    getStatus(rowData) {
        if (rowData.timesheet_approval_status === 0) {
            return 'Pending'
        } else if (rowData.timesheet_approval_status === 1) {
            return 'Approved'
        } else if (rowData.timesheet_approval_status === 2) {
            return 'Rejected'
        } else if (rowData.timesheet_approval_status === 7) {
            return 'Deleted'
        } else {
            return ''
        }
    }

    enableCheckBoxColumn() {
        let data = this.state.data
        let go = false
        for (let i = 0; i < data.modifiedData.length; i++) {
            const element = data.modifiedData[i];
            if (element.checkbox_enable) {
                go = true
                break
            }
        }
        if ([0, 1, 3, 4, 5].includes(this.state.data.approve_status)) {
            return true
        } else if (go) {
            return true
        } else {
            return false
        }
    }

    render() {

        const exportCsv = (columnList, initialData) => {
            const columns = columnList.filter(columnDef => {
                return !columnDef.hidden && columnDef.field && columnDef.export !== false;
            });

            const data = initialData.map(rowData =>
                columns.map(columnDef => {
                    // return columnDef.lookup ? columnDef.lookup[rowData[columnDef.field]] : rowData[columnDef.field];
                    return columnDef.render ? columnDef.render(rowData) : rowData[columnDef.field]
                })
            );

            const builder = new CsvBuilder("Timesheet booked by " + (this.state.data ? this.state.data.uname : "") + " for week [" + (this.state.data ? (this.state.data.from_date + " - " + this.state.data.to_date) : "") + '].csv');
            builder
                .setDelimeter(',')
                .setColumns(columns.map(columnDef => columnDef.title))
                .addRows(data)
                .exportFile();
        };
        return (
            <div>
                <ThemeProvider theme={bbColorTheme} >
                    <Toolbar>
                        <Box display="flex" flexDirection="row" width={"100%"}>
                            <Box flexGrow={1}>
                                <Typography variant="h6" id="tableTitle" component="div">
                                    {"Timesheet booked by " + (this.state.data?.uname) + " for week [" + moment(this.state.data?.from_date).format("DD/MM/YYYY") + " - " + moment(this.state.data?.to_date).format("DD/MM/YYYY") + "]"}
                                </Typography>
                            </Box>
                            <Tooltip title={"Export as CSV"}>
                                <IconButton size='small' onClick={() => {
                                    let detailPanelColumns = this.state.detailPanelColumns
                                    detailPanelColumns.splice(0, 1,
                                        {
                                            title: 'Projects', field: 'ttender_name',
                                            render: rowData => (rowData.ttender_name ? rowData.ttender_name : rowData.tname)
                                        })
                                    let data = this.state.data
                                    let exportData = []
                                    for (let i = 0; i < data.modifiedData.length; i++) {
                                        const element = data.modifiedData[i];
                                        for (let j = 0; j < element.detailPanel.length; j++) {
                                            const element2 = element.detailPanel[j];
                                            exportData.push(element2)
                                        }
                                    }
                                    exportCsv(detailPanelColumns, exportData)
                                }}>
                                    <SaveAltIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Toolbar>

                    <TableContainer component={Paper}>
                        <Table aria-label="customized table">
                            <TableHead>
                                <TableRow >
                                    <StyledTableCell align="center"></StyledTableCell>
                                    {this.enableCheckBoxColumn() ? <StyledTableCell align="center"> </StyledTableCell> : null}
                                    {this.state.columns.map((row) => (
                                        <StyledTableCell key={row.title} align={row.title === 'Projects' ? "left" : "center"}>{row.title}</StyledTableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.data?.modifiedData.map((row, i) =>
                                    this.state.rowLoading ? <CircularProgress key={i} /> :
                                        <TimesheetTemplateRow2
                                            data={this.state.data}
                                            dateColumns={this.state.dateColumns}
                                            columns={this.state.columns}
                                            detailPanelColumns={this.state.detailPanelColumns}
                                            index={i}
                                            approve_status={this.state.data.approve_status}
                                            key={i}
                                            row={row}
                                            handleSelect={this.handleSelect}
                                            handleIndivSelect={this.handleIndivSelect}
                                            open={row.is_open}
                                            handleOpen={this.handleOpen}
                                        />)}
                                <StyledTableRow >
                                    <StyledTableCell align="center"></StyledTableCell>
                                    {this.enableCheckBoxColumn() ?
                                        <StyledTableCell align="left">
                                        </StyledTableCell> : null}
                                    {
                                        this.state.columns.map((column, i) => (
                                            <StyledTableCell key={i} align={'center'}>
                                                {column.title === 'Total' ? (this.getTotal(this.state.data)) : null}
                                            </StyledTableCell>
                                        ))
                                    }
                                </StyledTableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </ThemeProvider>
            </div>
        );
    }
}

export default withSnackbar(TimesheetTemplate2);

export function tenderTaskStatus(code) {
    var status = {
        0: 'Not Started', 5: 'Preparation of documents/drawings', 10: 'Preparation of documents/drawings', 20: 'Preparation of documents/drawings',
        30: 'Preparation of documents/drawings', 40: 'Preparation of documents/drawings', 50: 'Preparation of documents/drawings',
        60: 'Checked within Department', 65: 'Approved within Department', 70: 'Site comments received, reviewed',
        80: 'Multi-disciplinary Reviewed & Commented', 90: 'Design verified.', 100: 'Design assured'
    }
    return status[code]
}