import { Box, Button, Checkbox, FormControl, InputLabel, MenuItem, Select, Tab, Tabs, TextField, FormControlLabel, } from '@material-ui/core'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import moment from 'moment'
import { withSnackbar } from 'notistack'
import React, { Component } from 'react'
import DateFnsUtils from '@date-io/date-fns';
import Service from "../../Networking/networkutils";
import { getCookie } from '../../Helper/CookieHelper';
import { Autocomplete } from '@material-ui/lab'
import { errorMessage } from '../../Helper/ErrorMessage'
import { LoopCircleLoading } from 'react-loadingg'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { getPrimaryColor } from '../../Helper/CommonThings'
import { CsvBuilder } from 'filefy'
import { json2excel } from 'js2excel';
import { screenSizeCheck } from '../../MainDashBoard/ScreensizeCheck/ScreensizeCheck'
import { getTimesheetReportColumns } from '../../Helper/TimesheetReportColumns'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));

// const columns = [
//     {
//         title: 'First Name',
//         field: 'uid__first_name',
//         // render: rowData => rowData
//     },
//     {
//         title: 'Last Name',
//         field: 'uid__last_name',
//         // render: rowData => rowData
//     },
//     {
//         title: 'Emp. Code',
//         field: 'uid__user_details',
//         render: rowData => rowData.uid__user_details__emp_code
//     },
//     {
//         title: 'Emp. R12',
//         field: 'uid__user_details',
//         render: rowData => rowData.uid__user_details__r12_emp_code
//     },
//     {
//         title: 'Emp. R12 Classification',
//         field: 'uid__user_details',
//         render: rowData => rowData.uid__user_details__r12_class_code
//     },
//     {
//         title: 'EDC/UK/SL',
//         field: 'current_role_loc',
//         render: rowData => rowData.current_role_loc === 1 ? "EDC" : rowData.current_role_loc === 2 ? "SL" : "UK"
//     },
//     {
//         title: 'Role',
//         field: 'current_role_name',
//         render: rowData => rowData.current_role_name
//     },
//     {
//         title: 'Grade',
//         field: 'current_role_grade',
//         render: rowData => rowData.current_role_grade ? rowData.current_role_grade.toUpperCase() : rowData.current_role_grade
//     },
//     {
//         title: 'Item ID',
//         field: 'id',
//         // render: rowData => rowData.uid__role_id__grade
//     },
//     {
//         title: 'Date',
//         field: 'start',
//         render: rowData => moment(rowData.start).format('DD/MM/YYYY')
//     },
//     {
//         title: 'Team',
//         field: 'gid__name',
//         // render: rowData => moment(rowData.start).format('DD/MM/YYYY')
//     },
//     {
//         title: 'Project Name',
//         field: 'ttenderid__from_tenderbid',
//         render: rowData => rowData.ttenderid__from_tenderbid__opportunity ? rowData.ttenderid__from_tenderbid__opportunity : 'Non Billable'
//     },
//     {
//         title: 'Client',
//         field: 'ttenderid__from_tenderbid',
//         render: rowData => rowData.ttenderid__from_tenderbid__client__name ? rowData.ttenderid__from_tenderbid__client__name : 'Non Billable'
//     },
//     {
//         title: 'Discipline',
//         field: 'ttenderid__from_tenderbid',
//         render: rowData => rowData.ttenderid__from_tenderbid__lead_dicipline ? getDiscipline()[rowData.ttenderid__from_tenderbid__lead_dicipline] : "Non Billable"
//     },
//     {
//         title: 'DS Name',
//         field: 'tpid__name',
//         render: rowData => rowData.tpid__name ? rowData.tpid__name : (rowData.tid__task_category__name ? rowData.tid__task_category__name : "Non Billable")
//     },
//     {
//         title: 'WBS Name',
//         field: 'twbsid__name',
//         render: rowData => rowData.twbsid__name ? rowData.twbsid__name : ''
//     },
//     {
//         title: 'Task ID',
//         field: 'ttid_id',
//         render: rowData => rowData.ttid_id ? rowData.ttid_id : rowData.tid_id
//     },
//     {
//         title: 'Task Name',
//         field: 'ttid__decs',
//         render: rowData => rowData.ttid__decs ? rowData.ttid__decs : rowData.ttid__name ? rowData.ttid__name : rowData.tid__name
//     },
//     {
//         title: '% Complete',
//         field: 'ttid__comp_percnt',
//         render: rowData => (rowData.ttid__comp_percnt || rowData.ttid__comp_percnt === 0) ? rowData.ttid__comp_percnt : 'NA'
//     },
//     {
//         title: 'Billable/Non Billable',
//         field: 'ttid__inc_evp',
//         render: rowData => rowData.ttid__inc_evp === 0 ? "Non Billable" : rowData.ttid__inc_evp === 1 ? "Billable" : "Non Billable"
//     },
//     // {
//     //     title: 'Type',
//     //     field: 'ttid_id',
//     //     render: rowData => rowData.ttid_id ? 'Available Workbook' : 'Non Billable'
//     // },
//     {
//         title: 'Hours',
//         field: 'hours',
//         render: rowData => (parseFloat(rowData.hours) / 3600).toFixed(2)
//     },
//     // {
//     //     title: 'Rate',
//     //     field: 'ts_rate',
//     //     render: rowData => rowData.ts_rate ? rowData.ts_rate : "NA"
//     // },
//     // {
//     //     title: 'Amount',
//     //     field: 'ts_cost',
//     //     render: rowData => rowData.ts_cost ? rowData.ts_cost : "NA"
//     // },
//     {
//         title: 'Comments',
//         field: 'comments',
//         // render: rowData => moment(rowData.start).format('DD/MM/YYYY')
//     },
//     {
//         title: 'Status',
//         field: 'is_approved',
//         render: rowData => rowData.is_approved === 0 ? (rowData.is_submitted === 1 ? 'Submitted' : 'InProgress') : rowData.is_approved === 1 ? "Approved" : rowData.is_approved === 2 ? 'Rejected' : null
//     },
//     {
//         title: 'BL Start',
//         field: 'ttid__from_date',
//         render: rowData => moment(rowData.ttid__from_date).format('DD/MM/YYYY')
//     },
//     {
//         title: 'BL Finish',
//         field: 'ttid__to_date',
//         render: rowData => moment(rowData.ttid__to_date).format('DD/MM/YYYY')
//     },
//     {
//         title: 'Act Start',
//         field: 'ttid__act_exp_start',
//         render: rowData => moment(rowData.ttid__act_exp_start).format('DD/MM/YYYY')
//     },
//     {
//         title: 'Act Finish',
//         field: 'ttid__act_exp_finish',
//         render: rowData => moment(rowData.ttid__act_exp_finish).format('DD/MM/YYYY')
//     },
// ]

class UserTimesheetReport extends Component {

    constructor(props) {
        super(props)

        this.state = {
            startDate: moment().subtract(10, 'days'),
            endDate: moment(),
            users: [],
            selectedUser: [],
            isLoading: false,
            selectedTeams: [],
            selectedProject: [],
            projects: [],
            teams: [],
            selectCountry: null,
            withCost: 0,
            clients: [],
            selectedClient: [],
            withOverheads: false
        }
    }

    componentDidMount() {
        this.getClients()
        if (this.props.teamUsers) {
            Service.get2(`/bb/api/user/report/view/team_members/?uid=` + user.id, {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {

                    this.setState({
                        users: [...[{
                            id: 0,
                            last_name: 'SELECT ALL',
                            first_name: ""
                        }], ...res.data]
                    })
                })
                .catch(error => {
                    errorMessage(this.props, error)
                });
        } else {
            Service.get2(`/bb/api/users/`, {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    this.setState({
                        users: [...[{
                            id: 0,
                            last_name: 'SELECT ALL',
                            first_name: ""
                        }], ...res.data]
                    })
                })
                .catch(error => {
                    errorMessage(this.props, error)
                });
        }

        if (this.props.allFilter) {
            this.setState({
                isLoading: true
            })
            Service.get2("/bb/api/teams/", {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    this.setState({
                        teams: res.data
                    })
                })
                .catch(error => {
                    errorMessage(this.props, error)
                });

            Service.get2(`/bb/api/tender/project/?tender_notnull=1`, {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {

                    // console.log(res.data)

                    var data1 = []

                    for (let i = 0; i < res.data.length; i++) {
                        const element = res.data[i];
                        if (element.tenderbid_data.lead_dicipline && element.tenderbid_data.lead_dicipline !== 'trail') {
                            data1.push(element)
                        }
                    }

                    // console.log(data1)

                    this.setState({
                        projects: data1,
                        isLoading: false
                    })
                })
                .catch(error => {
                    console.log(error)
                    errorMessage(this.props, error)
                });
        }

    }

    getClients() {
        Service.get2(`/bb/api/clients/`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({
                    clients: [...[{
                        id: 0,
                        name: 'SELECT ALL',
                    }], ...res.data]
                })
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    getReport() {
        var url = `/bb/api/task/approve/timesheet_report/?start=${this.state.startDate.format('DD-MM-YYYY')}&end=${this.state.endDate.format('DD-MM-YYYY')}`
        if (this.props.tender) {
            url += '&tender=' + this.props.tender
            if (this.props.project_all) {
                url += "&filter_type=all"
            } else {
                url += "&filter_type=limited"
            }
        }

        if (this.props.ds) {
            url += '&ds=' + this.props.ds
            if (this.props.project_all) {
                url += "&filter_type=all"
            } else {
                url += "&filter_type=limited"
            }
        }

        if (this.props.userEnabled && this.state.selectedUser.length) {
            url += '&uid=0'
            for (let i = 0; i < this.state.selectedUser.length; i++) {
                const element = this.state.selectedUser[i];
                url += ',' + element.id
            }
        }

        if (this.props.allFilter && this.state.selectedProject.length) {
            url += '&tender=0'
            for (let i = 0; i < this.state.selectedProject.length; i++) {
                const element = this.state.selectedProject[i];
                url += ',' + element.id
            }
        }
        if (this.props.allFilter && this.state.selectedClient.length) {
            url += '&client=0'
            for (let i = 0; i < this.state.selectedClient.length; i++) {
                const element = this.state.selectedClient[i];
                url += ',' + element.id
            }
        }

        if (this.props.allFilter && this.state.selectedTeams.length) {
            url += '&team=0'
            for (let i = 0; i < this.state.selectedTeams.length; i++) {
                const element = this.state.selectedTeams[i];
                url += ',' + element.id
            }
        }

        if (this.props.allFilter && this.state.selectCountry) {
            url += '&loc=' + this.state.selectCountry
        }

        if (this.props.allFilter) {
            url += "&filter_type=all"
        }

        if (this.state.withCost) {
            url += "&rate_and_cost=1"
        }

        if (this.state.withOverheads) {
            url += "&overheads=1"
        } else {
            url += "&overheads=0"
        }

        this.setState({
            isLoading: true,
            selectedUser: [],
            selectedTeams: [],
            selectedProject: [],
            selectCountry: null
        })
        Service.get2(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                if (res.status === 204) {
                    alert("There is no record for the selected date range.")
                    this.setState({
                        isLoading: false
                    })
                } else {
                    var data = getTimesheetReportColumns()
                    if (this.state.withCost) {
                        if (data[23].title !== "Rate") {
                            data.splice(23, 0, {
                                title: 'Rate',
                                field: 'ts_rate',
                                render: rowData => rowData.ts_rate ? rowData.ts_rate : "NA"
                            },
                                {
                                    title: 'Amount',
                                    field: 'ts_cost',
                                    render: rowData => rowData.ts_cost ? rowData.ts_cost : "NA"
                                },)
                        }
                    } else {
                        if (data[23].title === "Rate") {
                            data.splice(23, 2)
                        }
                    }
                    this.exportCsv(data, res.data)
                    // this.setState({ withCost: false })
                }

            })
            .catch(error => {
                if (error.status === 406) {
                    alert("You are not authorized for the report.")
                } else {
                    this.setState({
                        isLoading: false
                    })
                    errorMessage(this.props, error)
                }
            });
    }

    onTeamSelect = (event, values) => {
        this.setState({ selectedTeams: values });
    }

    onProjectSelect = (event, values) => {
        this.setState({ selectedProject: values });
    }

    onClientSelect = (event, values) => {
        if (values.length && values[0].id === 0) {
            this.setState({ selectedClient: this.state.clients });
        } else {
            this.setState({ selectedClient: values });
        }
        // this.setState({ selectedClient: values });
    }

    exportCsv = (columnList, initialData) => {
        const columns = columnList.filter(columnDef => {
            return !columnDef.hidden && columnDef.field && columnDef.export !== false;
        });

        const data = initialData.map(rowData =>
            columns.map(columnDef => {
                return columnDef.render ? columnDef.render(rowData) : rowData[columnDef.field];
            })
        );

        const builder = new CsvBuilder("timesheet_report_" + moment().format('DD-MM-YYYY hh:mm') + '.csv');
        builder
            .setDelimeter(',')
            .setColumns(columns.map(columnDef => columnDef.title))
            .addRows(data)
            .exportFile();
        this.setState({
            isLoading: false
        })
    };

    getRoleChange() {
        var url = `/bb/api/task/approve/user_role_change_data/?start=${this.state.startDate.format('DD-MM-YYYY')}&end=${this.state.endDate.format('DD-MM-YYYY')}`
        if (this.props.tender) {
            url += '&tender=' + this.props.tender
            if (this.props.project_all) {
                url += "&filter_type=all"
            } else {
                url += "&filter_type=limited"
            }
        }
        if (this.props.ds) {
            url += '&ds=' + this.props.ds
            if (this.props.project_all) {
                url += "&filter_type=all"
            } else {
                url += "&filter_type=limited"
            }
        }
        if (this.props.userEnabled && this.state.selectedUser.length) {
            url += '&uid=0'
            for (let i = 0; i < this.state.selectedUser.length; i++) {
                const element = this.state.selectedUser[i];
                url += ',' + element.id
            }
        }
        if (this.props.allFilter && this.state.selectedProject.length) {
            url += '&tender=0'
            for (let i = 0; i < this.state.selectedProject.length; i++) {
                const element = this.state.selectedProject[i];
                url += ',' + element.id
            }
        }
        if (this.props.allFilter && this.state.selectedTeams.length) {
            url += '&team=0'
            for (let i = 0; i < this.state.selectedTeams.length; i++) {
                const element = this.state.selectedTeams[i];
                url += ',' + element.id
            }
        }
        if (this.props.allFilter && this.state.selectCountry) {
            url += '&loc=' + this.state.selectCountry
        }
        if (this.props.allFilter) {
            url += "&filter_type=all"
        }
        this.setState({
            isLoading: true,
            selectedUser: [],
            selectedTeams: [],
            selectedProject: [],
            selectCountry: null,
        })
        Service.get2(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                if (res.status === 204) {
                    alert("There is no record for the selected date range.")
                    this.setState({
                        isLoading: false
                    })
                } else {
                    let down = [];

                    for (let i = 0; i < res.data.length; i++) {
                        let down2 = {};
                        const element = res.data[i];
                        if (element) {
                            down2["User ID"] = element.uid_id
                            down2["User Name"] = element.last_name + ", " + element.first_name
                            down2["Changed On"] = element.changed_on__date
                            down2["Changed Role ID"] = element.changed_role
                            down2["Changed Role"] = element.role_name
                            down2["Changed Location"] = element.changed_loc === 2 ? "Srilanka" : element.changed_loc === 0 ? "UK" : element.changed_loc === 1 ? "India" : ""
                            down.push(down2)
                        }
                    }
                    try {
                        json2excel({
                            data: down,
                            name: "rolechange_history",
                            formateDate: 'dd/mm/yyyy'
                        });
                        this.setState({ isLoading: false })
                    } catch (e) {
                        console.error('export error');
                    }
                }
            })
            .catch(error => {
                if (error.status === 406) {
                    alert("You are not authorized for the report.")
                } else {
                    this.setState({
                        isLoading: false
                    })
                    errorMessage(this.props, error)
                }
            });
    }

    render() {

        const handleStartDateChange = (date) => {
            this.setState({ startDate: moment(date) });
        };
        const handleEndDateChange = (date) => {
            this.setState({ endDate: moment(date) });
        };
        const handleUserSelection = (val) => {
            if (val.length && val[0].id === 0) {
                // console.log(val[0])
                this.setState({ selectedUser: this.state.users });
            } else {
                this.setState({ selectedUser: val });
            }
        };

        const handleCountryChange = (event) => {
            this.setState({
                selectCountry: event.target.value
            })
        };

        const handleCheckChange = (event) => {
            this.setState({ withOverheads: event.target.checked });
        };

        return (
            <div>

                {
                    this.props.withoutRateCost ? null :
                        <Tabs
                            value={this.state.withCost}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={(event, newValue) => { this.setState({ loading: true, withCost: newValue }, () => { this.setState({ loading: false }) }) }}
                            aria-label="disabled tabs example"
                        >
                            <Tab label="Without Rate & Cost " />
                            <Tab label="With Rate & Cost " />
                        </Tabs>
                }

                <div>
                    {this.state.isLoading ? <LoopCircleLoading color={getPrimaryColor()} /> :
                        <div>

                            <Box display="flex" justifyContent="center" p={1} flexDirection={'column'}>
                                <Box display="flex" justifyContent="center" flexDirection={'row'}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            margin="normal"
                                            id="date-picker-dialog"
                                            label="Start Date"
                                            inputVariant="outlined"
                                            format="dd/MM/yyyy"
                                            style={{ marginLeft: '10px', width: '500px' }}
                                            value={this.state.startDate}
                                            onChange={handleStartDateChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            margin="normal"
                                            id="date-picker-dialog2"
                                            label="End Date"
                                            inputVariant="outlined"
                                            format="dd/MM/yyyy"
                                            style={{ marginLeft: '10px', width: '500px' }}
                                            value={this.state.endDate}
                                            onChange={handleEndDateChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                    {this.props.allFilter ?
                                        <Autocomplete
                                            multiple
                                            id="export-projects"
                                            options={this.state.clients}
                                            value={this.state.selectedClient}
                                            disableCloseOnSelect
                                            style={{ marginLeft: '15px', marginTop: '15px', width: '500px' }}
                                            getOptionLabel={(option) => option.name}
                                            renderOption={(option, { selected }) => (
                                                <React.Fragment>
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{ marginRight: 8 }}
                                                        checked={selected}
                                                    />
                                                    {option.name}
                                                </React.Fragment>
                                            )}
                                            onChange={this.onClientSelect}
                                            fullWidth
                                            renderInput={(params) => (
                                                <TextField {...params} variant="outlined" label="Clients" placeholder="Clients" />
                                            )}
                                        />
                                        : null}

                                    {this.props.allFilter ?
                                        <FormControl style={{ marginLeft: '15px', marginTop: '15px', width: '500px' }}>
                                            <InputLabel id="demo-simple-select-label">Country</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={this.state.selectCountry}
                                                label="Country"
                                                variant='outlined'
                                                onChange={handleCountryChange}
                                            >
                                                <MenuItem value={null}>None</MenuItem>
                                                <MenuItem value={0}>UK</MenuItem>
                                                <MenuItem value={1}>India</MenuItem>
                                                <MenuItem value={2}>Srilanka</MenuItem>
                                                {/* <MenuItem value={30}>Thirty</MenuItem> */}
                                            </Select>
                                        </FormControl>
                                        : null}
                                </Box>

                                <Box display="flex" justifyContent="center" alignItems={'center'} flexDirection={'row'} mt={1}>
                                    {this.props.userEnabled ?
                                        <Autocomplete
                                            multiple
                                            id="export-selected_users"
                                            style={{ marginLeft: '15px', marginTop: '15px', width: '500px' }}
                                            options={this.state.users}
                                            value={this.state.selectedUser}
                                            disableCloseOnSelect
                                            getOptionLabel={(option) => option.last_name + ", " + option.first_name}
                                            renderOption={(option, { selected }) => (
                                                <React.Fragment>
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{ marginRight: 8 }}
                                                        checked={selected}
                                                    />
                                                    {option.last_name + ", " + option.first_name}
                                                </React.Fragment>
                                            )}
                                            onChange={(e, v) => handleUserSelection(v)}
                                            fullWidth
                                            renderInput={params => {
                                                const { InputProps, ...restParams } = params;
                                                const { startAdornment, ...restInputProps } = InputProps;
                                                return (
                                                    <TextField
                                                        variant="outlined"
                                                        label="Select Users"
                                                        placeholder="Select Users"
                                                        {...restParams}
                                                        InputProps={{
                                                            ...restInputProps,
                                                            startAdornment: (
                                                                <div style={{
                                                                    maxHeight: screenSizeCheck() ? 400 : 500,
                                                                    overflowY: 'auto',
                                                                }}
                                                                >
                                                                    {startAdornment}
                                                                </div>
                                                            ),
                                                        }}
                                                    />
                                                );
                                            }}
                                        /> : null}
                                    {this.props.allFilter ?
                                        <Autocomplete
                                            multiple
                                            id="export-projects"
                                            options={this.state.projects}
                                            disableCloseOnSelect
                                            style={{ marginLeft: '15px', marginTop: '15px', width: '500px' }}
                                            getOptionLabel={(option) => option.tender_name}
                                            renderOption={(option, { selected }) => (
                                                <React.Fragment>
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{ marginRight: 8 }}
                                                        checked={selected}
                                                    />
                                                    {option.tender_name}
                                                </React.Fragment>
                                            )}
                                            onChange={this.onProjectSelect}
                                            fullWidth
                                            renderInput={(params) => (
                                                <TextField {...params} variant="outlined" label="Projects" placeholder="Projects" />
                                            )}
                                        />
                                        : null}
                                    {this.props.allFilter ?
                                        <Autocomplete
                                            multiple
                                            id="export-teams"
                                            options={this.state.teams}
                                            disableCloseOnSelect
                                            style={{ marginLeft: '15px', marginTop: '15px', width: '500px' }}
                                            getOptionLabel={(option) => option.name}
                                            renderOption={(option, { selected }) => (
                                                <React.Fragment>
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{ marginRight: 8 }}
                                                        checked={selected}
                                                    />
                                                    {option.name}
                                                </React.Fragment>
                                            )}
                                            onChange={this.onTeamSelect}
                                            fullWidth
                                            renderInput={(params) => (
                                                <TextField {...params} variant="outlined" label="Teams" placeholder="Teams" />
                                            )}
                                        />
                                        : null}
                                    <Box marginLeft={'25px'} marginTop={'15px'} width='500px'>
                                        <FormControlLabel
                                            control={<Checkbox
                                                edge="start"
                                                checked={this.state.withOverheads}
                                                disableRipple
                                                color="primary"
                                                onChange={handleCheckChange}
                                            />}
                                            label="Include Non-Billable"
                                        /></Box>
                                </Box>
                            </Box>
                            <Box display='flex' flexDirection={'row'} justifyContent={'center'} mt={1}>
                                <Box>
                                    <Button
                                        size="medium"
                                        variant="contained" color="primary"
                                        onClick={() => this.getReport()}
                                    >Get Report</Button>
                                </Box>
                                {this.props.roleChangeHistory ?
                                    <>
                                        <Box>
                                            <Button
                                                style={{ marginLeft: '20px', }}
                                                size="medium"
                                                variant="contained" color="primary"
                                                onClick={() => this.getRoleChange()}
                                            >RoleChange History</Button>
                                        </Box></> : null}
                            </Box>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default withSnackbar(UserTimesheetReport)