import { Box, Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import React, { Component } from 'react';
import { Alert } from '@mui/material';
import { cloudMessaging } from '../firebase';
import { getToken } from "firebase/messaging";
import { BootstrapDialogTitleV4 } from '../Helper/DialogTitleM5';
import { errorMessage } from '../Helper/ErrorMessage';
import { getCookie } from './CookieHelper';
import Service from '../Networking/networkutils';

var token = getCookie("bb_ts_token");

class NotificationPermission extends Component {

    constructor(props) {
        super(props)

        this.state = {
            notificationAlert: false,
            notificationWarning: false,
            notificationBlockedWarning: false,
            notificationAllowWarning: false
        }
    }

    componentDidMount() {
        if (!localStorage.getItem('push-denied') || localStorage.getItem('push-denied') !== "true") {
            this.checkNotify()
        }
    }

    checkNotify() {
        if (!("Notification" in window)) {
            // Check if the browser supports notifications
            alert("This browser does not support desktop notification");
        } else if (Notification.permission === "granted") {
            // Check whether notification permissions have already been granted;
            // if so, create a notification
            // const notification = new Notification("PaRaM", {body: "fghfghj ghj vbnm fghj cvbn", icon: process.env.PUBLIC_URL + '/logo512.png'});
            // …
            this.requestNotificationToken()
        } else {
            // We need to ask the user for permission
            this.setState({ notificationAlert: true })
        }
        // console.log(Notification.permission)
        // At last, if the user has denied notifications, and you
        // want to be respectful there is no need to bother them anymore.
    }

    requestNotificationToken() {
        if (cloudMessaging) {
            Notification.requestPermission().then((permission) => {
                if (permission === 'granted') {
                    if (localStorage.getItem('push-denied')) {
                        localStorage.removeItem('push-denied')
                    }
                    if (this.state.notificationAlert) {
                        this.setState({ notificationAlert: false })
                    }
                    getToken(cloudMessaging, { vapidKey: 'BNNgkEtOcJQN9hW5EutQMFPhK1m2jFMHF8KRdj3hhdnvanToSnEXcxMzKlQG0yhIcSmnzUk3eFJNwl1B94cO_l4' }).then((currentToken) => {
                        if (currentToken) {
                            Service.post(`/bb/api/push/notification/token/update_notification_token/`, {
                                headers: {
                                    Authorization: "Token " + token,
                                },
                                data: {
                                    token: currentToken
                                }
                            })
                                .then(res => {
                                    // localStorage.setItem("system_token", currentToken);
                                    console.log("Device Registered for Notification")
                                })
                                .catch(error => {
                                    errorMessage(this.props, error)
                                });
                            // Send the token to your server and update the UI if necessary
                            // ...
                        } else {
                            // Show permission request UI
                            console.log('No registration token available. Request permission to generate one.');
                            // ...
                        }
                    }).catch((err) => {
                        console.log('An error occurred while retrieving token. ', err);
                        // ...
                    });
                } else if (permission === 'denied') {
                    this.setState({ notificationBlockedWarning: true })
                    this.props.enqueueSnackbar('Notification is blocked by you, so you have to enable it by using th lock icon in top left.', {
                        variant: 'warning'
                    });
                }
            })
            if (Notification.permission === "default") {
                this.setState({ notificationWarning: true })
            }
        }
    }

    render() {

        const handleNotificationAlertClose = () => {
            this.setState({ notificationAlert: false })
            localStorage.setItem('push-denied', "true")
        };

        return (
            <>
                <Dialog fullWidth={true} aria-labelledby="customized-dialog-title" maxWidth="xs" open={this.state.notificationAlert}>
                    <BootstrapDialogTitleV4 id="notification_alert" >Push-Notification</BootstrapDialogTitleV4>
                    <DialogContent >
                        <Box display={'flex'} flexDirection={'column'}>
                            <Box fontSize="12px">
                                An alert (typically a pop-up) generated by PaRaM when the application is not open, notifying you the new message, update, action to be taken, etc. similar to the windows outlook email notifications that pop-up at the right side bottom of the screen.
                                <br /><br />
                                Do you want to enable push notification?
                            </Box>
                            {this.state.notificationWarning ?
                                <Alert severity={this.state.notificationAllowWarning ? 'warning' : 'info'}> If you don't see the alert box for notification. Please enable it by using the Notification icon (or Bell Icon) in the address bar</Alert> :
                                null
                            }

                            {this.state.notificationBlockedWarning ?
                                <Alert severity='warning'> Notification is blocked by you, so you have to enable it by using th lock icon in the address bar.</Alert> :
                                null
                            }
                        </Box>
                    </DialogContent>
                    {this.state.notificationAllowWarning ?
                        <DialogActions>
                            <Button size='small' color='primary' onClick={handleNotificationAlertClose}>
                                Close
                            </Button>
                        </DialogActions>
                        :
                        <DialogActions>

                            <Button size='small' color='primary' onClick={() => {
                                this.setState({ notificationAllowWarning: true })
                                this.requestNotificationToken()
                            }}>
                                Ok
                            </Button>
                            <Button size='small' color='primary' onClick={handleNotificationAlertClose}>
                                Cancel
                            </Button>
                        </DialogActions>
                    }

                </Dialog>
            </>
        );
    }
}

export default NotificationPermission;