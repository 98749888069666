import React, { Component } from 'react'
import { Avatar, Box, Card, Tooltip, IconButton, Button, Grid, DialogContentText } from '@mui/material';
import 'react-multi-carousel/lib/styles.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import moment from "moment";
import { withSnackbar } from 'notistack';
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, } from '@material-ui/core';
import { CoffeeLoading } from 'react-loadingg';
import 'date-fns';
import Slide from '@material-ui/core/Slide';
import { CsvBuilder } from 'filefy';
import { getCookie } from '../../Helper/CookieHelper';
import Service from '../../Networking/networkutils';
import { errorMessage } from '../../Helper/ErrorMessage';
import { screenSizeCheck } from '../../MainDashBoard/ScreensizeCheck/ScreensizeCheck';
import { getPrimaryColor } from '../../Helper/CommonThings';
import { getGreencolor, getGreyColor, getLaterDuecolor } from '../../MainDashBoard/ColorTheme/ColorTheme';
import PostAddIcon from '@mui/icons-material/PostAdd';
import OutofWorkHrsRequest from './OutofWorkHrsRequest';

const token = getCookie("bb_ts_token");

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    if (props.slideCount <= props.data + props.currentSlide) {
        return <div></div>
    } else {
        return (
            <div
                className={className}
                style={{ ...style, display: "block", borderRadius: '50px', background: "grey" }}
                onClick={onClick}
            />
        );
    }
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    if (props.currentSlide <= 0) {
        return <div></div>
    } else {
        return (
            <div
                className={className}
                style={{ ...style, display: "block", borderRadius: '50px', background: "grey" }}
                onClick={onClick}
            />
        );
    }
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

var cardMaxHeight = 0
var boxHeight = 0

const user = JSON.parse(getCookie("bb_ts_user"));

class OutofWorkCardList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cardHeight: null,
            openRequest: false,
            dialogData: null,
            openTaskUpdate: false,
            perTaskLoading: false,
            taskPercent: 0,
            perTaskData: null,
            data: [],
            allData: null,
            projectLook: null,
            allRoles: null,
            isLoading: false,
            boxHeight: null,
            scrollHeight: 1800,
            openTaskLevelAllocation: false,
            openCoffee: false,
            ds: null,
            rates: null,
            openCompleteTask: false,
            openDateDialog: false,
            openSubTender: false,
            subTenderData: null,
            openTaskDownload: false,
            dialogLoader: false,
        }
    }

    componentDidMount() {
        boxHeight = 0
        cardMaxHeight = 0
        this.taskSearch(this.props.presentSearch)
        setTimeout(() => {
            this.setState({
                cardHeight: cardMaxHeight,
                boxHeight: boxHeight,
            })
        }, 200)
    }

    getHeight(ref) {
        if (ref && cardMaxHeight < ref.clientHeight) {
            cardMaxHeight = ref.clientHeight
        }
    }

    boxHeight(ref) {
        if (ref && boxHeight < ref.clientHeight) {
            boxHeight = ref.clientHeight
            // return boxHeight
        }
    }

    UserHoursRequest(data) {
        this.setState({ openRequest: true, dialogData: data })
    }

    fetchPerTaskData(rowData, type) {
        this.setState({ perTaskData: null })
        Service.get('/bb/api/tender/wbs/tasks/' + rowData.id + '/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                if (type === 'handleTaskPercent') {
                    this.setState({
                        perTaskLoading: false,
                        perTaskData: res.data,
                        taskPercent: res.data.comp_percnt,
                    })
                }
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    fetchPerTaskData2(rowData) {
        this.setState({
            openCoffee: true
        })
        Service.get('/bb/api/tender/wbs/tasks/' + rowData.id + '/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({
                    openTaskLevelAllocation: true,
                    allData: res.data,
                    openCoffee: false,
                    ds: res.data.t_wbs_data.pid_data,
                }, () => {
                    if ((this.state.ds && this.state.ds.contract_type)) {
                        Service.get('/bb/api/tender/rate/?pid=' + rowData.ds_id, {
                            headers: {
                                Authorization: "Token " + token,
                            },
                        })
                            .then(res2 => {
                                this.setState({ rates: res2.data })
                            })
                            .catch(error => {
                                errorMessage(this.props, error)
                            });
                    }
                })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    handleTaskComp(key) {

        if (this.state.taskPercent === 100 && key === 'proceed_not_if_100') {
            this.setState({
                openCompleteTask: true
            })
            return
        }

        this.setState({
            openTaskUpdate: false,
        })
        var data = this.state.perTaskData;

        var historydata = null

        if (data.comp_percnt !== this.state.taskPercent) {
            Service.post('/bb/api/tender/tasks/history/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: {
                    "t_pid": data.t_pid,
                    "t_wbs": data.t_ohlwbs,
                    "t_tasks": data.id,
                    "prev_task_status": data.comp_percnt,
                    "task_status": this.state.taskPercent,
                    "created_by": user.id
                },
            })
                .then(res => {
                    historydata = res.data
                    data.comp_percnt = this.state.taskPercent;
                    Service.put('/bb/api/tender/wbs/tasks/' + this.state.perTaskData.id + '/', {
                        headers: {
                            Authorization: "Token " + token,
                        },
                        data: data,
                    })
                        .then(res1 => {
                            var tempData = this.state.data
                            // console.log(tempData)
                            for (let i = 0; i < tempData.length; i++) {
                                const element = tempData[i];
                                if (element.id === this.state.perTaskData.id) {
                                    element.complete_percent = this.state.taskPercent;
                                    break
                                }
                            }


                            // if (this.state.taskPercent === 100) {
                            //     this.props.refreshData();
                            //     Service.patch('/bb/api/tender/wbs/tasks/complete_task/', {
                            //         headers: {
                            //             Authorization: "Token " + token,
                            //         },
                            //         data: {
                            //             "ttids": [data.id]
                            //         }
                            //     })
                            //         .catch(error => {
                            //             errorMessage(this.props, error)
                            //         });
                            // }


                            this.setState({
                                perTaskLoading: false,
                                taskPercent: 0,
                                openTaskUpdate: false,
                                data: tempData,
                                openCompleteTask: false
                            })
                            this.props.enqueueSnackbar('Task status updated!', {
                                variant: 'success'
                            });

                        })
                        .catch(error => {
                            errorMessage(this.props, error)
                            if (historydata) {
                                Service.delete('/bb/api/tender/tasks/history/' + historydata.id + '/', {
                                    headers: {
                                        Authorization: "Token " + token,
                                    },
                                })
                                    .catch(error => {
                                        errorMessage(this.props, error)
                                    });
                            }
                        });
                })
                .catch(error => {
                    errorMessage(this.props, error)
                });
        }
    }

    handleTaskPercent(value) {
        this.fetchPerTaskData(value, "handleTaskPercent");
        this.setState({ openTaskUpdate: true, taskPercent: value.comp_percnt, })
    }

    taskSearch(data) {
        const data_lowercase = data.toLowerCase()
        var search_words = data_lowercase.split(" ");
        search_words = search_words.filter(n => n)
        var filtered_data = []
        if (search_words.length === 0) {
            filtered_data = this.props.data
        }
        for (let i = 0; i < this.props.data.length; i++) {
            const element = this.props.data[i];
            var num_of_passed = false
            for (let j = 0; j < search_words.length; j++) {
                const el = search_words[j];
                num_of_passed = element.search_key.includes(el)
                if (!num_of_passed) {
                    break
                }
            }
            if (num_of_passed) {
                filtered_data.push(element)
            }
        }
        this.setState({ data: filtered_data })
        return filtered_data
    }

    refCallback = el => {
        if (el) {
            this.setState({
                scrollHeight: window.innerHeight - el.getBoundingClientRect().y - 28
            }, () => {
                this.props.childHeight(this.state.scrollHeight)
            })
        }
    };

    openSubTender(data) {
        this.setState({ subTenderData: data }, () => { this.setState({ openSubTender: true }) })
    }

    openTaskDownload() {
        this.setState({ openTaskDownload: true })
    }

    downloadSubtask() {
        var data = this.state.data
        var task_ids = []
        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            if (element) {
                task_ids.push(element.id)
            }
        }
        if (task_ids.length !== 0) {
            this.downloadData(task_ids)
        } else {
            this.props.enqueueSnackbar('No Tasks to download!', {
                variant: 'warning'
            });
        }
    }

    downloadData(ids) {
        Service.get(`/bb/api/tender/subtask/download/?tid=${ids.toString()}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.exportSubtaskCsv(res.data)
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    exportSubtaskCsv(initialData) {
        this.setState({ dialogLoader: true })
        const columns = ['Project Name', 'DS Name', 'WBS Name', 'Task ID', 'Task Name', 'Baseline Start', 'Baseline Finish', '% Complete', 'Actual Start', 'Actual Finish', 'Planner Task Name', 'Progress', 'Priority', 'Planner Task Start', 'Planner Task Finish', 'Planner Description', 'Members Assigned', 'Subtask Name', 'Subtask Progress', 'Subtask Priority', 'Subtask Start', 'Subtask Finish', 'Subtask Description', 'Subtask Members Assigned']

        const data = initialData.map(rowData =>
            columns.map(columnDef => {
                if (columnDef === 'Project Name') {
                    return rowData.maintask.tender_name
                } else if (columnDef === 'DS Name') {
                    return rowData.maintask.ds_name
                } else if (columnDef === 'WBS Name') {
                    return rowData.maintask.wbs_name
                } else if (columnDef === 'Task ID') {
                    return rowData.maintask.ohlwbs_tasks
                } else if (columnDef === 'Task Name') {
                    return rowData.maintask.task_name
                } else if (columnDef === 'Baseline Start') {
                    return moment(rowData.maintask.baseline_start).format('DD-MM-YYYY')
                } else if (columnDef === 'Baseline Finish') {
                    return moment(rowData.maintask.baseline_finish).format('DD-MM-YYYY')
                } else if (columnDef === '% Complete') {
                    return rowData.maintask.percentage_comp
                } else if (columnDef === 'Actual Start') {
                    return moment(rowData.maintask.act_start).format('DD-MM-YYYY')
                } else if (columnDef === 'Actual Finish') {
                    return moment(rowData.maintask.act_finish).format('DD-MM-YYYY')
                } else if (columnDef === 'Planner Task Name') {
                    return rowData.maintask.title
                } else if (columnDef === 'Progress') {
                    return rowData.maintask.progress
                } else if (columnDef === 'Priority') {
                    return rowData.maintask.priority
                } else if (columnDef === 'Planner Task Start') {
                    return rowData.maintask.planned_start
                } else if (columnDef === 'Planner Task Finish') {
                    return rowData.maintask.planned_end
                } else if (columnDef === 'Planner Description') {
                    return rowData.maintask.description
                } else if (columnDef === 'Members Assigned') {
                    let users = []
                    for (let i = 0; i < rowData.maintask.users.length; i++) {
                        const element = rowData.maintask.users[i];
                        if (element) {
                            users.push(element.last_name + ", " + element.first_name)
                        }
                    }
                    return users.toString()
                } else if (columnDef === 'Subtask Name') {
                    return rowData.subtask.title ? rowData.subtask.title : null
                } else if (columnDef === 'Subtask Progress') {
                    return rowData.subtask.progress ? rowData.subtask.progress : null
                } else if (columnDef === 'Subtask Priority') {
                    return rowData.subtask.priority ? rowData.subtask.priority : null
                } else if (columnDef === 'Subtask Start') {
                    return rowData.subtask.planned_start ? rowData.subtask.planned_start : null
                } else if (columnDef === 'Subtask Finish') {
                    return rowData.subtask.planned_end ? rowData.subtask.planned_end : null
                } else if (columnDef === 'Subtask Description') {
                    return rowData.subtask.description ? rowData.subtask.description : null
                } else if (columnDef === 'Subtask Members Assigned') {
                    if (rowData.subtask.users) {
                        let users = []
                        for (let i = 0; i < rowData.subtask.users.length; i++) {
                            const element = rowData.subtask.users[i];
                            if (element) {
                                users.push(element.last_name + ", " + element.first_name)
                            }
                        }
                        return users.toString()
                    }
                }
                return null
            })
        );

        const builder = new CsvBuilder("Subtask-" + moment().format('DD-MM-YYYY hh:mm') + '.csv');
        builder
            .setDelimeter(',')
            .setColumns(columns.map(columnDef => columnDef))
            .addRows(data)
            .exportFile();

        this.setState({ openTaskDownload: false, dialogLoader: false })
    };

    downloadTask() {
        var datas = this.state.data
        if (datas.length !== 0) {
            this.setState({ dialogLoader: true })
            const columns = ['Project Name', 'DS Name', 'WBS Name', 'Task ID', 'Task Name', 'Actual Start Date', 'Actual End Date', '% Complete', 'Members Assigned', 'Subtask Total', 'SubTask Done', 'Actual', 'Assigned', 'Remaining', 'Total']

            const data = datas.map(rowData =>
                columns.map(columnDef => {
                    if (columnDef === 'Project Name') {
                        return this.props.currentProject ? this.props.currentProject.tender_name : null
                    } else if (columnDef === 'DS Name') {
                        return rowData.ds_name
                    } else if (columnDef === 'WBS Name') {
                        return rowData.wbs_name
                    } else if (columnDef === 'Task ID') {
                        return rowData.id
                    } else if (columnDef === 'Task Name') {
                        return rowData.name
                    } else if (columnDef === 'Actual Start Date') {
                        return moment(rowData.start_date).format('DD-MM-YYYY')
                    } else if (columnDef === 'Actual End Date') {
                        return moment(rowData.end_date).format('DD-MM-YYYY')
                    } else if (columnDef === '% Complete') {
                        return rowData.complete_percent
                    } else if (columnDef === 'Members Assigned') {
                        let users = []
                        for (let i = 0; i < rowData.remaining_users.length; i++) {
                            const element = rowData.remaining_users[i];
                            if (element) {
                                users.push(element.last_name + ", " + element.first_name)
                            }
                        }
                        return users.toString()
                    } else if (columnDef === 'Subtask Total') {
                        return rowData.subtasks_total
                    } else if (columnDef === 'SubTask Done') {
                        return rowData.subtasks_done
                    } else if (columnDef === 'Actual') {
                        return rowData.is_approved
                    } else if (columnDef === 'Assigned') {
                        return rowData.total_hours_planned
                    } else if (columnDef === 'Remaining') {
                        return (rowData.total_hours - rowData.total_hours_planned) % 1 === 0 ? (rowData.total_hours - rowData.total_hours_planned) : (rowData.total_hours - rowData.total_hours_planned).toFixed(1)
                    } else if (columnDef === 'Total') {
                        return rowData.total_hours
                    }
                    return null
                })
            );

            const builder = new CsvBuilder("Task-" + moment().format('DD-MM-YYYY hh:mm') + '.csv');
            builder
                .setDelimeter(',')
                .setColumns(columns.map(columnDef => columnDef))
                .addRows(data)
                .exportFile();

            this.setState({ openTaskDownload: false, dialogLoader: false })
        } else {
            this.props.enqueueSnackbar('No Tasks to download!', {
                variant: 'warning'
            });
        }
    }

    render() {

        const settings = {
            centerMode: false,
            infinite: false,
            slidesToShow: screenSizeCheck() ? 4 : 6,
            slidesToScroll: screenSizeCheck() ? 4 : 6,
            speed: 300,
            rows: screenSizeCheck() ? 1 : 1,
            arrows: true,
            nextArrow: <SampleNextArrow data={screenSizeCheck() ? 5 : 6} />,
            prevArrow: <SamplePrevArrow data={screenSizeCheck() ? 5 : 6} />,
            adaptiveHeight: true,
            accessibility: true,
        };

        const handleRequestClose = () => {
            this.setState({ openRequest: false, })
        };

        const handleCompleteTaskClose = () => {
            this.setState({ openCompleteTask: false, });
        };

        const handleTaskDownloadClose = () => {
            this.setState({ openTaskDownload: false, })
        };

        return (
            <div ref={this.refCallback} style={{ height: this.state.scrollHeight + "px", overflowY: 'scroll', width: '100%', }}>
                {this.state.data.length === 0 ?
                    <Box mt={1} sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 1, fontWeight: 'bold', height: this.state.scrollHeight }}>No Tasks Available for your Search Keyword</Box> :
                    <Grid container spacing={1.5}>
                        {this.state.data.map((value, i) =>
                            <Grid item xs={screenSizeCheck() ? 12 / (this.props.maxCards - 1) : 12 / this.props.maxCards}>
                                <Box m={.2} ref={(r) => { this.boxHeight(r) }} sx={{
                                    height: '100%',
                                    alignItems: 'stretch',
                                }} >
                                    <Tooltip title={<div>DS Name : {value.ds_name}<br /> Wbs Name : {value.wbs_name}<br />Task Id : {value.id}<br />Task Name : {value.name}<br /></div>}>
                                        <Card sx={{ borderRadius: '20px 20px 20px 20px', height: '100%' }} elevation={2} >
                                            <div style={{ height: '100%' }}>
                                                <Box m={2} sx={{
                                                    alignItems: 'stretch',
                                                    flexDirection: 'row',
                                                }}>
                                                    <Box ref={(r) => { this.getHeight(r) }} sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        height: this.state.cardHeight ? this.state.cardHeight : '100%',
                                                    }}>
                                                        <Box sx={{
                                                            flexGrow: 1,
                                                            flexWrap: 'wrap'
                                                        }}>
                                                            <Box fontSize={'16px'} color={getPrimaryColor()} margin='2px' fontWeight={'bold'}>
                                                                {value.ds_name}
                                                            </Box>
                                                            <Box fontSize={'14px'} color={getGreyColor()} margin='2px' >
                                                                {value.name + " - "}
                                                                {value.comp_percnt + "%"
                                                                }
                                                                {value.inc_evp === 0 ? ' - Non Billable' : ' - Billable'}
                                                            </Box>
                                                        </Box>
                                                        <Box sx={{
                                                            height: '100%',
                                                            flexWrap: 'wrap',
                                                        }}   >
                                                            <Box display='flex' flexDirection='column'>
                                                                <Tooltip title="Request Hours">
                                                                    <IconButton size='small' disabled={value.complete_percent >= 90 ? true : false} onClick={() => this.UserHoursRequest(value)}>
                                                                        <PostAddIcon fontSize='small' />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Box>
                                                        </Box>
                                                    </Box>

                                                    <Box sx={{ alignSelf: 'flex-end' }}>
                                                        <Box sx={{
                                                            display: 'flex',
                                                            justifyContent: 'flex-start',
                                                            borderRadius: 1,
                                                        }}>
                                                            <Box fontSize={screenSizeCheck() ? '10px' : '12px'} color={getGreencolor()} fontWeight={'bold'} marginRight={value.task_hold === 1 ? '10px' : '15px'}>
                                                                Start: {moment(value.start_date).format('DD-MM-YYYY')}
                                                            </Box>

                                                            <Box fontSize={screenSizeCheck() ? '10px' : '12px'} fontWeight={'bold'} marginRight={value.task_hold === 1 ? '10px' : '15px'}>
                                                                End: {moment(value.end_date).format('DD-MM-YYYY')}

                                                            </Box>
                                                            <Tooltip title={value.task_stand === 1 ? "Start soon" : value.task_stand === 2 ? "Ready to start" : value.task_stand === 3 ? "Soon due" : value.task_stand === 4 ? "Over due" : value.task_stand === 6 ? "Later due" : 'No date info'}>
                                                                <Box marginRight={value.task_hold === 1 ? '10px' : '0px'} fontSize={screenSizeCheck() ? '10px' : '12px'} onClick={() => this.props.handleChange(value.task_stand)} fontWeight={'bold'} style={{ cursor: 'pointer' }} color={value.task_stand === 0 ? '#4DB6AC' : value.task_stand === 1 ? '#FFF176' : value.task_stand === 2 ? '#DCE775' : value.task_stand === 3 ? '#FFB74D' : value.task_stand === 4 ? '#EF9A9A' : value.task_stand === 6 ? getLaterDuecolor() : 'primary'}>
                                                                    {value.task_stand === 1 ? "Start soon" : value.task_stand === 2 ? "Ready to start" : value.task_stand === 3 ? "Soon due" : value.task_stand === 4 ? "Over due" : value.task_stand === 6 ? "Later due" : 'No date info'}
                                                                </Box>
                                                            </Tooltip>
                                                        </Box>

                                                        <Box
                                                            m={2}
                                                            sx={{
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                borderRadius: 1,
                                                            }}>
                                                            <Slider {...settings}>
                                                                {value.remaining_users.length > 0 ?
                                                                    value.remaining_users.map((name, i) =>
                                                                        <Box key={i}
                                                                            sx={{
                                                                                justifyContent: 'center',
                                                                                alignItems: 'center',
                                                                                borderRadius: 1,
                                                                                width: '100%',
                                                                            }} >
                                                                            <Tooltip title={this.props.is_teamtasks ? name.last_name + ", " + name.first_name + '(' + name.planed_hours + ')' : name.last_name + ", " + name.first_name}>
                                                                                <Avatar sx={{ bgcolor: '#99CFBB' }} ><Box fontWeight={'bold'} fontSize={'18px'}>{name.first_name.charAt(0) + name.last_name.charAt(0)}</Box></Avatar>
                                                                            </Tooltip>
                                                                        </Box>
                                                                    ) :
                                                                    <Box
                                                                        sx={{
                                                                            justifyContent: 'center',
                                                                            alignItems: 'center',
                                                                            borderRadius: 1,
                                                                            width: '100%',
                                                                        }} >
                                                                        <Tooltip title={"Not Available"}>
                                                                            <Avatar sx={{ bgcolor: '#c8c8c8' }} ><Box fontWeight={'bold'} fontSize={'18px'}>NA</Box></Avatar>
                                                                        </Tooltip>
                                                                    </Box>
                                                                }
                                                            </Slider>
                                                        </Box>
                                                    </Box>

                                                </Box>
                                            </div>
                                        </Card>
                                    </Tooltip>
                                </Box >
                            </Grid>
                        )}
                    </Grid>
                }

                <Dialog maxWidth="xs" onClose={handleRequestClose} fullWidth={true} aria-labelledby="customized-dialog-title" open={this.state.openRequest}>
                    <DialogTitle id="customized-dialog-title1" onClose={handleRequestClose}>
                        {this.state.dialogData ? (this.state.dialogData.decs ? this.state.dialogData.decs : this.state.dialogData.name) : null}
                    </DialogTitle>
                    <DialogContent dividers>
                        <OutofWorkHrsRequest value={this.state.dialogData} uid={user.id} tender={this.state.dialogData ? this.state.dialogData.project_id : null} onCloseCommand={handleRequestClose} />
                    </DialogContent>
                </Dialog>

                <Dialog maxWidth="sm" open={this.state.openCoffee} aria-labelledby="form-dialog-title">
                    {/* <DialogTitle id="customized-dialog-title" onClose={handleFeedbackClose}>
                                    Additional Hours Request
                                </DialogTitle> */}
                    <DialogContent>
                        <Box m={5} p={2}>
                            <CoffeeLoading />
                        </Box>
                    </DialogContent>
                </Dialog>

                <Dialog
                    open={this.state.openCompleteTask}
                    // fullWidth={true}
                    maxWidth={'md'}
                    onClose={handleCompleteTaskClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-title">{"You have updated task(s) to 100% complete.."}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            This process will mark:<br />
                            &nbsp;&nbsp;&nbsp;1. The task to completed status<br />
                            &nbsp;&nbsp;&nbsp;2. All the allocated remaining hours to the individuals in this task will be zeroed out<br />
                            &nbsp;&nbsp;&nbsp;3. All the CTC hours of the Roles will be set to zero<br />
                            &nbsp;&nbsp;&nbsp;4. All the actual hours will be equalled to allocated hours<br />
                            <pre />Note : If the user has done his timesheet but pending submission, they can still send it to you for approval. If the user has not initiated the timesheet for the hours allocated to them – those hours will be set to zero and will not be able to see the task in their calendar.

                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCompleteTaskClose} color="primary">
                            Close
                        </Button>
                        <Button onClick={() => { this.handleTaskComp('proceed_if_100') }} color="primary">
                            Proceed
                        </Button>

                    </DialogActions>
                </Dialog>

                <Dialog
                    fullWidth
                    TransitionComponent={Transition}
                    open={this.state.openTaskDownload}
                    onClose={handleTaskDownloadClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle>Task Download</DialogTitle>
                    <DialogActions>
                        {this.state.dialogLoader ? <CircularProgress /> :
                            <>
                                <Button onClick={() => this.downloadTask()} color="primary">
                                    task download
                                </Button>
                                <Button onClick={() => this.downloadSubtask()} color="primary">
                                    subtask download
                                </Button>
                                <Button onClick={handleTaskDownloadClose} color="primary">
                                    cancel
                                </Button>
                            </>
                        }
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default withSnackbar(OutofWorkCardList)