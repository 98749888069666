import { Box, Container, DialogContentText, Grid } from '@mui/material'
import React, { Component } from 'react'
import Tasks from './Tasks/Tasks'
import TeamTaskInfo from './TeamTaskInfo/TeamTaskInfo'
import { Row, Col } from 'react-grid';
import LevelResources from './TeamWorkload/LevelResources/LevelResources'
import TeamMembers from './TeamWorkload/TeamMembers/TeamMembers'
import { LoopCircleLoading } from 'react-loadingg';
import { withSnackbar } from 'notistack';
import { errorMessage } from '../../../Helper/ErrorMessage';
import Service from '../../../Networking/networkutils'
import { getCookie } from '../../../Helper/CookieHelper';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { Avatar, Button, DialogActions, DialogContent, Dialog, DialogTitle, IconButton, ListItem, ListItemAvatar, ListItemText, Toolbar, Typography } from '@mui/material';
import GroupIcon from '@material-ui/icons/Group';
import MakeTeamData from '../../../Teams/AssignRoles/MakeTeamData';
import TaskAllocationHelper from '../../TaskAllocationHelper/TaskAllocationHelper';
import TeamSummary from '../../../Teams/TeamSummary';
import TeamUserTaskMap from '../../../Teams/TeamUserTaskMap';
import { Alert } from '@material-ui/lab';
import TeamTaskAllocation from '../../../TeamTaskAllocation/TeamTaskAllocation';
import { getPrimaryColor } from '../../../Helper/CommonThings';
import { BootstrapDialog, BootstrapDialogTitle } from '../../../Helper/DialogTitleM5';
import Lookahead3M from '../../../Reports/Lookahead3M/Lookahead3M';
import OverheadsNonBillableChange from '../../../OverheadsNonBillableChange/OverheadsNonBillableChange';
import { AppBar } from '@material-ui/core';
import UserRequestForm from '../../../UserRequest/UserRequestForm';
import { getMode } from '../../ColorTheme/ColorTheme';
import { Link } from '@mui/material';

const token = getCookie("bb_ts_token");
// const user = JSON.parse(getCookie("bb_ts_user"));

var selectTeamDialogType = null
var taskLevelAllocationData = null

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class TeamTasks extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            data: null,
            taskdata: [],
            totalHoursRequestPending: 0,
            openDialogue: false,
            summaryDialogue: false,
            teamData: {},
            isloading1: false,
            openAssign: false,
            allRoles: '',
            allUid: '0',
            assignData: {},
            allroleid: '0',
            calData: {},
            makeTeamDataDialog: false,
            taskLevelAllocationHelper: false,
            open3MLookahead: false,
            overheadsDialog: false,
            openNewUserReq: false
        }
        this.handleClick = this.handleClick.bind(this)
        this.launchAuto = this.launchAuto.bind(this)
        this.refreshData = this.refreshData.bind(this)
        this.refreshSubTender = this.refreshSubTender.bind(this)
        this.taskAllocation = this.taskAllocation.bind(this)
        this.handleButton = this.handleButton.bind(this)
        this.progress = React.createRef();
        this.taskref = React.createRef()
        this.teamTaskAllocation = React.createRef();
        this.dialogClose = this.dialogClose.bind(this)
    }

    componentDidMount() {
        this.fetchTeamTaskData('start')
    }

    fetchTeamTaskData(data) {
        if (data === 'reload') {
            this.setState({ isLoading: true })
        }
        Service.get(`/bb/api/tender/project/lead_based_tasks/`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({
                    data: res.data,
                    isLoading: false,
                })
                if (data === 'continue') {
                    if (this.progress && this.progress.current) {
                        this.progress.current.fetchOps(res.data)
                    }
                    if (this.taskref && this.taskref.current) {
                        this.taskref.current.handleData(res.data.team_details);
                    }
                }
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });

    }

    handleClick(data) {
        if (data) {
            this.props.handleClick(data)
        }
    }

    launchAuto(uid, gid) {
        this.props.launchAuto(uid, gid)
    }

    refreshData() {
        this.fetchTeamTaskData('reload')
    }

    refreshSubTender() {
        this.fetchTeamTaskData('continue')
    }

    fetchOps() {
        this.fetchTeamTaskData('continue')

    }

    taskAllocation(value) {
        this.setTaskLevelAllocationData(value)
        // this.selectTeam('tasklevelallocation')

    }

    selectTeam(data) {
        if (data === 'teamsummary') {
            selectTeamDialogType = data
            if (this.state.data.teams.length === 1) {
                this.setState({ summaryDialogue: true, teamData: this.state.data.teams[0] })
            } else {
                this.setState({ openDialogue: true })
            }
        } else if (data === 'allocatetasks') {
            selectTeamDialogType = data
            if (this.state.data.teams.length === 1) {
                this.props.openLoader('open')
                this.setState({ teamData: this.state.data.teams[0] }, () => this.assignTask(this.state.teamData, selectTeamDialogType))
            } else {
                this.setState({ openDialogue: true })
            }
        } else if (data === '3wla') {
            selectTeamDialogType = data
            if (this.state.data.teams.length === 1) {
                this.props.openLoader('open')
                this.setState({ teamData: this.state.data.teams[0] }, () => this.assignTask(this.state.teamData, selectTeamDialogType))
            } else {
                this.setState({ openDialogue: true })
            }
        } else if (data === '3mla') {
            this.setState({ open3MLookahead: true })
        } else if (data === 'tasklevelallocation') {
            selectTeamDialogType = data
            if (this.state.data.teams.length === 1) {
                this.props.openLoader('open')
                this.setState({ teamData: this.state.data.teams[0] }, () => this.assignTask(this.state.teamData, selectTeamDialogType))
            } else {
                this.setState({ openDialogue: true, taskLevelAllocationHelper: false })
            }
        } else if (data === 'newuserrequest') {
            this.setState({ openNewUserReq: true })
        }
        else if (data === 'overheads') {
            this.setState({ overheadsDialog: true })
        }
    }

    assignTask(id, type) {
        Service.get("/bb/api/team/access/?gid=" + id.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {

                var roles1 = '0'

                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    roles1 = roles1 + "," + element.uid_data.role_id;
                }

                var uid1 = '0'

                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    uid1 = uid1 + "," + element.uid;
                }

                Service.get('/bb/api/secondary/roles/?uid_multi=' + uid1, {
                    headers: {
                        Authorization: "Token " + token,
                    },
                })
                    .then(res1 => {
                        for (let i = 0; i < res1.data.length; i++) {
                            const element = res1.data[i];
                            roles1 = roles1 + "," + element.role;
                        }

                        this.setState({ openAssign: type === 'allocatetasks', makeTeamDataDialog: type === '3wla', taskLevelAllocationHelper: type === 'tasklevelallocation', allUid: uid1, allRoles: res.data, assignData: id, allroleid: roles1, calData: id }, () => {
                            setTimeout(() => {
                                this.props.openLoader('close')
                            }, 2500)

                        })
                    })
                    .catch(error => {
                        errorMessage(this.props, error)
                    });

                // console.log(res.data)
            })
            .catch(error => {
                // console.log(error)
                errorMessage(this.props, error)
            });
    }

    selectedTeam(value) {
        if (selectTeamDialogType === 'teamsummary') {
            this.setState({ summaryDialogue: true, teamData: value, openDialogue: false })
        } else if (selectTeamDialogType === 'allocatetasks') {
            this.props.openLoader('open')
            this.setState({ teamData: value, openDialogue: false })
            this.assignTask(value, selectTeamDialogType)
        } else if (selectTeamDialogType === '3wla') {
            this.props.openLoader('open')
            this.setState({ teamData: value, openDialogue: false })
            this.assignTask(value, selectTeamDialogType)
        } else if (selectTeamDialogType === 'tasklevelallocation') {
            this.props.openLoader('open')
            this.setState({ teamData: value, openDialogue: false })
            this.assignTask(value, selectTeamDialogType)
        }
    }

    makeTeamDataDialogClose = () => {
        this.setState({ makeTeamDataDialog: false })
    }

    setTaskLevelAllocationData(data) {
        taskLevelAllocationData = data
        this.teamTaskAllocation.current.getTask(data.id)
    }

    handleButton(data) {
        this.selectTeam(data)
    }

    dialogClose() {
        this.setState({ openNewUserReq: false })
    }

    render() {

        const handleDialogueClose = () => {
            this.setState({ openDialogue: false })
        };

        const handleSummaryClose = () => {
            this.setState({ summaryDialogue: false })
        };

        const handle2MLAClose = () => {
            this.setState({ open3MLookahead: false })
        };

        const handleopenAssignClose = () => {
            this.setState({ openAssign: false })
        };

        const handleOverheadsClose = () => {
            this.setState({ overheadsDialog: false })
        };

        const handleClose = () => {
            this.setState({ openNewUserReq: false, });
        };


        return (
            <div>
                {this.state.isLoading ? <LoopCircleLoading color={getPrimaryColor()} /> :
                    <Container maxWidth={false} disableGutters>
                        <Row>
                            {(this.state.data.total_active_task / this.state.data.total_team_members) > 10 ?
                                <Box margin={'7px'} style={{ width: '100%' }}>
                                    <Alert severity="warning">Number of open tasks is high. Please review and update the completed tasks through EM</Alert>
                                </Box> : null}
                            <Box marginTop={'10px'} style={{ width: '100%' }}>
                                <Tasks ref={this.taskref} taskAllocation={this.taskAllocation} data={this.state.data ? this.state.data : null} is_teamtasks={this.props.is_teamtasks} refreshData={this.refreshData} refreshSubTender={this.refreshSubTender} />
                            </Box>
                        </Row>

                        <Row>
                            <Col md={3}>
                                <LevelResources data={this.state.data ? this.state.data : null} launchAuto={this.launchAuto} handleButton={this.handleButton} />
                            </Col>

                            <Col md={9} >
                                <Grid container direction={'row'}>

                                    <Box style={{ width: '100%', height: '100%' }}>
                                        <TeamTaskInfo ref={this.progress} show_profile={true} data={this.state.data ? this.state.data : null} handleClick={this.handleClick} />
                                    </Box>

                                    <Box marginTop={'20px'} style={{ width: '100%' }}>
                                        <TeamMembers data={this.state.data ? this.state.data.team_member_details : null} />
                                    </Box>

                                </Grid>
                            </Col>
                        </Row>
                    </Container>
                }

                <Dialog fullWidth={true} maxWidth='sm' onClose={handleDialogueClose} aria-labelledby="customized-dialog-title" open={this.state.openDialogue} TransitionComponent={Transition}>
                    <DialogTitle id="customized-dialog-title" onClose={handleDialogueClose}>
                        Select Team
                    </DialogTitle>
                    <DialogContent dividers>
                        {this.state.data ?
                            <Box flexDirection="column" display="flex" marginLeft='10px' alignItems={'center'} >
                                {this.state.data.teams.map((value, i) =>
                                    <ListItem button key={value} onClick={() => this.selectedTeam(value)} >
                                        <ListItemAvatar>
                                            <Avatar >
                                                <GroupIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={value.name} />
                                    </ListItem>
                                )}
                            </Box>
                            : null}

                    </DialogContent>
                    <DialogActions>
                        <Box display="flex" justifyContent="center" marginTop="5px" marginBottom="10px">
                            <Button size='small' color='primary' style={{ marginLeft: '5px', marginRight: '5px', }} onClick={handleDialogueClose}>close</Button>
                        </Box>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.openNewUserReq}
                    TransitionComponent={Transition}
                    keepMounted
                    fullWidth
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title"> <Box display={'flex'} flexDirection={'row'} width={'100%'}>
                        <Box flexGrow={1}>
                            <Typography variant='h5'>New User Request</Typography>
                        </Box>
                        <Box>
                            <Link
                                component="button"
                                variant="body2"
                                onClick={() => {
                                    window.open('https://faqs.bbparam.com/paramfaqs/paramforms')
                                }}
                                color={getMode() ? 'secondary' : 'primary'}
                            >
                                FAQ's
                            </Link>
                        </Box>
                    </Box></DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            Careful while entering the data because email field can't be changed later invitation.
                        </DialogContentText>
                        <UserRequestForm dialogClose={this.dialogClose} />
                    </DialogContent>
                </Dialog>

                {this.state.makeTeamDataDialog ?
                    <MakeTeamData
                        value={this.state.assignData}
                        team={this.state.allRoles}
                        rolesid={this.state.allroleid}
                        alluid={this.state.allUid}
                        launchAuto={this.launchAuto}
                        open={this.state.makeTeamDataDialog}
                        onClose={this.makeTeamDataDialogClose}
                    />
                    : null}

                {this.state.taskLevelAllocationHelper ?
                    <TaskAllocationHelper
                        team={this.state.allRoles}
                        roleid={this.state.allroleid}
                        alluid={this.state.allUid}
                        value={taskLevelAllocationData}
                    />
                    : null}

                <Dialog fullWidth onClose={handleSummaryClose} aria-labelledby="customized-dialog-title" maxWidth="xl" open={this.state.summaryDialogue}>
                    <DialogContent dividers>
                        <TeamSummary value={this.state.teamData} />
                    </DialogContent>
                </Dialog>

                <BootstrapDialog fullWidth onClose={handle2MLAClose} aria-labelledby="customized-dialog-title2" maxWidth={false} open={this.state.open3MLookahead}>
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handle2MLAClose}>
                        3 Month Lookahead
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                        <Lookahead3M value={this.state.data ? this.state.data.team_member_details : null} />
                    </DialogContent>
                </BootstrapDialog>

                <Dialog fullScreen open={this.state.openAssign} onClose={handleopenAssignClose} TransitionComponent={Transition}>
                    <AppBar position='relative'>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={handleopenAssignClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" >Task Allocation</Typography>
                        </Toolbar>
                    </AppBar>

                    <TeamUserTaskMap
                        team={this.state.allRoles}
                        roleid={this.state.allroleid}
                        alluid={this.state.allUid}
                    />
                </Dialog>

                <Dialog fullScreen open={this.state.overheadsDialog} onClose={handleOverheadsClose} TransitionComponent={Transition}>
                    <AppBar position='relative'>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={handleOverheadsClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" >Non Billable</Typography>
                        </Toolbar>
                    </AppBar>
                    <OverheadsNonBillableChange />
                </Dialog>

                <TeamTaskAllocation ref={this.teamTaskAllocation} />

                <Dialog maxWidth="sm" open={this.state.openloader} aria-labelledby="form-dialog-title">
                    <DialogContent>
                        <Box m={5} p={2}>
                            <LoopCircleLoading />
                        </Box>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
}

export default withSnackbar(TeamTasks)