import { withSnackbar } from 'notistack'
import React, { Component } from 'react'
import Service from "../Networking/networkutils";
import { getCookie } from '../Helper/CookieHelper';
import { errorMessage } from '../Helper/ErrorMessage';
import MaterialTable from 'material-table'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Switch, TextField, Typography } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Alert, Autocomplete } from '@material-ui/lab'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import moment from 'moment';
import { hourRequestReasonType, HtmlTooltip } from '../Helper/CommonThings';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { CsvBuilder } from 'filefy';


const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));

class LeadHoursRequest extends Component {

    s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
    }

    constructor(props) {
        super(props)

        this.state = {
            showAll: false,
            isLoading: true,
            allAllocated: [],
            allocated: null,
            openRequest: false,
            reason_type: 0,
            selectedRequest: null,
            totalAllocForRole: null,
            allRolesPlanned: [],
            allocatedChecked: false,
            openRejectConfirm: false,
            comment: null,
            hoursUtilizedData: null,
            rejectionReason: '',
            data: []
        }
    }

    componentDidMount() {
        this.fetchOps()
    }

    fetchOps() {
        var url = ''
        if (this.props.lead && this.props.task) {
            url = "/bb/api/user/hours/request/?lead=" + this.props.lead + "&ttid=" + this.props.task + "&status=0"
        } else {
            url = "/bb/api/user/hours/request/?lead=" + user.id + "&team=" + this.props.value.id + "&date_created__gte=" + moment().subtract(3, 'month').format('YYYY-MM-DD HH:mm:ss')
        }
        Service.get(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {

                this.setState({
                    data: res.data,
                    isLoading: false
                })
            })
            .catch(error => {
                // console.log(error)
                errorMessage(this.props, error)
            });
    }

    fetchTeamOps() {
        Service.get("/bb/api/user/hours/request/?team=" + this.props.value.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {

                this.setState({
                    data: res.data,
                    isLoading: false
                })
            })
            .catch(error => {
                // console.log(error)
                errorMessage(this.props, error)
            });
    }

    fetchAllocatedRoles(dataIn) {
        this.setState({
            isLoading: true
        })
        // console.log(dataIn)
        Service.get('/bb/api/tender/user/task/?tid=' + dataIn.ttid + "&uid=" + dataIn.uid, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {

                this.setState({
                    allAllocated: res.data,
                    isLoading: false,
                    allocated: res.data.length === 1 ? res.data[0] : null
                })
                if (res.data.length === 1) {
                    this.getAllocated(res.data[0].tasks_roles_data)
                }

            })
            .catch(error => {
                // console.log(error)
                errorMessage(this.props, error)
            });
    }

    approveAndRequest(roleid) {
        Service.post('/bb/api/tender/hours/request/', {
            headers: {
                Authorization: "Token " + token,
            },
            data: {
                pid: this.props.value.t_tasks_data.wbs.pid_data.id,
                tender_pid: this.props.value.t_tasks_data.wbs.pid_data.subproject_pid,
                upcometender: this.state.project_id.from_tenderbid,
                uid: user.id,
                pdm: this.props.pdmToRecv.id,
                hours_needed: this.state.hours,
                ttid: this.props.value.t_tasks_data.id,
                twbsid: this.props.value.t_tasks_data.wbs.id,
                trolesid: this.props.value.id,
                created_by: user.id
            }
        })
            .then(res => {
                this.props.enqueueSnackbar('Request sent successfully!', {
                    variant: 'success'
                });
                this.fetchData();
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    onRoleChange = (event, values) => {
        this.setState({
            allocated: values,
            allocatedChecked: false
        })
        this.getAllocated(values.tasks_roles_data)
    }

    getAllocated(data) {
        Service.get('/bb/api/tender/user/task/?trid=' + data.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                var alloc = 0
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    alloc += element.planed_hours
                }
                this.setState({
                    totalAllocForRole: alloc,
                    allRolesPlanned: res.data,
                    allocatedChecked: true,
                })
            })
            .catch(error => {
                // console.log(error)
                errorMessage(this.props, error)
            });
    }

    approveData(dataIn) {
        this.setState({
            isLoading: true,
            openRequest: false,
            hoursUtilizedData: null
        })
        const selectedRequest = this.state.selectedRequest
        var postData = {
            "request_id": selectedRequest.id,
            "role_id": dataIn.tasks_roles_data.id,
            "allocation_id": dataIn.id,
            "hours": this.state.hours,
            "reason_type": this.state.reason_type,
            "comment": this.state.comment
        }
        Service.post("/bb/api/user/hours/request/approve/", {
            headers: {
                Authorization: "Token " + token,
            },
            data: postData
        })
            .then(res2 => {

                var stateData = this.state.data
                for (let i = 0; i < stateData.length; i++) {
                    let element = stateData[i];
                    if (element.id === selectedRequest.id) {
                        stateData[i] = res2.data
                        break;
                    }
                }
                this.setState({
                    isLoading: false,
                    data: stateData,
                })
                this.props.enqueueSnackbar('Updated successfully!', {
                    variant: 'success'
                });
            })
            .catch(error => {
                console.log(error)
                if (this.state.showAll) {
                    this.fetchTeamOps()
                } else {
                    this.fetchOps()
                }
                // console.log(error)
                errorMessage(this.props, error)
            });
    }

    rejectData() {

        if (!this.state.rejectionReason) {
            this.props.enqueueSnackbar('Add reason for rejection!', {
                variant: 'warning'
            })
            return
        } else {
            this.setState({
                isLoading: true,
                openRejectConfirm: false,
            })
            const selectedRequest = this.state.selectedRequest
            var postData = {
                "request_id": selectedRequest.id,
                "reason_for_rejection": this.state.rejectionReason
            }
            Service.post("/bb/api/user/hours/request/reject/", {
                headers: {
                    Authorization: "Token " + token,
                },
                data: postData
            })
                .then(res2 => {

                    var stateData = this.state.data
                    for (let i = 0; i < stateData.length; i++) {
                        let element = stateData[i];
                        if (element.id === selectedRequest.id) {
                            stateData[i] = res2.data
                            break;
                        }
                    }
                    this.setState({
                        isLoading: false,
                        data: stateData,
                        rejectionReason: ''
                    })
                    this.props.enqueueSnackbar('Updated successfully!', {
                        variant: 'success'
                    });
                })
                .catch(error => {
                    console.log(error)
                    if (this.state.showAll) {
                        this.fetchTeamOps()
                    } else {
                        this.fetchOps()
                    }
                    // console.log(error)
                    errorMessage(this.props, error)
                });
        }
    }

    handleMail(to, sub, body, reply_to) {
        // console.log(to)
        Service.patch("/bb/api/mail/send_mail", {
            headers: {
                Authorization: "Token " + token,
            },
            data: [{
                sub: sub,
                to: to,
                body: body,
                reply_to: reply_to,
            }],
        })
            .then(res => {

            })
            .catch(error => {
                console.log(error)
                this.props.enqueueSnackbar('Unable to send mail request!', {
                    variant: 'warning'
                });

            });
    }

    usageData(ttid, uid) {
        // console.log(ttid)
        Service.patch("/bb/api/team/tasks/get_utilized_hours/", {
            headers: {
                Authorization: "Token " + token,
            },
            data: {
                uid: uid,
                ttid: ttid
            },
        })
            .then(res => {
                // console.log(res.data)
                this.setState({
                    hoursUtilizedData: res.data
                })
            })
            .catch(error => {
                this.props.enqueueSnackbar('Hours utilized data unavailable.', {
                    variant: 'error'
                });

            });
    }

    downloadExcel() {

        var sheets = ["Hours Request"]
        var sheetData = {}
        var exportData = {}
        sheetData["Hours Request"] = {
            '!ref': "A1:O" + (this.state.data.length + 1),
            '!cols': [
                { wch: 35 }
            ],
            'A1': { t: "s", v: "Project" },
            'B1': { t: "s", v: "DS" },
            'C1': { t: "s", v: "WBS" },
            'D1': { t: "s", v: "Task" },
            'E1': { t: "s", v: "Role" },
            'F1': { t: "s", v: "Hours Requested" },
            'G1': { t: "s", v: "Hours Approved" },
            'H1': { t: "s", v: "Status" },
            'I1': { t: "s", v: "Requested By" },
            'J1': { t: "s", v: "Requested To" },
            'K1': { t: "s", v: "Reason Type" },
            'L1': { t: "s", v: "Reason for Rejection" },
            'M1': { t: "s", v: "Comment" },
            'N1': { t: "s", v: "Reviewed By" },
            'O1': { t: "s", v: "Requested Date" },
        }
        let sheet = sheetData['Hours Request']
        for (let i = 0; i < this.state.data.length; i++) {
            const element = this.state.data[i];
            sheet["A" + (i + 2)] = { t: "s", v: element.tender_data.tender_name }
            sheet["B" + (i + 2)] = { t: "s", v: element.task_data.t_wbs_data.pid_data.name }
            sheet["C" + (i + 2)] = { t: "s", v: element.task_data.t_wbs_data.name }
            sheet["D" + (i + 2)] = { t: "s", v: element.task_data.name }
            sheet["E" + (i + 2)] = { t: "s", v: element.role_data.name }
            sheet["F" + (i + 2)] = { t: "s", v: element.hours_needed }
            sheet["G" + (i + 2)] = { t: "s", v: element.hours_needed }
            sheet["H" + (i + 2)] = { t: "s", v: element.status === 0 ? 'Pending' : element.status === 1 ? 'Waiting for EM Approval' : element.status === 2 ? 'Approved' : element.status === 3 ? 'Rejected' : element.status === 4 ? 'Partially Approved' : null }
            sheet["I" + (i + 2)] = { t: "s", v: element.uid_data.first_name }
            sheet["J" + (i + 2)] = { t: "s", v: element.lead_data.first_name }
            sheet["K" + (i + 2)] = { t: "s", v: element.reason_type === 0 ? 'NA!' : element.status === 1 ? 'Rework - external' : element.status === 2 ? 'Rework - internal' : element.status === 3 ? 'Additional info received' : element.status === 4 ? 'Change in original scope' : element.status === 5 ? 'Incorrect Estimate' : element.status === 6 ? 'Extended work scope' : element.status === 7 ? 'Increase within budget' : null }
            sheet["L" + (i + 2)] = { t: "s", v: element.reason_for_rejection }
            sheet["M" + (i + 2)] = { t: "s", v: element.comment }
            sheet["N" + (i + 2)] = { t: "s", v: element.approved_by_data.first_name }
            sheet["O" + (i + 2)] = { t: "s", v: element.date_created ? moment(element.date_created).format('DD-MM-YYYY') : null }

        }
        exportData = {
            'SheetNames': sheets,
            'Sheets': sheetData
        }
        // console.log(exportData)
        var blob = new Blob([this.s2ab(XLSX.write(exportData, { bookType: 'xlsx', type: 'binary' }))], { type: "application/vnd.ms-excel" });
        saveAs(blob, "Request History - " + moment().format("DD-MM-YYYY") + ".xlsx");
    }

    render() {

        const handleHoursChange = (event) => {
            if (parseFloat(event.target.value) <= 300 || event.target.value === "") {
                this.setState({
                    hours: event.target.value
                })

            } else {
                this.setState({
                    hours: 300
                })
                this.props.enqueueSnackbar('Not more than 100 hours is allowed in single request!', {
                    variant: 'warning'
                });
            }
        };

        const handleRequestClose = () => {
            this.setState({ openRequest: false, hoursUtilizedData: null })
        };

        const handleRejectConfirmClose = () => {
            this.setState({ openRejectConfirm: false, })
        };

        const handleReasonTypeChange = (event) => {
            this.setState({
                reason_type: event.target.value
            })
        };

        const handleCommentChange = (event) => {
            this.setState({
                comment: event.target.value
            })
        };

        const exportCsv = (columnList, initialData) => {
            const columns = columnList.filter(columnDef => {
                return !columnDef.hidden && columnDef.field && columnDef.export !== false;
            });

            const data = initialData.map(rowData =>
                columns.map(columnDef => {
                    return columnDef.render ? columnDef.render(rowData) : columnDef.lookup ? columnDef.lookup[rowData[columnDef.field]] : rowData[columnDef.field];
                })
            );

            const builder = new CsvBuilder("Request History - " + moment().format("DD-MM-YYYY")+ ".csv");
            builder
                .setDelimeter(',')
                .setColumns(columns.map(columnDef => columnDef.title))
                .addRows(data)
                .exportFile();
        };

        return (
            <div style={{ display: 'flex' }}>
                <MaterialTable
                    title={this.props.value ? this.props.value.name : null}
                    columns={[

                        {
                            title: 'Project', field: 'tender_data.tender_name', editable: 'never',
                            headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
                            render: rowData => rowData.tender_data.tender_name
                            // lookup: this.state.projectLookUp
                            // render: rowData => this.props.projectLookUp[rowData.tasks_roles_data.t_tasks_data.t_wbs_data.pid_data.subproject_pid]
                        },
                        {
                            title: 'DS', field: 'task_data.t_wbs_data.pid_data.name', editable: 'never',
                            headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
                            render: rowData => rowData.task_data.t_wbs_data.pid_data.name
                            // lookup: this.state.projectLookUp
                            // render: rowData => this.props.projectLookUp[rowData.tasks_roles_data.t_tasks_data.t_wbs_data.pid_data.subproject_pid]
                        },
                        {
                            title: 'WBS', field: 'task_data.t_wbs_data.name', editable: 'never',
                            headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
                            render: rowData => rowData.task_data.t_wbs_data.name
                            // lookup: this.state.projectLookUp
                            // render: rowData => this.props.projectLookUp[rowData.tasks_roles_data.t_tasks_data.t_wbs_data.pid_data.subproject_pid]
                        },
                        {
                            title: 'Task', field: 'task_data.name', editable: 'never',
                            headerStyle: { minWidth: 250 }, cellStyle: { minWidth: 250 },
                            render: rowData => rowData.task_data && rowData.task_data.decs ? rowData.task_data.decs : rowData.task_data.name
                            // lookup: this.state.projectLookUp
                            // render: rowData => this.props.projectLookUp[rowData.tasks_roles_data.t_tasks_data.t_wbs_data.pid_data.subproject_pid]
                        },
                        {
                            title: 'Role', field: 'role_data.name', editable: 'never',
                            headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 },
                            render: rowData => rowData.role_data.name
                            // lookup: this.state.projectLookUp
                            // render: rowData => this.props.projectLookUp[rowData.tasks_roles_data.t_tasks_data.t_wbs_data.pid_data.subproject_pid]
                        },
                        {
                            title: 'Hours Requested', field: 'hours_needed',
                            // lookup: this.state.projectLookUp
                            // render: rowData => this.props.projectLookUp[rowData.tasks_roles_data.t_tasks_data.t_wbs_data.pid_data.subproject_pid]
                        },
                        {
                            title: 'Hours Approved', field: 'hours_needed', editable: 'never',
                            // lookup: this.state.projectLookUp
                            render: rowData => [1, 2, 4].includes(rowData.status) ? (rowData.pdm_req_data ? ([0, 2].includes(rowData.pdm_req_data.status) ? (rowData.hours_needed - rowData.pdm_req_data.hours_needed) : (rowData.hours_needed)) : rowData.hours_needed) : (null),
                        },
                        {
                            title: 'Status', field: 'status', editable: 'never',
                            lookup: { 0: 'Pending', 1: 'Waiting for EM Approval', 2: 'Approved', 3: 'Rejected', 4: 'Partially Approved' },
                        },
                        {
                            title: 'Requested By', field: 'uid_data.first_name',
                            render: rowData => rowData.uid_data.last_name + ", " + rowData.uid_data.first_name
                            // render: rowData => rowData.uid_data.last_name + ", " + rowData.uid_data.first_name
                        },
                        {
                            title: 'Requested To', field: 'lead_data.first_name',
                            render: rowData => rowData.lead_data.last_name + ", " + rowData.lead_data.first_name
                            // render: rowData => rowData.uid_data.last_name + ", " + rowData.uid_data.first_name
                        },
                        {
                            title: 'Reason Type', field: 'reason_type', editable: 'never',
                            headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 },
                            lookup: { 0: 'NA!', 1: 'Rework - external', 2: 'Rework - internal', 3: 'Additional info received', 4: 'Change in original scope', 5: 'Incorrect Estimate', 6: 'Extended work scope', 7: 'Increase within budget', },
                        },
                        {
                            title: 'Reason for Rejection', field: 'reason_for_rejection', editable: 'never',
                            headerStyle: { minWidth: 150 }, cellStyle: { minWidth: 150 },
                        },
                        {
                            title: 'Comment', field: 'comment', editable: 'never',
                            headerStyle: { minWidth: 200 }, cellStyle: { minWidth: 200 },
                            // lookup: { 0: 'Pending', 1: 'Approved', 2: 'Rejected' },
                        },

                        {
                            title: 'Reviewed By', field: 'approved_by_data.first_name', editable: 'never',
                            hidden: !this.state.showAll,
                            // render: rowData => rowData.approved_by_data ? rowData.approved_by_data.first_name : null
                        },
                        {
                            title: 'Requested Date', field: 'date_created', type: 'date', defaultSort: 'desc', editable: 'never',
                            render: rowData => rowData.date_created ? moment(rowData.date_created).format('DD-MM-YYYY') : null
                            // lookup: this.state.projectLookUp
                            // render: rowData => this.props.projectLookUp[rowData.tasks_roles_data.t_tasks_data.t_wbs_data.pid_data.subproject_pid]
                        },

                    ]}
                    data={this.state.data}
                    isLoading={this.state.isLoading}
                    options={{
                        padding: "dense",
                        // filtering: true,
                        // exportButton: true,
                        doubleHorizontalScroll: true,
                        paging: false,
                        toolbarButtonAlignment: 'left',
                        searchFieldAlignment: 'left',
                        exportButton: { csv: true },
                        exportCsv: (columns, data) => {
                            exportCsv(columns, data)
                        },

                        // actionsColumnIndex: -1,

                    }}
                    // isLoading={this.state.isLoading}
                    actions={[

                        {
                            icon: () => <FormControlLabel
                                style={{ padding: '3px' }}
                                control={
                                    <Switch
                                        checked={this.state.showAll}
                                        // onChange={handleChange}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label="Show All"
                            />,
                            hidden: this.props.value ? this.props.value.lead === 0 : true,
                            tooltip: 'Hour Request',
                            isFreeAction: true,
                            onClick: (event, rowData) => {
                                if (!this.state.showAll) {
                                    this.fetchTeamOps()
                                } else {
                                    this.fetchOps()
                                }
                                this.setState({
                                    isLoading: true,
                                    showAll: !this.state.showAll
                                })
                            }
                        },
                        // {
                        //     icon: 'download',
                        //     tooltip: 'Excel Download',
                        //     isFreeAction: true,
                        //     disabled: this.state.data.length === 0,
                        //     onClick: (event, rowData) => {
                        //        this.downloadExcel()
                        //     }
                        // },
                        rowData => ({
                            icon: 'check',
                            // hidden: this.props.value ? this.props.value.lead === 0 : true,
                            tooltip: 'Approve',
                            disabled: rowData.status !== 0,
                            onClick: (event, rowData) => {
                                this.fetchAllocatedRoles(rowData)
                                this.usageData(rowData.ttid, rowData.uid)
                                this.setState({
                                    selectedRequest: rowData,
                                    openRequest: true,
                                    hours: rowData.hours_needed,
                                    allocated: null,
                                    allAllocated: [],
                                    reason_type: rowData.reason_type,
                                })
                            }
                        }),

                        rowData => ({
                            icon: 'close',
                            // hidden: this.props.value ? this.props.value.lead === 0 : true,
                            tooltip: 'Reject',
                            disabled: rowData.status !== 0,
                            onClick: (event, rowData) => {
                                this.setState({
                                    selectedRequest: rowData,
                                    openRejectConfirm: true
                                })
                            }
                        }),

                    ]}
                />
                <Dialog onClose={handleRequestClose} fullWidth="true" aria-labelledby="customized-dialog-title" open={this.state.openRequest}>
                    <DialogContent dividers>
                        <FormControl variant="outlined" fullWidth style={{ marginTop: '15px' }} >
                            <InputLabel id="demo-simple-select-outlined-label">Reason Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={this.state.reason_type}
                                onChange={handleReasonTypeChange}
                                label="Reason Type"
                            >
                                <MenuItem value={0}></MenuItem>
                                {[1, 2, 3, 4, 5, 6, 7].map((item) =>
                                    <MenuItem value={item}>
                                        <HtmlTooltip
                                            style={{ width: '100%' }}
                                            title={
                                                <React.Fragment>
                                                    <Typography color="inherit">{hourRequestReasonType(item).menu}</Typography>
                                                    {hourRequestReasonType(item).note}
                                                </React.Fragment>
                                            }
                                        >
                                            <Box display="flex">{hourRequestReasonType(item).menu}</Box>
                                        </HtmlTooltip>
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                        <Autocomplete
                            id={"roles"}
                            fullWidth
                            style={{ marginTop: '15px' }}
                            value={this.state.allocated}
                            options={this.state.allAllocated}
                            getOptionLabel={(option) => option.tasks_roles_data.name}
                            getOptionSelected={(option, value) => value !== '' && option.id === value.id}
                            onChange={(e, v) => this.onRoleChange(e, v)}
                            renderInput={(params) => <TextField {...params} label="Select Role" variant="outlined" />}
                        />

                        <TextField
                            id="hours"
                            label="Approving hours"
                            value={this.state.hours}
                            onChange={handleHoursChange}
                            style={{ marginTop: '15px' }}
                            fullWidth
                            type="number"
                            variant="outlined"
                        />
                        {(this.state.hoursUtilizedData) ?
                            <Alert style={{ marginTop: '15px' }} severity="info">
                                <Box display="flex" flexDirection="row" fontSize={14}><Box fontWeight="fontWeightBold">{this.state.hoursUtilizedData.event_hours}</Box>&nbsp;hours from&nbsp;<Box fontWeight="fontWeightBold">{this.state.hoursUtilizedData.allowed_hours}</Box>&nbsp;allocated hours is utilized for this task</Box>
                            </Alert>
                            : null
                        }
                        {this.state.allocated && (this.state.hours > ((this.state.allocated.tasks_roles_data.hours + this.state.allocated.tasks_roles_data.req_hours) - this.state.totalAllocForRole)) ? (
                            <Alert style={{ marginTop: '15px' }} severity="warning">{
                                (((this.state.allocated.tasks_roles_data.hours + this.state.allocated.tasks_roles_data.req_hours) - this.state.totalAllocForRole)) ? (((this.state.allocated.tasks_roles_data.hours + this.state.allocated.tasks_roles_data.req_hours) - this.state.totalAllocForRole) + ' hours is now available under this role will be allocated to the member upon your approval. Additional ' + (this.state.hours - ((this.state.allocated.tasks_roles_data.hours + this.state.allocated.tasks_roles_data.req_hours) - this.state.totalAllocForRole))
                                    + " hours will be sent to EM.")
                                    : (((this.state.allocated.tasks_roles_data.hours + this.state.allocated.tasks_roles_data.req_hours) - this.state.totalAllocForRole) + ' hours is now available under this role. ' + (this.state.hours - ((this.state.allocated.tasks_roles_data.hours + this.state.allocated.tasks_roles_data.req_hours) - this.state.totalAllocForRole))
                                        + " hours will be sent to EM for approval.")
                            }</Alert>
                        ) : null}
                        {this.state.allocated && (this.state.hours > ((this.state.allocated.tasks_roles_data.hours + this.state.allocated.tasks_roles_data.req_hours) - this.state.totalAllocForRole)) ? (
                            <TextField
                                id="outlined-multiline-static"
                                label="Comment (to EM)"
                                multiline
                                style={{ marginTop: '15px' }}
                                fullWidth
                                rows={3}
                                value={this.state.comment}
                                onChange={handleCommentChange}
                                // defaultValue="Default Value"
                                variant="outlined"
                            />
                        ) : null}

                        <Box display="flex" justifyContent="center" m={1} p={1}>
                            <Box>
                                <Button size='small' variant="contained" color="primary"
                                    disabled={(!(this.state.allocatedChecked && this.state.hours && this.state.allocated && this.state.reason_type))
                                        || ((this.state.allocated && (this.state.hours > ((this.state.allocated.tasks_roles_data.hours + this.state.allocated.tasks_roles_data.req_hours) - this.state.totalAllocForRole))) && !this.state.comment)}
                                    onClick={() => this.approveData(this.state.allocated)}
                                >
                                    Approve
                                </Button>

                            </Box>
                        </Box>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.openRejectConfirm}
                    onClose={handleRejectConfirmClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Are you sure? Do you want to reject the request?"}</DialogTitle>
                    <DialogContent>
                        <TextField
                            id="outlined-multiline-static"
                            label="Reason for rejection"
                            multiline
                            rows={4}
                            variant="outlined"
                            fullWidth
                            onChange={(e) => this.setState({ rejectionReason: e.target.value })}
                        />
                    </DialogContent>

                    <DialogActions>
                        <Button size='small' onClick={handleRejectConfirmClose} color="primary">
                            Cancel
                        </Button>
                        <Button size='small' onClick={() => this.rejectData()} color="primary" autoFocus>
                            Reject
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default withSnackbar(LeadHoursRequest)