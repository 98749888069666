import React, { Component } from 'react'
import Service from "../../Networking/networkutils";
import MaterialTable from 'material-table'
import { Box, Button } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import { getCookie } from '../../Helper/CookieHelper';
import ReactGA from 'react-ga';
import { errorMessage } from '../../Helper/ErrorMessage';
import AddIcon from '@material-ui/icons/Add';

const token = getCookie("bb_ts_token");
const access = JSON.parse(getCookie("bb_ts_access"));

class RolesTemplate extends Component {

    constructor(props) {
        super(props)

        this.state = {
            columns: [
                { title: 'ID', field: 'id', editable: 'never', },
                { title: 'Name', field: 'name' },
                {
                    title: 'Grade', field: 'grade',
                    lookup: { 'a': 'A', 'b': 'B', 'c': 'C', 'd': 'D', 'e': 'E', 'f': 'F', 'g': 'G' },
                }
            ],
            data: [],
        }
    }

    componentDidMount() {
        this.fetchOps();
    }


    fetchOps() {

        var pageView = window.location.pathname + "/#Roles";
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);

        Service.get('/bb/api/roles/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res.data)
                this.setState({ data: res.data, })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    patchData() {

        var pageView = window.location.pathname + "/#Roles";
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);

        Service.patch("/bb/api/roles/bulk_update/", {
            headers: {
                Authorization: "Token " + token,
            },
            data: this.state.data,
        })
            .then(res => {
                // console.log(res.data)
                this.fetchOps();
                this.props.enqueueSnackbar('Data saved successfully!', {
                    variant: 'success'
                });
            })
            .catch(error => {
                errorMessage(this.props, error)
            });

    }

    postData(postData) {

        var pageView = window.location.pathname + "/#Roles";
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);

        Service.post("/bb/api/roles/", {
            headers: {
                Authorization: "Token " + token,
            },
            data: postData,
        })
            .then(res => {
                // console.log(res.data)
                this.fetchOps();
                this.props.enqueueSnackbar('Data saved successfully!', {
                    variant: 'success'
                });
            })
            .catch(error => {
                this.fetchOps();
                errorMessage(this.props, error)
            });

    }

    // deleteData(postData) {
    //     Service.delete("/bb/api/roles/"+postData.id, {
    //         headers: {
    //             Authorization: "Token " + token,
    //         },
    //     })
    //         .then(res => {
    //             // console.log(res.data)
    //             this.fetchOps();
    //             this.props.enqueueSnackbar('Data deleted successfully!', {
    //                 variant: 'success'
    //             });
    //         })
    //         .catch(error => {
    //             this.props.enqueueSnackbar('Something went wrong!', {
    //                 variant: 'error'
    //             });
    //         });

    // }


    render() {
        return (
            <div>

                {((access && access.access && access.access.l11a && access.access.l11a.l11a_b) || (access && access.access && access.access.l11a && access.access.l11a.l11a_c)) ? (
                    <Box display="flex" flexDirection="row-reverse" p={1} m={1} >
                        <Button variant="contained" onClick={e => this.patchData()} color="primary" size='small'>Save</Button>
                    </Box>
                ) : (null)}

                <MaterialTable
                    title="Roles"
                    columns={[
                        { title: 'ID', field: 'id', editable: 'never', },
                        { title: 'Name', field: 'name' },
                        {
                            title: 'Grade', field: 'grade',
                            lookup: { 'a': 'A', 'b': 'B', 'c': 'C', 'd': 'D', 'e': 'E', 'f': 'F', 'g': 'G' },
                        }
                    ]}
                    icons={{
                        Add: props => <Button size='small' color='primary' variant="contained" startIcon={<AddIcon />}>
                            Add Role
                        </Button>
                    }}
                    data={this.state.data}
                    options={{
                        padding: "dense",
                        pageSize: 100,
                        pageSizeOptions: [50, 100, 200],
                        addRowPosition: "first",
                    }}
                    editable={
                        ((access && access.access && !access.access.l11a.l11a_b) && (access && access.access && access.access.l11a.l11a_c)) ? (
                            {

                                onRowUpdate: (newData, oldData) =>
                                    new Promise(resolve => {
                                        setTimeout(() => {
                                            resolve();
                                            if (oldData) {
                                                this.setState(prevState => {
                                                    const data = [...prevState.data];
                                                    data[data.indexOf(oldData)] = newData;
                                                    return { ...prevState, data };
                                                });
                                            }
                                        }, 600);
                                    }),
                                // onRowDelete: oldData =>
                                //     new Promise(resolve => {
                                //         setTimeout(() => {
                                //             resolve();
                                //             this.setState(prevState => {
                                //                 const data = [...prevState.data];
                                //                 data.splice(data.indexOf(oldData), 1);
                                //                 this.deleteData(oldData)
                                //                 return { ...prevState, data };
                                //             });
                                //         }, 600);
                                //     }),
                            }
                        ) : ((access && access.access && access.access.l11a.l11a_b) && (access && access.access && !access.access.l11a.l11a_c)) ? (
                            {
                                onRowAdd: newData =>
                                    new Promise(resolve => {
                                        setTimeout(() => {
                                            resolve();
                                            this.setState(prevState => {
                                                const data = [...prevState.data];
                                                data.push(newData);
                                                this.postData(newData)
                                                return { ...prevState, data };
                                            });
                                        }, 600);
                                    }),

                            }
                        ) : ((access && access.access && access.access.l11a.l11a_b) && (access && access.access && access.access.l11a.l11a_c)) ? (
                            {
                                onRowAdd: newData =>
                                    new Promise(resolve => {
                                        setTimeout(() => {
                                            resolve();
                                            this.setState(prevState => {
                                                const data = [...prevState.data];
                                                data.push(newData);
                                                this.postData(newData)
                                                return { ...prevState, data };
                                            });
                                        }, 600);
                                    }),
                                onRowUpdate: (newData, oldData) =>
                                    new Promise(resolve => {
                                        setTimeout(() => {
                                            resolve();
                                            if (oldData) {
                                                this.setState(prevState => {
                                                    const data = [...prevState.data];
                                                    data[data.indexOf(oldData)] = newData;
                                                    return { ...prevState, data };
                                                });
                                            }
                                        }, 600);
                                    }),
                                // onRowDelete: oldData =>
                                //     new Promise(resolve => {
                                //         setTimeout(() => {
                                //             resolve();
                                //             this.setState(prevState => {
                                //                 const data = [...prevState.data];
                                //                 data.splice(data.indexOf(oldData), 1);
                                //                 this.deleteData(oldData)
                                //                 return { ...prevState, data };
                                //             });
                                //         }, 600);
                                //     }),
                            }
                        ) : (null)}
                />
            </div>
        )
    }
}

export default withSnackbar(RolesTemplate);