import React, { Component } from 'react'
import MaterialTable from 'material-table'
import Service from "../../Networking/networkutils";
import { withSnackbar } from 'notistack';
import { LoopCircleLoading } from 'react-loadingg';
import moment from 'moment';

import { errorMessage } from '../../Helper/ErrorMessage';

// import Moment from 'react-moment';
import { getCookie } from '../../Helper/CookieHelper';
import ReactGA from 'react-ga';
import { getPrimaryColor } from '../../Helper/CommonThings';
var momentRange = require('moment-range');
momentRange.extendMoment(moment);

const user = JSON.parse(getCookie("bb_ts_user"));
// const access = JSON.parse(getCookie("bb_ts_access"));
const token = getCookie("bb_ts_token");

class ManageTimeSheet extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: [],
            open: false,
            isLoading: true,
            selectDate: new Date(),
            checkedB: true,
            tabledata: [],
        }
    }


    componentDidMount() {
        var pageView = window.location.pathname;
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);
        this.fetchOps();
    }

    fetchOps() {
        Service.get('/bb/api/tender/approval/?approve_by=' + user.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({
                    data: res.data,
                    tabledata: res.data,
                    isLoading: false,
                });
                if (res.data.length > 0) {
                    var emails = null
                    for (let i = 0; i < res.data.length; i++) {
                        const element = res.data[i];
                        if (emails) {
                            emails += "," + element.created_by
                        } else {
                            emails = element.created_by
                        }
                    }

                    Service.get(`/bb/api/users/?email=` + emails, {
                        headers: {
                            Authorization: "Token " + token,
                        },
                    })
                        .then(res1 => {
                            var lookup = {}
                            for (let i = 0; i < res1.data.length; i++) {
                                const element = res1.data[i];
                                lookup[element.email] = element.last_name + ", " + element.first_name
                            }
                            this.setState({ usersData: lookup })

                        })
                        .catch(error => {
                            errorMessage(this.props, error)
                        });
                }

            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }


    render() {

        return (
            <div>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                    <div>

                        <MaterialTable
                            title="Tender Approval"
                            columns={[
                                { title: 'Tender', field: 'tender_pid_data.tender_name' },
                                {
                                    title: 'Requested By', field: 'created_by',
                                    lookup: this.state.usersData
                                }, {
                                    title: 'Requested Date', field: 'date_created',
                                    render: rowData => moment(rowData.date_created).format("DD-MM-YYYY")
                                }, {
                                    title: 'Status', field: 'status', filtering: false,
                                    lookup: { 0: 'Pending', 1: 'Approved', 2: 'Rejected' },
                                }, {
                                    title: 'Approved On', field: 'approved_on',
                                    render: rowData => rowData.approved_on ? moment(rowData.approved_on).format("DD-MM-YYYY") : ""
                                }
                                // {
                                //     title: 'Approver', field: 'to_date',
                                //     render: rowData => 
                                // },
                            ]}
                            data={this.state.tabledata}
                            actions={[
                                rowData => ({
                                    icon: 'launch',
                                    tooltip: 'Detail View',
                                    onClick: (event, rowData) => { window.open("/tender/approval/" + rowData.id) },
                                }),
                            ]}
                            options={{
                                padding: "dense",
                                pageSize: 20,
                                pageSizeOptions: [10, 20, 50],
                                filtering: true,
                                actionsColumnIndex: -1
                            }}
                        />
                    </div>
                )}
            </div>

        )
    }
}

export default withSnackbar(ManageTimeSheet);