import MaterialTable from 'material-table';
import { withSnackbar } from 'notistack'
import React, { Component } from 'react'
import { getCookie } from '../Helper/CookieHelper';
import { errorMessage } from '../Helper/ErrorMessage';
import Service from '../Networking/networkutils';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { CsvBuilder } from 'filefy';

const token = getCookie("bb_ts_token");

class EMReqHis extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      data: []
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData() {
    Service.get("/bb/api/tender/hours/request/?uid=" + this.props.uid + "&ttid=" + this.props.value.id, {
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then(res => {
        this.setState({
          data: res.data,
          isLoading: false,
        })
      })
      .catch(error => {
        // console.log(error)
        errorMessage(this.props, error)
      });
  }

  putData(data) {
    // console.log(data)
    Service.get("/bb/api/tender/hours/request/" + data.id + "/", {
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then(res => {
        if (res.data.status === 0) {
          Service.put("/bb/api/tender/hours/request/" + data.id + "/", {
            headers: {
              Authorization: "Token " + token,
            },
            data: data,
          })
            .then(res => {
              this.props.enqueueSnackbar('Updated Successfully!', {
                variant: 'success'
              });
            })
            .catch(error => {
              // console.log(error)
              errorMessage(this.props, error)
            });
        } else {
          this.props.enqueueSnackbar('Failed!', {
            variant: 'error'
          });
        }

      })
      .catch(error => {
        // console.log(error)
        errorMessage(this.props, error)
      });
  }

  downloadExcel() {

    var sheets = ["Request History"]
    var sheetData = {}
    var exportData = {}
    sheetData["Request History"] = {
      '!ref': "A1:O" + (this.state.data.length + 1),
      '!cols': [
        { wch: 35 }
      ],
      'A1': { t: "s", v: "Hours" },
      'B1': { t: "s", v: "Reason Type" },
      'C1': { t: "s", v: "Comment" },
      'D1': { t: "s", v: "Requested Date" },
      'E1': { t: "s", v: "Status" },
      'F1': { t: "s", v: "Approved By" },
    }
    let sheet = sheetData['Hours Request']
    for (let i = 0; i < this.state.data.length; i++) {
      const element = this.state.data[i];
      sheet["A" + (i + 2)] = { t: "s", v: element.hours_needed }
      sheet["B" + (i + 2)] = { t: "s", v: element.reason_type === 0 ? 'NA!' : element.status === 1 ? 'Rework - external' : element.status === 2 ? 'Rework - internal' : element.status === 3 ? 'Additional info received' : element.status === 4 ? 'Change in original scope' : element.status === 5 ? 'Incorrect Estimate' : element.status === 6 ? 'Extended work scope' : element.status === 7 ? 'Increase within budget' : null }
      sheet["C" + (i + 2)] = { t: "s", v: element.comment }
      sheet["D" + (i + 2)] = { t: "s", v: element.date_created ? moment(element.date_created).format('DD-MM-YYYY') : null }
      sheet["E" + (i + 2)] = { t: "s", v: element.status === 0 ? 'Pending' : element.status === 1 ? 'Waiting for EM Approval' : element.status === 2 ? 'Approved' : element.status === 3 ? 'Rejected' : element.status === 4 ? 'Partially Approved' : null }
      sheet["F" + (i + 2)] = { t: "s", v: element.approved_by_data.first_name }
    }
    exportData = {
      'SheetNames': sheets,
      'Sheets': sheetData
    }
    // console.log(exportData)
    var blob = new Blob([this.s2ab(XLSX.write(exportData, { bookType: 'xlsx', type: 'binary' }))], { type: "application/vnd.ms-excel" });
    saveAs(blob, "Request History - " + moment().format("DD-MM-YYYY") + ".xlsx");
  }

  render() {

    const exportCsv = (columnList, initialData) => {
      const columns = columnList.filter(columnDef => {
        return !columnDef.hidden && columnDef.field && columnDef.export !== false;
      });

      const data = initialData.map(rowData =>
        columns.map(columnDef => {
          return columnDef.render ? columnDef.render(rowData) : columnDef.lookup ? columnDef.lookup[rowData[columnDef.field]] : rowData[columnDef.field];
        })
      );

      const builder = new CsvBuilder("Request History - " + moment().format("DD-MM-YYYY") + ".csv");
      builder
        .setDelimeter(',')
        .setColumns(columns.map(columnDef => columnDef.title))
        .addRows(data)
        .exportFile();
    };

    return (
      <div>
        <MaterialTable
          title={"Request History for " + (this.props.value.decs ? this.props.value.decs : this.props.value.name)}
          columns={[
            {
              title: 'Hours', field: 'hours_needed',
              // lookup: this.state.projectLookUp
              // render: rowData => this.props.projectLookUp[rowData.tasks_roles_data.t_tasks_data.t_wbs_data.pid_data.subproject_pid]
            },
            {
              title: 'Reason Type', field: 'reason_type', editable: 'never',
              lookup: { 0: 'NA!', 1: 'Rework - external', 2: 'Rework - internal', 3: 'Additional info received', 4: 'Change in original scope', 5: 'Incorrect Estimate', 6: 'Extended work scope', 7: 'Increase within budget', },
            },
            {
              title: 'Comment', field: 'comment', editable: 'never',
              // lookup: this.state.projectLookUp
              // render: rowData => this.props.projectLookUp[rowData.tasks_roles_data.t_tasks_data.t_wbs_data.pid_data.subproject_pid]
            },
            {
              title: 'Requested Date', field: 'date_created', type: 'date', defaultSort: 'desc', editable: 'never',
              // lookup: this.state.projectLookUp
              // render: rowData => this.props.projectLookUp[rowData.tasks_roles_data.t_tasks_data.t_wbs_data.pid_data.subproject_pid]
            },

            {
              title: 'Status', field: 'status', editable: 'never',
              lookup: { 0: 'Pending', 1: 'Approved', 2: 'Rejected' },
            },
            {
              title: 'Approved By', field: 'approved_by_data.first_name', editable: 'never',
              render: rowData => rowData.approved_by_data ? rowData.approved_by_data.first_name : null
            },

          ]}
          data={this.state.data}
          isLoading={this.state.isLoading}
          options={{
            padding: "dense",
            doubleHorizontalScroll: true,
            paging: false,
            exportCsv: (columns, data) => {
              exportCsv(columns, data)
            },
            exportButton: { csv: true },

          }}
          // actions={[
          //   {
          //     icon: 'download',
          //     tooltip: 'Excel Download',
          //     isFreeAction: true,
          //     disabled: this.state.data.length === 0,
          //     onClick: (event, rowData) => {
          //       this.downloadExcel()
          //     }
          //   }
          // ]}
          editable={{
            isEditable: rowData => rowData.status === 0,
            onRowUpdate: (newData, oldData) =>
              new Promise(resolve => {
                if (newData.hours_needed) {
                  if (parseFloat(newData.hours_needed) <= 100) {
                    this.putData(newData)
                    setTimeout(() => {
                      resolve();
                      if (oldData) {
                        this.setState(prevState => {
                          const data = [...prevState.data];
                          data[data.indexOf(oldData)] = newData;
                          return { ...prevState, data };
                        });
                      }
                    }, 60);
                  } else {
                    setTimeout(() => {
                      resolve();
                      if (oldData) {
                        this.setState(prevState => {
                          const data = [...prevState.data];
                          data[data.indexOf(oldData)] = oldData;
                          return { ...prevState, data };
                        });
                      }
                    }, 60);
                    this.props.enqueueSnackbar('Not more than 100 hours is allowed in single request!', {
                      variant: 'warning'
                    });
                  }
                } else {
                  this.props.enqueueSnackbar("Request hours can't be empty!", {
                    variant: 'warning'
                  });
                }

              }),
          }}
        />
      </div>
    )
  }
}

export default withSnackbar(EMReqHis)
