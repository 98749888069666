import React, { Component } from 'react'
import MaterialTable from 'material-table'
import Service from "../../Networking/networkutils";
import { withSnackbar } from 'notistack';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Slide from '@material-ui/core/Slide';
import { LoopCircleLoading } from 'react-loadingg';
import { withStyles, ThemeProvider, Button, Box, createTheme, TextField, DialogTitle, DialogContentText, DialogContent, DialogActions } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { blue } from '@material-ui/core/colors';
import moment from "moment";
import ManageWBS from '../../Manage/TenderManage/ManageWBS';
import { getCookie } from '../../Helper/CookieHelper';
import TenderSubApproval from './TenderSubApproval';
import BalfourBeattyHeader from '../../CommonTips/BalfourBeattyHeader';
import ReactGA from 'react-ga';
import { errorMessage } from '../../Helper/ErrorMessage';
import { getDarkMode, getPrimaryColor } from '../../Helper/CommonThings';

const useStyles = (theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
});

const bbColorTheme = createTheme({
    palette: {
        primary: {
            main: '#00b300',
            contrastText: '#fff',
        },
        secondary: {
            main: '#cca300',
            contrastText: '#fff',
        },
        error: {
            main: "#ff0000",
            contrastText: '#fff',
        },
        type: getDarkMode(),
    },
})

const bbColorThemered = createTheme({
    palette: {
        primary: {
            main: "#ff0000",
            contrastText: '#fff',
        },
        secondary: {
            main: '#cca300',
            contrastText: '#fff',
        },
        error: {
            main: "#ff0000",
            contrastText: '#fff',
        },
        type: getDarkMode(),
    },
})

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));

// var dialogmsg = "";
var submitdata = null;
var proj_ids = []
class TenderProjects extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: [],
            dataAll: [],
            rates: [],
            data2: [],
            open: false,
            isLoading: true,
            isLoading2: true,
            isLoading4: true,
            allData: 'null',
            openEdit: false,
            openCalc: false,
            key: 0,
            subproj: [],
            updateNeed: false,
            fullData: [],
            approveStat: 0,
            comments: '',
            textDialogData: '',
            openText: false,
            dialogTitle: '',
        }
    }

    componentDidMount() {

        if (!getCookie("bb_ts_token")) {
            window.location.href = '/'
        }

        var pageView = window.location.pathname;
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);
        // console.log(user)
        if (user) {
            this.fetchOps();
        } else {
            this.props.enqueueSnackbar('Unauthorized user!', {
                variant: 'error'
            });
            window.location.href = '/'
        }
    }

    fetchOps() {
        Service.get('/bb/api/tender/approval/' + this.props.match.params.appid + "/", {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                var dataD = [];
                if (res.data.approve_by === user.id) {

                    for (let i = 0; i < res.data.projects.length; i++) {
                        const element = res.data.projects[i];
                        // console.log(element.tenderbid_data)
                        if (element.from_tenderbid && !element.is_subproject) {
                            element.name = element.tender_name;
                            dataD.push(element)
                        }
                        // else if (!element.is_subproject && element.tenderbid_data && element.tenderbid_data.lead_dicipline === 'edc') {
                        //     // console.log(element)
                        //     if (element.from_tenderbid && (element.tenderbid_data.bid_lead === user.id || element.tenderbid_data.lde === user.id || element.tenderbid_data.pdm === user.id || element.tenderbid_data.lead_engineer === user.id)) {
                        //         element.name = element.tender_name;
                        //         dataD1.push(element)
                        //     } else if (user.is_admin) {
                        //         dataD1.push(element)
                        //     }

                        // }

                        proj_ids.push(element.id)

                    }

                } else {
                    this.props.enqueueSnackbar('Unauthorized user!', {
                        variant: 'error'
                    });
                    window.location.href = '/'
                }

                Service.patch('/bb/api/tender/rate/get_data/', {
                    headers: {
                        Authorization: "Token " + token,
                    },
                    data: proj_ids
                })
                    .then(res1 => {
                        // console.log(res.data)
                        if (res.data.length === 0) {
                            this.props.enqueueSnackbar('Goto Rates tab to initiate default rates!', {
                                variant: 'warning'
                            });
                        } else {

                            this.setState({
                                isLoading2: false,
                                rates: res1.data,
                            });
                        }

                        Service.patch('/bb/api/tender/tasks/rolesnodata/get_data/', {
                            headers: {
                                Authorization: "Token " + token,
                            },
                            data: proj_ids
                        })
                            .then(res2 => {
                                this.setState({
                                    data: dataD,
                                    dataAll: res.data.projects,
                                    comments: res.data.comment_reply,
                                    fullData: res.data,
                                    isLoading: false,
                                    updateNeed: false,
                                    approveStat: res.data.status,
                                });
                                // console.log(res.data)
                                this.setState({ data2: JSON.parse(JSON.stringify(res2.data)), isLoading4: false, });
                            })
                            .catch(error => {
                                errorMessage(this.props, error)
                            });

                    })
                    .catch(error => {
                        errorMessage(this.props, error)
                    });



            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });

    }

    giveaccess() {

        var json = {
            email: submitdata.email,
            is_staff: true,
            is_lead: true,
            is_admin: !submitdata.is_admin,
        }

        Service.put("/bb/api/tender/project/" + submitdata.id + "/", {
            headers: {
                Authorization: "Token " + token,
            },
            data: json,
        })
            .then(res => {

                this.setState({ open: false, });
                // console.log(res.data)
                this.props.enqueueSnackbar('Permission changed for ' + submitdata.email, {
                    variant: 'success'
                });
                this.fetchOps();
            })
            .catch(error => {
                // console.log(error)
                errorMessage(this.props, error)
            });
    }

    costCalc(rowData) {
        var cost = 0;
        for (let i = 0; i < this.state.data2.length; i++) {
            const element = this.state.data2[i];
            if (element.t_pid === rowData.id) {
                cost = cost + this.costCalc2(element)
            }
        }
        for (let i = 0; i < this.state.dataAll.length; i++) {
            const element = this.state.dataAll[i];
            // console.log(element.is_subproject +'   '+ element.subproject_pid +'   '+ rowData.id)
            if (element.is_subproject && element.subproject_pid === rowData.id) {
                for (let j = 0; j < this.state.data2.length; j++) {
                    const element1 = this.state.data2[j];
                    if (element1.t_pid === element.id) {
                        cost = cost + this.costCalc2(element1)
                    }
                }
            }
        }
        return new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(cost);
    }

    costCalc2(data1) {
        if (data1.country === 'in' || data1.country === 'sl') {
            for (let i = 0; i < this.state.rates[0].t_rates.length; i++) {
                const element = this.state.rates[0].t_rates[i];
                if (data1.grade.toUpperCase() === element.grade) {
                    // console.log(data1.no_off + "  " + data1.hours + " " + element.costin)
                    return data1.hours * element.costin;
                }
            }
        } else {
            for (let i = 0; i < this.state.rates[0].t_rates.length; i++) {
                const element = this.state.rates[0].t_rates[i];
                if (data1.grade.toUpperCase() === element.grade) {
                    return data1.hours * element.costuk;
                }
            }
        }
        return 0;
    }

    hourCalc(rowData) {
        var cost = 0;
        for (let i = 0; i < this.state.data2.length; i++) {
            const element = this.state.data2[i];
            if (element.t_pid === rowData.id) {
                cost = cost + parseFloat(element.hours);
            }
        }
        for (let i = 0; i < this.state.dataAll.length; i++) {
            const element = this.state.dataAll[i];
            // console.log(element.is_subproject +'   '+ element.subproject_pid +'   '+ rowData.id)
            if (element.is_subproject && element.subproject_pid === rowData.id) {
                for (let j = 0; j < this.state.data2.length; j++) {
                    const element1 = this.state.data2[j];
                    if (element1.t_pid === element.id) {
                        cost = cost + parseFloat(element1.hours);
                    }
                }
            }
        }
        return cost.toFixed(2);
    }

    dateParse(date) {
        var formatDate = moment(date).format('DD/MM/YYYY');
        // console.log(formatDate)
        if (formatDate === 'Invalid date') {
            return 'NA';
        } else {
            return formatDate;
        }
    }

    changeStatus(dataCh) {
        Service.patch("/bb/api/tender/project/bulk_update/", {
            headers: {
                Authorization: "Token " + token,
            },
            data: dataCh,
        })
            .then(res => {
                Service.put('/bb/api/tender/bid/' + dataCh[0].from_tenderbid, {
                    headers: {
                        Authorization: "Token " + token,
                    },
                    data: { status: dataCh[0].audit_status },
                })
                    .then(res1 => {
                        // console.log(res1.data)
                        this.setState({ isLoading: false, allData: res.data, });
                        // window.location.href = '/estimate'

                    })
                    .catch(error => {
                        this.fetchOps()
                        this.setState({ isLoading: false })
                        errorMessage(this.props, error)
                    });

            })
            .catch(error => {

                this.setState({ isLoading: false })
                this.fetchOps()
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    approve() {

        if (!(!this.state.comments || this.state.comments === '' || this.state.comments === undefined || this.state.comments === null)) {
            this.setState({ isLoading: true })

            var dataAll = this.state.dataAll;
            if (dataAll[0].audit_status === 1) {
                dataAll[0].audit_status = 10;
            } else if (dataAll[0].audit_status === 5) {
                dataAll[0].audit_status = 10;
            }
            dataAll[0].approved_by = user.id;

            for (let i = 1; i < dataAll.length; i++) {
                const element = dataAll[i];
                if (element.audit_status === 1) {
                    element.audit_status = 10
                } else if (element.audit_status === 5) {
                    element.audit_status = 10
                }
                element.approved_by = user.id;
            }

            Service.put("/bb/api/tender/approval/" + this.state.fullData.id, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: {
                    tender_pid: this.state.fullData.tender_pid,
                    upcometender_id: this.state.fullData.upcometender_id,
                    comment_reply: this.state.comments,
                    status: 1,
                    actual_approved_by: user.id,
                    approved_on: new Date(),
                },
            })
                .then(res => {

                    this.setState({ approveStat: 1 })

                    var sub = user.last_name + ", " + user.first_name + " approved " + dataAll[0].name
                    if (dataAll[0].prepared_by_data) {
                        var body = "Hi " + dataAll[0].prepared_by_data.last_name + ", " + dataAll[0].prepared_by_data.first_name + "\n   " + dataAll[0].name + " tender prepared by you is approved by " + user.last_name + ", " + user.first_name
                        this.handleMail(dataAll[0].prepared_by_data.email, sub, body, user.email);
                    }
                    if (dataAll[0].reviewed_by_data) {
                        var body1 = "Hi " + dataAll[0].reviewed_by_data.last_name + ", " + dataAll[0].reviewed_by_data.first_name + "\n   " + dataAll[0].name + " tender reviewed by you is approved by " + user.last_name + ", " + user.first_name
                        this.handleMail(dataAll[0].reviewed_by_data.email, sub, body1, user.email);
                    }

                    this.props.enqueueSnackbar('Approved!', {
                        variant: 'success'
                    });
                    this.changeStatus(dataAll)

                })
                .catch(error => {
                    console.log(error)
                    errorMessage(this.props, error)
                });
        } else {
            this.props.enqueueSnackbar('Please write you comments in the Comment box above!!', {
                variant: 'warning'
            });
        }

    }

    reject() {

        if (!(!this.state.comments || this.state.comments === '' || this.state.comments === undefined || this.state.comments === null)) {
            this.setState({ isLoading: true })

            var dataAll = this.state.dataAll;
            if (dataAll[0].audit_status === 1) {
                dataAll[0].audit_status = 0
            } else if (dataAll[0].audit_status === 5) {
                dataAll[0].audit_status = 4
            }

            for (let i = 1; i < dataAll.length; i++) {
                const element = dataAll[i];
                if (element.audit_status === 1) {
                    element.audit_status = 0
                } else if (element.audit_status === 5) {
                    element.audit_status = 4
                }
            }

            Service.put("/bb/api/tender/approval/" + this.state.fullData.id, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: {
                    tender_pid: this.state.fullData.tender_pid,
                    upcometender_id: this.state.fullData.upcometender_id,
                    comment_reply: this.state.comments,
                    status: 2,
                    actual_approved_by: user.id,
                    approved_on: new Date(),
                },
            })
                .then(res => {

                    this.setState({ approveStat: 2 })

                    var sub = user.last_name + ", " + user.first_name + " rejected " + dataAll[0].name
                    if (dataAll[0].prepared_by_data) {
                        var body = "Hi " + dataAll[0].prepared_by_data.last_name + ", " + dataAll[0].prepared_by_data.first_name + "\n   " + dataAll[0].name + " tender prepared by you is rejected by " + user.last_name + ", " + user.first_name
                        this.handleMail(dataAll[0].prepared_by_data.email, sub, body, user.email);
                    }
                    if (dataAll[0].reviewed_by_data) {
                        var body1 = "Hi " + dataAll[0].reviewed_by_data.last_name + ", " + dataAll[0].reviewed_by_data.first_name + "\n   " + dataAll[0].name + " tender reviewed by you is rejected by " + user.last_name + ", " + user.first_name
                        this.handleMail(dataAll[0].reviewed_by_data.email, sub, body1, user.email);
                    }

                    this.props.enqueueSnackbar('Rejected!', {
                        variant: 'success'
                    });

                    this.changeStatus(dataAll)

                })
                .catch(error => {
                    console.log(error)
                    errorMessage(this.props, error)
                });
        } else {
            this.props.enqueueSnackbar('Please write you comments in the Comment box above!!', {
                variant: 'warning'
            });
        }

    }

    handleMail(to, sub, body, reply_to) {
        Service.patch("/bb/api/mail/send_mail", {
            headers: {
                Authorization: "Token " + token,
            },
            data: [{
                sub: sub,
                to: to,
                body: body,
                reply_to: reply_to,
            }],
        })
            .then(res => {

            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)

            });
    }

    render() {
        const { classes } = this.props;

        const handleClose2 = () => {
            this.setState({ openCalc: false, });
        };
        const handleCloseText = () => {
            this.setState({ openText: false, });
        };

        const handleCommChange = (e) => {
            this.setState({ comments: e.target.value, })
        };

        return (
            <div>
                <BalfourBeattyHeader />
                {(this.state.isLoading || this.state.isLoading2 || this.state.isLoading4) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                    <div>

                        <MaterialTable
                            title="Tenders"
                            columns={[
                                {
                                    title: 'Deliverables Type', field: 'type',
                                    lookup: { 0: 'Budget', 1: 'Change', },
                                },
                                { title: 'Tender Name', field: 'name', headerStyle: { minWidth: 500 }, cellStyle: { minWidth: 500 }, },
                                {
                                    title: 'From', field: 'from_date',
                                    render: rowData => this.dateParse(rowData.from_date)
                                },
                                {
                                    title: 'To', field: 'to_date',
                                    render: rowData => this.dateParse(rowData.to_date)
                                },
                                {
                                    title: 'Budgeted Hours', field: 'hour',
                                    render: rowData => <Typography>{this.hourCalc(rowData)}</Typography>
                                },
                                {
                                    title: 'Budgeted Amount', field: 'amount',
                                    render: rowData => <Typography>{this.costCalc(rowData)}</Typography>
                                },
                            ]}
                            data={this.state.data}

                            options={{
                                padding: "dense",
                                // actionsColumnIndex: -1
                            }}

                            actions={[
                                rowData => ({
                                    icon: 'details',
                                    tooltip: 'Scope Summary',
                                    onClick: (event, rowData) => { this.setState({ dialogTitle: 'Scope Summary', textDialogData: rowData.scope_summary, openText: true, }) },
                                }),
                                rowData => ({
                                    icon: 'assessment',
                                    tooltip: 'Mini Report',
                                    onClick: (event, rowData) => { window.open('/tender/mini/report/' + rowData.id) },
                                }),
                            ]}

                            detailPanel={rowData => {
                                return (
                                    <TenderSubApproval value={rowData} onReload={() => this.setState({ updateNeed: true })} projs={this.state.dataAll} roles={this.state.data2} rates={this.state.rates} />
                                )
                            }}
                        />
                        <TextField
                            id="standard-multiline-static"
                            label="Comments"
                            multiline
                            rows={5}
                            variant="outlined"
                            fullWidth
                            disable
                            style={{ margin: '20px' }}
                            value={this.state.comments}
                            onChange={handleCommChange}
                        />
                        <ThemeProvider theme={bbColorTheme} >

                            {(this.state.approveStat === 1) ? (
                                <Box display="flex" justifyContent="center" m={2} p={2} bgcolor="background.paper">
                                    <ThemeProvider theme={bbColorTheme} ><Typography component={'span'} style={{ marginTop: '25px', marginLeft: '10px', marginRight: '10px' }} color="primary" ><Box fontWeight="fontWeightBold">This Tender version is approved.</Box></Typography></ThemeProvider>
                                </Box>
                            ) : (this.state.approveStat === 2) ? (
                                <Box display="flex" justifyContent="center" m={2} p={2} bgcolor="background.paper">
                                    <ThemeProvider theme={bbColorThemered} ><Typography component={'span'} style={{ marginTop: '25px', marginLeft: '10px', marginRight: '10px' }} color="primary" ><Box fontWeight="fontWeightBold">This Tender version is rejected.</Box></Typography></ThemeProvider>
                                </Box>
                            ) : (
                                <Box display="flex" justifyContent="center" m={2} p={2} bgcolor="background.paper">
                                    <Button
                                        size='small'
                                        variant="contained"
                                        color="primary"
                                        style={{ marginLeft: '10px', marginRight: '10px' }}
                                        onClick={() => this.approve()}
                                    >
                                        Approve
                                    </Button>

                                    <ThemeProvider theme={bbColorThemered} >
                                        <Button
                                            size='small'
                                            variant="contained"
                                            color="primary"
                                            style={{ marginLeft: '10px', marginRight: '10px' }}
                                            onClick={() => this.reject()}
                                        >
                                            Reject
                                        </Button>
                                    </ThemeProvider>
                                </Box>

                            )}
                        </ThemeProvider>
                        <Dialog fullScreen open={this.state.openCalc} onClose={handleClose2} TransitionComponent={Transition}>
                            <AppBar className={classes.appBar}>
                                <Toolbar>
                                    <IconButton edge="start" color="inherit" onClick={handleClose2} aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                    <Typography variant="h6" className={classes.title}>
                                        Summary for {this.state.allData.name}
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            <ManageWBS value={this.state.allData} />
                        </Dialog>


                        <Dialog open={this.state.openText} fullwidth="true" maxWidth="xl" onClose={handleCloseText} aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">{this.state.dialogTitle}</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    {this.state.textDialogData}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button size='small' onClick={handleCloseText} color="primary">
                                    Close
                                </Button>

                            </DialogActions>
                        </Dialog>

                    </div>
                )
                }
            </div>

        )
    }
}

export default withStyles(useStyles)(withSnackbar(TenderProjects));